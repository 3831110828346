import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  defaultGridOptions,
  ClientDataGrid,
} from '@bit/sixsprints.utils.kg-grid';
import { Box, Center, Flex} from '@chakra-ui/react';
import Bro from 'brototype';
import {
  ActionContainer,
  GridButton,
  GridInput,
} from '../../components/misc/grid-ui';
import useNotify from '../../utils/notifications';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import ROUTES from '../routes';
import {
  getDefaultLocationFromRules,
  getDefaultholidayType,
  getDefaultDepartmentFromRules,
  getDefaultDateFromRules,
  getDefaultDatetoFromRules,
  getDefaultApplicableForRules,
} from '../../utils/leave-util';
import PageTitle from '../../components/misc/page-title';

const ManageHoliday = () => {
  const { t } = useI18nContext();
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const toast = useNotify();
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });

 
  var visibilityFilter = {
    yearMarker: {
      filter: new Date().getTime(),
      filterTo: null,
      filterType: 'date',
      type: 'equals',
    },
  };

  const previewCallback = val => {
    setPreviewData(val);
    setIsOpen(true);
  };

  const onSubmitImport = () => {
    setIsOpen(false);
  };

  const transformColDef = fields => {
    var modifiedColDef = fields
      .map(field => {
        const { cellRenderer, ...rest } = field;
        switch (field.colId) {
          case 'slug':
            return null;
          case 'name':
            return {
              ...rest,
              cellRendererFramework: params => {
                return (
                  <Flex justifyContent="flex-start">
                    <Center>
                    <Box
                     
                      w="15px"
                      h="15px"
                      borderRadius="4px"
                      bg={Bro(params).iCanHaz('data').color}
                    ></Box>
                    <Box ml="10px">
                      <Link
                        to={`/home/holiday/edit-holiday/${Bro(params).iCanHaz(
                          'data.slug'
                        )}`}
                        className="link"
                        state={{
                          data: params.data ? params.data : {},
                        }}
                      >
                        {params.value ? params.value : ''}
                      </Link>
                    </Box>
                    </Center>
                  </Flex>
                );
              },
            };
          case 'holidayMasterRule.holidayRuleApplicable':
            return {
              ...rest,
              cellRendererFramework: params => {
                return (
                  <>
                    {getDefaultApplicableForRules(
                      Bro(params).iCanHaz('data.holidayMasterRule')
                    )}
                  </>
                );
              },
            };
          case 'holidayMasterRule.holidayType':
            return {
              ...rest,
              cellRendererFramework: params => {
                return (
                  <>
                    {getDefaultholidayType(
                      Bro(params).iCanHaz('data.holidayMasterRule'),t
                    )}
                  </>
                );
              },
            };

            case 'holidayMasterRule.department':
              return {
                ...rest,
                cellRendererFramework: params => {
                  return (
                    <>
                      {getDefaultDepartmentFromRules(
                        Bro(params).iCanHaz('data.holidayMasterRule'),t
                      )}
                    </>
                  );
                },
              };  

          case 'holidayMasterRule.location':
            return {
              ...rest,
              cellRendererFramework: params => {
                return (
                  <>
                    {getDefaultLocationFromRules(
                      Bro(params).iCanHaz('data.holidayMasterRule'),t
                    )}
                  </>
                );
              },
            };

            case 'fromDate':
            return {
              ...rest,
              cellRendererFramework: params => {
                return (
                  <>
                     {getDefaultDateFromRules(
                      Bro(params).iCanHaz('data.holidayMasterRule'),t
                    )} 
                  </>
                );
              },
            };
            
            case 'toDate':
            return {
              ...rest,
              cellRendererFramework: params => {
                return (
                  <>
                     {getDefaultDatetoFromRules(
                      Bro(params).iCanHaz('data.holidayMasterRule'),t
                    )} 
                  </>
                );
              },
            };


          default:
            return field;
        }
      })
      .filter(d => !!d);
    return modifiedColDef;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const Title = () => (
    <PageTitle style={{mb:4}} title={t('manage_holiday_header')}
   />
  );

  return (
    <Box>
      <DataGrid
        gridProps={{ ...defaultGridOptions, transformColDef, previewCallback }}
        beans={{
          restApi,
          storage,
          i18n: t,
          notificationService: simpleToast,
          // todo: fix default filter functionality in KG Grid and then uncomment defaultFilter
          /* defaultFilter: visibilityFilter, */
        }}
        dataProps={{ collection: 'holidayMaster', csvName: 'holiday-master' }}
        uiComponents={{
          Title:Title,
          ActionContainer,
          Button: GridButton,
          Input: GridInput,
          hideEdit: true,
          hideImport: true,
          hideExport: true,
          addNewBtnText: t('btn_add_holiday'),
          addNewPath: ROUTES.DASHBOARD + ROUTES.HOLIDAY+ ROUTES.ADD_HOLIDAY,
        }}
      />

      {isOpen && (
        <Rodal
          visible={true}
          showCloseButton={false}
          animation="slideRight"
          width={95}
          height={95}
          measure="%"
          className="filter-modal"
          customStyles={{ overflow: 'auto' }}
          style={{ marginBottom: 20 }}
        >
          <ClientDataGrid
            beans={{
              restApi,
              storage,
              i18n: t,
              notificationService: simpleToast,
            }}
            dataProps={{
              collection: 'holidayMaster',
              rowData: previewData,
              onImportSuccessCallback: onSubmitImport,
              hideAddNew: true,
              colWidth: 150,
            }}
            uiComponents={{
              ActionContainer,
              Button: GridButton,
            }}
          />
        </Rodal>
      )}
    </Box>
  );
};

export  default ManageHoliday 
