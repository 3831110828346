const en = {
  login_heading: 'Login',
  nav_log_out: 'Logout',
  nav_profile: 'Profile',
  login_subheading: 'Please login to your account',
  login_form_label_email: 'Email Address',
  login_form_otp: 'OTP',
  login_form_label_password: 'Password',
  login_form_label_new_password: 'New Password',
  login_form_confirm_new_password: 'Confirm New Password',
  login_forgot_password: 'Forgot Password?',
  reset_pass_heading: 'Reset Password',
  forgot_pass_heading: 'Forgot Password',
  set_pass_heading: 'Set Password',
  otp_required: 'OTP is required',
  reset_form_label_password: 'New Password',
  reset_retype__form_label_password: 'Confirm New Password',
  login_submit: 'Log in',
  login_email_invalid: 'Email is invalid',
  form_name_invalid: 'Field is invalid',
  accountNumber_invalid_length_msg: 'Invalid Account Number',
  account_holder_name_required: 'Account holder name is required',
  resume_required: 'Resume is required',
  graduationDegreeURL_required: 'Graduation degree is required',
  login_email_required: 'Email can not be empty',
  login_password_required: 'Password can not be empty',
  login_failed_toast_title: 'Login Failed',
  form_field_required: 'Field is required',
  dependent_details_heading: 'Dependent Details',
  details_subheading: 'Details about yourself',
  emergency_details_heading: 'Emergency Details',
  health_details_heading: 'Health Check-Up',
  last_name_required: 'Last name is required',
  first_name_required: 'First name is required',
  employeeId_prefix_required: 'Employee ID Prefix is required',
  employeeId_required: 'Employee ID is required',
  gender_required: 'Gender is required',
  dob_required: 'Birth date is required',
  mobile_required: 'Mobile number is required',
  department_required: 'Department is required',
  tou_required: 'Type of user is required',
  contract_type_required: 'Contract type is required',
  date_of_joining_required: 'Date of joining is required',
  positive_message: 'only positive numbers are allowed',
  contract_required: 'Contract type is required',
  doj_required: 'Date of joining is required',
  bank_name_required: 'Bank name is required',
  branch_name_required: 'Branch name is required',
  branch_code_required: 'Branch code is required',
  branch_address_required: 'Branch address is required',
  account_type_required: 'Account type is required',
  account_number_required: 'Account number is required',
  employee_name_required: 'Employee name is required',
  phone_number_required: 'Phone number is required',
  employee_address_required: 'Employee Address is required',
  blood_pressure_required: 'Blood pressure is required',
  diabetes_required: 'Diabetes is required',
  blood_group_required: 'Blood group is required',
  allergies_required: 'Allergies is required',
  occupation_required: 'Occupation is required',
  relationship_required: 'Relationship is required',
  postal_code_required: 'Postalcode is required',
  email_required: 'email is required',
  id_required: 'id is required',
  state_required: 'State is required',
  blood_pressure: 'Blood Pressure',
  diabetes: 'Diabetes',
  blood_group: 'Blood Group',
  previous_illness: 'Previous Illness in Past 6 Months (if yes, please share)',
  allergies: 'Allergies',
  medical_certificate: 'Medical Fitness Certificate',
  covid_test: 'Covid 19 Test',
  label_last_name: 'Last Name',
  label_middle_name: 'Middle Name',
  label_first_name: 'First Name',
  label_id_prefix: 'Employee ID Prefix',
  label_emp_id: 'Employee ID',
  label_gender: 'Gender',
  label_birthday: 'Birthday',
  mobile_number: 'Mobile Number',
  label_phone_number: 'Phone Number',
  department: 'Department',
  title: 'Title',
  type_of_contract: 'Type of contract',
  date_of_joc: 'Date of Joining the Company',
  contract_end_date: 'Contract end Date',
  button_text_back: 'Back',
  button_text_save_next: 'SAVE & NEXT',
  button_text_save: 'Save',
  button_add: 'ADD',
  button_remove: 'Remove',
  label_postal_code: 'Postal Code',
  label_state: 'State',
  label_address: 'Address',
  label_relationship: 'Relationship with Employee',
  label_occupation: 'Occupation',
  bank_info_heading: 'Bank Information',
  bank_info_subheading: 'Please provide your bank details',
  label_bank_name: 'Bank Name',
  label_branch_name: 'Branch Name',
  label_branch_code: 'Branch Code',
  label_branch_add: 'Branch Address',
  label_acc_type: 'Account Type',
  label_acc_no: 'Account Number',
  label_emp_name: 'Employee Name',
  label_tsdate: 'Transfer Starting Date',
  label_emp_address: 'Employee Address',
  label_dependent_details_msg: 'Dependent Detail',
  label_emergency_contact_msg1: 'Emergency Contact',
  label_emergency_contact_msg2: ' (in the country of your nationality) ',
  nav_dashboard: 'DASHBOARD',
  nav_user_dashboard: 'USER DASHBOARD',
  nav_employee: 'EMPLOYEE',
  nav_payroll: 'PAYROLL',
  nav_user_management: 'USER MANAGEMENT',
  aggrid_add_btn: 'ADD',
  personal_info_head: 'PERSONAL INFO',
  personal_info: 'Personal Info',
  personal_info_cap: 'PERSONAL INFO',
  personal_info_heading: 'Personal Information',
  document_upload_heading: 'Documents Upload',
  'address_id_proof': 'Any Photo ID proof, Passport/ Voter id',
  'employement_related_document':
    'Employment related documents ( prev documents )',
  'graduation_key': 'Graduation Degree',
  'resume_key': 'Resume',
  document_upload_subheading:
    'Please upload digital photos of the following documents',
  label_doc_type: 'Document Type',
  placeholder_text_gender: 'Select gender',
  gender_type_male: 'MALE',
  gender_type_female: 'FEMALE',
  gender_type_others: 'Others',
  label_phoneticFirstName: 'Phonetic First Name',
  label_phoneticMiddleName: 'Phonetic Middle Name',
  label_phoneticLastName: 'Phonetic Last Name',
  label_fax: 'Fax',
  placeholder_text_acc_type: 'Select option',
  acc_type_current: 'CURRENT',
  acc_type_savings: 'SAVINGS',
  label_ifsc_code: 'IFSC Code',
  label_swift_code: 'Swift Code',
  file_upload_text: 'Choose File',
  placeholder_blood_group: 'Select Blood Group',
  blood_group_a_positive: 'A+',
  blood_group_b_positive: 'B+',
  blood_group_ab_positive: 'AB+',
  blood_group_o_positive: 'O+',
  sidebar_bank_info: 'Bank Information',
  sidebar_emergency: 'Emergency Contact',
  sidebar_dependents: 'Dependents Details',
  sidebar_health_checkup: 'Health Checkup',
  sidebar_doc_upload: 'Other Documents',
  label_email: 'Email',
  label_official_mail: 'Email',
  label_official_designation: 'Designation',
  label_no_file_chosen: 'No File Chosen',
  button_text_choose_file: 'Choose File',
  user_page_title: 'User Management',
  breadcrumb_home: 'Home',
  add_employee_heading: 'Add New Employee',
  welcome: 'Welcome',
  description: 'Description',
  // employee dashboard starts here
  attendance: 'Attendance',
  leaves: 'Leaves',
  team_members: 'Team members',
  my_profile: 'My Profile',
  create_announcement: 'Announcement',
  'announcement_add_btn': 'Add',
  'announcement_add_heading': 'Add Announcement',
  label_title: 'Title',
  title_required: 'Title is required',
  title_invalid: 'Title is invalid',
  label_type_of_announcement: 'Type of Announcement',
  type_of_announcement_required: 'Type of Announcement is required',
  type_of_announcement_invalid: 'Type of Announcement is invalid',
  label_start_date: 'Start Date',
  start_date_required: 'Start Date is required',
  label_end_date: 'End Date',
  end_date_required: 'End Date is required',
  'end_st_start_date_msg': 'End Date must be greater than Start Date',
  label_description: 'Description',
  description_required: 'Description is required',
  add_announcement_success_toast: 'Announcement created successfully',
  // employee dashboard ends here
  'aggrid.import.success': "Sucessfully Imported",
  'aggrid.add.btn': 'Add',
  'aggrid.delete.btn': 'Delete',
  'aggrid.start.edit': 'Start Edit',
  'aggrid.end.edit': 'End Edit',
  'aggrid.export.btn': 'Export',
  'aggrid.import.btn': 'Import',
  'aggrid.reset.all.btn': 'Reset All',
  'aggrid.search.here': 'Search here',
  'aggrid.no.rows.selected.delete': 'Select atleast one row to delete',
  'aggrid.confirm.delete': 'Are you sure you want to delete the selected rows?',
  'aggrid.save.success': 'Success',
  'aggrid.delete.success': 'Row(s) deleted successfully',
  'aggrid.total.rows': 'Total Rows',
  'aggrid.data.import.success': 'Data imported successfully',
  'aggrid.import.loading.btn': 'Loading...',
  'aggrid.column.state.btn': 'Save column state',
  'aggrid.submit.btn': 'Submit',
  'aggrid.cancel.btn': 'Cancel',
  'contract.date.msg': 'Contract end date must be greater than Date of joining',
  //admin dashboard
  Profile_Update_Requests: 'Profile Update Requests',
  Requested_Leaves: 'Requested Leaves',
  Other_Requests: 'Other Requests',
  Add_Employee: 'Add Employee',
  Special_Days: 'Special Days',
  Payroll_Management: 'Payroll Management',
  Employee_Gender: 'Employee Gender',
  Request: 'Request',
  quick_links: 'Quick Links',
  Men: 'Men',
  Women: 'Women',
  update: 'Update',
  blood_pressure_invalid: 'blood pressure is invalid',
  mobile_no_invalid: 'Mobile Number is invalid',
  state_invalid: 'State is invalid',
  address_required: 'Address is required',
  postal_code_invalid: 'Postal code is invalid',
  pass_must_watch: 'Passwords must match',
  phone_no_invalid: 'Phone number is invalid',
  account_no_invalid: 'Account number is invalid',
  ifsc_code_required: 'IFSC code is required',
  branch_name_invalid: 'Branch name is invalid',
  bank_name_invalid: 'Bank name is invalid',
  ifsc_code_invalid: 'IFSC code is invalid',
  branch_address_invalid: 'Branch address is invalid',
  acc_holder_name_invalid: 'Account holder name is invalid',
  occupation_invalid: 'Occupation is invalid',
  relationship_invalid: 'Relationship is invalid',
  last_name_invalid: 'Last name is invalid',
  first_name_invalid: 'First name is invalid',
  employeeId_prefix_invalid: 'Invalid Employee ID Prefix',
  employeeId_invalid: 'Invalid Employee ID',
  designation_invalid: 'Designation is invalid',
  employmentRelatedDocumentURL_required:
    'Employement Related Document is required',
  anyAddressIdproofURL_required: 'Any AddressId Proof is required',
  designation_required: 'Designation is required',
  department_invalid: 'Department is invalid',
  date_invalid: 'Date is invalid',
  label_personal_mail: 'Personal Email',
  dob_invalid: 'Date of birth is invalid',
  personal_info_success_toast: 'Basic Information added successfully',
  bank_info_success_toast: 'Bank Information added successfully',
  label_acc_holder_name: 'Account Holder Name',
  dependent_info_success_toast: 'Dependent details added successfully',
  emergency_info_success_toast: 'Emergency Contact Details added successfully',
  document_upload_success_toast: 'Document uploaded successfully',
  add_employee_success_toast: 'User created successfully',
  label_designation: 'Designation',
  health_info_success_toast: 'Medical Records added successfully',
  reset_pass_success_toast: 'Password Changed Successfully',
  forgot_pass_success_toast_title:
    'Mail has been sent to your official Email Id',
  label_personal_info: 'Personal Information',
  label_bank_info: 'Bank Information',
  label_dependent_info: 'Dependent Details',
  label_emergency_info: 'Emergency Details',
  label_health_info: 'Health Check-Up',
  label_document_info: 'Documents Upload',
  edit_profile_header_text: `'s Profile`,
  'file.delete.success': 'File Deleted Succesfully',
  'no.data.msg': 'No Data',
  toast_mess_file_format_error: 'File Format not supported',
  toast_desc_file_format_error: 'Only pdf, png, jpeg, jpg files supported',
  personal_info_updated:'Basic Information Updated',
  health_info_updated:'Medical Records Updated',
  emergency_info_updated:'Emergency Contact Details Updated',
  dependent_info_updated:'Dependent Details Updated',
  bank_info_updated:'Bank Information Updated',
  document_updated_successfully_toast:'Documents details updated Successfully',
  document_upload_successfully_toast:'Document uploaded successfully',
  // leave
  nav_hr_payroll: 'LEAVE',
  sub_nav_leave: 'Leave',
  btn_apply_leave: 'Apply for Leave',
  btn_cancel: 'Cancel',
  btn_submit: 'Submit',

  apply_leave_success_toast: 'Leave Applied Successfully',
  label_leave_type: 'Leave Type',
  leave_type_required: 'Leave Type is required',
  leave_type_invalid: 'Leave Type is invalid',
  label_day_type: 'Day Type',
  day_type_required: 'Day Type is required',
  day_type_invalid: 'Day Type is invalid',
  label_from: 'From',
  from_date_required: 'From Date is required',
  label_to: 'To',
  to_date_required: 'To Date is required',
  'to.lt.from.date.msg': 'To date cannot be less than From date',
  label_reason: 'Reason',
  reason_required: 'Reason is required',
  'from_not_equal_to_date_msg': 'From date and To Date should be same',

  heading_leave_description: 'Leave Description',
  label_status: 'Status',
  label_comment: 'Comment',
  btn_approve: 'Approve',
  btn_reject: 'Reject',
  leave_status_success_toast: 'Leave Status updated successfully.',




  // leave master

  add_leave_master_heading: 'Add Leave Type',
  label_leave_master_name: 'Name',
  label_leave_master_active: 'Active',
  label_include_in_leave_balance: 'Include in Leave Balance',
  label_leave_year: 'Leave Year',
  heading_applicable_scenarios: 'Applicable Scenarios',
  label_scenario: 'Scenario',
  label_pay_type: 'Pay Type',
  label_leave_count: 'Default Allowance',
  label_rollover_count: 'Allowed Rollover Leave Count',
  label_negative_leave_balance_allowed: 'Allowed Negative Leaves',
  label_overall_capping: 'Maximum Leave Capping',
  label_rule_applicable: 'Applicable For',
  label_department: 'Department',
  label_location: 'Location',
  label_leave_cycle_period: 'Leave Cycle Period',
  btn_create_leave_master: 'Create Leave',
  all:"All",

  error_name_required: 'Please fill the name',
  error_leave_year_required: 'Please select the leave year',
  leave_count_required: 'Please fill the leave count',
  value_invalid: 'Please enter a valid value',
  'rc.gt.lc.msg': 'Rollover Leave count must be smaller than Actual Leave Count',
  negative_allowed_balance_invalid: 'Allowed Negative Leaves must be 0 or negative',
  leave_rule_applicable_required: 'Please select an option',

  leave_master_page_title: "Leave Type",
  btn_add_leave_type: "Add Leave Type",

  update_leave_master_heading: "Update Leave Type",
  btn_update_leave_master: "Update",
  add_leave_master_success_toast: 'Leave Updated Successfully',
  update_leave_master_success_toast: 'Leave Type Updated Successfully',


  apply_leave_heading: "Apply Leave",
  leave_page_title: 'Leave Requests',
  leave_user_page_title: "Leave",
  manage_leave:'Manage Leaves',


    // Attendence
    nav_attendance:'ATTENDANCE',
    nav_attendance_text:'Attendance',
    nav_attendance_list:'List',
    nav_attendance_regularisation:'Regularisation',
    manage_regularisation: 'Manage Regularisation',
    regularisation_requests: 'Regularisation Requests',
    regularisation_date:'Date of Regularisation',
    apply_regularisation_success_toast:'Regularisation Applied Successfully',
    regularisation_date_required:'Date of Regularisation is required',
    approve_reject_attn_request: 'Approve/Reject Request',
    heading_regularisation: 'Apply Regularisation Request',
    edit_heading_regularisation: 'Edit Regularisation Request',
    apply_regularisation: 'Apply for Regularisation',
    attendance_summary: 'Employee Attendance Summary',
    mark_attendance: 'Mark Attendance',
    button_mark_attendance: 'Mark Attendance',
    check_box_work_from_home: 'Work From Home',
    employee_search: 'Employee Search',
    regularisation_category: 'Regularisation Category',
    btn_apply: 'Apply',
    category_required: 'Category is required',
    overlap_holiday: 'Overlap Holiday/Weekend',
    auro_approve: 'Auto Approve',
    approve: 'Approved',
    rejected: 'Rejected',
    cancelled: 'Cancelled',
    pending: 'Pending',
    weekend: 'Weekend',
    present: 'Present',
    holiday: 'Holiday',
    absent: 'Absent',
    leave: "Leave",
    regular_request: 'Regularisation Request',


  'grid.reset.col': 'Reset',
  'grid.columns':'Columns',
  'grid.pin.column': 'Pin Column',
  'grid.pin.left': 'Pin Left',
  'grid.pin.right': 'Pin Right',
  'grid.no.pin': 'No Pin',
  'grid.autosize.this.col': 'Autosize This Column',
  'grid.autosize.all.col': 'Autosize All Columns',
  'grid.select.all': 'Select All',
  'grid.clear.filter': 'Clear Filter',
  'grid.apply.filter': 'Apply Filter',
  'grid.filters': 'Filters',
  'grid.equals': 'Equals',
  'grid.not.equals': 'Not Equals',
  'grid.less.than': 'Less Than',
  'grid.less.than.or.equals': 'Less Than or Equals',
  'grid.greater.than': 'Greater Than',
  'grid.greater.than.or.equals': 'Greater Than or Equals',
  'grid.in.range': 'In Range',
  'grid.and.condition': 'AND',
  'grid.or.condition': 'OR',
  'announcement.heading':'Announcement',
  label_leave_description:'Leave Description',
  select_an_option:'Select an option',
  leave_year_label:`Calender Year(1st Jan to 31st Dec)`,
  label_purpose:'Purpose of leave',
  label_reporting_manager:'Reporting Manager',
  leave_raised_by:'Leave Raised By',
  label_upload_doc:'Document Name',

  //Holiday
  nav_holiday:'HOLIDAY',
  manage_holiday:'Manage Holiday',
  add_holiday:'Add Holiday',
  add_holiday_heading:'Add Holiday',
  label_name:'Name',
  label_type_of_holiday:'Holiday Type',
  label_appicable:'Applicable For',
  label_shift:'Shift',
  button_Submit:'Submit',
  create_holiday_success_toast:'Holiday Created Successfully',
  checkBoxText:'(Leaves that are already applied for this holiday will be reprocessed and the balance will be adjusted accordingly)',
  manage_holiday_header:'Manage Holiday',
  btn_add_holiday:'Add Holiday',
  label_reminder_sent:'No. of day(s) before which the reminder should be sent',
  update_holiday_heading:'Update Holiday',
  update_holiday_success_toast:'Holiday Updated Successfully',
  skip_this_Scenario:'Skip this Scenario',

  manage_leave_label:'Manage Leaves',
  apply_leave:'Apply Leave',
  leave_regularisation:'Leave Types',
  manage_leave_master:'Manage Leave Types',
  apply_leave_master:'Add Leave Type',
  add_more_documents_label:'Add More Documents',

  //leave-status
  total_leaves_label:'Total Leaves',
  rem_leaves_label:'Remaining Leaves',
  leave_name_label:'Leave Name',
  year_start_bal_label:'Year Start Balance',
  leaves_curr_year_label:'Leaves Accrued in Current Year',
  leave_avail_year_label:'Leaves Availed in Current Year',
  curr_leave_bal_label:'Current Leave Balance',

  //Set up
  nav_set_up: "SET UP",
  nav_general: "General",
  nav_company_details: "Company Details",
  nav_theme: "Theme",
  nav_location: "Location",
  manage_location: "Manage Office Location",

  nav_department: "Departments",
  manage_department: "Manage Departments",
  add_department: "Add Department",

  nav_designation: "Designations",
  manage_designation: "Manage Designations",
  add_designation: "Add Designation",

  add_success: 'Created successfully',
  update_success: 'Updated successfully',

  nav_country: "Country",
  manage_countries: "Manage Countries",
  add_country: "Add Country",
  edit_country: "Edit Country",
  field_country: "Country Name",
  field_country_code: "Country Code(ISO)",
  field_calling_code: "Calling Code",
  field_time_zone: "Time Zone",

  country_name_required: "Country Name is required",
  country_name_invalid: "Country Name is invalid",
  country_code_required: "Country Code is required",
  country_code_invalid: 'Country Code is invalid',
  calling_code_required: "Calling Code is required",
  calling_code_invalid: 'Calling Code is invalid',
  timeZone_required: 'Time Zone is required',
  select_timeZone: 'Select Time Zone',


  manage_state: "Manage State",
  add_state: "Add State",
  edit_state: "Edit State",
  field_state: "State Name",
  field_select_country: "Select Country",

  add_location:'Add Office Location',

  //themes
  theme_label:'Themes',
  theme_subtitle:' Customize the theme for your organization',
  your_logo_label:'Your Logo',
  upload_file_label:'Upload File',
  upload_logo_instruction1:'This logo will appear on the portal',
  upload_logo_instruction2:'Preferred image size 240px 240px maximium size of 2MB',
  choose_file_label:'Choose File',
  color_theme_label:'Color Theme',

  //Add-location
  add_location_label:'Add Office Location',
  location_name_label:'Location Name',
  mail_alias_label:'Mail Alias',
  
//Add-department
department_name:'Department Name',
department_lead:'Department Lead',
parent_department:'Parent Department',
  
//Add-designation
designation_name:'Designation Name',

//Upload-logo
upload_logo:'Upload Logo',
upload_logo_suggestion:' Recommended resolution 80 x 55',
upload_logo_instruction:'Browse the file to be Uploaded',
upload_fevicon_instruction:'Upload favicon image',
favicon_label:'Fevicon',

//Company-details
company_details_label:'Company Details',
modify_settings_label:'Modify Settings',
company_details_subheading:`Add your organization's details, define Super Administrator,
personalise using your company logo and add other company related
settings`,
basic_details:' Basic Details',
company_name:'Company Name',
website:'Website',
industry:'Industry',
contact_person_name:'Contact Person Name',
contact_number:'Contact Number',
address_details:'Address Details',
address_line1:'Address Line 1',
address_line2:'Address Line 2',
city_label:'City',
state_province_label:'State/Province',
zip_pin_code:'ZIP/PIN Code',
super_administrator:'Super Administrator',
email_id:'Email ID',
email_settings:'Email settings',
default_from_address:'Default From Address',
locale_settings:'Locale Settings',
country_region:'Country/Region',
time_zone:'Time Zone',
display_settings:'Display Settings',
name_to_be_displayed:'Name to be displayed',
time_format:'Time Format',
date_format:'Date Format',
suggestion_super_admin:'All license realted e-mails would be sent to this e-mail id.',

company_details_updated:'Company Details Updated',

//themes
pic_uploaded_successfully:'Picture uploaded successfully',
themes_updated:'Themes Updated Sucessfully',


//roles_and_permissions
nav_role_Permission:'Roles and Permissions',
manage_roles:'Manage Roles',
add_new_role:'Add New Role',
roleName:'Role Name',
role_name_required: 'Role name is required',
update_role: 'Update Role',

department_name_required:'Department Name is required',
edit_department:'Edit Department',
department_Details_updated:'Department Details Updated',
department_created:'Department Created Successfully',

designation_Details_updated:'Designation Details Updated',
designation_created:'Designation Created Successfully',
designation_name_required:'Designation Name is required',
edit_designation:'Edit Designation',

//document_and_records
nav_document_records:'Documents & Records',
manage_qualification:'Manage Qualification',
add_qualification:'Add Qualification',
update_qualification:'Update Qualification',
manage_medical_records:'Manage Medical Records',
add_medical_records:'Add Medical Records',
manage_document_list:'Manage Document List',
add_document_list:'Add Document List',

date_key: "Date",
general_key: "General",

//add-qualification
qualificationName:'Qualification Name',
qualification_name_required:'Qualification Name is Required',

check_leave_status:'Check Leave Status',
upload_document:'Upload Document',
leave_status:'Leave Status',

//medical-records
medicalRecordName:'Medical Record Name',

edit_location_label:'Edit Location',
location_details_updated:'Location Details Updated',
location_details_created:'New Location Created',
location_name_required:'Location Name is Required',

update_document_list:'Update Document List',
update_medical_records:'Update Medical Records',

medical_record_name_required:'Medical Record Name is Required',
document_name_required:'Document Name is Required',

//employee personnel
nav_employee_personnel: 'Employee Personnel',
add_source_of_hire: 'Add Source of Hire',
source_of_hire: 'Source of Hire',
manage_source_of_hire: 'Manage Source of Hire',
source_of_hire_details_updated: 'Source of Hire Details Updated',
source_of_hire_created: 'Source of Hire Created Successfully',
source_of_hire_required: 'Source of Hire is required',
edit_source_of_hire: 'Edit Source of Hire',

add_employee_status: 'Add Employee Status',
manage_employee_status: 'Manage Employee Status',
employee_status_name: 'Status Name',
employee_status_details_updated: 'Employee Status Details Updated',
employee_status_created: 'Employee Status Created Successfully',
employee_status_name_required: 'Status Name is required',
edit_employee_status: 'Edit Employee Status',

add_employee_type: 'Add Employee Type',
manage_employee_type: 'Manage Employee Type',
employee_type: 'Employee Type',
employee_type_details_updated: 'Employee Type Details Updated',
employee_type_created: 'Employee Type Created Successfully',
employee_type_required: 'Employee Type is required',
edit_employee_type: 'Edit Employee Type',

//Medical-Records
medical_records_label:'Medical Records',
documents_label:'Documents',
add_more_document_label:'Add More Documents',
//Basic-Info
profile_pic_instruction:
"Profile picture should be in the standard format png jpg & not more than 5MB",
employee_ID_label:"Employee ID",
Jptc_email_ID_label:'JPTC Email ID',
personal_email_Id_label:'Personal Email ID',
curr_address_label:'Current Address',
permanent_add_label:'Permanent Address',
aadhar_card_no_label:'Aadhaar Card Number',
pan_number_label:'PAN Card Number',
marital_status_label:'Maritial Status',
anniversary_date_label:'Anniversary Date',
passport_number_label:'Passport Number',

basic_info_label:'Basic Information',
passport_no_invalid:'Passport Number is invalid',
pan_no_invalid:'Pan Number is invalid',
addhar_no_invalid:'Addhar Number is invalid',
marital_status_required:'Marital Status is Required',
//Add-employee
reporting_to:'Reporting To',
label_role:'Role',
label_seating_location:'Seating Location',
label_work_phone:'Work Phone',
label_source_of_hire:'Source Of Hire',
location_required:'Location is required',
location_invalid:'Location is invalid',
employeeType_required:'Employee Type is required',
employeeType_invalid:'Employee Type is invalid',
role_required:'Role is required',
role_invalid:'Role is invalid',
sourceOfHire_required:'Source of Hire is required',
sourceOfHire_invalid:'Source of Hire is invalid',
seatingLocation_required:'Seating Location is required',
seatingLocation_invalid:'Seating Location is invalid',
contractEndDate_invalid:'Contract End Date is invalid',
reportingTo_required:'Reporting To is required',
city_required:'City is required',

//onboarding
work_exp_label:'Work Experience',
work_info_label:'Work Information',
summary:'Summary',

//education
sidebar_education:'Education',
education_details_label:'Education Details',
board_label:'Board',
school_medium_label:'School Medium',
percentage_label:'Percentage',
label_passing_year:'Passing Year',
course_label:'Course',
specialization_label:'Specialization',
course_type_label:'Course Type',
university_college_label:'University/College',
certificate_label:'Certificate(If Any)',
document_name_placeholder:'Name of the document',
add_more_certificate_label:'Add More Certificate',
education_label:'Education',
school_college_label:'School/College',
select_course_label:'Select Course',
select_specialization_label:'Select Specialization',
select_course_type:'Select Course Type',
board_required:'Board is required',
schoolMedium_required:'School Medium is required',
percentage_required:'Percentage is required',
passing_year_required:'Passing Year is required',
passing_year_invalid:'Passing Year is invalid',
schoolName_required:'School Name is required',
schoolName_invalid:'School Name is invalid',
tenth_details_added:'Tenth Details added successfully',
twelth_details_success_toast:'Twelfth Details added successfully',
degree_info_success_toast:'Graduation Details added successfully',
university_required:'University Name is required',
university_invalid:'University Name is invalid',
specialization_invalid:'Specialization is invalid',
specialization_required:'Specialization is required',
courseType_required:'Course Type is required',
courseType_invalid:'Course Type is invalid',

sidebar_uploaded_doc:'UPLOADED DOCS',
profile_info:'PROFILE INFO',

//work-Experience info
work_experience_heading: 'Work Experience',
label_previous_company_name: 'Previous Company Name',
label_job_title: 'Job Title',
label_from_date: 'From Date',
label_to_date: 'To Date',
label_relevance: 'Relevance',
select_relevance: 'Select',
relevance_yes: 'Yes',
relevance_no: 'No',
label_duration: 'Duration',
label_job_description: 'Job Description',
label_work_experience_Detail_msg: 'Work Experience Detail',

summary_info_heading: 'Summary',
label_about_me: 'About Me',
label_askme_about_expertise: 'Ask me about/Expertise ',

//work-Info
work_info_heading: 'Work',
select_department: 'Select',
select_location: 'Select',
label_reporting_to: 'Reporting To',
select_reporting_to: 'Select',
select_designation: 'Select',
label_date_of_joining: 'Date of Joining',
label_employee_type: 'Employee Type',
select_employee_type: 'Select',
select_role: 'Select',
label_previous_experience: 'Previous Experience',
label_jptc_experience: 'JPTC Experience',
label_uploaded_doc:'Uploaded Documents',
select_board:'Select Board',
select_school_medium:'Select School Medium',

button_text_exit:'Exit',
exit_alert_msg_edit_profile:`Are you sure you want to exit.before exit please save your current change's otherwise, all current change's will be discarded.`,
work_experience_updated:'Work experience updated',
summary_info_updated:'Summary information updated',
work_information_updated:'Work information updated',
tenth_details_updated:`Tenth details updated`,
twelth_details_updated_toast:`Twelfth details updated`,
degree_info_updated_toast:'Degree details updated',
summary_info_success_toast:'Summary information added successfully',
label_total_experience:'Total Experience',
previous_comp_name_required:'Previous company name is required',
previous_comp_name_invalid:'Previous company name is invalid',
job_title_required:'Job Title is required',
job_title_invalid:'Job Title is invalid',
btn_text_next:'Next',
file_uploaded_successfully:'File uploaded successfully',
label_employee_status:'Employee Status',
employeeStatus_required:'Employee status is required',

tenth_education:'Tenth Documents',
twelfth_education:'Twelfth Documents',
label_status_updated:'Status Updated',
work_information_success_toast:'Work Information added successfully',
work_experience_success_toast:'Work Experience added successfully',
course_required:'Course is required',

//PAYROLL
manage_employee_payroll:'Manage Employee Payroll',
define_employee_payroll:'Define Employee Payroll',
btn_apply_payroll:'Apply For Payroll',
effective_from_date:'Effective From',
select_structure:'Select a Structure',
nav_structure:'Salary Structure',
manage_salary_structure:'Manage Salary Structures',
create_salary_structure:'Create Salary Structure',


//SALARY-STRUCTURE
add_more_components_label:'Add More Components',
edit_salary_structure:'Edit Salary Structure',
component_name:'Name of Component',
structure_name_rquired:'Structure name is required',
componentName_required:'Component name is required',
percentage_required:'Percentage is required',

//SALARY-COMPONENTS
nav_components:'Salary Components',
manage_salary_components:'Manage Salary Components',
add_salary_components:'Add Salary Component',
componentName:'Component Name',
update_salary_components:'Update Salary Component',
employee_payroll_define_success:'Employee payroll defined successfully',
payroll_label:'Payroll',
edit_payroll_header:`'s Payroll`,
employee_payroll_update_success:'Emplyee payroll updated successfully',
update_payroll_btn:'Update Payroll',
select_an_option_employee:'Please select an employee',
ctc_required_msg:'CTC is required',
effective_date_required:'Effective from is required',
select_an_structure:'Please select a structure',
payroll_label_history:'Payroll History'


};
export default en;

