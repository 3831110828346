import { storage } from '@bit/sixsprints.core.beans-web';
import { Keys } from '@bit/sixsprints.utils.data';
import { I18nContextProvider } from '@bit/sixsprints.utils.i18n';
import AppRoutes from '@bit/sixsprints.utils.routes';
import {
  Box, ChakraProvider, CircularProgress, extendTheme,
  Flex
} from '@chakra-ui/react';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import { version } from '../package.json';
import languages from './i18n/i18n';
import routeObj from './pages/route-obj';
import routeObjUser from './pages/routes-obj-user';
import { titleSubject } from './pages/set-up/general/edit-company-details';
import { themeSubject } from './pages/set-up/general/theme';
import DataService, { loginSubject } from './services/data-service';
import theme from './theme/theme';
import { AppConstants } from './utils/constants';
import { getImageUrl, isAdmin } from './utils/helpers';


const App = () => {
  const token = storage.get(Keys.TOKEN);
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [tenant, setTenant] = useState(null);

  const initTheme = extendTheme({
    ...theme,
    ...(tenant && tenant.theme),
  });

  initTheme.colors.strip = {
    100: '#FAFAFA',
  };

  const getMasterData = () => {
    DataService.get('master-data')
      .then(resp => {
        storage.set(AppConstants.AUTH.CONFIG, resp.data.data);
      })
      .catch(err => console.log(err));
  };

  const getTenantData = () => {
    DataService.get('tenant')
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data');
        let faviConUrl =  getImageUrl(Bro(resp).iCanHaz('data.data.assets.faviConUrl'));
        const favicon = document.getElementById('favicon');
        favicon.href = faviConUrl;
        document.title = Bro(resp).iCanHaz('data.data.name');
        storage.set(AppConstants.AUTH.TENANT, data);
        setTenant(data);
      })
      .catch(err => console.log(err));
  };
  useEffect(() => {
    let loginSub = loginSubject.subscribe(value => {
      setIsAuthenticated(value);
    });
    return () => {
      loginSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let themeSub = themeSubject.subscribe(value => {
      if(value){
        getTenantData();
      }
    });
    return () => {
      themeSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    let titleSub = titleSubject.subscribe(value => {
      if(value){
        getTenantData();
      }
    });
    return () => {
      titleSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    getTenantData();
    getMasterData();
  }, []);

  return (
    <Box>
      {tenant ? (
        <I18nContextProvider languages={languages}>
          <ChakraProvider theme={initTheme}>
            <AppRoutes
              routeObj={isAdmin() ? routeObj:routeObjUser}
              basename={'/'}
              isAuthenticated={isAuthenticated}
            />
          </ChakraProvider>
        </I18nContextProvider>
      ) : (
        <Flex h="90vh" justifyContent="center" alignItems="center">
            <CircularProgress isIndeterminate />
        </Flex>
      )}
      <div className="versionWrap noPrint">Version ({version})</div>
    </Box>
  );
};

export default App;
