import React, { useEffect, useState } from 'react';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import {
  Box,
  Flex,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Text,
} from '@chakra-ui/react';
import Bro from 'brototype';
import SmallUploadIcon from '../../icons/small-upload-icon';
import DataService from '../../services/data-service';
import useNotify from '../../utils/notifications';

const FileUploader = ({
  name,
  label,
  register,
  uploadValue,
  error,
  isRequired,
  hideFileName,
  ...rest
}) => {
  const [fileName, setFileName] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const { t } = useI18nContext();
  const toast = useNotify();
  const handleClick = () => {
    hiddenFileInput.current.value = '';
    hiddenFileInput.current.click();
  };

  const handleChange = event => {

    let fileArray = Object.values(event.target.files);
    let filePath = fileArray[0]; 
    

    // Allowing file type 
    let allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i; 
    
    if (!allowedExtensions.exec(filePath.name)) { 
      toast({
        title: t('toast_mess_file_format_error'),
        description: t('toast_desc_file_format_error'),
        status: 'error',
      });

      hiddenFileInput.current.value = ''; 
      return; 
  }  
    
    let formData = new FormData();
    formData.append('file', fileArray[0]);

    DataService.post('file/upload', formData)
      .then(resp => {
        const response =
          Bro(resp).iCanHaz('data.data') || Bro(resp).iCanHaz('data');
        uploadValue(response);
        setFileName(fileArray[0].name);
        toast({
          title: t('File uploaded successfully'),
          description: resp.message,
          status: 'success',
        });
      })
      .catch(err => {
        toast({
          title: t(err.message),
          description: err.message,
          status: 'error',
        });
      });
  };

  useEffect(() => {
    register(name);
  }, []);

  return (
    <FormControl id={name} isInvalid={!!error} {...rest}>
      {label && (
        <FormLabel fontFamily="Open Sans" fontWeight="small" mb={3}>
          {label}
        </FormLabel>
      )}
      <Flex justifyContent="center" alignItems="center">
        {!hideFileName && (
          <Box
            flex={1}
            h="42px"
            bgColor="#ffffff"
            borderRadius="12px"
            minWidth="0"
            border="1px solid #E2E3E5"
          >
            <Text
              fontFamily="Open Sans"
              fontStyle="normal"
              fontWeight="500"
              fontSize="14px"
              mr={3}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              mt={2}
              mx={3}
            >
              {fileName || t('label_no_file_chosen')}
            </Text>
          </Box>
        )}
        <IconButton
          onClick={handleClick}
          bgColor="brand.500"
          borderRadius="12px"
          aria-label="Search database"
          icon={<SmallUploadIcon />}
          ml={4}
          shadow="none"
        />
        <input
          type="file"
          accept=".jpg, .png, .jpeg, .pdf"
          ref={hiddenFileInput}
          name={name}
          onChange={handleChange}
          style={{ display: 'none' }}
        />
      </Flex>
      {error && (
        <Center>
          <FormErrorMessage>
            <Text>{t('form_field_required')}</Text>
          </FormErrorMessage>
        </Center>
      )}
    </FormControl>
  );
};
export default FileUploader;
