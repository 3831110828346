import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { Table, Tbody, Td, Tr } from '@chakra-ui/table';
import { useNavigate } from '@reach/router';
import Bro from 'brototype';
import { useEffect, useState } from 'react';
import EditSvg from '../../../components/misc/edit-svg';
import PageTitle from '../../../components/misc/page-title';
import DataService from '../../../services/data-service';
import Loader from '../../../components/misc/loader';
import { Center } from '@chakra-ui/react';
import { storage } from '@bit/sixsprints.core.beans-web';
import { AppConstants } from '../../../utils/constants';


const CompanyDetails = () => {
  const { t } = useI18nContext();
  const navigate = useNavigate();
  const [companyInfo, setCompanyInfo] = useState(null);

  const navigateToEditMode = () => {
    navigate('/home/set-up/general/company-details/edit');
  };

  const getCompanyInfo = () => {
    DataService.post('org/search', { page: 0, size: 4 })
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.content');
        let companyDetails=data[0];
      setCompanyInfo(companyDetails);
    });
  };

    const timeFormat = storage.get(AppConstants.AUTH.CONFIG).timeFormat;
    const dateFormat = storage.get(AppConstants.AUTH.CONFIG).dateFormat;
    const displayNameType = storage.get(AppConstants.AUTH.CONFIG).dispalyName;

    const timeFormatArr = [];
  
    timeFormat && timeFormat.map((item)=>{
      if(companyInfo){
      if(companyInfo.displaySettings && companyInfo.displaySettings.timeFormat===item.key)
      {
          let o = {label:item.label,item};
          timeFormatArr.push(o);
        }
      }
      })
     
    const dateFormatArr=[];
   dateFormat && dateFormat.map((item)=>{
    if(companyInfo){
      if(companyInfo.displaySettings && companyInfo.displaySettings.dateFormat===item.key)
      {
          let o = {label:item.label,item};
          dateFormatArr.push(o);
        }}
      })

        
    const displayNameTypeArr=[];
    displayNameType && displayNameType.map((item)=>{
      if(companyInfo){
       if(companyInfo.displaySettings && companyInfo.displaySettings.displayName===item.key)
       {
           let o = {label:item.label,item};
           displayNameTypeArr.push(o);
         }}
       })

  useEffect(()=>{
    getCompanyInfo()
  },[])


  return companyInfo===null ? (<Flex
    flex={1}
    h="100%"
    overflow="auto"
    direction="column"
    css={{
      '&::-webkit-scrollbar': {
        width: '0px',
      },
    }}
  >
    
    <Flex>
      <Box as={Flex} direction="column" mb={5}>
        <PageTitle title={t('company_details_label')} />

        <Text
          fontFamily="Raleway"
          fontWeight="600"
          fontSize="14px"
          color="#818081"
          mt={2}
        >
          {t('company_details_subheading')}
        </Text>
      </Box>
    </Flex>  <Center>
      <Loader/>
      </Center></Flex>):(
    <Flex
      flex={1}
      h="100%"
      overflow="auto"
      direction="column"
      css={{
        '&::-webkit-scrollbar': {
          width: '0px',
        },
      }}
    >
        <Flex direction="row" justifyContent="space-between" mb={2}>
          <Box>
            <PageTitle title={t('company_details_label')} />
          </Box>
          <Box as={Flex} direction="row" cursor={'pointer'} bg="#EAEAEA"  borderRadius="12px" p={2} onClick={navigateToEditMode}>
            <EditSvg w={3} h={3} mr={1} />
            <Box ml={2}>
            <Text
            fontFamily="Raleway"
            fontWeight="600"
            fontSize="14px"
            color="#818081"
          >
           {t('modify_settings_label')}
          </Text>
          </Box>
          </Box>
        </Flex>
        <Flex>
        <Text
            fontFamily="Raleway"
            fontWeight="600"
            fontSize="14px"
            color="#818081"
          >
           {t('company_details_subheading')}
          </Text>
        </Flex>
      <Box>
      <Box
      mt={4}
        py={2}
        bgColor="white"
        borderRadius="16px"
      >
        <Text
          m={4}
          px={4}
          fontSize="20px"
          fontWeight="bold"
          fontFamily="Raleway"
          color="#24293D"
        >
         {t('basic_details')}
        </Text>
        <Box m={5}>
          <Table fontSize="14px" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('company_name')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('name') || '--'}</Text>
                </Td>
                <Td>
                  <Text color="#ACACAC">{t('website')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('webSite') ||'--'}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('industry')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('industry') ||'--'}</Text>
                </Td>
                <Td>
                  <Text color="#ACACAC">{t('contact_person_name')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('contactPerson') ||'--'}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('label_email')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('email') ||'--'}</Text>
                </Td>
                <Td>
                  <Text color="#ACACAC">{t('contact_number')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('contactNumber') ||'--'}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
        <Box px={10} py={2} bg="#FAFAFA">
          <Text
            fontSize="16px"
            fontWeight="bold"
            fontFamily="Raleway"
            color="#24293D"
          >
           {t('address_details')}
          </Text>
        </Box>
        <Box m={5}>
          <Table fontSize="14px" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('address_line1')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('address.addressLine1') ||'--'}</Text>
                </Td>
                <Td>
                  <Text color="#ACACAC">{t('address_line2')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('address.addressLine2') ||'--'}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('city_label')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('address.city') ||'--'}</Text>
                </Td>
                <Td>
                  <Text color="#ACACAC">{t('state_province_label')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('address.state') ||'--'}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('nav_country')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('address.country') ||'--'}</Text>
                </Td>
                <Td>
                  <Text color="#ACACAC">{t('zip_pin_code')}</Text>
                </Td>
                <Td>
                  <Text>{Bro(companyInfo).iCanHaz('address.postal') ||'--'}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box mt={4} py={1} bgColor="white" borderRadius="16px">
        <Text
          m={4}
          px={4}
          fontSize="20px"
          fontWeight="bold"
          fontFamily="Raleway"
          color="#24293D"
        >
         {t('super_administrator')}
        </Text>
        <Box m={5}>
          <Table fontSize="14px" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <Text color="#EB0808">{t('email_id')}</Text>
                </Td>
                <Td px={12}>
                  <Text>{Bro(companyInfo).iCanHaz('superAdminEmail') ||'--'}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box mt={4} py={1} bgColor="white" borderRadius="16px">
        <Text
          m={4}
          px={4}
          fontSize="20px"
          fontWeight="bold"
          fontFamily="Raleway"
          color="#24293D"
        >
         {t('email_settings')}
        </Text>
        <Box m={5}>
          <Table fontSize="14px" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('default_from_address')}</Text>
                </Td>
                <Td px={12}>
                  <Text>{Bro(companyInfo).iCanHaz('defaultEmail')||'--'}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box mt={4} py={1} bgColor="white" borderRadius="16px">
        <Text
          m={4}
          px={4}
          fontSize="20px"
          fontWeight="bold"
          fontFamily="Raleway"
          color="#24293D"
        >
         {t('locale_settings')}
        </Text>
        <Box m={5}>
          <Table fontSize="14px" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('country_region')}</Text>
                </Td>
                <Td px={12}>
                  <Text>{Bro(companyInfo).iCanHaz('localeSetting.country') ||'--'}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('time_zone')}</Text>
                </Td>
                <Td px={12}>
                  <Text>{Bro(companyInfo).iCanHaz('localeSetting.timeZone.name') ||'--'}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box mt={4} py={1} bgColor="white" borderRadius="16px">
        <Text
          m={4}
          px={4}
          fontSize="20px"
          fontWeight="bold"
          fontFamily="Raleway"
          color="#24293D"
        >
         {t('display_settings')}
        </Text>
        <Box m={5}>
          <Table fontSize="14px" size="sm">
            <Tbody>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('name_to_be_displayed')}</Text>
                </Td>
                <Td px={12}>
                  <Text>{displayNameTypeArr.length>0 ? displayNameTypeArr.map((item)=>(item.label)) : '--'}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('time_format')}</Text>
                </Td>
                <Td px={12}>
                <Text>{timeFormatArr.length >0 ? timeFormatArr.map((item)=>(item.label)) : '--'}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text color="#ACACAC">{t('date_format')}</Text>
                </Td>
                <Td px={12}>
                  <Text>{dateFormatArr.length>0 ?dateFormatArr.map((item)=>(item.label)) : '--'}</Text>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
      </Box>
    </Flex>
  );
};
export default CompanyDetails;
