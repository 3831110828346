import * as React from "react";
import { Icon } from "@chakra-ui/react"


function SetupIcon(props) {
  return (
    <Icon
    width={26}
    height={26}
    viewBox="0 0 26 26"
    fill="none"
    {...props}
  >
    <path
        d="M12.945 15.752c-3.738 0-6.891.61-6.891 2.967 0 2.358 3.174 2.947 6.89 2.947 3.738 0 6.892-.61 6.892-2.967 0-2.358-3.174-2.948-6.891-2.948z"
        fill="currentColor"
      />
      <path
        opacity={0.4}
        d="M12.945 13.506c2.53 0 4.56-2.04 4.56-4.586a4.557 4.557 0 00-4.56-4.587c-2.531 0-4.56 2.04-4.56 4.587a4.557 4.557 0 004.56 4.586zM22.845 9.988c.655-2.576-1.264-4.889-3.709-4.889-.266 0-.52.03-.768.08-.033.007-.07.024-.09.053-.022.037-.005.087.019.119a6.291 6.291 0 011.156 3.652 6.242 6.242 0 01-1.066 3.51.172.172 0 00.116.265c.17.03.345.046.523.05 1.78.048 3.378-1.104 3.82-2.84z"
        fill="currentColor"
      />
      <path
        d="M24.71 16.052c-.326-.698-1.112-1.178-2.309-1.413-.564-.138-2.092-.333-3.513-.307-.021.003-.033.018-.035.027-.003.014.003.037.031.052.657.327 3.195 1.748 2.876 4.746-.013.13.09.242.22.223.624-.09 2.232-.437 2.73-1.52a2.083 2.083 0 000-1.808z"
        fill="currentColor"
      />
      <path
        opacity={0.4}
        d="M7.632 5.178a3.807 3.807 0 00-.768-.079c-2.445 0-4.365 2.313-3.709 4.889.44 1.736 2.038 2.888 3.818 2.84.179-.004.354-.02.524-.05a.172.172 0 00.115-.264 6.245 6.245 0 01-1.066-3.511c0-1.356.423-2.617 1.158-3.652.023-.032.04-.082.017-.12-.02-.03-.055-.045-.09-.053z"
        fill="currentColor"
      />
      <path
        d="M3.599 14.639c-1.197.235-1.982.714-2.308 1.413a2.078 2.078 0 000 1.81c.498 1.08 2.106 1.429 2.73 1.518.13.019.232-.092.219-.223-.32-2.997 2.22-4.418 2.877-4.745.027-.016.033-.038.03-.053-.002-.01-.013-.024-.034-.026-1.422-.028-2.949.168-3.514.306z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default SetupIcon;

