import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';

const AlertDialogModal = ({
    heading, 
    bodyContent,
    showCloseBtn,
    colorScheme,
    cancelRef,
  ...rest
}) => {
    const { isOpen = true } = useDisclosure();
  return (
    <AlertDialog
      motionPreset="slideInTop"
      colorScheme={colorScheme}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      {...rest}
    >
      <AlertDialogOverlay>  
        <AlertDialogContent>
        {/* {showCloseBtn={showCloseBtn} && <AlertDialogCloseButton _focus="none" />} */}

          <AlertDialogBody p={3}>{bodyContent}</AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertDialogModal;
