import { storage, useFormController } from '@bit/sixsprints.core.beans-web';
import { ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import Bro from 'brototype';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import SelectThemeInput from '../../../../components/form-elements/select-input';
import ThemeInput from '../../../../components/form-elements/theme-input';
import ForwardArrow from '../../../../icons/forward-arrow';
import DataService from '../../../../services/data-service';
import { AppConstants } from '../../../../utils/constants';
import useNotify from '../../../../utils/notifications';
import { navigate } from '@reach/router';
import PageTitle from '../../../../components/misc/page-title';
import ListHeader from '../../../../components/misc/list-header';
import ThemeAsyncSelectInput from '../../../../components/form-elements/theme-async-select-input';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import Loader from '../../../../components/misc/loader';
import DocumentUploadCertificate from '../../../../components/form-elements/document-uploader';
import ThemeHeading from '../../../../components/misc/heading';

export const transformArray = (inputArray, values) => {
  const newArray = [];
  let clonedArray = _.cloneDeep(inputArray);
  clonedArray.forEach(item => {
    if (values[item.key]) {
      item['documentName'] = item.key;
      item['url'] = values[item.key];
      newArray.push(item);
    } else if (item['url']) {
      newArray.push(item);
    }
  });
  return newArray;
};

// It returns the target array
export const mergeDocs = (source, target) => {
  let newArr = [];
  source.forEach(item => {
    target.forEach(doc => {
      if (item.documentName === doc.key) {
        doc['date'] = item.date;
        doc['documentName'] = item.documentName;
        doc['documentStatus'] = item.documentStatus;
        doc['url'] = item.url;
        checkIfExistAndPush(newArr, doc);
      } else {
        checkIfExistAndPush(newArr, doc);
      }
    });
  });
  return newArr;
};

const checkIfExistAndPush = (list, item) => {
  if (!_.find(list, item)) {
    list.push(item);
  }
};

const HealthInfo = ({ t, next, prev, userSlug,userData, isEdit }) => {
  const toast = useNotify();
  const [bloodGrp, setBloodGrp] = useState(null);
  const [diabetes, setDiabetes] = useState(null);
  const [isDocLoading, setIsDocLoading] = useState(false);
  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
  const { langCode } = useI18nContext();

  const [documentsIndexes, setDocumentsIndexes] = useState([0]);
  const [documentsCounter, setDocumentsCounter] = useState(0);
  const [documentList,setDocumentList] = useState([]);
  const configDocs = storage.get(AppConstants.AUTH.CONFIG).healthDocuments;
  const tableRows = configDocs;

  const apiOptions = {
    url: `/user/health-checkup?userSlug=${userSlug}`,
    method: 'put',
  };

  const transformRequest = values => {
    const { bloodPressure, diabetes, bloodGroup, allergies, ...data } = values;
     let documents = [];
      values.documents.forEach((elements,_index) => {
      if (elements.documentSlug && elements.url) {
        documents.push({
          documentSlug: elements.documentSlug.slug?elements.documentSlug.slug:elements.documentSlug,
          url: elements.url,
        });
      }
    });
    return { bloodPressure, diabetes, bloodGroup, allergies, documents 
  }};

  const onSuccess = resp => {
    if (!isEdit) {
      toast({
        title: t('health_info_success_toast'),
        description: resp.message,
        status: 'success',
      });
      next();
    } else {
      toast({
        title: t('health_info_updated'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  const onError = resp => {
    toast({
      title: t('health_check_failed_toast_title'),
      description: resp.message,
      status: 'error',
    });
  };

  const getDynamicYupValidation = fields => {
    let schemaObj = {
      bloodPressure: Yup.string()
        .nullable()
        .transform(v => (v === '' ? null : v)),
      diabetes: Yup.string().required(t('diabetes_required')),
      bloodGroup: Yup.string().required(t('blood_group_required')),
      allergies: Yup.string()
        .nullable()
        .transform(v => (v === '' ? null : v)),
    };

    return Yup.object(schemaObj);
  };

  const {
    isLoading,
    onSubmit,
    register,
    setValue,
    getValues,
    reset: resetForm,
    errors,
    // watch,
    control,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: getDynamicYupValidation(configDocs),
    transformRequest,
  });

  const [userInfo, setUserInfo] = useState(null);


  const getUserInfo = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.healthCheckup');
        setDocumentsIndexes(data.documents.length>0 ? data.documents : [0]);
        setValue('documents',data.documents);
        resetForm(data);
        setUserInfo(data);
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  const addDocuments = () => {
    setDocumentsIndexes(prevIndexes => [...prevIndexes, documentsCounter]);
    setDocumentsCounter(prevCounter => prevCounter + 1);
  };

  const removeDocuments = index => () => {
    setDocumentsIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    setDocumentsCounter(prevCounter => prevCounter - 1);
    const currFormValues = getValues();
    let data = currFormValues.documents.filter(
      (item, _index) => _index !== index
    );
    currFormValues.documents = data;
    resetForm(currFormValues);
  };

  const transformDocumentList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.medicalRecordName[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptions = async () => {
    let data = await DataService.post('/medicalRecord/search', {
      page: 0,
      size: 10,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    setDocumentList(transformDocumentList(data));
    // return data;
  };

  const bloodPressure = [
    {
      key: 'NORMAL',
      label: 'Normal',
    },
    {
      key: 'HIGH',
      label: 'High',
    },
    {
      key: 'LOW',
      label: 'Low',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const bloodGrpArr = storage.get(AppConstants.AUTH.CONFIG).bloodGroups;
    const diabetesArr = storage.get(AppConstants.AUTH.CONFIG).diabetes;
    setBloodGrp(bloodGrpArr);
    setDiabetes(diabetesArr);
  }, []);

  useEffect(getUserInfo, []);
  useEffect(() => {
    promiseOptions()
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode]);

  return (
    (isDocLoading  && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex>
          <Box as={Flex} direction="column" mb={5}>
            {!isEdit ? 
            (<ThemeHeading heading={t('medical_records_label')} subHeading={t('details_subheading')} mb={10}/>):(<ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
              'edit_profile_header_text'
            )}`} subHeading={t('details_subheading')}/>)}
          </Box>
        </Flex>
        <Center>
          <Loader />
        </Center>
      </Flex>
    )) || (
      <Flex flexGrow={1}>
        <Flex flex={1} direction="column" mr={'10em'}>
          {!isEdit ? (<ThemeHeading heading={t('medical_records_label')} subHeading={t('details_subheading')}/>):(<ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
              'edit_profile_header_text'
            )}`} subHeading={t('details_subheading')}/>)}
          <Box
            as={'form'}
            onSubmit={onSubmit}
            noValidate="noValidate"
            mt={'3em'}
          >
            <Grid templateColumns="repeat(6, 1fr)" gap={6}>
              <GridItem colSpan={2}>
                <SelectThemeInput
                  label={t('blood_pressure')}
                  mt=".8rem"
                  options={bloodPressure}
                  labelKey={'label'}
                  valueKey={'key'}
                  name="bloodPressure"
                  error={errors.bloodPressure}
                  register={register}
                  placeholder={'Select'}
                />
              </GridItem>
              <GridItem colSpan={2}>
                <SelectThemeInput
                  label={t('diabetes')}
                  mt=".8rem"
                  options={diabetes}
                  labelKey={'label'}
                  valueKey={'key'}
                  name="diabetes"
                  error={errors.diabetes}
                  placeholder={'Select Diabetes Type'}
                  register={register}
                  isRequired
                />
              </GridItem>
              <GridItem colSpan={2}>
                <SelectThemeInput
                  mt=".8rem"
                  label={t('blood_group')}
                  options={bloodGrp}
                  labelKey={'label'}
                  valueKey={'key'}
                  name="bloodGroup"
                  error={errors.bloodGroup}
                  placeholder={t('placeholder_blood_group')}
                  register={register}
                  type="text"
                  isRequired
                />
              </GridItem>
              <GridItem colSpan={6} mt={3}>
                <ThemeInput
                  label={t('allergies')}
                  name="allergies"
                  error={errors.allergies}
                  register={register}
                  type="text"
                  mt={1}
                />
              </GridItem>
              <GridItem colSpan={6} mt={3} mr={'20%'}>
                <Heading as="h5" size="sm" mt={6}>
                  {t('documents_label')}
                </Heading>
                <Stack spacing={0}>
                  {documentsIndexes.map((item, index) => {
                    const fieldName = `documents[${index}]`;
                    return (
                      <Stack key={index} w="100%">
                        {index > 0 && (
                          <ListHeader
                            msg={`${t('document')} ${index + 1}`}
                            index={documentsIndexes.length}
                            onBtnClick={removeDocuments(index)}
                          />
                        )}
                        <Flex direction="row" justifyContent="space-between">
                          <Box w="48%">
                          <SelectThemeInput
                              options={documentList}
                              name={`[${fieldName}].documentSlug`}
                              error={Bro(errors).iCanHaz(
                                `documents.${index}.documentName`
                              )}
                              labelKey="name"
                              valueKey="slug"
                              register={register}
                              type="text"
                              placeholder={t('document_name_placeholder')}
                              mt={4}
                            />
                          </Box>
                          <Box w="50%" pt="1em" pl="0.7em">
                            <DocumentUploadCertificate
                              borderRadius={'12px'}
                              register={register}
                              control={control}
                              defaultValue=""
                              name={`[${fieldName}].url`}
                              url={'file/upload'}
                              error={Bro(errors).iCanHaz(
                                `documents.${index}.url`
                              )}
                              index={index}
                              certificateList={userInfo && userInfo.documents}
                              uploadValue={url =>
                                setValue(`[${fieldName}].url`, url)
                              }
                            />
                          </Box>
                        </Flex>
                      </Stack>
                    );
                  })}
                  <Stack display="inline">
                    <Heading
                      as="h5"
                      size="sm"
                      display="inline"
                      onClick={addDocuments}
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {t('add_more_document_label')}
                    </Heading>
                  </Stack>
                </Stack>
              </GridItem>
            </Grid>
            <Flex justifyContent="space-between" alignItems="center" mt={20}>
              {isEdit ? (
                <Flex flex={1} justifyContent={'flex-end'}>
                  <Button align="flex-end" px={10} type="submit" isLoading={isLoading}>
                    {t('button_text_save')}
                  </Button>
                </Flex>
              ) : (
                <>
                  <Button
                    leftIcon={<ArrowBackIcon />}
                    variant="ghost"
                    onClick={prev}
                  >
                    {t('button_text_back')}
                  </Button>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    rightIcon={<ForwardArrow />}
                  >
                    {t('button_text_save_next')}
                  </Button>
                </>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
    )
  );
};

export default HealthInfo;
