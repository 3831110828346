import { storage, useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { useNavigate } from '@reach/router';
import ThemeInput from '../../components/form-elements/theme-input';
import useNotify from '../../utils/notifications';
import { ForgotPassValidationSchema } from '../../utils/validations';
import ROUTES from '../routes';
import LangSelect from '../../components/misc/lang-select';
import { AppConstants } from '../../utils/constants';

const apiOptions = {
  url: '/auth/user/send-otp',
  method: 'post',
};

const ForgotPassword = props => {
  const toast = useNotify();
  const { t } = useI18nContext();
  const navigate = useNavigate();
  const tenantBackground = storage.get(AppConstants.AUTH.TENANT).assets
    .sideBannerImageUrl;

  const onSuccess = resp => {
    toast({
      title: t('forgot_pass_success_toast_title'),
      description: resp.message,
      status: 'success',
    });
    navigate(ROUTES.RESET_PASSWORD, { state: { email: getValues().email } });
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    errors,
    getValues,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: ForgotPassValidationSchema(t),
  });

  return (
    <Flex h="100vh" bgColor="#F5F6F9">
      <Box position="absolute" top="1em" marginLeft="10vw">
        <LangSelect />
      </Box>
      <Flex flex={1} px={32} direction="column" justify="center">
        <Heading as="h1" size="lg">
          {t('forgot_pass_heading')}
        </Heading>
        <Box as={'form'} maxW={440} onSubmit={onSubmit} noValidate="noValidate">
          <Stack spacing={6} my={16}>
            <ThemeInput
              label={t('login_form_label_email')}
              name="email"
              error={errors.email}
              register={register}
              type="email"
              isRequired
            />

            <Button type="submit" isLoading={isLoading} w="full">
              <Text fontSize="sm" fontWeight="bold" fontFamily="raleway">
                {t('reset_pass_heading')}
              </Text>
            </Button>
          </Stack>
        </Box>
      </Flex>
      <Box
        flex={2}
        h="full"
        bgImage={`url(${tenantBackground})`}
        bgRepeat="no-repeat"
        bgSize="100% 100%"
      />
    </Flex>
  );
};

export default ForgotPassword;
