import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import { Center, Flex, Grid, GridItem } from '@chakra-ui/react';
import { useLocation, useParams } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import Loader from '../../components/misc/loader';
import PageTitle from '../../components/misc/page-title';
import useQueryParams from '../../hooks/query-params';
import DataService from '../../services/data-service';
import DocumentInfo from '../onboarding/steps/document-upload/document-info';
import BankInfo from '../onboarding/steps/personal-info/bank-info';
import DependentInfo from '../onboarding/steps/personal-info/dependent-info';
import EmergencyInfo from '../onboarding/steps/personal-info/emergency-info';
import HealthInfo from '../onboarding/steps/personal-info/health-info';
import PersonalInfo from '../onboarding/steps/personal-info/personal-info';



const DocumentDashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams();
  const { t } = useI18nContext();
  const [userInfo, setUserInfo] = useState({});
  const location = useLocation();
  const params = useQueryParams(location);
  const [currentStep] = useState((params.screen * 1) || 0);

  const getUserInfo = () => {
    setIsLoading(true);
    DataService.get(`/user/${slug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data');
        setUserInfo(data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  // function RenderDocumentSelector() {
  //   return (
  //     <Select
  //       size="md"
  //       variant="outline"
  //       bg="white"
  //       onChange={e => setDocumentType(e.target.value)}
  //     >
  //       <option value="PersonalInfo">{t('label_personal_info')}</option>
  //       <option value="BankInfo">{t('label_bank_info')}</option>
  //       <option value="DependentInfo">{t('label_dependent_info')}</option>
  //       <option value="EmergencyInfo">{t('label_emergency_info')}</option>
  //       <option value="HealthInfo">{t('label_health_info')}</option>
  //       <option value="AdminDocUpload">{t('label_document_info')}</option>
  //     </Select>
  //   );
  // }



  const renderDocumentSelector = (currentStep) => {
    let component =<HealthInfo t={t} userSlug={slug} isEdit />; 

    switch (currentStep) {
      case 0:
        component = <PersonalInfo t={t} userSlug={slug} isEdit />
        break;
      case 1:
        component = <DocumentInfo t={t} userSlug={slug} isEdit />
        break;
      case 2:
        component =<BankInfo t={t} userSlug={slug} isEdit />
        break;
      case 3:
        component =<DependentInfo t={t} userSlug={slug} isEdit />
        break;
      case 4:
        component =<EmergencyInfo t={t} userSlug={slug} isEdit />
        break;
      case 5:
        component =<HealthInfo t={t} userSlug={slug} isEdit />
        break;
    }

    return component;
  };

 

  useEffect(()=>getUserInfo(), []);

  return (
    <Flex flex={2} direction="column">
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center">
          <Center>
            <Loader colorScheme={'blue'} />
          </Center>
        </Flex>
      ) : (
        <Grid templateColumns="14fr 1fr">
          <GridItem px={10} pb={6}>

             <PageTitle style={{mb:6}} title={`${Bro(userInfo).iCanHaz('personalInfo.firstName')} ${t(
                'edit_profile_header_text'
              )}`} />
            {renderDocumentSelector(currentStep)}
          </GridItem>
          {/* <GridItem py={10}>{RenderDocumentSelector()}</GridItem> */}
        </Grid>
      )}
    </Flex>
  );
};

export default DocumentDashboard;
