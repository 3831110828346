import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  ClientDataGrid,
  defaultGridOptions,
} from '@bit/sixsprints.utils.kg-grid';
import { Flex } from '@chakra-ui/react';
import { Link } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import {
  ActionContainer,
  GridButton,
  GridInput,
} from '../../../components/misc/grid-ui';
import PageTitle from '../../../components/misc/page-title';
import colors from '../../../theme/foundations/colors';
import useNotify from '../../../utils/notifications';
import ROUTES from '../../routes';

const ManageLocation = () => {
  const { t } = useI18nContext();
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const toast = useNotify();
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });

  const previewCallback = val => {
    setPreviewData(val);
    setIsOpen(true);
  };

  const onSubmitImport = () => {
    setIsOpen(false);
  };

  const transformColDef = fields => {
    const modifiedColDef = fields
      .map(field => {
        const { cellRenderer, ...rest } = field;
        switch (field.colId) {
          case 'locationName.en':
            return {
              ...rest,
              cellRendererFramework: params => {
                return (
                  <Link
                    to={`${params.data.slug}`}
                    state={params.data}
                    className="link"
                  >
                    {params.value ? params.value : ''}
                  </Link>
                );
              },
            };

          case 'locationName.jp':
            return {
              ...rest,
              cellRendererFramework: params => {
                return <>{Bro(params).iCanHaz('data.departmentName.ja')}</>;
              },
            };

          default:
            return field;
        }
      })
      .filter(d => !!d);
    return modifiedColDef;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flex={1} direction="column">
      <PageTitle style={{ mb: 2 }} title={t('manage_location')} />
      <DataGrid
        gridProps={{ ...defaultGridOptions, transformColDef, previewCallback }}
        beans={{ restApi, storage, i18n: t, notificationService: simpleToast }}
        dataProps={{ collection: 'location', csvName: 'location_list' }}
        uiComponents={{
          ActionContainer,
          Button: GridButton,
          Input: GridInput,
          hideColumnState: true,
          hideEdit: true,
          hideImport: true,
          hideExport: true,
          addNewPath: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_LOCATION,
        }}
      />

      {isOpen && (
        <Rodal
          visible={true}
          showCloseButton={false}
          animation="slideRight"
          width={95}
          height={95}
          measure="%"
          className="filter-modal"
          customStyles={{ overflow: 'auto' }}
          style={{ marginBottom: 20 }}
        >
          <ClientDataGrid
            beans={{
              restApi,
              storage,
              i18n: t,
              notificationService: simpleToast,
            }}
            dataProps={{
              collection: 'location',
              rowData: previewData,
              onImportSuccessCallback: onSubmitImport,
              hideAddNew: true,
              colWidth: 150,
            }}
            uiComponents={{
              ActionContainer,
              Button: GridButton,
            }}
          />
        </Rodal>
      )}
    </Flex>
  );
};

export default ManageLocation;
