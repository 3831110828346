import { storage, useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Stack,
} from '@chakra-ui/react';
import Bro from 'brototype';
import { useEffect } from 'react';
import { useState } from 'react';
import ThemeDateInput from '../../../../../components/form-elements/date-picker/date-picker';
import SelectThemeInput from '../../../../../components/form-elements/select-input';
// import ThemeAsyncSelectInput from '../../../../../components/form-elements/theme-async-select-input';
import ThemeInput from '../../../../../components/form-elements/theme-input';
import ListHeader from '../../../../../components/misc/list-header';
import ForwardArrow from '../../../../../icons/forward-arrow';
import DataService from '../../../../../services/data-service';
import { AppConstants } from '../../../../../utils/constants';
import _ from 'lodash';
import moment from 'moment';
import useNotify from '../../../../../utils/notifications';
import { DegreeInfoSchema } from '../../../../../utils/validations';
import Loader from '../../../../../components/misc/loader';
// import DocumentUploadCertificate from '../../../../../components/form-elements/document-uploader';

const GraducationInfo = ({ t, prev, next, slug,isEdit }) => {
  const toast = useNotify();
  const { langCode } = useI18nContext();

  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);

  const [specialization, setSpecialization] = useState(null);
  const [courseType, setCourseType] = useState(null);
  
  const [coursesIndexes, setCoursesIndexes] = useState([0]);
  const [coursesCounter, setCoursesCounter] = useState(0);
  
  const [courseList,setCourseList] = useState([])

  const addCourses = () => {
    setCoursesIndexes(prevIndexes => [...prevIndexes, coursesCounter]);
    setCoursesCounter(prevCounter => prevCounter + 1);
  };

  const removeCourses = index => () => {
    setCoursesIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    setCoursesCounter(prevCounter => prevCounter - 1);
    const currFormValues = getValues();
    let data = currFormValues.courses.filter(
      (item, _index) => _index !== index
    );
    currFormValues.courses = data;

    resetForm(currFormValues);
  };

  const apiOptions = {
    url: `/user/education-details-degree?userSlug=${slug}`,
    method: 'put',
  };

  const transformRequest = values => {
    const data = { ...values };
    if (!_.isEmpty(data)) {
      _.forEach(data.courses, function (obj) {
        _.set(obj, 'passingYear', moment(obj.passingYear).format('YYYY'));
        _.set(obj,'certificate',[])
      });
    }
    return data.courses;
  };

  const onSuccess = resp => {
    if (!isEdit) {
    // const userData = storage.get(Keys.USER);
    // userData.personalInfo = userInfo;
    // storage.set(Keys.USER, userData);
    toast({
      title: t('degree_info_success_toast'),
      description: resp.message,
      status: 'success',
    });
    next();
    }else{
      toast({
        title: t('degree_info_updated_toast'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    control,
    errors,
    setValue,
    getValues,
    reset: resetForm,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: DegreeInfoSchema(t),
    transformRequest,
  });

  // const addCertificate = () => {
  //   setCertificateIndexes(prevIndexes => [...prevIndexes, certificateCounter]);
  //   setCertificateCounter(prevCounter => prevCounter + 1);
  // };

  // const removeCertificate = index => () => {
  //   setCertificateIndexes(prevIndexes => [
  //     ...prevIndexes.filter((item, _index) => _index !== index),
  //   ]);
  //   setCertificateCounter(prevCounter => prevCounter - 1);
  //   const currFormValues = getValues();
  //   let data =
  //     currFormValues &&
  //     currFormValues.courses.map(
  //       item =>
  //         item.certificates &&
  //         item.certificates.filter((item, _index) => _index !== index)
  //     );
  //   // currFormValues.courses.certificates = data;
  //   resetForm(currFormValues);
  // };

  const transformList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.qualification[langCode];
      return obj;
    });
    return transformedList;
  };


  const promiseOptions = async () => {
    let data = await DataService.post('/qualification/search', {
      page: 0,
      size: 80,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    setCourseList(transformList(data));
    // return data;
  };

  useEffect(() => {
    promiseOptions()
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode]);

  useEffect(() => {
    let specializationArr = storage.get(
      AppConstants.AUTH.CONFIG
    ).specialization;
    let courseTypeArr = storage.get(AppConstants.AUTH.CONFIG).courseType;
    setSpecialization(specializationArr);
    setCourseType(courseTypeArr);
  }, []);

  const [isDocLoading, setIsDocLoading] = useState(false);
  
  const getUserInfo = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${slug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz(
          'data.data.educationDetails.degreeDtails'
        );
       
        setCoursesIndexes(data.length > 0 ? data : [1]);

        let newData =
          data &&
          data.map(item => {
            item.passingYear = moment(item.passingYear.toString()).toDate();
            return item;
          });
        resetForm({
          courses: newData,
        });
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  useEffect(getUserInfo, []);

  return (
    (isDocLoading && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Center>
          <Loader />
        </Center>
      </Flex>
    )) || (
      <Flex flexGrow={1}>
        <Flex flex={1} direction="column" mr={'10em'}>
          {/* {!isEdit && ( */}
          <Flex direction="column" alignItems="flex-end" mb={2}>
            <Button leftIcon={<AddIcon />} onClick={addCourses}>
              {t('button_add')}
            </Button>
          </Flex>
          {/* )} */}
          <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate">
            {coursesIndexes.map((item, index) => {
              const fieldName = `courses[${index}]`;
              return (
                <Stack>
                  <ListHeader
                    msg={` ${t('education_details_label')} ${index + 1} `}
                    index={coursesIndexes.length}
                    onBtnClick={index == 0 ? null : removeCourses(index)}
                  />
                  <Grid templateColumns="repeat(6, 1fr)" gap={6}>
                    <GridItem colSpan={6}>
                    <SelectThemeInput
                        options={courseList}
                        label={t('course_label')}
                        name={`${fieldName}.courseSlug`}
                        error={Bro(errors).iCanHaz(
                          `courses.${index}.courseSlug`
                        )}
                        register={register}
                        valueKey="slug"
                        labelKey="name"
                        type="text"
                        mt={5}
                        isRequired
                        placeholder={t('select_course_label')}
                      />
                    </GridItem>
                    <GridItem colSpan={3}>
                      <SelectThemeInput
                        options={specialization}
                        label={t('specialization_label')}
                        name={`${fieldName}.specialization`}
                        error={Bro(errors).iCanHaz(
                          `courses.${index}.specialization`
                        )}
                        register={register}
                        valueKey="key"
                        labelKey="label"
                        type="text"
                        mt={5}
                        isRequired
                        placeholder={t('select_specialization_label')}
                      />
                    </GridItem>
                    <GridItem colSpan={3}>
                      <SelectThemeInput
                        options={courseType}
                        label={t('course_type_label')}
                        name={`${fieldName}.courseType`}
                        error={Bro(errors).iCanHaz(
                          `courses.${index}.courseType`
                        )}
                        register={register}
                        valueKey="key"
                        labelKey="label"
                        type="text"
                        mt={5}
                        isRequired
                        placeholder={t('select_course_type')}
                      />
                    </GridItem>
                    <GridItem colSpan={4} mt={3}>
                      <ThemeInput
                        label={t('university_college_label')}
                        name={`${fieldName}.university`}
                        error={Bro(errors).iCanHaz(
                          `courses.${index}.university`
                        )}
                        register={register}
                        type="text"
                        mt={1}
                        isRequired
                      />
                    </GridItem>

                    <GridItem colSpan={2} mt={3}>
                      <ThemeDateInput
                        label={t('label_passing_year')}
                        name={`${fieldName}.passingYear`}
                        placeholder={t('select_an_option')}
                        showTimeSelect={false}
                        error={Bro(errors).iCanHaz(
                          `courses.${index}.passingYear`
                        )}
                        showYearPicker
                        defaultValue={''}
                        dateFormat="yyyy"
                        //    maxDate={new Date()}
                        yearItemNumber={10}
                        control={control}
                      />
                    </GridItem>
                    {/* <GridItem colSpan={6} mt={3} mr={'38%'}>
                      <Heading as="h5" size="sm" mt={6}>
                        {t('certificate_label')}
                      </Heading>
                      <Stack spacing={2}>
                        {certificateIndexes.map((item, index) => {
                          const fieldsName = `${fieldName}.certificate[${index}]`;
                          return (
                            <Stack key={index}>
                              {index > 0 && (
                                <ListHeader
                                  msg={`${t('certificate')} ${index + 1}`}
                                  index={certificateIndexes.length}
                                  onBtnClick={removeCertificate(index)}
                                />
                              )}
                              <Flex
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Box w="50%">
                                  <SelectThemeInput
                                    options={documentList}
                                    name={`[${fieldsName}].documentName`}
                                    labelKey="label"
                                    valueKey="key"
                                    register={register}
                                    type="text"
                                    placeholder={t('select_an_option')}
                                    mt={4}
                                  />
                                </Box>
                                <Box w="47%" pt="1em">
                                  <DocumentUploadCertificate
                                    borderRadius={'12px'}
                                    register={register}
                                    control={control}
                                    defaultValue=""
                                    name={`[${fieldsName}].url`}
                                    url={'file/upload'}
                                    // error={Bro(errors).iCanHaz(
                                    //   `documents.${index}.url`
                                    // )}
                                    index={index}
                                    // certificateList={
                                    //   // userInfo &&
                                    //   // userInfo.map(item => {
                                    //   //   return item.certificate;
                                    //   // })
                                    //   // userInfo && Bro(userInfo[index]).iCanHaz('certificate')
                                    // }
                                    uploadValue={url =>
                                      setValue(`[${fieldsName}].url`, url)
                                    }
                                    //  showViewIcon='true'
                                  />
                                </Box>
                              </Flex>
                            </Stack>
                          );
                        })}
                        <Stack display="inline">
                          <Heading
                            as="h5"
                            size="sm"
                            display="inline"
                            onClick={addCertificate}
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            {t('add_more_certificate_label')}
                          </Heading>
                        </Stack>
                      </Stack>
                    </GridItem>*/}
                  </Grid>
                </Stack> 
              );
            })}

            <Flex justifyContent="space-between" alignItems="center" my={24}>
              {isEdit ? (
              <Flex flex={1} justifyContent={'space-between'}>
                 <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="ghost"
                  onClick={prev}
                >
                  {t('button_text_back')}
                </Button>
                <Button align="flex-end" type="submit" px={10} isLoading={isLoading}>
                  {t('button_text_save')}
                </Button>
              </Flex>
            ) : (
              <>
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="ghost"
                  onClick={prev}
                >
                  {t('button_text_back')}
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  rightIcon={<ForwardArrow />}
                >
                  {t('button_text_save_next')}
                </Button>
              </>
               )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
    )
  );
};
export default GraducationInfo;
