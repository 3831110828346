import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  ClientDataGrid,
  defaultGridOptions,
} from '@bit/sixsprints.utils.kg-grid';
import { Flex } from '@chakra-ui/react';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import { Link } from '@reach/router';
import {
  ActionContainer,
  GridButton,
  GridInput,
} from '../../../../components/misc/grid-ui';
import PageTitle from '../../../../components/misc/page-title';
import useNotify from '../../../../utils/notifications';
import ROUTES from '../../../routes';

const ManageCountry = () => {
  const { t } = useI18nContext();
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const toast = useNotify();
  const [gridApi, setGridApi] = useState(null);
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });

  const previewCallback = (val, gridApi) => {
    setPreviewData(val);
    setIsOpen(true);
    setGridApi(gridApi);
  };

  const onSubmitImport = () => {
    gridApi.refreshServerSideStore({ purge: true });
    setIsOpen(false);
  };

  const transformColDef = fields => {
    const modifiedColDef = fields
      .map(field => {
        const { cellRenderer, ...rest } = field;
        switch (field.colId) {
          case 'country.en':
            return {
              ...rest,
              cellRendererFramework: params => {
                return (
                  <Link
                    to={`${params.data.slug}`}
                    state={params.data}
                    className="link"
                  >
                    {params.value ? params.value : ''}
                  </Link>
                );
              },
            };
          default:
            return field;
        }
      })
      .filter(d => !!d);
    return modifiedColDef;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flex={1} direction="column">
      <PageTitle style={{ mb: 2 }} title={t('manage_countries')} />
      <DataGrid
        gridProps={{ ...defaultGridOptions, transformColDef, previewCallback }}
        beans={{ restApi, storage, i18n: t, notificationService: simpleToast }}
        dataProps={{ collection: 'country', csvName: 'user_list' }}
        uiComponents={{
          ActionContainer,
          Button: GridButton,
          Input: GridInput,
          hideColumnState: true,
          hideEdit: true,
          addNewPath: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_COUNTRY,
        }}
      />

      {isOpen && (
        <Rodal
          visible={true}
          showCloseButton={false}
          animation="slideRight"
          width={95}
          height={95}
          measure="%"
          className="filter-modal"
          customStyles={{ overflow: 'auto' }}
          style={{ marginBottom: 20 }}
        >
          <ClientDataGrid
            beans={{
              restApi,
              storage,
              i18n: t,
              notificationService: simpleToast,
            }}
            dataProps={{
              collection: 'country',
              rowData: previewData,
              onImportSuccessCallback: onSubmitImport,
              hideAddNew: true,
              importUrl: 'country/excel',
              colWidth: 150,
            }}
            uiComponents={{
              ActionContainer,
              Button: GridButton,
            }}
          />
        </Rodal>
      )}
    </Flex>
  );
};

export default ManageCountry;
