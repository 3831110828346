import { storage, useFormController } from '@bit/sixsprints.core.beans-web';
import { Box, Flex, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import DocUploadTable from '../../components/form-elements/doc-upload-table';
import ThemeHeading from '../../components/misc/heading';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { AppConstants } from '../../utils/constants';
import Bro from 'brototype';
import { useNavigate } from '@reach/router';
import {
  transformArray,
  mergeDocs,
} from '../onboarding/steps/personal-info/health-info';

import * as Yup from 'yup';
import _ from 'lodash';
import useNotify from '../../utils/notifications';
import DataService from '../../services/data-service';
import Loader from '../../components/misc/loader';

const AdminDocUpload = ({ userSlug, isEdit }) => {
  const [docList, setDocList] = useState([]);
  const [isDocLoading, setIsDocLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const configDocs = storage.get(AppConstants.AUTH.CONFIG).proofDocuments;

  const { t } = useI18nContext();

  const apiOptions = {
    url: `/user/document-upload?userSlug=${userSlug}`,
    method: 'put',
  };

  const toast = useNotify();

  const transformRequest = values => {
    let documents = transformArray(docList, values);
    return { ...values, ...{ documents } };
  };

  const onSuccess = resp => {
    toast({
      title: t('document_upload_success_toast'),
      description: resp.message,
      status: 'success',
    });
    let respData = Bro(resp).iCanHaz('documentUpload.documents');
    let newDocList = mergeDocs(respData, docList);
    setDocList(newDocList);
    if (!isEdit) {
      navigate('/home', { replace: true });
    }
  };

  const getDynamicYupValidation = fields => {
    let schemaObj = {};
    fields.forEach(f => {
      schemaObj[f.key] = f.isRequired
        ? Yup.string().required(t('form_field_required'))
        : Yup.string();
    });
    return Yup.object(schemaObj);
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    setValue,
    errors,
    watch,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: getDynamicYupValidation(configDocs),
    transformRequest,
  });

  const getUserInfo = () => {
    setIsDocLoading(true);
    let docs = _.cloneDeep(configDocs);
    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const documentList =
          Bro(resp).iCanHaz('data.data.documentUpload.documents') || [];
        setUserData(documentList);
        if (documentList.length) {
          let newDocList = mergeDocs(documentList, docs);
          setDocList(newDocList);
        } else {
          setDocList(configDocs);
        }
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  const onDeleteIconClick = (rowData) => {
    setIsDocLoading(true);
    let docs = _.cloneDeep(configDocs);
    let tempUserData = { ...userData };
    let rowIndex = tempUserData.findIndex(
      item => item.documentName === rowData.documentName
    );
    let tempRowData = {
      date: 0,
      documentStatus: null,
      documentName: null,
      url: null,
    };
    tempUserData[rowIndex] = tempRowData;
    DataService.put(`/user/document-upload?userSlug=${userSlug}`, tempUserData)
      .then(resp => {
        toast({
          title: t('file.delete.success'),
          description: resp.message,
          status: 'success',
        });
        const data =
          Bro(resp).iCanHaz('data.data.documentUpload.documents') || [];
        setUserData(data);
        const documentList = Bro(data).iCanHaz('documents') || [];
        if (documentList.length) {
          let newDocList = mergeDocs(documentList, docs);
          setDocList(newDocList);
        } else {
          setDocList(configDocs);
        }
        setIsDocLoading(false);
      })

      .catch(err => {
        toast({
          title: t('file.delete.error'),
          description: err.message,
          status: 'error',
        });
        setIsDocLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(getUserInfo,[]);

  return (
    <Flex flexGrow={1} direction="column">
      <Flex flex={1} direction="column">
        {!isEdit && (
          <ThemeHeading
            heading={t('document_upload_heading')}
            subHeading={t('document_upload_subheading')}
            mb={10}
          />
        )}

        <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate">
          {isDocLoading ? (
            <Flex justify="center" alignItems="center">
              <Loader />
            </Flex>
          ) : (
            <DocUploadTable
              columns={['Document Type', 'Uploaded Date', 'Status', 'Action']}
              rows={docList}
              register={register}
              formSetValue={setValue}
              formWatcher={watch}
              formErrors={errors}
              deleteClickCallback={onDeleteIconClick}
            />
          )}

          <Flex justifyContent="space-between" alignItems="center" mt={12}>
            <Flex flex={1} justifyContent={'flex-end'}>
              <Button type="submit" isLoading={isLoading}>
                {t('update')}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default AdminDocUpload;
