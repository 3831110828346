import { useFormController } from '@bit/sixsprints.core.beans-web';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Flex} from '@chakra-ui/react';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import DocUploadTable from '../../../../components/form-elements/doc-upload-table';
import ThemeHeading from '../../../../components/misc/heading';
import ForwardArrow from '../../../../icons/forward-arrow';
import DataService from '../../../../services/data-service';
import useNotify from '../../../../utils/notifications';
import { useNavigate } from '@reach/router';
import Loader from '../../../../components/misc/loader';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import _ from 'lodash';
import { DocumentsValidationSchema } from '../../../../utils/validations';


const DocumentInfo = ({ t, next, prev, userSlug,userData, isEdit }) => {
  const { langCode } = useI18nContext();
  const navigate = useNavigate();
  const toast = useNotify();
  
  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
  const [indexes, setIndexes] = useState([1]);
  const [counter, setCounter] = useState(0);
  const [isDocLoading, setIsDocLoading] = useState(false);
  const [documentList,setDocumentList] = useState(null);

  const apiOptions = {
    url: `/user/document-upload?userSlug=${userSlug}`,
    method: 'put',
  };


  const transformRequest = values => {
    const data={...values}
    if (!_.isEmpty(data)) {
      _.forEach(data.documents, function (obj,index) {
        delete(obj.date)
      });
      return data;
    }
    return [null];
  };

  const onSuccess = resp => {
    if (!isEdit) {
    toast({
      title: t('document_upload_successfully_toast'),
      description: resp.message,
      status: 'success',
    });
       navigate('/home', { replace: true });
      // next();
    }
    else{
      toast({
        title: t('document_updated_successfully_toast'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  // const getDynamicYupValidation = fields => {
  //   let schemaObj = {};

  //   fields.forEach(f => {
  //     schemaObj[f.key] = Yup.object().shape({
  //       documentStatus: Yup.string(),
  //       url: f.isRequired
  //         ? Yup.string().required(t('form_field_required'))
  //         : Yup.string(),
  //     });
  //   });

  //   return Yup.object(schemaObj);
  // };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    setValue,
    errors,
    watch,
    control,
    reset:resetForm,
    getValues,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: DocumentsValidationSchema(t),
    transformRequest,
  });


  const getUserInfo = () => {
    setIsDocLoading(true);

    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const data =
          Bro(resp).iCanHaz('data.data.documentUpload.documents') || [];
        setIndexes(data.length>0 ? data : [0]);
        resetForm({
          documents: data,
        });
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };


  const transformDocumentList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.documentName[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptionsForDocument = async () => {
    let data = await DataService.post('/documentList/search', {
      page: 0,
      size: 30,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    setDocumentList(transformDocumentList(data));
    // return data;
  };

  useEffect(() => {
    promiseOptionsForDocument()
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode]);
  
  useEffect(getUserInfo,[]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const addDocuments = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeDocuments = index => () => {
    setIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    setCounter(prevCounter => prevCounter - 1);
    const currFormValues = getValues();

    resetForm({
      documents: currFormValues.documents.filter(
        (item, _index) => _index !== index
      ),
    });
  };


  return (
    <Flex flexGrow={1}>

      <Flex flex={1} direction="column" mr={'10em'}>
        {!isEdit ? (
          <ThemeHeading
            heading={t('document_upload_heading')}
            subHeading={t('document_upload_subheading')}
            mb={10}
          />
        ):( <ThemeHeading mb={10} heading={`${Bro(userData).iCanHaz('firstName')} ${t(
          'edit_profile_header_text'
        )}`} subHeading={t('details_subheading')}/>) }

        <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate">
          {isDocLoading ? (
            <Flex justify="center" alignItems="center">
              <Loader />
            </Flex>
          ) : (
            <DocUploadTable
              columns={['Document Name','Uploaded Date', 'Action']}
              register={register}
              control={control}
              t={t}
              documentList={documentList}
              formSetValue={setValue}
              formWatcher={watch}
              getValues={getValues}
              resetForm={resetForm}
              indexes={indexes}
              counter={counter}
              addDocuments={addDocuments}
              removeDocuments={removeDocuments}
              errors={errors}
              Bro={Bro}
              // canReview={isEdit}
            />
           )}
          <Flex justifyContent="space-between" alignItems="center" mt={10}>
            {isEdit ? (
              <Flex flex={1} justifyContent={'flex-end'}>
                <Button align="flex-end" px={8} type="submit" isLoading={isLoading}>
                  {t('button_text_save')}
                </Button>
              </Flex>
            ) : (
              <>
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="ghost"
                  onClick={prev}
                >
                  {t('button_text_back')}
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  rightIcon={<ForwardArrow />}
                >
                  {t('button_text_save_next')}
                </Button>
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

export default DocumentInfo;
