import { useFormController, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Stack,
} from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import ThemeDateInput from '../../components/form-elements/date-picker/date-picker';
import SelectThemeInput from '../../components/form-elements/select-input';
import ThemeAsyncSelectInput from '../../components/form-elements/theme-async-select-input';
import ThemeTextAreaInput from '../../components/form-elements/theme-textarea';
import useNotify from '../../utils/notifications';
import { AttendanceValidationSchema } from '../../utils/validations';
import { AppConstants } from '../../utils/constants';
import { SearchIcon } from '@chakra-ui/icons';
import DataService from '../../services/data-service';
import { getMonthStartDate, getUser, isAdmin } from '../../utils/helpers';
import PageTitle from '../../components/misc/page-title';
import moment from 'moment';
import ThemeInput from '../../components/form-elements/theme-input';
import ThemeRadioGroup from '../../components/form-elements/radio-input';

const apiOptions = {
  url: '/attendance/regularization',
  method: 'post',
};

const editApiOptions = {
  url: '/attendance/regularization/update',
  method: 'put',
};

const ApplyRegularisation = () => {
  const navigate = useNavigate();
  const toast = useNotify();
  const { t } = useI18nContext();
  const { slug } = useParams();
  const { data: requestData } = useLocation().state;
  const isEditMode = slug ? true : false;
  const { slug: userSlug } = getUser();
  const category = storage.get(
    AppConstants.AUTH.CONFIG
  ).attendanceRegularizationCategory;

  const transformRequest = values => {
    const data = isEditMode
      ? Object.assign(requestData, values)
      : { ...values };
    data.status = values.status;
    data.fromDate = new Date(data.fromDate).getTime();
    data.toDate = isAdmin()
      ? new Date(data.toDate).getTime()
      : new Date(data.fromDate).getTime();
    if (data.status) {
      data.status = 'APPROVED';
    } else {
      data.status = 'PENDING';
    }

    if (!isEditMode && isAdmin()) {
      data.userSlug = data.userSlug.slug;
    } else {
      if (!data.userSlug) {
        data.userSlug = userSlug;
      }
    }
    return data;
  };

  const onSuccess = resp => {
    toast({
      title: t('apply_regularisation_success_toast'),
      description: resp.message,
      status: 'success',
    });
    navigate(-1);
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const checkAdmin = isAdmin();

  const { isLoading, onSubmit, register, control, errors } = useFormController({
    onSuccess,
    onError,
    apiOptions: isEditMode ? editApiOptions : apiOptions,
    yupSchema: AttendanceValidationSchema({ t, checkAdmin }),
    transformRequest,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [requestData]);

  const transformUserList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.userId = item.userId;
      obj.name = item.personalInfo.firstName + ' ' + item.personalInfo.lastName;
      return obj;
    });
    return transformedList;
  };

  const promiseOptions = async text => {
    let data = await DataService.post('/user/search', {
      page: 0,
      size: 100,
      filterModel: {
        'personalInfo.firstName': {
          filterType: 'text',
          type: null,
          filter: text,
        },
        'personalInfo.lastName': {
          filterType: 'text',
          type: null,
          filter: text,
        },
      },
    }).then(resp => resp.data.data.content);
    data = transformUserList(data);
    return data;
  };
  
  const getMinDate = () => {
    if (isAdmin()) {
      // return getMonthStartDate(moment().subtract(1, 'months'));
      return getMonthStartDate(moment().startOf('year').add(-1,'years'))
    } else if (moment().format('D') <= 7) {
      return getMonthStartDate(moment().subtract(1, 'months'));
    } else {
      return getMonthStartDate();
    }
  };

  const [startDate, setStartDate] = useState(getMinDate());
  const [attendanceType, setAttendanceType] = useState('false');

  const holidayOptions = [
    { label: 'Yes', key: 'true' },
    { label: 'No/Not Applicable', key: 'false' },
  ];

  return (
    <Flex flex={1} direction="column">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle
          title={
            !isEditMode
              ? t('heading_regularisation')
              : t('edit_heading_regularisation')
          }
        />
      </Flex>
      <Box
        as={'form'}
        onSubmit={onSubmit}
        noValidate="noValidate"
        bgColor="white"
        borderRadius="16px"
      >
        <Stack mr={'10em'}>
          <Grid templateColumns="repeat(12, 1fr)" p={10} gap={8}>
            {isAdmin() && (
              <GridItem colSpan={6}>
                {!isEditMode ? (
                  <ThemeAsyncSelectInput
                    showKeyInLabel={true}
                    promiseOptions={promiseOptions}
                    labelKey="name"
                    valueKey="slug"
                    name="userSlug"
                    labelIdKey="userId"
                    defaultValue=""
                    control={control}
                    error={errors.userSlug}
                    label={t('employee_search')}
                    placeholder={<SearchIcon />}
                    isRequired
                  />
                ) : (
                  <ThemeInput
                    label={t('employee_search')}
                    disabled={true}
                    defaultValue={requestData.userName}
                    type="text"
                  />
                )}
              </GridItem>
            )}

            <GridItem colSpan={6}>
              <SelectThemeInput
                options={category}
                label={t('regularisation_category')}
                labelKey="label"
                valueKey="key"
                defaultValue={requestData && requestData.category}
                name="category"
                error={errors.category}
                register={register}
                type="text"
                mt={4}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={6}>
              <ThemeDateInput
                showMonthDropdown={false}
                showYearDropdown={false}
                label={isAdmin() ? t('label_from') : t('regularisation_date')}
                name="fromDate"
                defaultValue={requestData && new Date(requestData.fromDate)}
                maxDate={moment().subtract(1, 'days').valueOf()}
                minDate={getMinDate()}
                error={errors.fromDate}
                showTimeSelect={false}
                onSelect={date => setStartDate(date)}
                dateFormat="yyyy/MM/dd"
                control={control}
                mt={1}
                isRequired
              />
            </GridItem>
            {isAdmin() && (
              <GridItem colSpan={6}>
                <ThemeDateInput
                  showMonthDropdown={false}
                  showYearDropdown={false}
                  label={t('label_to')}
                  name="toDate"
                  defaultValue={requestData && new Date(requestData.toDate)}
                  showTimeSelect={false}
                  error={errors.toDate}
                  dateFormat="yyyy/MM/dd"
                  control={control}
                  minDate={startDate}
                  maxDate={moment().subtract(1, 'days').valueOf()}
                  mt={1}
                  isRequired
                />
              </GridItem>
            )}
            <GridItem colSpan={6}>
              <ThemeRadioGroup
                name="overlapHoliday"
                radioGroupValue={isEditMode ? requestData && requestData.overlapHoliday:attendanceType}
                radioGroupOnChange={e => setAttendanceType(e)}
                label={t('overlap_holiday')}
                error={Bro(errors).iCanHaz('overlapHoliday')}
                register={register}
                isRequired
                options={holidayOptions}
                direction="row"
                spacing="20"
              />
            </GridItem>

            <GridItem colSpan={12} mt={3}>
              <ThemeTextAreaInput
                type="text"
                label={t('label_reason')}
                name="reason"
                defaultValue={requestData && requestData.reason}
                error={Bro(errors).iCanHaz('reason')}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            {isAdmin() && (
              <GridItem colSpan={8}>
                <Flex>
                  {/* <Checkbox
                  mr={5}
                  defaultChecked={requestData && requestData.overlapHoliday}
                  name="overlapHoliday"
                  ref={register}>
                  {t("overlap_holiday")}
                </Checkbox> */}

                  <Checkbox name="status" ref={register}>
                    {t('auro_approve')}
                  </Checkbox>
                </Flex>
              </GridItem>
            )}
          </Grid>
        </Stack>
        <Flex px={10} pb={10}>
          <Button type="submit" isLoading={isLoading} mr={10}>
            {t('btn_apply')}
          </Button>
          <Button onClick={onCancel} variant="ghost">
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ApplyRegularisation;
