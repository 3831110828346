import React from 'react'

function SvgEyeOff() {
    return (
        <div>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.33889 3.29C5.25045 3.19676 5.14545 3.1228 5.02988 3.07234C4.91432 3.02188 4.79046 2.99591 4.66538 2.99591C4.5403 2.99591 4.41644 3.02188 4.30088 3.07234C4.18532 3.1228 4.08032 3.19676 3.99187 3.29C3.81324 3.47831 3.71289 3.7337 3.71289 4C3.71289 4.2663 3.81324 4.5217 3.99187 4.71L9.33254 10.34C8.99465 11.0026 8.86828 11.7624 8.97215 12.5067C9.07603 13.2509 9.40463 13.9401 9.90924 14.4721C10.4138 15.004 11.0676 15.3504 11.7736 15.4599C12.4797 15.5694 13.2004 15.4362 13.829 15.08L19.1696 20.71C19.2578 20.8037 19.3627 20.8781 19.4783 20.9289C19.5939 20.9797 19.7179 21.0058 19.8431 21.0058C19.9684 21.0058 20.0924 20.9797 20.208 20.9289C20.3235 20.8781 20.4285 20.8037 20.5166 20.71C20.6056 20.617 20.6761 20.5064 20.7243 20.3846C20.7725 20.2627 20.7972 20.132 20.7972 20C20.7972 19.868 20.7725 19.7373 20.7243 19.6154C20.6761 19.4936 20.6056 19.383 20.5166 19.29L5.33889 3.29ZM12.2543 13.5C11.8769 13.5 11.515 13.342 11.2481 13.0607C10.9813 12.7794 10.8313 12.3978 10.8313 12V11.93L12.3112 13.49L12.2543 13.5Z" fill="#24293D"/>
            <path d="M12.4622 17C8.38321 17.1 5.70813 13.41 4.87336 12C5.46763 11.0007 6.18183 10.0865 6.99824 9.27999L5.61327 7.87C4.5426 8.93347 3.62537 10.1564 2.89076 11.5C2.8075 11.652 2.76367 11.8245 2.76367 12C2.76367 12.1755 2.8075 12.348 2.89076 12.5C3.48839 13.59 6.6852 19 12.2725 19H12.5097C13.5603 18.9671 14.5973 18.7404 15.5737 18.33L14.0749 16.75C13.5478 16.8962 13.0069 16.98 12.4622 17Z" fill="#24293D"/>
            <path d="M21.6165 11.5C21.0094 10.39 17.6608 4.81999 11.9976 4.99999C10.947 5.03285 9.90994 5.25961 8.93359 5.66999L10.4324 7.24999C10.9595 7.10382 11.5004 7.01997 12.045 6.99999C16.1146 6.88999 18.7896 10.59 19.6339 12C19.025 13.0023 18.2947 13.9167 17.4616 14.72L18.894 16.13C19.9781 15.0693 20.9082 13.8462 21.6544 12.5C21.732 12.3445 21.7693 12.1702 21.7627 11.9946C21.756 11.8191 21.7056 11.6485 21.6165 11.5Z" fill="#24293D"/>
            </svg>

        </div>
    )
}

export default SvgEyeOff
