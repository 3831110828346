import * as React from "react"

const ProfilePic=(props)=> {
  return (
    <svg
      width={110}
      height={110}
      viewBox="0 0 110 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.5} fill="#CDD0DF">
        <path d="M51 46.75a17 17 0 100-34 17 17 0 000 34zM76.5 89.25A4.25 4.25 0 0080.75 85a29.75 29.75 0 00-59.5 0 4.25 4.25 0 004.25 4.25h51z" />
      </g>
    </svg>
  )
}

export default ProfilePic
