import React, { useEffect } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import LeftNav from '../left-nav/left-nav';
import Header from '../../components/misc/header';
import PerfectScrollbar from 'react-perfect-scrollbar';

const Home = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Flex h="100vh">
      <Box
        display={{ base: 'none', md: 'flex' }}
        w={'18vw'}
        maxW={260}
        bg="white"
      >
        <LeftNav />
      </Box>
      <Flex flex={1} bg="gray.100" overflowY="auto" direction="column">
        <PerfectScrollbar>
          <Box px={{base:2, sm:4, md:14}} pt={4}>
            <Header />
            {React.cloneElement(props.children)}
          </Box>
        </PerfectScrollbar>
      </Flex>
    </Flex>
  );
};

export default Home;
