import React, { useEffect } from 'react';
import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  defaultGridOptions,
} from '@bit/sixsprints.utils.kg-grid';
import { Box, Flex, Heading, Tooltip } from '@chakra-ui/react';
import Bro from 'brototype';
import {
  ActionContainer,
  GridButton,
  GridInput,
} from '../../components/misc/grid-ui';
import useNotify from '../../utils/notifications';
import 'rodal/lib/rodal.css';
import { AppConstants } from '../../utils/constants';
import { getDateFormatter, getUser } from '../../utils/helpers';
import ApprovedIcon from '../../icons/check-circle';
import CancelledIcon from '../../icons/banned-circle';
import RejectIcon from '../../icons/cross-circle';
import PendingIcon from '../../icons/pending-circle';
import Legends from './legends';



import StatusSelect from '../../components/form-elements/status-select';
import { Link } from '@reach/router';

const ManageAttdRegularisation = () => {
  const { t } = useI18nContext();
  const toast = useNotify();
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });

  const statusOptions = storage.get(AppConstants.AUTH.CONFIG).attendanceRegularizationStatus;
  const role = storage.get(AppConstants.AUTH.ROLE);
  const isAdmin = role === AppConstants.ROLE.ADMIN;
  var visibilityFilter = null;
  if (!isAdmin) {
    const { slug } = getUser();
    visibilityFilter = { "userSlug": { values: [slug], filterType: "set" } };
  }

  const transformColDef = fields => {
    const modifiedColDef = fields.map(field => {
      const { cellRenderer, ...rest } = field;
      switch (field.colId) {
        case 'userName':
          field.sortable = false;
          field.filter = false;
          return field;
        case 'userDepartment':
          field.sortable = false;
          field.filter = false;
          return field;
        case 'userDesignation':
          field.sortable = false;
          field.filter = false;
          return field;
        case 'slug':
          return {
            ...rest,
            cellRendererFramework: params => {
              return (
                <Flex justifyContent="flex-start">
                  <Box
                  >
                    {params.data.status === AppConstants.REGULARISATION_STATUS.APPROVED && <ApprovedIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.PENDING && <PendingIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.CANCELLED && <CancelledIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.REJECTED && <RejectIcon />}
                  </Box>
                  <Box ml="10px">
                    {params.data.status === AppConstants.REGULARISATION_STATUS.PENDING ? <Link
                      to={`/home/attendance/regularisation/request/${Bro(params).iCanHaz(
                        'data.slug'
                      )}`}
                      className="link"
                      state={{
                        data: params.data ? params.data : {},
                      }}
                    >
                      {params.value ? params.value : ''}
                    </Link> : params.value}
                  </Box>
                </Flex>
              );
            },
          };
        case 'fromDate':
        case 'toDate':
          return {
            ...rest,
            filter:false,
            cellRendererFramework: params => {
              return getDateFormatter(params.value, 'DD/MM/YYYY');
            },
          };
        case 'status':
          return {
            ...rest,
            cellRendererFramework: params => {
              return (<StatusSelect
                gridApi={params.api}
                url="/attendance/regularization?propChanged=status"
                data={Bro(params).iCanHaz('data')}
                isDisabled={params.value === AppConstants.REGULARISATION_STATUS.APPROVED}
                defaultValue={Bro(params).iCanHaz('data.status')}
                options={statusOptions}
              />);
            },
          };

        case 'reason':
          return {
            ...rest,
            filter:false,
            cellRendererFramework: params => {
              return (
                <Tooltip hasArrow label={params.value} bg="white" color="black" placement="left">
                  {params.value}
                </Tooltip>
              );
            },
          };



        default:
          return field;
      }
    }).filter(d => !!d);

    return modifiedColDef;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const PageTitle = () => (
    <Heading as="h3" fontSize="24px" mb={4} fontWeight="600">
      {t("manage_regularisation")}
    </Heading>
  );

  return (
    <Flex flex={1} direction="column">
      <DataGrid
        gridProps={{ ...defaultGridOptions, transformColDef }}
        beans={{ restApi, storage, i18n: t, notificationService: simpleToast, defaultFilter: visibilityFilter }}
        dataProps={{ collection: 'attendance/regularization', csvName: 'attendance-regularization' }}
        uiComponents={{
          Title: PageTitle,
          ActionContainer,
          Button: GridButton,
          Input: GridInput,
          hideEdit: true,
          hideColumnState: true,
          hideExport: true,
          hideImport: true,
          hideDelete: true,
          hideAddNew: true
        }}
      />
      <Legends />
    </Flex>
  );
};

export default ManageAttdRegularisation;
