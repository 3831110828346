import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import React from 'react';
import ForwardArrow from '../../icons/forward-arrow';

const BodyContent = ({ onCloseBtnClick, isFirstTimeUser, username = '' }) => {
  return (
    <Box>
      <Flex>
        <Box
          bgImage="url('/assets/modal/calendar.png')"
          bgSize="cover"
          h={'3.5em'}
          w={'3.5em'}
        />
        <Spacer />
        <Box
          bgImage="url('/assets/img/search.png')"
          bgSize="cover"
          h={'3.5em'}
          w={'3.5em'}
        />
      </Flex>
      <Center py={5}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {isFirstTimeUser ? (
            <>
              <Heading as="h1" fontSize={'1.5rem'} fontFamily={'Raleway'}>
                Welcome Aboard, {username}
              </Heading>
              <Text fontSize={'0.85rem'} textAlign="center" mt="8px">
                we hope you will have an amazing time working with us.
              </Text>
            </>
          ) : (
            <>
              <>
                <Heading as="h1" fontSize={'1.5rem'} fontFamily={'Raleway'}>
                  Welcome Back, {username}
                </Heading>
                <Text fontSize={'0.85rem'} textAlign="center" mt="8px">
                  please complete your profile.
                </Text>
              </>
            </>
          )}

          {/* <Center>
            <UnorderedList my="30px">
              <ListItem fontSize={'0.85rem'} my={2}>
                Lorem Ipsum is simply dummy text of the printing and.
              </ListItem>
              <ListItem fontSize={'0.85rem'} my={2}>
                Lorem Ipsum is simply dummy text of the printing and.
              </ListItem>
              <ListItem fontSize={'0.85rem'} my={2}>
                Lorem Ipsum is simply dummy text of the printing and.
              </ListItem>
            </UnorderedList>
          </Center> */}
        </Flex>
      </Center>
      <Center>
        <Button
          onClick={onCloseBtnClick}
          borderRadius="12px"
          size={'lg'}
          width={'55%'}
          maxWidth={'300px'}
          px={'30px'}
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontSize={'0.85rem'} fontFamily={'Raleway'}>
            {'Continue'}
          </Text>
          <ForwardArrow />
        </Button>
      </Center>
      <Flex>
        <Box
          bgImage="url('/assets/modal/curriculum.png')"
          bgSize="cover"
          h={'3.5em'}
          w={'3.5em'}
        />
        <Spacer />
        <Box
          bgImage="url('/assets/modal/megaphone.png')"
          bgSize="cover"
          h={'3.5em'}
          w={'3.5em'}
        />
      </Flex>
    </Box>
  );
};

export default BodyContent;
