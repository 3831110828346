import { useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Button } from '@chakra-ui/button';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, GridItem, Stack } from '@chakra-ui/layout';
import { useLocation, useNavigate, useParams } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react'
import ThemeAsyncSelectInput from '../../../components/form-elements/theme-async-select-input';
import ThemeInput from '../../../components/form-elements/theme-input';
import PageTitle from '../../../components/misc/page-title';
import DataService from '../../../services/data-service';
import useNotify from '../../../utils/notifications';
import { DepartmentSchema } from '../../../utils/validations';
import ROUTES from '../../routes';

const apiOptions = {
  url: '/department',
  method: 'post',
};

const apiOptionsPut = {
  url:'/department/update',
  method: 'put',
}

const AddDepartment=()=>{
    const navigate = useNavigate();
    const toast = useNotify();
    const { t ,langCode, languages } = useI18nContext();
    const langList = Object.keys(languages);
  
    const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
    const { slug } = useParams();
    const isEdit = slug ? true : false;

 
  
    const DepartmentData = useLocation().state;


    const navigateToPrevScreen=()=>{
      navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_DEPARTMENT);
    }


    const transformRequest=(data)=>{
      const dataDto={
      departmentName:data.departmentName,
      mail:data.mail,
      departmentLeadDto:{
        firstName:data.departmentLeadSlug && data.departmentLeadSlug.firstName || null,
        lastName: data.departmentLeadSlug && data.departmentLeadSlug.lastName || null,
      },
      departmentLeadSlug: data.departmentLeadSlug && data.departmentLeadSlug.slug || null,
      parentDepartment: data.departmentSlug && data.departmentSlug.name,
      parentDepartmentSlug: data.departmentSlug && data.departmentSlug.slug,
      slug:isEdit ? DepartmentData.slug:null,
      id:isEdit ? DepartmentData.id:null
      }
      return dataDto;
    }

    const onSuccess = resp => {
      toast({
        title: isEdit? t('department_Details_updated'):t('department_created'),
        description: resp.message,
        status: 'success',
      });
      navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_DEPARTMENT);
    };
  
    const onError = resp => {
      toast({
        title: t('Error'),
        description: resp.message,
        status: 'error',
      });
    };
  

    const {
      isLoading,
      onSubmit,
      register,
      errors,
      reset: resetForm,
      control
    } = useFormController({
      onSuccess,
      onError,
      apiOptions:isEdit ? apiOptionsPut:apiOptions,
       yupSchema: DepartmentSchema(t),
      transformRequest,
    });

    useEffect(() => {
      setShouldLoadOptions(!shouldLoadOptions);
    }, [langCode]);

    const transformUserList = data => {
      let transformedList = data.map(item => {
        let obj = {};
        obj.slug = item.slug;
        obj.userId = item.userId;
        obj.name=  item.personalInfo.firstName +' '+ item.personalInfo.lastName;
        obj.firstName = item.personalInfo.firstName;
        obj.lastName= item.personalInfo.lastName;
        return obj;
      });
      return transformedList;
    };
  
    const promiseOptions = async text => {
      let data = await DataService.post('/user/search', {
        page: 0,
        size: 10,
        filterModel: {
          'personalInfo.firstName': {
            filterType: 'text',
            type: null,
            filter: text,
          },
          'personalInfo.lastName': {
            filterType: 'text',
            type: null,
            filter: text,
          },
        },
      }).then(resp => resp.data.data.content);
      data = transformUserList(data);
      return data;
    };
  
  
    const transformStateList = data => {
      let transformedStateList = data.map(item => {
        let obj = {};
        obj.slug = item.slug;
        obj.name = item.departmentName[langCode];
        return obj;
      });
      return transformedStateList;
    };
  
    const promiseOptionsForDepartment = async () => {
      let data = await DataService.post('department/search', {
        page: 0,
        size: 10,
        filterModel: {},
      }).then(resp => resp.data.data.content);
      data = transformStateList(data);
      return data;
    };


    return(
        <Flex flex={1} direction="column">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle title={isEdit ? t('edit_department'):t('add_department')} />
      </Flex>
      <Box
        as={'form'}
        bgColor="white"
        borderRadius="16px"
        onSubmit={onSubmit}
        noValidate="noValidate"
      >
        <Stack>
          <Grid templateColumns="repeat(10, 1fr)" p={12} gap={8}>
          {langList.map(item => (
            <GridItem key={item} colSpan={4}>
              <ThemeInput
                defaultValue={isEdit ? DepartmentData.departmentName[`${item}`] : null}
                label={`${t('department_name')} - ${item.toUpperCase()}`}
                name={`departmentName.${item}`}
                type="text"
                mt={1}
                register={register}
                error={Bro(errors).iCanHaz(`departmentName.${item}`)}
                isRequired
              />
            </GridItem>
             ))}
            <GridItem colSpan={4}>
            <ThemeInput
                defaultValue={isEdit ? DepartmentData.mail : null}
                label={t('mail_alias_label')}
                name={'mail'}
                type="text"
                mt={1}
                error={Bro(errors).iCanHaz(`mail`)}
                register={register}
              />
            </GridItem>
            <GridItem colSpan={4}>
            <ThemeAsyncSelectInput
                  // showKeyInLabel={true}
                  key={shouldLoadOptions}
                  defaultValue={isEdit ?  {name: DepartmentData.departmentLeadDto ? DepartmentData.departmentLeadDto.firstName+' '+DepartmentData.departmentLeadDto.lastName :null, slug:DepartmentData ? DepartmentData.departmentLeadSlug :null}:null}
                  promiseOptions={promiseOptions}
                  labelKey="name"
                  valueKey="slug"
                  // labelIdKey="userId"
                  name="departmentLeadSlug"
                  control={control}
                  // error={errors.userSlug}
                  label={t('department_lead')}
                  placeholder={<SearchIcon />}
                />
            </GridItem>
            <GridItem colSpan={4}>
            <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue={isEdit ? {name: DepartmentData.parentDepartment, slug: DepartmentData.parentDepartmentSlug}:null}
                promiseOptions={promiseOptionsForDepartment}
                labelKey="name"
                valueKey="slug"
                name="departmentSlug"
                control={control}
                // error={errors.stateSlug}
                label={t('parent_department')}
                placeholder={<SearchIcon />}
              />
            </GridItem>
           
          </Grid>
        </Stack>
        <Flex px={10} pb={10} ml={2}>
          <Button px="2rem" type="submit" isLoading={isLoading} mr={5}>
            {t('button_text_save')}
          </Button>
          <Button onClick={navigateToPrevScreen} variant="ghost">
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
    )
}
export default AddDepartment;