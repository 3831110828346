import { useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Grid, GridItem, Stack } from '@chakra-ui/layout';
import { useLocation, useNavigate, useParams } from '@reach/router';
import Bro from 'brototype';
import React from 'react'
import ThemeInput from '../../../components/form-elements/theme-input';
import PageTitle from '../../../components/misc/page-title';
import useNotify from '../../../utils/notifications';
import { DesignationSchema } from '../../../utils/validations';
import ROUTES from '../../routes';


const apiOptions = {
  url: '/designation',
  method: 'post',
};

const apiOptionsPut = {
  url:'/designation/update',
  method: 'put',
}

const AddDesignation=()=>{
    const navigate = useNavigate();
    const toast = useNotify();
    const { t,languages } = useI18nContext();
    const langList = Object.keys(languages);

    const { slug } = useParams();
    const isEdit = slug ? true : false;

    const onNavigateToPrevScreen=()=>{
      navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_DESIGNATION);
    }
 
  
    const DesignationData = useLocation().state;

    const transformRequest=(values)=>{
      const data=Object.assign(DesignationData,values)
      return data;
    }

    const onSuccess = resp => {
      toast({
        title: isEdit? t('designation_Details_updated'):t('designation_created'),
        description: resp.message,
        status: 'success',
      });
      navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_DESIGNATION);
    };
  
    const onError = resp => {
      toast({
        title: t('Error'),
        description: resp.message,
        status: 'error',
      });
    };

    const {
      isLoading,
      onSubmit,
      register,
      errors    } = useFormController({
      onSuccess,
      onError,
      apiOptions:isEdit ? apiOptionsPut:apiOptions,
      yupSchema: DesignationSchema(t),
      transformRequest,
    });

    return(
        <Flex flex={1} direction="column">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle title={ isEdit ? t('edit_designation'):t('add_designation')} />
      </Flex>
      <Box
        as={'form'}
        bgColor="white"
        borderRadius="16px"
        onSubmit={onSubmit}
        noValidate="noValidate"
      >
        <Stack>
          <Grid templateColumns="repeat(10, 1fr)" p={12} gap={8}>
          {langList.map(item => (
            <GridItem key={item} colSpan={4}>
              <ThemeInput
                defaultValue={isEdit ? DesignationData.designationName[`${item}`] : null}
                label={`${t('designation_name')}- ${item.toUpperCase()}`}
                name={`designationName.${item}`}
                type="text"
                register={register}
                error={Bro(errors).iCanHaz(`designationName.${item}`)}
                mt={1}
                isRequired
              />
            </GridItem>
          ))}
            <GridItem colSpan={4}>
            <ThemeInput
                defaultValue={isEdit ? DesignationData.mail : null}
                label={t('mail_alias_label')}
                name={'mail'}
                type="text"
                register={register}
                mt={1}
                error={Bro(errors).iCanHaz(`mail`)}
              />
            </GridItem>
          </Grid>
        </Stack>
        <Flex px={10} pb={10} ml={2}>
          <Button px="2rem" type="submit" mr={5} isLoading={isLoading}>
            {t('button_text_save')}
          </Button>
          <Button variant="ghost" onClick={onNavigateToPrevScreen}>
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
    )
}
export default AddDesignation;