import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

const ModalDialog = ({
  // isOpen,
  bodyContent,
  showCloseBtn,
  scrollBehavior,
  ...rest
}) => {
  const { isOpen = true } = useDisclosure();
  return (
    <Modal
      size="xl"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      isCentered={true}
      scrollBehavior={scrollBehavior}
      {...rest}
    >
      <ModalOverlay />

      <ModalContent borderRadius={'20px'} w="80vw">
        {showCloseBtn && <ModalCloseButton _focus="none"/>}

        <ModalBody p={5} mt={showCloseBtn ? 5 : 0}>
          {bodyContent}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalDialog;
