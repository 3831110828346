import * as React from "react";
import { Icon } from "@chakra-ui/react"


function ChevDownIcon(props) {
  return (
    <Icon
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M3.984 4a.934.934 0 01-.305-.05.823.823 0 01-.26-.143L.234 1.142A.675.675 0 01.061.925a.576.576 0 010-.512C.1.332.16.258.234.196A.825.825 0 01.494.05a.937.937 0 01.611 0 .825.825 0 01.26.145L3.985 2.4 6.611.283a.804.804 0 01.262-.161.928.928 0 01.636-.009.816.816 0 01.269.154.662.662 0 01.172.23.567.567 0 01-.037.533.703.703 0 01-.203.212L4.526 3.813A.883.883 0 013.984 4z"
      fill="currentColor"
    />
    </Icon>
  )
}

export default ChevDownIcon;

