import { Box, Flex, Stack, Text } from '@chakra-ui/react';
import Bro from 'brototype';
import { useState } from 'react';
import ThemeHeading from '../../../../components/misc/heading';
import GraducationInfo from './education/graduation-info';
import TenthInfo from './education/tenth-info';
import TwelfthInfo from './education/twelfth-info';

const EducationInfo = ({ t, prev, next ,userSlug,userData,isEdit }) => {
  const [step, setStep] = useState(1);
  const nextStep = () => {
    if (step < 3) setStep(step + 1);
  };

  const previousStep = () => {
    if (step >= 1) setStep(step - 1);
  };

  const educationStep = step;
  const renderCourse = step => {
    let component = <TenthInfo t={t} prev={prev} next={nextStep} slug={userSlug} isEdit={isEdit}/>;
    switch (step) {
      case 1:
        component = <TenthInfo t={t} prev={prev} next={nextStep} slug={userSlug} isEdit={isEdit}/>;
        break;
      case 2:
        component = <TwelfthInfo t={t} prev={previousStep} next={nextStep} slug={userSlug} isEdit={isEdit}/>;
        break;
      case 3:
        component = <GraducationInfo t={t} prev={previousStep} next={next} slug={userSlug} isEdit={isEdit}/>;
        break;
    }
    return component;
  };
  return (
    <Flex flex={1} direction="column">
        {!isEdit ? (<ThemeHeading
                heading={t('education_details_label')}
                subHeading={t('details_subheading')}
              />):(<ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
                'edit_profile_header_text'
              )}`} subHeading={t('details_subheading')}/>)}
      <Stack spacing={6} w="100%" mt={4}>
        <Flex my="0.5em" flexDirection="row" w="100%" alignItems="center">
          <Text>{t('10th')}</Text>
          <Box
            mx="1em"
            w="4em"
            border={
              educationStep > 1 ? '1px solid #101C4D' : '1px solid #9EA0AB'
            }
            h="1px"
          ></Box>
          <Text color={educationStep >= 2 ? '#101C4D' : '#9EA0AB'}>
            {t('12th')}
          </Text>
          <Box
            mx="1em"
            w="4em"
            border={
              educationStep > 2 ? '1px solid #101C4D' : '1px solid #9EA0AB'
            }
            h="1px"
          ></Box>
          <Text color={educationStep >= 3 ? '#101C4D' : '#9EA0AB'}>
            {t('Degree')}
          </Text>
        </Flex>
      </Stack>
      {renderCourse(educationStep)}
    </Flex>
  );
};
export default EducationInfo;
