import { storage } from '@bit/sixsprints.core.beans-web';
import { Keys } from '@bit/sixsprints.utils.data';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from '@reach/router';
import Bro from 'brototype';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ThemeButton from '../../components/buttons/theme-button';
import AppointmentForm from '../../components/dashboard/announcement-form';
import QuickLink from '../../components/dashboard/quick-links';
import CalendarBlueIcon from '../../components/misc/calendar-blue-svg';
import Loader from '../../components/misc/loader';
import PageTitle from '../../components/misc/page-title';
import ProfilePic from '../../components/misc/profile-pic';
import EditIcon from '../../icons/dashboard/edit-icon';
// Icons
//requests
import DataService from '../../services/data-service';
import colors from '../../theme/foundations/colors';
import { getDateFormatter } from '../../utils/helpers';
import ROUTES from '../routes';

// const Card = ({ icon: Icon, label, count }) => {
//   return (
//     <AspectRatio ratio={1.22} bg="white" borderRadius="22px">
//       <Flex direction="column" align="flex-start" p={{ md: 3, xl: 5 }}>
//         <AspectRatio ratio={1} width="29.6%">
//           <Icon width="100%" height="auto" />
//         </AspectRatio>
//         <Spacer />
//         <Text
//           fontWeight="bold"
//           fontSize={{ md: '14px', xl: '18px' }}
//           lineHeight={{ md: '16px', xl: '25px' }}
//           mb={1}
//         >
//           {count}
//         </Text>
//         <Text
//           color="gray.200C"
//           fontSize={{ md: '12px', xl: '16px' }}
//           lineHeight={{ md: '14px', xl: '22px' }}
//         >
//           {label}
//         </Text>
//       </Flex>
//     </AspectRatio>
//   );
// };

const AdminDashboard = () => {
  const { t } = useI18nContext();
  const [isloader, setIsLoader] = useState(true);
  const [announcementInfo, setAnnouncementInfo] = useState([]);
  const [announcementObj, setAnnouncementObj] = useState({});
  const [createValue, setCreateValue] = useState(null);
  const navigate = useNavigate();

  const { personalInfo } = Bro(storage.get(Keys.USER)).iCanHaz('data')
    ? Bro(storage.get(Keys.USER)).iCanHaz('data')
    : storage.get(Keys.USER);

  // const [options] = useState({
  //   labels: [t('Men'), t('Women')],

  //   colors: ['#0461FF', '#CF142B'],
  //   legend: {
  //     position: 'bottom',
  //   },
  // });
  // // const [series] = useState([70, 30]);
  // const [type] = useState('donut');
  const { isOpen, onOpen, onClose } = useDisclosure();
  //card

  //request
  // const requests = [
  //   {
  //     icon: ProfileUpdate,
  //     label: t('Profile_Update_Requests'),
  //     count: 80,
  //     path: '/',
  //   },
  //   {
  //     icon: RequestLeave,
  //     label: t('Requested_Leaves'),
  //     count: 30,
  //     path: '/',
  //   },
  //   {
  //     icon: OtherLeave,
  //     label: t('Other_Requests'),
  //     count: 10,
  //     path: '/',
  //   },
  // ];
  //links
  const links = [
    {
      title: t('Add_Employee'),

      path: '/home/employee/add-new',
    },
    {
      title: t('Profile_Update_Requests'),
      path: '/home/employee/add-new',
    },
    {
      title: t('Special_Days'),

      path: '/home/employee/add-new',
    },

    {
      title: t('Payroll_Management'),

      path: '/',
    },
  ];

  const getAnnouncementData = () => {
    DataService.post('announcement/search', { page: 0, size: 3 })
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.content');
        const currentDate = moment().format('LL')
        const TodayDate = new Date(currentDate).getTime();
        let announcementInfo = data.map((index,key)=>{ return index.endTime >= TodayDate ? index : null  })
        const announcementInfoData = announcementInfo.filter(function (el) {
          return el != null;
        });
        setAnnouncementInfo(announcementInfoData);
        setIsLoader(false);
      })
      .catch(() => {
        setIsLoader(false);
      });
  };
  useEffect(() => getAnnouncementData(), []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flex={1} direction="column">
      <PageTitle
        style={{ mb: 6 }}
        title={`${t('welcome')}, ${personalInfo.firstName} ${
          personalInfo.middleName || ''
        }  ${personalInfo.lastName}`}
      />

      <Box flex={1}>
        <Grid templateColumns={{base:"1fr", md:"3fr 2fr"}} gap="26px" h="100%" pb={12}>
          <GridItem rowSpan={2}>
            <Grid height="100%" column={3} templateRows="auto 1fr" gap="26px">
              <ThemeButton
                iconProp={{ px: '2', py: '1' }}
                px={5}
                py={20}
                d="flex"
                width="100%"
                style={{
                  textAlign: 'center',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
                flexDirection="column"
                onClick={() =>
                  navigate(
                    ROUTES.DASHBOARD +
                      ROUTES.ATTENDANCE +
                      ROUTES.MARK_ATTENDANCE
                  )
                }
                bg="white"
                color={"brand.500"}
                text={t('button_mark_attendance')}
              />
              <ThemeButton
                iconProp={{ px: '2', py: '1' }}
                px={5}
                py={20}
                d="flex"
                width="100%"
                style={{
                  textAlign: 'center',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
                flexDirection="column"
                onClick={() =>
                  navigate(ROUTES.DASHBOARD + ROUTES.LEAVE + ROUTES.ADD_LEAVE)
                }
                bg="white"
                color="#ED5019"
                text={t('btn_apply_leave')}
              />
              <ThemeButton
                iconProp={{ px: '2', py: '1' }}
                px={5}
                py={20}
                d="flex"
                width="100%"
                style={{
                  textAlign: 'center',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
                flexDirection="column"
                onClick={() =>
                  navigate(
                    ROUTES.DASHBOARD +
                      ROUTES.ATTENDANCE +
                      ROUTES.REGULARISATION_REQUEST
                  )
                }
                bg="white"
                color={colors.brand[900]}
                text={t('apply_regularisation')}
              />

              <GridItem
                colSpan={3}
                bg="white"
                borderRadius="22px"
                pt={5}
                px={6}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mb={10}
                >
                  <Heading as="h5" pt={1} fontSize="18px" lineHeight="26px">
                    {t('announcement.heading')}
                  </Heading>

                  <Center>
                    <Button
                      leftIcon={<AddIcon />}
                      onClick={() => {
                        setCreateValue('true');
                        onOpen();
                      }}
                      alignSelf="flex-end"
                    >
                      {t('announcement_add_btn')}
                    </Button>
                  </Center>
                </Flex>
                <Drawer
                  size="sm"
                  isOpen={isOpen}
                  placement="right"
                  onClose={onClose}
                  scrollBehavior="inside"
                >
                  <DrawerOverlay>
                    <DrawerContent>
                      <Box position="absolute" right="10px">
                        <DrawerCloseButton _focus="none"/>
                      </Box>
                      <DrawerBody>
                        {createValue === 'true' ? (
                          <AppointmentForm onCloseBtnClick={onClose} />
                        ) : (
                          <AppointmentForm
                            announcementObj={announcementObj}
                            isEdit
                          />
                        )}
                      </DrawerBody>
                    </DrawerContent>
                  </DrawerOverlay>
                </Drawer>
                {isloader ? (
                  <Flex
                    flex={1}
                    justifyContent="center"
                    alignItems="center"
                    h="254px"
                  >
                    <Loader colorScheme={'blue'} />
                  </Flex>
                ) : (
                  announcementInfo.length !== 0 &&
                  announcementInfo.map((item, index) => {
                    return (
                      <Flex
                        key={index}
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Box
                          mx={10}
                          bgColor="brand.500"
                          w="100%"
                          h="70px"
                          borderRadius="18px"
                          flexDirection="row"
                          mb={5}
                          onClick={() => {
                            setAnnouncementObj(item);
                            setCreateValue('false');
                            onOpen();
                          }}
                        >
                          <Box
                            as={Flex}
                            mt={3}
                            ml="10px"
                            justifyContent="space-between"
                          >
                            <Box as={Flex}>
                              <CalendarBlueIcon />
                              <Box mx="2px" mb="5px" ml="10px">
                                <Text color="#FFFFFF"  fontSize="14px">
                                  {item.title}
                                </Text>
                                <Text color="#FFFFFF"  fontSize="12px">
                                  {item.description}
                                </Text>
                              </Box>
                            </Box>
                            <Box mt="1px" mr="10px">
                              <Text color="#FFFFFF"  fontSize="14px">
                                {getDateFormatter(item.startTime, 'DD/MM/YYYY')}
                                -{getDateFormatter(item.endTime, 'DD/MM/YYYY')}
                              </Text>
                            </Box>
                          </Box>
                        </Box>
                      </Flex>
                    );
                  })
                )}
              </GridItem>
            </Grid>
          </GridItem>

          <GridItem
            rowSpan={2}
            borderRadius="22px"
          >
            <Flex flexDirection="column"  h="100%">
              <Box flex={1} bg="#FFFFFF" borderRadius="22px" p={5}>
                <Flex direction="column" h="100%">
                  <Flex justify="space-between" align="center" mb={16}>
                    <Heading as="h5" fontSize="18px" lineHeight="26px">
                      {t('my_profile')}
                    </Heading>
                    <EditIcon
                      boxSize="28px"
                      bg="none"
                      size="sm"
                      boxShadow="none"
                      variant="ghost"
                      onClick={() => {
                        navigate('/profile');
                      }}
                      cursor='pointer'
                    />
                  </Flex>

                  <Center flex={1} flexDirection="column" mb={14}>
                    <Flex alignSelf="stretch" justify="center">
                      <Box borderRadius="full">
                        <ProfilePic
                         personalInfo={personalInfo}
                          containerStyle={{
                            width: '135px',
                            height: '135px',
                          }}
                        />
                      </Box>
                    </Flex>
                    <Text
                      fontSize="20px"
                      fontWeight="bold"
                      lineHeight="27px"
                      mb="10px"
                    >
                      {Bro(personalInfo).iCanHaz('firstName')}{' '}
                      {Bro(personalInfo).iCanHaz('lastName')}
                    </Text>
                  </Center>

                  <Spacer />

                  <Box>

                    <Text
                      color="gray.200"
                      fontSize="12px"
                      lineHeight="20px"
                      mb={3}
                    ></Text>
                    <Text
                      color="gray.200"
                      fontSize="12px"
                      lineHeight="20px"
                      mb={3}
                    ></Text>
                    <Text
                      color="gray.200"
                      fontSize="12px"
                      lineHeight="20px"
                    ></Text>
                  </Box>
                </Flex>
              </Box>

              <Box borderRadius="22px" background="#FFFFFF" my={10}>
                <Heading as="h4" size="md" ml="26px" mt="26px" mb="15px">
                  {t('quick_links')}
                </Heading>
                <Flex flexWrap="wrap" justifyContent="space-between" px="26px">
                  {links.map((item, i) => (
                    <QuickLink {...item} key={i} />
                  ))}
                </Flex>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </Flex>
  );
};

export default AdminDashboard;
