import * as React from "react";
import { Icon } from "@chakra-ui/react"


const UserIcon
 =  (props) => (
    <Icon
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M15.313 4a4.024 4.024 0 003.957 4.759c.245-.001.49-.025.73-.07v7.973C20 20.015 18.021 22 14.662 22H7.346C3.98 22 2 20.016 2 16.662V9.355C2 6.002 3.979 4 7.346 4h7.967zm.121 5.994l-.117.008a.73.73 0 00-.488.294l-2.409 3.1-2.743-2.16a.74.74 0 00-1.047.14l-2.954 3.81a.72.72 0 00-.159.457l.003.11a.738.738 0 001.36.32l2.471-3.196 2.744 2.151a.74.74 0 001.055-.131l2.858-3.688v-.018a.754.754 0 00-.14-1.045.73.73 0 00-.551-.144l.117-.008z"
        fill="currentColor"
      />
      <path
        opacity={0.4}
        d="M19.5 7a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        fill="currentColor"
      />
    </Icon>
)

export default UserIcon

