import { useFormController } from '@bit/sixsprints.core.beans-web';
import {
  Box,
  Button,
  Center,
  Flex,
  GridItem,
  IconButton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import useNotify from '../../utils/notifications';
import { AcceptLeaveValidationSchema } from '../../utils/validations';
import DataService from '../../services/data-service';
import { storage } from '@bit/sixsprints.core.beans-web';
import Bro from 'brototype';
import { AppConstants } from '../../utils/constants';
import { navigate, useParams } from '@reach/router';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { getDateFormatter } from '../../utils/helpers';
import ThemeTextAreaInput from '../../components/form-elements/theme-textarea';
import { Table, Tbody, Thead, Th, Tr, Td } from '@chakra-ui/react';
import { Keys } from '@bit/sixsprints.utils.data';
import EyeIcon from '../../icons/eye-icon';
import ModalDialog from '../../components/modals/modal';
import PdfViewer from '../../components/misc/pdf-viewer';
import PageTitle from '../../components/misc/page-title';

const ViewLeave = () => {
  const toast = useNotify();
  const { t } = useI18nContext();
  const { slug } = useParams();
  const [leaveInfo, setLeaveInfo] = useState({});
  const [isAccepted, setIsAccepted] = useState(false);
  const [docVal,setDocumentVal] = useState(0);
  const role = storage.get(AppConstants.AUTH.ROLE);
  const isAdmin = role === AppConstants.ROLE.ADMIN;

  const apiOptions = {
    url: `/leave/update`,
    method: 'put',
  };

  const getLeaveInfo = () => {
    DataService.get(`/leave/${slug}`)
      .then(resp => {
        var data = Bro(resp).iCanHaz('data.data');
        setDocumentVal(data.documentUpload.documents.length)
        let dayTypeOptionsArr = storage.get(AppConstants.AUTH.CONFIG)
          .leaveDayType;
        let leaveStatusOptionsArr = storage.get(AppConstants.AUTH.CONFIG)
          .leaveStatus;
        data.dayTypeValue = dayTypeOptionsArr.find(
          d => d.key === data.dayType
        ).label;
        data.leaveStatusValue = leaveStatusOptionsArr.find(
          d => d.key === data.status
        ).label;
        setLeaveInfo(data);
      });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageSrc, setImageSrc] = useState('');

  const onViewClick = rowData => {
    setImageSrc(
      `${rowData}&${AppConstants.AUTH.AUTH_TOKEN_KEY}=${storage.get(
        Keys.TOKEN
      )}`
    );
    onOpen();
  };

  const onSuccess = resp => {
    toast({
      title: t('leave_status_success_toast'),
      description: resp.message,
      status: 'success',
    });
    navigate(-1);
  };

  const transformRequest = values => {
    const data = {
      ...leaveInfo,
      comment: values.comment,
      status: isAccepted ? 'APPROVED' : 'REJECTED',
    };
    return data;
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const { isLoading, onSubmit, register, errors } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: AcceptLeaveValidationSchema(t),
    transformRequest,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getLeaveInfo();
  }, []);

  return (
    <Flex flexGrow={1}>
      <Flex flex={1} direction="column">
        <PageTitle title={t('heading_leave_description')} />
        <Box as={'form'} mt={5} onSubmit={onSubmit} noValidate="noValidate">
          <Box bg="white" p={6} borderRadius="16px">
            <Table fontSize="14px" size="sm">
              <Tbody>
                <Tr>
                  <Td>
                    <Text color="#ACACAC">Leave Raised By</Text>
                  </Td>
                  <Td>
                    <Text>{leaveInfo.leaveRaisedBy}</Text>
                  </Td>
                  <Td>
                    <Text color="#ACACAC">Day Type</Text>
                  </Td>
                  <Td>
                    <Text>{leaveInfo.dayTypeValue}</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="#ACACAC">Reporting Manager</Text>
                  </Td>
                  <Td>
                    <Text>{leaveInfo.manager}</Text>
                  </Td>
                  <Td>
                    <Text color="#ACACAC">Leave Type</Text>
                  </Td>
                  <Td>
                    <Text>{leaveInfo.leaveType}</Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="#ACACAC">From Date</Text>
                  </Td>
                  <Td>
                    <Text>
                      {getDateFormatter(leaveInfo.startTime, 'DD/MM/YYYY')}
                    </Text>
                  </Td>
                  <Td>
                    <Text color="#ACACAC">To Date</Text>
                  </Td>
                  <Td>
                    <Text>
                      {getDateFormatter(leaveInfo.endTime, 'DD/MM/YYYY')}
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="#ACACAC">Duration</Text>
                  </Td>
                  <Td>
                    <Text>{leaveInfo.noOfDays + ' ' + (leaveInfo.noOfDays>1 ? 'Days':'Day')}</Text>
                  </Td>
                  <Td>
                    <Text color="#ACACAC">Applied On</Text>
                  </Td>
                  <Td>
                    <Text>
                      {getDateFormatter(leaveInfo.dateCreated, 'DD/MM/YYYY')}
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                    <Text color="#ACACAC">Purpose Of Leave</Text>
                  </Td>
                  <Td>
                    <Text>{leaveInfo.reason}</Text>
                  </Td>
                  <Td color="#ACACAC">
                    <Text>{t('label_status')}</Text>
                  </Td>
                  <Td>
                    <Text>{leaveInfo.leaveStatusValue}</Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          {docVal > 0 && (
            <Box bg="white" p={6} borderRadius="16px" mt={4}>
              <Table fontSize="14px" size="sm">
                <Thead>
                  <Tr>
                    <Th>
                      <Text color="#ACACAC">Document Type</Text>
                    </Th>
                    <Th>
                      <Text color="#ACACAC">Upload Date</Text>
                    </Th>
                    <Th>
                      <Text color="#ACACAC">View</Text>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {leaveInfo.documentUpload &&
                    leaveInfo.documentUpload.documents.map(item => {
                      return (
                        <Tr>
                          <Td>
                            <Text>{item.documentName}</Text>
                          </Td>
                          <Td>
                            <Text>
                              {getDateFormatter(item.date, 'DD/MM/YYYY')}
                            </Text>
                          </Td>
                          <Td>
                            <IconButton
                              bg="none"
                              size="sm"
                              boxShadow="none"
                              variant="ghost"
                              icon={<EyeIcon boxSize="20px" />}
                              onClick={() => onViewClick(item.url)}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </Box>
         )}
          <Box bg="white" p={6} borderRadius="16px" mt={4}>
            {isAdmin && leaveInfo.status === 'PENDING' ? (
              <GridItem colSpan={8}>
                <ThemeTextAreaInput
                  type="text"
                  label={t('label_comment')}
                  name="comment"
                  error={errors.comment}
                  register={register}
                  mt={1}
                  isRequired
                />
              </GridItem>
            ) : (
              <>
                <Flex ml={3}>
                  <Text fontSize="13px" color="#ACACAC">
                    Comment
                  </Text>

                  <Text ml={4} fontSize="13px">
                    {leaveInfo.comment}
                  </Text>
                </Flex>
              </>
            )}

            {isAdmin && leaveInfo.status === 'PENDING' && (
              <Flex justifyContent="left" mt={5}>
                <Button
                  type="submit"
                  mr={5}
                  onClick={() => setIsAccepted(true)}
                >
                  {t('btn_approve')}
                </Button>
                <Button
                  bg="red.400"
                  type="submit"
                  onClick={() => setIsAccepted(false)}
                >
                  {t('btn_reject')}
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
        <ModalDialog
          isOpen={isOpen}
          onClose={onClose}
          size="xxl"
          showCloseBtn={true}
          scrollBehavior="inside"
          closeOnOverlayClick={true}
          bodyContent={
            <Center h="100%" p="5px">
              <PdfViewer pdfUrl={imageSrc} />
            </Center>
          }
        />
      </Flex>
    </Flex>
  );
};

export default ViewLeave;
