import React from "react";

function PartyHatSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      fill="none"
      viewBox="0 0 23 23"
    >
      <g fill="#ACACAC" clipPath="url(#clip0)">
        <path d="M20.97 20.294H2.03a1.353 1.353 0 100 2.705h18.94a1.353 1.353 0 100-2.705zM11.5 4.059A2.03 2.03 0 1011.5 0a2.03 2.03 0 000 4.059zM6.63 10.969l-.892 1.93 5.762 5.762 5.762-5.762-.891-1.93-4.871 4.87-4.87-4.87z"></path>
        <path d="M8.125 7.728L7.234 9.66l4.266 4.267 4.267-4.267-.892-1.93-3.375 3.375-3.375-3.376zM20.05 18.94l-2.184-4.733-4.733 4.733h6.918zM2.95 18.94h6.917l-4.733-4.733-2.185 4.733z"></path>
        <path d="M14.271 6.42l-.769-1.667a3.374 3.374 0 01-4.004 0l-.77 1.666L11.5 9.19l2.771-2.77z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H23V23H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PartyHatSvg;
