import {
  FormLabel,
  FormControl,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';
import React from 'react';

const SelectThemeInput = ({
  name,
  register,
  label,
  error,
  defaultValue,
  isRequired,
  value,
  valueKey,
  labelKey,
  options,
  onChange,
  placeholder,
  ...rest
}) => {
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Select
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        value={value}
        focusBorderColor="brand.300"
        ref={register}
        defaultValue={defaultValue}
        {...rest}
      >
        {(options || []).map((op, idx) => {
          return (
            <option key={idx} value={valueKey ? op[valueKey] : op}>
              {labelKey ? op[labelKey] : op}
            </option>
          );
        })}
      </Select>
      {error && (
        <FormErrorMessage fontFamily="Open Sans" fontSize="12px">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default SelectThemeInput;
