import React from 'react';
import colors from '../../theme/foundations/colors';
import { Link } from "@reach/router"

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        style: {
          color: isCurrent ? "brand.500" : colors.gray[200],
          background: isCurrent
            ? 'linear-gradient(89.55deg, #F1F2F5 0.39%, rgba(251, 251, 251, 0.4) 99.61%)'
            : colors.white,
          borderRadius: "16px 0px 0px 16px"

        }
      };
    }}
  />
);

export default NavLink;