import * as React from "react";
import { Icon } from "@chakra-ui/react"


const DashboardIcon = (props) => (
    <Icon
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <path
            opacity={0.4}
            d="M16.076 2h3.386A2.549 2.549 0 0122 4.56v3.415a2.549 2.549 0 01-2.538 2.56h-3.386a2.549 2.549 0 01-2.539-2.56V4.56A2.549 2.549 0 0116.076 2z"
            fill="currentColor"
        />
        <path
            d="M7.924 13.466a2.549 2.549 0 012.539 2.56v3.414A2.55 2.55 0 017.924 22H4.54A2.55 2.55 0 012 19.44v-3.415a2.549 2.549 0 012.539-2.56h3.385zm11.538 0A2.549 2.549 0 0122 16.026v3.414A2.55 2.55 0 0119.462 22h-3.386a2.55 2.55 0 01-2.539-2.56v-3.415a2.549 2.549 0 012.539-2.56h3.386zM7.924 2a2.549 2.549 0 012.539 2.56v3.415a2.549 2.549 0 01-2.539 2.56H4.54A2.549 2.549 0 012 7.974V4.56A2.549 2.549 0 014.539 2h3.385z"
            fill="currentColor"
        />
    </Icon>
)

export default DashboardIcon;