import * as React from "react";
import { Icon } from "@chakra-ui/react"


function BannedCircle(props) {
  return (
    <Icon
    width={5}
    height={5}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
      <path
        d="M10.5 1.75a8.75 8.75 0 100 17.5 8.75 8.75 0 000-17.5zm7 8.75a6.93 6.93 0 01-1.479 4.287L6.212 4.98A6.93 6.93 0 0110.5 3.5a7 7 0 017 7zm-14 0a6.93 6.93 0 011.479-4.287l9.808 9.808A6.93 6.93 0 0110.5 17.5a7 7 0 01-7-7z"
        fill="#F7B714"
      />
    </Icon>
  )
}

export default BannedCircle;

