import { useFormController } from '@bit/sixsprints.core.beans-web';
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Stack,
  Center,
} from '@chakra-ui/react';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import ThemeInput from '../../../../components/form-elements/theme-input';
import ThemeAsyncSelectInput from '../../../../components/form-elements/theme-async-select-input';
import ThemeDateInput from '../../../../components/form-elements/date-picker/date-picker';
import ThemeHeading from '../../../../components/misc/heading';
import ListHeader from '../../../../components/misc/list-header';
import ForwardArrow from '../../../../icons/forward-arrow';
import useNotify from '../../../../utils/notifications';
import { AppConstants } from '../../../../utils/constants';
import { EmergencyValidationSchema } from '../../../../utils/validations';
import DataService from '../../../../services/data-service';
import SelectThemeInput from '../../../../components/form-elements/select-input';
import { navigate } from '@reach/router';
import { storage } from '@bit/sixsprints.core.beans-web';
import _ from 'lodash';
import moment from 'moment';
import Loader from '../../../../components/misc/loader';

const EmergencyInfo = ({
  t,
  next,
  prev,
  userSlug,
  userDataInfo,
  isEdit,
  showSkipButton = false,
}) => {
  const toast = useNotify();
  const [indexes, setIndexes] = useState([1]);
  const [counter, setCounter] = useState(0);
  const [showSkipBtn, setShowSkipBtn] = useState(showSkipButton);

  const { langCode } = useI18nContext();
  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);

  const [relationships, setRelationships] = useState(null);
  const [gender, setGender] = useState(null);


  const apiOptions = {
    url: `/user/emergency-details?userSlug=${userSlug}`,
    method: 'put',
  };

  const transformRequest = values => {
    const data = {...values};
    
    if (!_.isEmpty(data)) {
      _.forEach(data.emergency, function (obj,index) {
        _.set(
          obj,
          'dateOfBirth',
          moment(obj.dateOfBirth).valueOf(),
          _.set(obj, 'countrySlug', obj.country?.slug||userData[index]?.countrySlug),
          _.set(obj, 'stateSlug', obj.state?.slug||userData[index]?.stateSlug),
         
        );
        delete(obj.country)
        delete(obj.state);
      });
      return data.emergency;
    }
    return [null];
  };

  const onSuccess = resp => {
    if (!isEdit) {
      toast({
        title: t('emergency_info_success_toast'),
        description: resp.message,
        status: 'success',
      });

      next();
    } else {
      toast({
        title: t('emergency_info_updated'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    errors,
    getValues,
    reset: resetForm,
    control,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: EmergencyValidationSchema(t),
    transformRequest,
  });

  const addEmergencyDetail = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeEmergencyDetail = index => () => {
    setIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    setCounter(prevCounter => prevCounter - 1);
    const currFormValues = getValues();

    resetForm({
      emergency: currFormValues.emergency.filter(
        (item, _index) => _index !== index
      ),
    });
  };

  const skipEmergencyDetail = () => {
    setIndexes([]);
    next();
  };
  
  const [isDocLoading, setIsDocLoading] = useState(false);
  const [userData,setUserData] = useState(null);


  const transformCountryList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.country[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptionsForCountry = async () => {
    let data = await DataService.post('/country/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformCountryList(data);
    return data;
  };

  const transformStateList = (data = []) => {
    let transformedStateList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.state[langCode];
      return obj;
    });
    return transformedStateList || [];
  };

  const promiseOptionsForState = async () => {
    let data = await DataService.post('/state/search', {
      page: 0,
      size: 50,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformStateList(data);
    return data;
  };

  const getUserInfo = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${userSlug}`).then(resp => {
      const data = Bro(resp).iCanHaz('data.data.emergencyDetails');
      showSkipButton && setShowSkipBtn(true);
      setUserData(data);
      let newData =
      data &&
      data.map(item => {
        item.dateOfBirth = moment(item.dateOfBirth).isValid() ? moment(item?.dateOfBirth).toDate():null;
        return item;
      });
      showSkipButton && setShowSkipBtn(true); 
      setIndexes(data?data:[0]);
      resetForm({
        emergency: newData,
      });
      setIsDocLoading(false);
    })
    .catch(err => {
      setIsDocLoading(false);
    });
  };


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode]);

  useEffect(getUserInfo, []);

  useEffect(() => {
    const masterdata = storage.get(AppConstants.AUTH.CONFIG);
    masterdata.relationShip && setRelationships(masterdata.relationShip);
    let genderArr = storage.get(AppConstants.AUTH.CONFIG).genders;
    setGender(genderArr);
  }, []);

  return (
    (isDocLoading && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex>
      <Box as={Flex} direction="column" mb={5}>
      {!isEdit ? (<ThemeHeading heading={t('emergency_details_heading')} subHeading={t('details_subheading')}/>):(
        <ThemeHeading heading={`${Bro(userDataInfo).iCanHaz('firstName')} ${t(
          'edit_profile_header_text'
        )}`} subHeading={t('details_subheading')}/>
      )}
      </Box>
    </Flex> 
        <Center>
          <Loader/>
        </Center>
      </Flex>
    )) || (
    <Flex flexGrow={1}>
      <Flex flex={1} direction="column" mr={'10em'}>
        <Flex justifyContent="space-between" alignItems="center" mb={10}>
          {!isEdit ? (
            <ThemeHeading
              heading={t('emergency_details_heading')}
              subHeading={t('details_subheading')}
            />
          ) : (
            <ThemeHeading heading={`${Bro(userDataInfo).iCanHaz('firstName')} ${t(
              'edit_profile_header_text'
            )}`} subHeading={t('details_subheading')}/>
          )}

          <Center>
            <Button
              leftIcon={<AddIcon />}
              onClick={addEmergencyDetail}
              alignSelf="flex-end"
            >
              {t('button_add')}
            </Button>
            {showSkipBtn && (
              <Button
                ml={5}
                onClick={skipEmergencyDetail}
                disabled={isEdit}
                alignSelf="flex-end"
              >
                Skip
              </Button>
            )}
          </Center>
        </Flex>
        <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate">
          {indexes.map((item, index) => {
            const fieldName = `emergency[${index}]`;
            return (
              <Stack key={index}>
                <ListHeader
                  msg={` ${t('label_emergency_contact_msg1')} ${index + 1} ${t(
                    'label_emergency_contact_msg2'
                  )}`}
                  index={indexes.length}
                  onBtnClick={removeEmergencyDetail(index)}
                />
                <Grid templateColumns="repeat(6, 1fr)" gap={6}>
                  <GridItem colSpan={2}>
                    <ThemeInput
                      label={t('label_last_name')}
                      name={`${fieldName}.lastName`}
                      error={Bro(errors).iCanHaz(`emergency.${index}.lastName`)}
                      register={register}
                      type="text"
                      mt={1}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <ThemeInput
                      label={t('label_middle_name')}
                      name={`${fieldName}.middleName`}
                      error={Bro(errors).iCanHaz(
                        `emergency.${index}.middleName`
                      )}
                      register={register}
                      type="text"
                      mt={1}
                    />
                  </GridItem>
                  <GridItem colSpan={2}>
                    <ThemeInput
                      label={t('label_first_name')}
                      name={`${fieldName}.firstName`}
                      error={Bro(errors).iCanHaz(
                        `emergency.${index}.firstName`
                      )}
                      register={register}
                      type="text"
                      mt={1}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <SelectThemeInput
                      options={gender}
                      label={t('label_gender')}
                      valueKey="key"
                      labelKey="label"
                      name={`${fieldName}.gender`}
                      placeholder={t('placeholder_text_gender')}
                      error={Bro(errors).iCanHaz(`emergency.${index}.gender`)}
                      register={register}
                      type="text"
                      mt="1.1rem"
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <ThemeDateInput
                      label={t('label_birthday')}
                      name={`${fieldName}.dateOfBirth`}
                      error={Bro(errors).iCanHaz(
                        `emergency.${index}.dateOfBirth`
                      )}
                      showTimeSelect={false}
                      defaultValue={''}
                      dateFormat="yyyy/MM/dd"
                      control={control}
                      mt={1}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <ThemeInput
                      label={t('label_occupation')}
                      name={`${fieldName}.occupation`}
                      error={Bro(errors).iCanHaz(
                        `emergency.${index}.occupation`
                      )}
                      register={register}
                      type="text"
                      mt={1}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <SelectThemeInput
                      options={relationships}
                      valueKey="key"
                      labelKey="label"
                      label={t('label_relationship')}
                      name={`${fieldName}.relationship`}
                      placeholder={t('select_an_option')}
                      error={Bro(errors).iCanHaz(
                        `emergency.${index}.relationship`
                      )}
                      register={register}
                      type="text"
                      mt="1.1rem"
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <ThemeInput
                      label={t('label_phone_number')}
                      name={`${fieldName}.phoneNumber`}
                      error={Bro(errors).iCanHaz(
                        `emergency.${index}.phoneNumber`
                      )}
                      register={register}
                      type="text"
                      mt={1}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <ThemeInput
                      label={t('label_email')}
                      name={`${fieldName}.emailId`}
                      error={Bro(errors).iCanHaz(`emergency.${index}.emailId`)}
                      register={register}
                      type="text"
                      mt={1}
                    />
                  </GridItem>
                  <GridItem colSpan={4} mt={3}>
                    <ThemeInput
                      label={t('label_address')}
                      name={`${fieldName}.address`}
                      error={Bro(errors).iCanHaz(`emergency.${index}.address`)}
                      register={register}
                      type="text"
                      mt={1}
                      bgColor="white"
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <ThemeInput
                      label={t('city_label')}
                      name={`${fieldName}.city`}
                      error={Bro(errors).iCanHaz(`emergency.${index}.city`)}
                      register={register}
                      type="text"
                      mt={1}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <ThemeAsyncSelectInput
                      key={shouldLoadOptions}
                      promiseOptions={promiseOptionsForState}
                      defaultValue={[{  
                        name: userData && Bro(userData[index]).iCanHaz('stateName'),
                        slug: userData && Bro(userData[index]).iCanHaz(`stateSlug`)}]}
                      labelKey="name"
                      valueKey="slug"
                      name={`${fieldName}.state`}
                      control={control}
                      error={Bro(errors).iCanHaz(`emergency.${index}.state`)}
                      label={t('state_province_label')}
                      placeholder={t('select_an_option')}
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <ThemeInput
                      label={t('label_postal_code')}
                      name={`${fieldName}.postalCode`}
                      error={Bro(errors).iCanHaz(
                        `emergency.${index}.postalCode`
                      )}
                      register={register}
                      type="text"
                      mt={1}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <ThemeAsyncSelectInput
                      key={shouldLoadOptions}
                      promiseOptions={promiseOptionsForCountry}
                      defaultValue={[{  
                        name: userData && Bro(userData[index]).iCanHaz('countryName'),
                        slug: userData && Bro(userData[index]).iCanHaz(`countrySlug`)}]}
                      labelKey="name"
                      valueKey="slug"
                      name={`${fieldName}.country`}
                      control={control}
                      error={Bro(errors).iCanHaz(`emergency.${index}.country`)}
                      label={t('field_select_country')}
                      placeholder={t('select_an_option')}
                    />
                  </GridItem>
                </Grid>
              </Stack>
            );
          })}
          <Flex justifyContent="space-between" alignItems="center" my={14}>
            {isEdit ? (
              <Flex flex={1} justifyContent={'flex-end'}>
                <Button align="flex-end" type="submit" px={10} isLoading={isLoading}>
                {t('button_text_save')}
                </Button>
              </Flex>
            ) : (
              <>
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="ghost"
                  onClick={prev}
                >
                  {t('button_text_back')}
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  rightIcon={<ForwardArrow />}
                >
                  {t('button_text_save_next')}
                </Button>
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  ));
};

export default EmergencyInfo;
