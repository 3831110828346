import { BehaviorSubject } from 'rxjs';
import { restApi as Api } from '@bit/sixsprints.core.beans-web';
import { storage } from '@bit/sixsprints.core.beans-web';
import { Keys } from '@bit/sixsprints.utils.data';
import { AppConstants } from '../utils/constants';

const { onboardingStep = null } = storage.get(Keys.USER) || {};
export const loginSubject = new BehaviorSubject(!!storage.get(Keys.TOKEN));
export const onboardingSubject = new BehaviorSubject(
  onboardingStep && onboardingStep > 5
);

const apiOptions = {
  url: '',
  method: '',
};

const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;

const DataService = {
  get(url) {
    apiOptions.url = url;
    apiOptions.method = 'get';
    Api.addGlobalHeader(
      AppConstants.AUTH.ACCEPT_LANGUAGE,
      storage.get(AppConstants.AUTH.LANG_CODE)
    );
    Api.addGlobalHeader(
      AppConstants.TIMEZONE_HEADER,
      timeZoneId
    );
    return Api.fetch({ ...apiOptions });
  },

  post(url, data) {
    apiOptions.url = url;
    apiOptions.method = 'post';
    Api.addGlobalHeader(
      AppConstants.AUTH.ACCEPT_LANGUAGE,
      storage.get(AppConstants.AUTH.LANG_CODE)
    );
    Api.addGlobalHeader(
      AppConstants.TIMEZONE_HEADER,
      timeZoneId
    );
    return Api.fetch({ ...apiOptions, data });
  },

  put(url, data) {
    apiOptions.url = url;
    apiOptions.method = 'put';
    Api.addGlobalHeader(
      AppConstants.AUTH.ACCEPT_LANGUAGE,
      storage.get(AppConstants.AUTH.LANG_CODE)
    );
    Api.addGlobalHeader(
      AppConstants.TIMEZONE_HEADER,
      timeZoneId
    );
    return Api.fetch({ ...apiOptions, data });
  },

  delete(url) {
    apiOptions.url = url;
    apiOptions.method = 'delete';
    Api.addGlobalHeader(
      AppConstants.AUTH.ACCEPT_LANGUAGE,
      storage.get(AppConstants.AUTH.LANG_CODE)
    );
    Api.addGlobalHeader(
      AppConstants.TIMEZONE_HEADER,
      timeZoneId
    );
    return Api.fetch({ ...apiOptions });
  },
};

export default DataService;
