import { storage, useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { useNavigate } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import ThemeDateInput from '../../components/form-elements/date-picker/date-picker';
import SelectThemeInput from '../../components/form-elements/select-input';
import ThemeRadioGroup from '../../components/form-elements/radio-input';
import ThemeTextAreaInput from '../../components/form-elements/theme-textarea';
import useNotify from '../../utils/notifications';
import { ApplyLeaveValidationSchema } from '../../utils/validations';
import DataService from '../../services/data-service';
import {
  getMonthEndDate,
  getMonthStartDate,
  getUser,
  getUserPersonalInfo,
  isAdmin,
} from '../../utils/helpers';
import ThemeInput from '../../components/form-elements/theme-input';
import { AppConstants } from '../../utils/constants';
import FileUploader from '../../components/form-elements/file-upload';
import { SearchIcon } from '@chakra-ui/icons';
import ThemeAsyncSelectInput from '../../components/form-elements/theme-async-select-input';
import PageTitle from '../../components/misc/page-title';
import moment from 'moment';
import Loader from '../../components/misc/loader';
import TrashIcon from '../../icons/trash-icon';

const apiOptions = {
  url: '/leave',
  method: 'post',
};

const ApplyLeave = () => {
  const navigate = useNavigate();
  const toast = useNotify();
  const { t } = useI18nContext();
  const [dayType, setDayType] = useState(null);
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [leaveDayTypeOptions, setLeaveDayTypeOptions] = useState([]);
  const { slug } = getUser();
  const [managerDetails, setManagerDetails] = useState(null);
  const [fullUserName, setFullUserName] = useState(null);
  const [userSlug, setUserSlug] = useState(null);
  const [managerSlug, setManagerslug] = useState(null);
  const [employeeSlug, setEmployeeSlug] = useState(null);
  const [isDocLoading, setIsDocLoading] = useState(false);

  const role = storage.get(AppConstants.AUTH.ROLE);
  const isAdminRole = role === AppConstants.ROLE.ADMIN;
  // const isAdmin = role === AppConstants.ROLE.ADMIN;
  const [documentCounter, setDocumentCounter] = useState(0);
  const [documentIndexes, setDocumentIndexes] = useState([0]);
  const [startDate, setStartDate] = useState(new Date());

  const leaveStatusLink = () => {
    window.open(`/home/leave/status/${isAdmin() ? userSlug : slug}`);
  };

  const getLeaveTypeAllowed = () => {
    DataService.get(`/leaveMaster/user/${slug}`).then(resp => {
      const data = Bro(resp).iCanHaz('data.data');
      setLeaveTypeOptions(data);
    });
  };

  const [reportingTo, setReportingTo] = useState(null);

  const getUserDetails = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${slug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data');
        const reportingTo = Bro(resp).iCanHaz('data.data.workInformationDto');
        let name =
          data.personalInfo.firstName +
          ' ' +
          data.personalInfo.lastName +
          '(' +
          data.userId +
          ')';
        setFullUserName(name);
        setManagerDetails(isAdmin() ? null:data?.workInformationDto?.reportingTo);
        setManagerslug(isAdmin() ? null:data?.workInformationDto?.managerSlug);
        setReportingTo(reportingTo?.reportingTo);
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  const transformRequest = values => {
    const { firstName, lastName } = getUserPersonalInfo();
    let employeeSlugVal = isAdminRole === true ? employeeSlug : slug;
    const data = {
      dayType: values.dayType,
      userSlug: employeeSlugVal,
      leaveRaisedByUserSlug: slug,
      leaveTypeSlug: values.leaveTypeSlug,
      startTime: new Date(values.startTime).getTime(),
      managerSlug: managerSlug,
      documentUpload: {
        documents: values.documents,
      },
      firstName,
      lastName,
      endTime: new Date(values.endTime).getTime(),
      reason: values.reason,
    };
    return data;
  };

  const onSuccess = resp => {
    toast({
      title: t('apply_leave_success_toast'),
      description: resp.message,
      status: 'success',
    });
    navigate(isAdmin() ? '/home/leave/manage-leave' : '/home/leave/user');
  };

  const onCancel = () => {
    navigate(isAdmin() ? '/home/leave/manage-leave' : '/home/leave/user');
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    control,
    errors,
    setValue,
    reset: resetForm,
    getValues,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: ApplyLeaveValidationSchema(t),
    transformRequest,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let dayTypeOptionsArr = storage.get(AppConstants.AUTH.CONFIG).leaveDayType;
    setLeaveDayTypeOptions(dayTypeOptionsArr);
    getLeaveTypeAllowed();
    getUserDetails();
  }, []);

  const transformUserList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.userId = item.userId;
      obj.name = item.personalInfo.firstName + ' ' + item.personalInfo.lastName;
      obj.manager = item?.workInformationDto?.reportingTo;
      obj.managerSlug = item?.workInformationDto?.managerSlug;
      obj.slug = item.slug;
      return obj;

    });
    return transformedList;
  };

  const promiseOptions = async text => {
    let data = await DataService.post('/user/search', {
      page: 0,
      size: 50,
      filterModel: {
        'personalInfo.firstName': {
          filterType: 'text',
          type: null,
          filter: text,
        },
        'personalInfo.lastName': {
          filterType: 'text',
          type: null,
          filter: text,
        },
      },
    }).then(resp => resp.data.data.content);
    data = transformUserList(data);
    return data;
  };

  const getAsyncDetails = values => {
    setEmployeeSlug(values.slug);
    setManagerslug(values.managerSlug);
    setManagerDetails(values.manager);
    setUserSlug(values.slug);
    setReportingTo(values.reportingTo);
  };

  const addDocuments = () => {
    setDocumentIndexes(prevIndexes => [...prevIndexes, documentCounter]);
    setDocumentCounter(prevCounter => prevCounter + 1);
  };

  const removeDocuments = index => () => {
    setDocumentIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    setDocumentCounter(prevCounter => prevCounter - 1);
    //  const currFormValues = getValues()
    // resetForm(currFormValues,currFormValues.documents=currFormValues.documents.filter(
    //   (item, _index) => _index !== index
    //    ),);
  };

  return((isDocLoading && (
    <Flex
      flex={1}
      h="100%"
      overflow="auto"
      direction="column"
      css={{
        '&::-webkit-scrollbar': {
          width: '0px',
        },
      }}
    >
      <Flex>
        <Box as={Flex} direction="column" mb={5}></Box>
      </Flex>
      <Center>
        <Loader />
      </Center>
    </Flex>
  )) || (
    <Flex flex={1} direction="column">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle title={t('apply_leave_heading')} />
        {isAdmin() ? (
          ''
        ) : (
          <Button mr={10} onClick={leaveStatusLink}>
            {t('check_leave_status')}
          </Button>
        )}
      </Flex>
      <Box
        as={'form'}
        onSubmit={onSubmit}
        noValidate="noValidate"
        bgColor="white"
        borderRadius="16px"
      >
        <Stack>
          <Grid templateColumns="repeat(10, 1fr)" p={12} gap={8}>
            <GridItem colSpan={4}>
              <ThemeInput
                label={t('leave_raised_by')}
                type="text"
                defaultValue={fullUserName}
                isReadOnly={true}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <ThemeRadioGroup
                name="dayType"
                radioGroupValue={dayType}
                radioGroupOnChange={e => setDayType(e)}
                label={t('label_day_type')}
                error={Bro(errors).iCanHaz('dayType')}
                register={register}
                mt={2}
                isRequired
                options={leaveDayTypeOptions}
                direction="row"
                spacing="20"
              />
            </GridItem>
            <GridItem colSpan={4}>
              <SelectThemeInput
                options={leaveTypeOptions}
                label={t('label_leave_type')}
                labelKey="name"
                valueKey="slug"
                name="leaveTypeSlug"
                error={errors.leaveTypeSlug}
                placeholder={t('select_an_option')}
                register={register}
                type="text"
                mt={3}
                isRequired
              />
            </GridItem>
            {isAdminRole && (
              <GridItem colSpan={4}>
                <ThemeAsyncSelectInput
                  showKeyInLabel={true}
                  promiseOptions={promiseOptions}
                  labelKey="name"
                  valueKey="slug"
                  labelIdKey="userId"
                  name="userSlug"
                  defaultValue=""
                  control={control}
                  onChange={values => {
                    getAsyncDetails(values);
                  }}
                  error={errors.userSlug}
                  label={t('employee_search')}
                  placeholder={<SearchIcon />}
                />
              </GridItem>
            )}
            <GridItem colSpan={4}>
              <ThemeInput
                label={t('label_reporting_manager')}
                type="text"
                defaultValue={managerDetails}
                isReadOnly={true}
              />
            </GridItem>
            {isAdminRole && (
              <GridItem colSpan={4}>
                <Button
                  mt={7}
                  w="100%"
                  disabled={employeeSlug ? false : true}
                  onClick={leaveStatusLink}
                >
                  {t('check_leave_status')}
                </Button>
              </GridItem>
            )}
            <GridItem colSpan={4} mt={3}>
              <ThemeDateInput
                showMonthDropdown={false}
                showYearDropdown={false}
                label={t('label_from')}
                name="startTime"
                error={errors.startTime}
                showTimeSelect={false}
                dateFormat="yyyy/MM/dd"
                onSelect={date => setStartDate(date)}
                minDate={getMonthStartDate(moment().startOf('year'))}
                maxDate={getMonthEndDate(moment().endOf('year'))}
                control={control}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={4} mt={3}>
              <ThemeDateInput
                showMonthDropdown={false}
                showYearDropdown={false}
                label={t('label_to')}
                name="endTime"
                showTimeSelect={false}
                error={errors.endTime}
                dateFormat="yyyy/MM/dd"
                minDate={startDate}
                maxDate={getMonthEndDate(moment().endOf('year'))}
                control={control}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={8} mt={3}>
              <ThemeTextAreaInput
                type="text"
                label={t('label_purpose')}
                name="reason"
                error={Bro(errors).iCanHaz('reason')}
                register={register}
                h={100}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={10} mb={4} mr={'40%'}>
              <Box as={Flex} justifyContent="space-between">
                <Text my={4} fontSize="16px" fontWeight="600">
                  {t('upload_document')}
                </Text>
              </Box>
              <Stack spacing={0}>
              {documentIndexes.map((item, index) => {
                const fieldName = `documents[${index}]`;
                return (
                  <>
                    <Flex
                      justifyContent="space-between"
                    >
                      <Box w={'50%'}>
                        <ThemeInput
                          name={`[${fieldName}].documentName`}
                          type="text"
                          register={register}
                          placeholder={t('label_upload_doc')}
                        />
                      </Box>
                      <Box
                        w={'45%'}
                        mt={'13px'}
                        ml={'1em'}
                      >
                        <FileUploader
                          register={register}
                          name={`[${fieldName}].url`}
                          error={errors.document}
                          uploadValue={url =>
                            setValue(`[${fieldName}].url`, url)
                          }
                        />
                      </Box>
                      {index > 0 ? (
                        <IconButton
                        ml={'1em'}
                        mt={'1.5em'}
                        bg="none"
                        size="sm"
                        style={{ cursor: 'pointer' }}
                        boxShadow="none"
                        variant="ghost"
                        _focus='none'
                        icon={<TrashIcon boxSize="24px" />}
                        onClick={removeDocuments(index)}
                      />
                      ) : (
                        <Box mr={'2.5em'}></Box>
                      )}
                    </Flex>
                  </>
                );
              })}
               </Stack>
              <Text
                fontSize="12px"
                onClick={addDocuments}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {t('add_more_documents_label')}
              </Text>
            </GridItem>
          </Grid>
        </Stack>
        <Flex px={10} pb={10} ml={2}>
          <Button type="submit" isLoading={isLoading} mr={10}>
            {t('btn_apply_leave')}
          </Button>
          <Button onClick={onCancel} variant="ghost">
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
  ));
};

export default ApplyLeave;
