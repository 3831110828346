import React from 'react';
import colors from '../../theme/foundations/colors';
import { Link } from '@reach/router';

const NavLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        style: {
          color: isCurrent && 'brand.500',
        },
      };
    }}
  />
);

export default NavLink;
