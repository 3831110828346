import React from 'react';
import { useFormController } from '@bit/sixsprints.core.beans-web';
import { useParams, useLocation, useNavigate } from '@reach/router';
import Bro from 'brototype';
import useNotify from '../../../../utils/notifications';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Box, Flex, Grid, GridItem, Stack } from '@chakra-ui/layout';
import PageTitle from '../../../../components/misc/page-title';
import ThemeInput from '../../../../components/form-elements/theme-input';
import { Button } from '@chakra-ui/react';
import ROUTES from '../../../routes';
import { SourceOfHireSchema } from '../../../../utils/validations';

const apiOptions = {
  url: '/sourceOfHire',
  method: 'post',
};

const apiOptionsPut = {
  url: '/sourceOfHire/update',
  method: 'put',
};

const AddSourceOfHire = () => {
  const toast = useNotify();
  const navigate = useNavigate();
  const { t, languages } = useI18nContext();
  const langList = Object.keys(languages);
  const { slug } = useParams();
  const isEdit = slug ? true : false;

  const onNavigateToPrevScreen = () => {
    navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_SOURCE_OF_HIRE);
  };

  const SourceOfHireData = useLocation().state;
  const transformRequest = values => {
    const data = Object.assign(SourceOfHireData, values);
    return data;
  };

  const onSuccess = resp => {
    toast({
      title: isEdit
        ? t('source_of_hire_details_updated')
        : t('source_of_hire_created'),
      description: resp.message,
      status: 'success',
    });
    navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_SOURCE_OF_HIRE);
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const { onSubmit, register, errors, isLoading } = useFormController({
    onSuccess,
    onError,
    apiOptions: isEdit ? apiOptionsPut : apiOptions,
    yupSchema: SourceOfHireSchema(t),
    transformRequest,
  });

  return (
    <Flex flex={1} direction="column">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle
          title={isEdit ? t('edit_source_of_hire') : t('add_source_of_hire')}
        />
      </Flex>
      <Box
        as={'form'}
        bgColor="white"
        borderRadius="16px"
        onSubmit={onSubmit}
        noValidate="noValidate"
      >
        <Stack>
          <Grid templateColumns="repeat(10, 1fr)" p={12} gap={8}>
            {langList.map(item => (
              <GridItem key={item} colSpan={4}>
                <ThemeInput
                  defaultValue={
                    isEdit ? SourceOfHireData.sourceOfHire[`${item}`] : null
                  }
                  label={`${t('source_of_hire')}- ${item.toUpperCase()}`}
                  name={`sourceOfHire.${item}`}
                  type="text"
                  register={register}
                  error={Bro(errors).iCanHaz(`sourceOfHire.${item}`)}
                  mt={1}
                  isRequired
                />
              </GridItem>
            ))}
          </Grid>
        </Stack>
        <Flex px={10} pb={10} ml={2}>
          <Button px="2rem" type="submit" isLoading={isLoading} mr={5} mt={3}>
            {t('button_text_save')}
          </Button>
          <Button variant="ghost" onClick={onNavigateToPrevScreen} mt={3}>
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AddSourceOfHire;
