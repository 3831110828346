import React from 'react';
import { CompactPicker } from 'react-color';
import { FormLabel } from '@chakra-ui/react';

const ColorPicker = ({ label, onChange, ...rest }) => (
  <>
    <FormLabel>{label}</FormLabel>
    <CompactPicker onChange={onChange} {...rest} />
  </>
);

export default ColorPicker;
