import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Button } from '@chakra-ui/react';
import { useLocation, useNavigate } from '@reach/router';
import React from 'react';

const AddNewAction = (props) => {
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const { t } = useI18nContext();

  const onAdd = () => {
    navigate(props.addNewPath || `${path}/add-new`);
  };

  return (
    <Button size={'sm'} fontSize={'sm'} onClick={onAdd}>
      {t(props.addNewBtnText || 'aggrid.add.btn')}
    </Button>
  );
};

export default AddNewAction;
