import * as React from "react"

const CalendarBlueIcon=(props)=>{
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width={40} height={40} rx={14} fill="#C6DAFF" />
      <path
        d="M24.388 27.598a.918.918 0 01-.888-.921.9.9 0 01.877-.92h.011c.503 0 .91.412.91.92 0 .509-.407.92-.91.92zm-4.367-3.987a.89.89 0 01-.92-.866c0-.498.385-.91.878-.932.481 0 .877.38.888.866a.89.89 0 01-.846.932zm0 3.933a.89.89 0 01-.92-.867c0-.498.385-.91.878-.932.481 0 .877.38.888.868a.89.89 0 01-.846.93zm-4.398-3.933a.89.89 0 01-.92-.866c0-.498.385-.91.877-.932.481 0 .877.38.888.866a.89.89 0 01-.845.932zm-.011 3.933a.89.89 0 01-.92-.867c0-.498.385-.91.877-.932.482 0 .878.38.888.868a.89.89 0 01-.845.93zm7.898-4.81c0-.498.386-.899.878-.91.482 0 .867.39.888.867a.89.89 0 01-.856.92.882.882 0 01-.91-.866v-.01zM10.25 18.03v8.247c0 3.423 2.162 5.558 5.555 5.558h8.38c3.425 0 5.565-2.091 5.565-5.492V18.03h-19.5z"
        fill="#0461FF"
      />
      <path
        opacity={0.4}
        d="M10.253 18.029c.014-.636.068-1.898.168-2.305.514-2.284 2.259-3.736 4.752-3.943h9.654c2.472.218 4.238 1.68 4.752 3.943.1.396.153 1.668.167 2.305H10.253z"
        fill="#0461FF"
      />
      <path
        d="M15.997 15.14a.809.809 0 00.824-.835v-3.303a.81.81 0 00-.824-.835.81.81 0 00-.824.835v3.303c0 .478.353.834.824.834zM24.003 15.14c.46 0 .824-.357.824-.835v-3.303a.816.816 0 00-.824-.835.81.81 0 00-.824.835v3.303c0 .478.353.834.824.834z"
        fill="#0461FF"
      />
    </svg>
  )
}

export default CalendarBlueIcon
