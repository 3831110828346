import React, { useState } from 'react';
import {
  RadioGroup,
  Stack,
  Radio,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from "@chakra-ui/react";

const ThemeRadioGroup = ({
  name,
  register,
  label,
  error,
  isRequired,
  options,
  radioGroupValue,
  radioGroupOnChange,
  direction,
  spacing,
  placeholder,
  isReadOnly,
  ...reset
}) => {
  const [ value, setValue ] = useState(radioGroupValue);
  const onChange  = e => {
    radioGroupOnChange && radioGroupOnChange(e);
    setValue(e);
  }
  const time = new Date().getTime();
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
      <FormLabel fontFamily="Open Sans" fontWeight="small" mb="14px">{label}</FormLabel>
      <RadioGroup onChange={onChange} value={`${value}`}>
        <Stack spacing={spacing} direction={direction}>
          {(options || []).map((item, index) => (
            <Radio isReadOnly={isReadOnly} name={name} ref={register} value={item.key} key={index} id={`${index}_${time}`} {...reset}>
              {item.label}
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}

    </FormControl>
  );
};

export default ThemeRadioGroup;
