import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  defaultGridOptions,
  ClientDataGrid,
} from '@bit/sixsprints.utils.kg-grid';
import { Box, Heading } from '@chakra-ui/react';
import Bro from 'brototype';
import {
  ActionContainer,
  GridButton,
  GridInput,
} from '../../components/misc/grid-ui';
import useNotify from '../../utils/notifications';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import ROUTES from '../routes';

const ManageAttendance = () => {
  const { t } = useI18nContext();
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const toast = useNotify();
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });


  const previewCallback = val => {
    setPreviewData(val);
    setIsOpen(true);
  };

  const onSubmitImport = () => {
    setIsOpen(false);
  };

  const transformColDef = fields => {
    const modifiedColDef = fields.map(field => {
      const { cellRenderer, ...rest } = field;
      if(field.colId !== 'userId'){
        field.sortable = false;
        field.filter = false;
      }
      switch(field.colId) {
        case 'userId':
          return {
            ...rest,
            cellRendererFramework: params => {
              return (
                <Link
                  to={`${ROUTES.DASHBOARD + ROUTES.ATTENDANCE + ROUTES.MANAGE_ATTENDANCE}/${params.data.userSlug}`}
                  className="link"
                >
                  {params.value ? params.value : ''}
                </Link>
              );
            },
          };
        default:
          return field;
      }
      
    }).filter(d => !!d);

    return modifiedColDef;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const PageTitle = () => (
    <Heading as="h3" fontSize="24px" mb={5} fontWeight="600">
      {t("attendance_summary")}
    </Heading>
  );

  return (
    <Box>
      <DataGrid
        gridProps={{ ...defaultGridOptions, transformColDef, previewCallback }}
        beans={{ restApi, storage, i18n: t, notificationService: simpleToast}}
        dataProps={{ collection: 'user/attendance/summary', csvName: 'attendance' }}
        uiComponents={{
          Title:PageTitle,
          ActionContainer,
          Button: GridButton,
          Input: GridInput,
          hideEdit: true,
          hideColumnState:true,
          hideImport:true,
          hideDelete: true,
          hideAddNew: true,
          hideSearch:true
        }}
      />

      {isOpen && (
        <Rodal
          visible={true}
          showCloseButton={false}
          animation="slideRight"
          width={95}
          height={95}
          measure="%"
          className="filter-modal"
          customStyles={{ overflow: 'auto' }}
          style={{ marginBottom: 20 }}
        >
          <ClientDataGrid
            beans={{
              restApi,
              storage,
              i18n: t,
              notificationService: simpleToast,
            }}
            dataProps={{
              collection: 'leave',
              rowData: previewData,
              onImportSuccessCallback: onSubmitImport,
              hideAddNew: true,
              colWidth: 150,
            }}
            uiComponents={{
              ActionContainer,
              Button: GridButton,
            }}
          />
        </Rodal>
      )}
    </Box>
  );
};

export default ManageAttendance;
