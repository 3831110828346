import ForgotPassword from '../pages/auth/forgot-password';
import Login from '../pages/auth/login';
import ResetPassword from '../pages/auth/reset-password';
import Onboarding from '../pages/onboarding/onboarding';
import Home from './home/home';
import Payroll from './payroll/payroll';
import DocumentDashboard from './document-dashboard/documentDashboard';
import AdminDocUpload from './admin-doc-upload/admin-doc-upload';
import ROUTES from './routes';
import UserProfileEdit from './user-details/user-profile-edit';
import LeaveList from './leave/leave-list';
import ApplyLeave from './leave/apply-leave';
import LeaveStatus from './leave/leave-status';
import ViewLeave from './leave/view-leave';
import UserLeaveList from './leave/user-leave-list';
import MainDashboard from './dashboard/main-dashboard';
import EditLeaveMaster from './leave-master/edit-leave-master';
import AttendanceCalendar from './attendance/mark-attendance';
import ApplyRegularisation from './attendance/apply-regularisation';
import AttnRegularisationList from './attendance/regularisation-list';
import AttendanceList from './attendance/attendance-list';
import ManageAttdRegularisation from './attendance/manage-regularisation';

const routeObjUser = {
  default: {
    privateRoute: ROUTES.ONBOARDING,
    publicRoute: ROUTES.LOGIN,
  },
  private: [
    { path: ROUTES.ONBOARDING, component: Onboarding },

    { path: ROUTES.USER_PROFILE, component: UserProfileEdit },
    {
      path: ROUTES.DASHBOARD,
      component: Home,
      routes: [
        {
          path: '/',
          component: MainDashboard,
        },
        { path: ROUTES.ADMIN_DASHBOARD, component: MainDashboard },
        { path: ROUTES.PAYROLL, component: Payroll },
        { path: ROUTES.ADMIN_DASHBOARD, component: MainDashboard },
        { path: ROUTES.DOCUMENT_DASHBOARD, component: DocumentDashboard },
        { path: ROUTES.DOCUMENT_UPLOAD_TABLE, component: AdminDocUpload },
        {
          path: ROUTES.LEAVE,
          component: props => <>{props.children}</>,
          routes: [
            { path: ROUTES.MANAGE_LEAVE, component: LeaveList },
            { path: ROUTES.USER_LEAVE, component: UserLeaveList },
            { path: ROUTES.ADD_LEAVE, component: ApplyLeave },
            { path: ROUTES.VIEW_LEAVE, component: ViewLeave },
            { path: ROUTES.LEAVE_STATUS, component: LeaveStatus },
            { path: ROUTES.LEAVE_STATUS_USER, component: LeaveStatus },
            {
              path: ROUTES.EDIT_REGULARISATION_REQUEST,
              component: EditLeaveMaster,
            },
          ],
        },

        {
          path: ROUTES.ATTENDANCE,
          component: props => <>{props.children}</>,
          routes: [
            { path: ROUTES.ATTENDANCE_USER_LIST, component: AttendanceList },
            { path: ROUTES.ATTENDANCE_LIST, component: AttendanceList },
            { path: ROUTES.MARK_ATTENDANCE, component: AttendanceCalendar },
            {
              path: ROUTES.MANAGE_REGULARISATION,
              component: ManageAttdRegularisation,
            },
            {
              path: ROUTES.REGULARISATION_LIST,
              component: AttnRegularisationList,
            },
            {
              path: ROUTES.REGULARISATION_REQUEST,
              component: ApplyRegularisation,
            },
            {
              path: ROUTES.ATTN_REGULARISATION_REQUEST_EDIT,
              component: ApplyRegularisation,
            },
          ],
        },
      ],
    },
  ],
  public: [
    {
      path: ROUTES.LOGIN,
      component: Login,
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      component: ForgotPassword,
    },
    {
      path: ROUTES.RESET_PASSWORD,
      component: ResetPassword,
    },
  ],
};

export default routeObjUser;
