import * as Yup from 'yup';

const noSpecialChars = /^[a-zA-Z]{0,30}$/;
const onlyAlphabets = /^[A-Z]+$/i;
const onlyDigit = /^[0-9]*$/;
const ifscRegex = /^[A-Za-z]{4}[0]{1}[a-zA-Z0-9]{6}$/;
const accountNoRegExp = /^(?!0{9,12})\d{9,18}[^.]+$/;
const noSpaceBeg = /^([^0-9]*)[^-\s][a-zA-Z-_\\s]+$/g;
const mobileRegExp = /^[6-9]\d{9}$/;
const phoneNumberRegExp = /^(?!0{9,12})[0-9]\d{9,12}$/;
const passportNumberRegExp = /^[A-Z][1-9]\d\s?\d{4}[1-9]$/gi;
const panCardNumberRegExp = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
// const addharNumberRegExp = /^[2-9]{1}[0-9]{3}\\s[0-9]{4}\\s[0-9]{4}$/;
//const phoneRegExp = /^[1-9][0-9]{9}[^.]+$/;
// const numberRegExp = /^[0-9]+$/;
// const bankFieldChars = /^[a-zA-Z ]{2,30}$/;
// const urlRegExp = /^((https?|ftp|smtp):\/\/)?(www.)(.*?)\.(?:com|au|uk|co|in)$/;
// const decimalRegExp = /^[0-9]\d*(\.\d+)?$/;
// const noNumberAllow = /^([^0-9]*)$/;
// const dotRegExp = /[- /.]$/;
// const bloodPressureReg = /^[a-zA-Z]{2,30}$/;

export const LoginValidationSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('login_email_invalid'))
      .required(t('login_email_required')),
    password: Yup.string().required(t('login_password_required')),
  });

export const ForgotPassValidationSchema = t =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('login_email_invalid'))
      .required(t('login_email_required')),
  });

export const ResetPassValidationSchema = t =>
  Yup.object().shape({
    otp: Yup.string().required(t('otp_required')),
    password: Yup.string()
      .required(t('login_password_required'))
      .nullable('' + t('form_name_invalid'))
      .test(
        'len',
        t('form.mobileNumber.invalid.length.msg'),
        val => !val || (val && val.toString().length > 4)
      ),
    rePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('pass_must_watch'))
      .nullable('' + t('form_name_invalid'))
      .test(
        'len',
        t('form.mobileNumber.invalid.length.msg'),
        val => !val || (val && val.toString().length > 4)
      ),
  });

export const PersonalValidationSchema = t =>
  Yup.object().shape({
    lastName: Yup.string()
      .required(t('last_name_required'))
      .matches(noSpecialChars, '' + t('last_name_invalid'))
      .nullable('' + t('last_name_invalid')),
    middleName: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
    firstName: Yup.string()
      .required(t('first_name_required'))
      .matches(noSpecialChars, '' + t('first_name_invalid'))
      .nullable('' + t('first_name_invalid')),
    phoneticFirstName: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
    phoneticLastName: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
    phoneticMiddleName: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
    gender: Yup.string().required(t('gender_required')),
    dateOfBirth: Yup.string()
      .required(t('dob_required'))
      .nullable('' + t('dob_invlid')),
    mobileNumber: Yup.string()
      .required(t('mobile_required'))
      .matches(mobileRegExp, '' + t('mobile_no_invalid'))
      .min(10, t('mobile_no_invalid'))
      .max(10, t('mobile_no_invalid')),
    aadhar: Yup.string()
      .transform(value => (value === '' ? null : value))
      .nullable()
      .min(12, t('addhar_no_invalid'))
      .max(12, t('addhar_no_invalid')),
    panCard: Yup.string()
      .transform(value => (value === '' ? null : value))
      .nullable()
      .matches(panCardNumberRegExp, '' + t('pan_no_invalid'))
      .typeError('' + t('phone_no_invalid')),
    personalEmail: Yup.string()
      .email(t('login_email_invalid'))
      .nullable('' + t('email_invalid')),
    passPort: Yup.string()
      .transform(value => (value === '' ? null : value))
      .nullable()
      .matches(passportNumberRegExp, '' + t('passport_no_invalid'))
      .typeError('' + t('phone_no_invalid')),
    maritalStatus: Yup.string().required(t('marital_status_required')),
    // anniversaryDate:Yup.string.when('maritalStatus',{
    //   is:'MARRIED',
    //   then:Yup.string().required(t("please_fill_the_detail"))
    // })
  });

export const BankValidationSchema = t =>
  Yup.object().shape({
    name: Yup.string()
      .required(t('bank_name_required'))
      .matches(noSpaceBeg, '' + t('bank_name_invalid'))
      .nullable('' + t('bank_name_invalid')),
    branchName: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
    ifscCode: Yup.string()
      .required(t('ifsc_code_required'))
      .matches(ifscRegex, '' + t('ifsc_code_invalid')),
    branchAddress: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
    accountType: Yup.string().required(t('account_type_required')),
    accountNumber: Yup.string()
      .required(t('account_number_required'))
      .matches(accountNoRegExp, '' + t('account_no_invalid'))
      .min(9, t('account_no_invalid'))
      .max(18, t('account_no_invalid')),
    accountHolderName: Yup.string()
      .required(t('account_holder_name_required'))
      .matches(noSpaceBeg, '' + t('acc_holder_name_invalid'))
      .nullable('' + t('acc_holder_name_invalid')),
    swiftCode: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
  });

export const DependentValidationSchema = t =>
  Yup.object().shape({
    dependent: Yup.array().of(
      Yup.object().shape({
        lastName: Yup.string()
          .required(t('last_name_required'))
          .matches(noSpecialChars, '' + t('last_name_invalid'))
          .nullable('' + t('last_name_invalid')),
        middleName: Yup.string()
          .nullable()
          .transform(v => (v === '' ? null : v)),
        firstName: Yup.string()
          .required(t('first_name_required'))
          .matches(noSpecialChars, '' + t('first_name_invalid'))
          .nullable('' + t('first_name_invalid')),
        gender: Yup.string().required(t('gender_required')),
        phoneNumber: Yup.string()
          .required(t('phone_number_required'))
          .matches(phoneNumberRegExp, '' + t('phone_no_invalid'))
          .transform(value => (isNaN(value) ? undefined : value)),
        dateOfBirth: Yup.string()
          .required(t('dob_required'))
          .nullable('' + t('dob_invalid')),
        relationship: Yup.string()
          .nullable('' + t('relationship_invalid'))
          .required(t('relationship_required')),
        occupation: Yup.string()
          .required(t('occupation_required'))
          .matches(noSpaceBeg, '' + t('occupation_invalid'))
          .nullable('' + t('occupation_invalid')),
        postalCode: Yup.string()
          .min(6, t('postal_code_invalid'))
          .max(6, t('postal_code_invalid'))
          .required(t('postal_code_required')),
        // state: Yup.object()
        //   .required(t('state_required')).nullable(''),
        address: Yup.string().required(t('address_required')),
        // country: Yup.object().required(t('country_name_required')).nullable(),
        occupation: Yup.string()
          .required(t('occupation_required'))
          .matches(noSpaceBeg, '' + t('occupation_invalid'))
          .nullable('' + t('occupation_invalid')),
        city: Yup.string()
          .required(t('city_required'))
          .matches(noSpaceBeg, '' + t('city_invalid'))
          .nullable('' + t('city_invalid')),
        emailId: Yup.string()
          .email(t('login_email_invalid'))
          .nullable('' + t('email_invalid')),
      })
    ),
  });

export const EmergencyValidationSchema = t =>
  Yup.object().shape({
    emergency: Yup.array().of(
      Yup.object().shape({
        lastName: Yup.string()
          .required(t('last_name_required'))
          .matches(noSpecialChars, '' + t('last_name_invalid'))
          .nullable('' + t('last_name_invalid')),
        middleName: Yup.string(),
        firstName: Yup.string()
          .required(t('first_name_required'))
          .matches(noSpecialChars, '' + t('first_name_invalid'))
          .nullable('' + t('first_name_invalid')),
        phoneNumber: Yup.string()
          .required(t('phone_number_required'))
          .matches(phoneNumberRegExp, '' + t('phone_no_invalid'))
          .transform(value => (isNaN(value) ? undefined : value)),
        relationship: Yup.string()
          .required(t('relationship_required'))
          .matches(noSpaceBeg, '' + t('relationship_invalid'))
          .nullable('' + t('relationship_invalid')),
        postalCode: Yup.string()
          .min(6, t('postal_code_invalid'))
          .max(6, t('postal_code_invalid'))
          .required(t('postal_code_required')),
        gender: Yup.string().required(t('gender_required')),
        dateOfBirth: Yup.string()
          .required(t('dob_required'))
          .nullable('' + t('dob_invlid')),
        // state: Yup.object().required(t('state_required')).nullable(),
        address: Yup.string().required(t('address_required')),
        // country: Yup.object().required(t('country_name_required')).nullable(),
        occupation: Yup.string()
          .required(t('occupation_required'))
          .matches(noSpaceBeg, '' + t('occupation_invalid'))
          .nullable('' + t('occupation_invalid')),
        city: Yup.string()
          .required(t('city_required'))
          .matches(noSpaceBeg, '' + t('city_invalid'))
          .nullable('' + t('city_invalid')),
        emailId: Yup.string()
          .email(t('login_email_invalid'))
          .nullable('' + t('email_invalid')),
      })
    ),
  });

export const HealthCheckupValidationSchema = t =>
  Yup.object().shape({
    bloodPressure: Yup.string().default(null),
    diabetes: Yup.string().required(t('diabetes_required')),
    bloodGroup: Yup.string().required(t('blood_group_required')),
    allergies: Yup.string().default(null),
  });

export const DocumentUploadValidationSchema = t =>
  Yup.object().shape({
    resume: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
    graduationDegree: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
    employmentRelatedDocument: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
    addressIdProof: Yup.string()
      .nullable()
      .transform(v => (v === '' ? null : v)),
  });

export const AddEmployeeValidationSchema = t =>
  Yup.object().shape({
    personalInfo: Yup.object().shape({
      firstName: Yup.string()
        .required(t('first_name_required'))
        .matches(noSpaceBeg, '' + t('first_name_invalid'))
        .nullable('' + t('first_name_invalid')),
      lastName: Yup.string()
        .required(t('last_name_required'))
        .matches(noSpaceBeg, '' + t('last_name_invalid'))
        .nullable('' + t('last_name_invalid')),
    }),
    idPrefix: Yup.string()
      .required(t('employeeId_prefix_required'))
      .matches(onlyAlphabets, '' + t('employeeId_prefix_invalid')),
    idSuffix: Yup.string()
      .required(t('employeeId_required'))
      .matches(onlyDigit, '' + t('employeeId_invalid')),
    email: Yup.string()
      .email(t('login_email_invalid'))
      .required(t('login_email_required')),
    personalEmail: Yup.string()
      .transform(value => (value === '' ? null : value))
      .nullable()
      .email(t('login_email_invalid')),
    workInformationDto: Yup.object().shape({
      departmentSlug: Yup.object()
        .required(t('department_required'))
        .nullable(),
      designationSlug: Yup.object()
        .required(t('designation_required'))
        .nullable(),
      locationSlug: Yup.object().required(t('location_required')).nullable(),
      employeeTypeSlug: Yup.object()
        .required(t('employeeType_required'))
        .nullable(),
      roleSlug: Yup.object().required(t('role_required')).nullable(),
      sourceOfHireSlug: Yup.object()
        .required(t('sourceOfHire_required'))
        .nullable(),
      employeeStatusSlug: Yup.object()
        .required(t('employeeStatus_required'))
        .nullable(),
      seatingLocation: Yup.string()
        .required(t('seatingLocation_required'))
        .nullable(),
      dateOfJoining: Yup.date()
        .required(t('doj_required'))
        .transform(value => (isNaN(value) ? null : value))
        .nullable('' + t('doj_invalid')),
      phone: Yup.string(),
      managerSlug: Yup.object().required(t('reportingTo_required')).nullable(),
    }),
  });

export const AddAppointmentValidationSchema = t =>
  Yup.object().shape({
    type: Yup.string()
      .required(t('type_of_announcement_required'))
      .oneOf(
        ['GENERAL', 'HOLIDAY', 'EMERGENCY'],
        t('type_of_announcement_invalid')
      )
      .nullable('' + t('type_of_announcement_invalid')),
    title: Yup.string()
      .required(t('title_required'))
      .matches(noSpaceBeg, '' + t('title_invalid'))
      .nullable('' + t('title_invalid')),
    startDate: Yup.date()
      .required(t('start_date_required'))
      .transform(value => (isNaN(value) ? null : value)),
    endDate: Yup.date()
      .required(t('end_date_required'))
      .when(
        'startDate',
        (startDate, schema) =>
          startDate && schema.min(startDate, t('end_st_start_date_msg'))
      )
      .transform(value => (isNaN(value) ? null : value)),
    description: Yup.string()
      .required(t('description_required'))
      .nullable('' + t('description_invalid')),
  });

const checkDateValidation = (to, from) => {
  let toDate = new Date(to);
  let fromDate = new Date(from);
  if (toDate.getTime() === fromDate.getTime()) {
    return true;
  }
  return false;
};

export const ApplyLeaveValidationSchema = t =>
  Yup.object().shape({
    leaveTypeSlug: Yup.string().required(t('leave_type_required')),
    dayType: Yup.string()
      .required(t('day_type_required'))
      .oneOf(['FULL', 'HALF_DAY'], t('day_type_invalid'))
      .nullable('' + t('day_type_invalid')),
    startTime: Yup.date()
      .required(t('from_date_required'))
      .transform(value => (isNaN(value) ? null : value)),
    endTime: Yup.date()
      .required(t('to_date_required'))
      .transform(value => (isNaN(value) ? null : value))
      .when(
        'startTime',
        (startTime, schema) =>
          startTime && schema.min(startTime, t('to.lt.from.date.msg'))
      )
      .when(['dayType', 'startTime'], (dayType, startTime, schema) => {
        if (dayType === 'HALF_DAY') {
          return schema.test(
            'dateMatch',
            t('from_not_equal_to_date_msg'),
            val => !val || (val && checkDateValidation(val, startTime))
          );
        }
        return schema;
      }),
    reason: Yup.string().required(t('reason_required')),
  });

export const AcceptLeaveValidationSchema = t =>
  Yup.object().shape({
    comment: Yup.string().required(t('comment_required')),
  });

export const CreateLeaveMasterValidationSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(t('error_name_required')),
    leaveMasterRules: Yup.array().of(
      Yup.object().shape({
        leaveCount: Yup.number()
          .required(t('leave_count_required'))
          .nullable('' + t('leave_count_invalid'))
          .min(0, t('value_invalid'))
          .transform(value => (isNaN(value) ? 0 : value)),
        rollOverCount: Yup.number()
          .min(0, t('value_invalid'))
          .transform(value => (isNaN(value) ? 0 : value))
          .when(['leaveCount'], (leaveCount, schema) => {
            if (!isNaN(leaveCount) && leaveCount !== 0) {
              return schema.test(
                'validity',
                t('rc.gt.lc.msg'),
                val => isNaN(val) || val < leaveCount
              );
            }
            return schema;
          }),
        allowedNegativeLeaveCount: Yup.number()
          .max(0, t('negative_allowed_balance_invalid'))
          .transform(value => (isNaN(value) ? 0 : value)),
        maximumRollOverLimit: Yup.number().transform(value =>
          isNaN(value) ? 0 : value
        ),
        leaveRuleApplicable: Yup.string().required(
          t('leave_rule_applicable_required')
        ),
        leaveCyclePeriod: Yup.string()
          .required(t('leave_rule_applicable_required'))
          .nullable('' + t('leave_rule_applicable_required')),
        payType: Yup.string()
          .required(t('leave_rule_applicable_required'))
          .nullable('' + t('leave_rule_applicable_required')),
      })
    ),
  });

export const AttendanceValidationSchema = ({t,checkAdmin}) =>
Yup.object().shape({
    category: Yup.string().required(t('category_required')),
    fromDate: Yup.date()
      .required(checkAdmin ? t('from_date_required'):t('regularisation_date_required'))
      .transform(value => (isNaN(value) ? null : value)),
    toDate: checkAdmin ? Yup.date()
      .required(t('to_date_required'))
      .when(
        'fromDate',
        (fromDate, schema) =>
          fromDate && schema.min(fromDate, t('end_st_start_date_msg'))
      )
      .transform(value => (isNaN(value) ? null : value)):Yup.date(),
    overlapHoliday: Yup.string().required(t('leave_rule_applicable_required')),
    reason: Yup.string().required(t('reason_required')),
  });

export const CreateHolidayMasterSchema = t =>
  Yup.object().shape({
    name: Yup.string().required(t('error_name_required')),
    holidayMasterRule: Yup.array().of(
      Yup.object().shape({
        // holidayType: Yup.string()
        //   .required(t('leave_rule_applicable_required')),
      })
    ),
  });

export const CountrySchema = t =>
  Yup.object().shape({
    country: Yup.object().shape({
      en: Yup.string()
        .required(t('country_name_required'))
        .matches(onlyAlphabets, '' + t('country_name_invalid')),
      ja: Yup.string().required(t('country_name_required')),
    }),
    countryCode: Yup.string()
      .required(t('country_code_required'))
      .matches(onlyAlphabets, '' + t('country_code_invalid')),
    callingCode: Yup.string()
      .required(t('calling_code_required'))
      .matches(onlyDigit, '' + t('calling_code_invalid')),
    timeZone: Yup.object().required(t('timeZone_required')),
  });

export const DepartmentSchema = t =>
  Yup.object().shape({
    departmentName: Yup.object().shape({
      en: Yup.string().required(t('department_name_required')),
      ja: Yup.string().required(t('department_name_required')),
    }),
    mail: Yup.string().email(t('login_email_invalid')),
  });

export const DesignationSchema = t =>
  Yup.object().shape({
    designationName: Yup.object().shape({
      en: Yup.string().required(t('designation_name_required')),
      ja: Yup.string().required(t('designation_name_required')),
    }),
    mail: Yup.string().email(t('login_email_invalid')),
  });

export const CompanyDetailsSchema = t =>
  Yup.object().shape({
    email: Yup.string().email(t('login_email_invalid')),
    superAdminEmail: Yup.string().email(t('login_email_invalid')),
    defaultEmail: Yup.string().email(t('login_email_invalid')),
  });

export const LocationSchema = t =>
  Yup.object().shape({
    locationName: Yup.object().shape({
      en: Yup.string().required(t('location_name_required')),
      ja: Yup.string().required(t('location_name_required')),
    }),
    mail: Yup.string().email(t('login_email_invalid')),
  });

export const RoleSchema = t =>
  Yup.object().shape({
    roleName: Yup.object().shape({
      en: Yup.string().required(t('role_name_required')),
      ja: Yup.string().required(t('role_name_required')),
    }),
  });

export const QualificationSchema = t =>
  Yup.object().shape({
    qualification: Yup.object().shape({
      en: Yup.string().required(t('qualification_name_required')),
      ja: Yup.string().required(t('qualification_name_required')),
    }),
  });

export const MedicalRecordSchema = t =>
  Yup.object().shape({
    medicalRecordName: Yup.object().shape({
      en: Yup.string().required(t('medical_record_name_required')),
      ja: Yup.string().required(t('medical_record_name_required')),
    }),
  });

export const DocumentListSchema = t =>
  Yup.object().shape({
    documentName: Yup.object().shape({
      en: Yup.string().required(t('document_name_required')),
      ja: Yup.string().required(t('document_name_required')),
    }),
  });

export const SourceOfHireSchema = t =>
  Yup.object().shape({
    sourceOfHire: Yup.object().shape({
      en: Yup.string().required(t('source_of_hire_required')),
      ja: Yup.string().required(t('source_of_hire_required')),
    }),
  });

export const EmployeeTypeSchema = t =>
  Yup.object().shape({
    employeeType: Yup.object().shape({
      en: Yup.string().required(t('employee_type_required')),
      ja: Yup.string().required(t('employee_type_required')),
    }),
  });

export const EmployeeStatusSchema = t =>
  Yup.object().shape({
    statusName: Yup.object().shape({
      en: Yup.string().required(t('employee_status_name_required')),
      ja: Yup.string().required(t('employee_status_name_required')),
    }),
  });

export const SchoolInfoSchema = t =>
  Yup.object().shape({
    board: Yup.string()
      .required(t('board_required'))
      .nullable('' + t('board_required')),
    schoolMedium: Yup.string()
      .required(t('schoolMedium_required'))
      // .matches(noSpaceBeg, '' + t('title_invalid'))
      .nullable('' + t('schoolMedium_required')),
    percentage: Yup.string()
      .required(t('percentage_required'))
      .transform(value => (isNaN(value) ? null : value)),
    passingYear: Yup.date()
      .required(t('passing_year_required'))
      .nullable('' + t('passing_year_invalid'))
      // .when(
      //   'startDate',
      //   (startDate, schema) =>
      //     startDate && schema.min(startDate, t('end_st_start_date_msg'))
      // )
      .transform(value => (isNaN(value) ? null : value)),
    schoolName: Yup.string()
      .required(t('schoolName_required'))
      .nullable('' + t('schoolName_invalid')),
  });

export const DegreeInfoSchema = t =>
  Yup.object().shape({
    courses: Yup.array().of(
      Yup.object().shape({
        courseSlug: Yup.string().required(t('course_required')).nullable(),
        university: Yup.string()
          .required(t('university_required'))
          .nullable('' + t('university_invalid')),
        passingYear: Yup.string()
          .required(t('passing_year_required'))
          .nullable('' + t('passing_year_invalid')),
        specialization: Yup.string()
          .nullable('' + t('specialization_invalid'))
          .required(t('specialization_required')),
        courseType: Yup.string()
          .required(t('courseType_required'))
          .nullable('' + t('courseType_invalid')),
      })
    ),
  });

export const WorkExperienceSchema = t =>
  Yup.object().shape({
    workExperiences: Yup.array().of(
      Yup.object().shape({
        companyName: Yup.string()
          .required(t('previous_comp_name_required'))
          .nullable('' + t('previous_comp_name_invalid')),
        jobTitle: Yup.string()
          .required(t('job_title_required'))
          .nullable('' + t('job_title_invalid')),
        fromDate: Yup.string().required(t('from_date_required')).nullable(''),
        toDate: Yup.string().required(t('to_date_required')).nullable(''),
        relevance: Yup.string().nullable(''),
        jobDescription: Yup.string().nullable(''),
      })
    ),
  });

export const DocumentsValidationSchema = t =>
  Yup.object().shape({
    documents: Yup.array().of(
      Yup.object().shape({
        documentSlug: Yup.string().required(
          t('leave_rule_applicable_required')
        ),
      })
    ),
  });

export const CreateSalaryStructureSchema = t =>
  Yup.object().shape({
    structureName: Yup.string().required(t('structure_name_rquired')),
    components: Yup.array().of(
      Yup.object().shape({
        componentSlug: Yup.string().required(
          t('leave_rule_applicable_required')
        ),
        percentage: Yup.string().required(t('percentage_required')),
      })
    ),
  });

export const PayrollComponentSchema = t =>
  Yup.object().shape({
    payrollComponent: Yup.object().shape({
      en: Yup.string().required(t('componentName_required')),
      ja: Yup.string().required(t('componentName_required')),
    }),
  });

export const DefinePayrollComponentSchema = ({ t, isEdit }) =>
  Yup.object().shape({
    userSlug: isEdit
      ? Yup.object()
      : Yup.object()
          // .required(t('select_an_option_employee'))
          .nullable('' + t('select_an_option_employee')),
    ctc: Yup.string()
      .required(t('ctc_required_msg'))
      .nullable('' + t('ctc_required_msg')),
    effectiveDate: Yup.string()
      .required(t('effective_date_required'))
      .nullable(''),
    // structureName:Yup.object()
    // .required(t('select_an_structure'))
    // .nullable(''),
  });
