import { useFormController, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Stack,
  Center,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import ThemeRadioGroup from '../../components/form-elements/radio-input';
import ThemeInput from '../../components/form-elements/theme-input';
import useNotify from '../../utils/notifications';
import { CreateLeaveMasterValidationSchema } from '../../utils/validations';
import { AppConstants } from '../../utils/constants';
import { roundToNearestHalf } from '../../utils/helpers';
import { AddIcon } from '@chakra-ui/icons';
import ThemeSwitch from '../../components/form-elements/theme-switch';
import ThemeTextAreaInput from '../../components/form-elements/theme-textarea';
import ColorPicker from '../../components/form-elements/color-picker/color-picker';
import MultiSelect from '../../components/form-elements/multi-select/multi-select';
import EditSvg from '../../components/misc/edit-svg';
import PageTitle from '../../components/misc/page-title';

const apiOptions = {
  url: '/leaveMaster',
  method: 'post',
};

const CreateLeaveMaster = () => {
  const navigate = useNavigate();
  const toast = useNotify();
  const { t } = useI18nContext();

  const [colorValue, setColorValue] = useState('#000000');
  const [selectedCalendarMonth, setSelectedCalendarMonth] = useState({});

  // const [leaveYear, setLeaveYear] = useState(
  //   AppConstants.LEAVE.LEAVE_YEAR.CALENDAR_YEAR
  // );

  const [indexes, setIndexes] = useState([0]);
  // const [departmentVisibility, setDepartmentVisibility] = useState({});
  const [locationVisibility, setLocationVisibility] = useState({});
  const [monthlyLeaveCalendarVisibility, setMonthlyLeaveCalendarVisibility] =
    useState({});
  const [leaveMasterRuleMonthlyLeaves, setLeaveMasterRuleMonthlyLeaves] =
    useState({});
  const [leaveYearCalender, setLeaveYearCalender] = useState(
    // AppConstants.LEAVE.LEAVE_YEAR.CALEDAR_YEAR_MONTHS
    AppConstants.LEAVE.LEAVE_YEAR.CALEDAR_YEAR_MONTHS
  );

  const leavePayTypeOptions = storage.get(
    AppConstants.AUTH.CONFIG
  ).leavePayType;
  const lifeCyclePeriodOptions = storage.get(
    AppConstants.AUTH.CONFIG
  ).leaveCyclePeriod;

  const leaveRuleApplicableAllOptions = [
    { key: 'ALL', label: 'All Employees' },
  ];
  const leaveRuleApplicableSpecificOptions = [
    { key: 'SPECIFIC', label: 'Specific Employees' },
  ];

  const departmentOptions = [
    {
      label:'IT',
      key:'IT',
    },
    {
      label: 'HR',
      key: 'HR',
    },
    {
      label: 'Sales',
      key: 'SALES',
    },
    {
      label: 'Marketing',
      key: 'MARKETING',
    },
  ]; // todo

  const locationOptions = [
    { label: 'Delhi', key: 'DELHI' },
    { label: 'Tokyo', key: 'TOKYO' },
    {label:'Gurugram',key:'GURUGRAM'},
    { label: 'Pune', key: 'PUNE' },
    { label: 'Mumbai', key: 'MUMBAI' },
  ]; // todo

  const addRule = () => {
    setIndexes(prevIndexes => [...prevIndexes, Math.max(...prevIndexes) + 1]);
  };

  const removeRule = index => () => {
    // setDepartmentVisibility(prev => ({ ...prev, [index]: false }));
    setLocationVisibility(prev => ({ ...prev, [index]: false }));
    setMonthlyLeaveCalendarVisibility(prev => ({ ...prev, [index]: false }));

    setIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    const currFormValues = getValues();
    resetForm(
      currFormValues,
      (currFormValues.leaveMasterRules = currFormValues.leaveMasterRules.filter(
        (item, _index) => _index !== index
      ))
    );
  };

  // const changeDepartmentVisibility = (index, val) => {
  //   setDepartmentVisibility(prev => ({ ...prev, [index]: val !== 'ALL' }));
  //   if (val === 'ALL')
  //     setLocationVisibility(prev => ({ ...prev, [index]: false }));
  // };

  const changeLocationVisibility = (index, val) => {
    setLocationVisibility(prev => ({
      ...prev,
      [index]: val.length > 0,
    }));
  };

  const calculateLeaveByMonth = (
    leaveCount,
    monthIndex /* starts from 0 */
  ) => {
    return (
      roundToNearestHalf(((monthIndex + 1) * leaveCount) / 12) -
      (monthIndex === 0 ? 0 : 1) *
        roundToNearestHalf((monthIndex * leaveCount) / 12)
    );
  };

  const updateLeaveYearCalendarValues = (type, index, val) => {
    const currFormValues = getValues();
    const leaveMasterRule = { ...currFormValues.leaveMasterRules[index] };
    var updatedLeaves = [];

    if (type === 'leaveCount') {
      if (
        leaveMasterRule.leaveCyclePeriod ===
        AppConstants.LEAVE.LEAVE_CYCLE_PERIOD.YEARLY
      ) {
        updatedLeaves = [...Array(12)].map(() => val.target.value);
      } else {
        updatedLeaves = [...Array(12)].map((d, i) =>
          calculateLeaveByMonth(val.target.value, i)
        );
      }
    } else if (type === 'leaveCyclePeriod') {
      let leaveCount =
        leaveMasterRule.leaveCount === '' ? 0 : leaveMasterRule.leaveCount || 0;
      if (val === AppConstants.LEAVE.LEAVE_CYCLE_PERIOD.YEARLY) {
        updatedLeaves = [...Array(12)].map(() => leaveCount);
      } else {
        updatedLeaves = [...Array(12)].map((d, i) =>
          calculateLeaveByMonth(leaveCount, i)
        );
      }
      setMonthlyLeaveCalendarVisibility(prev => ({
        ...prev,
        [index]: val !== AppConstants.LEAVE.LEAVE_CYCLE_PERIOD.YEARLY,
      }));
    }
    setLeaveMasterRuleMonthlyLeaves(prev => {
      return { ...prev, [index]: updatedLeaves };
    });
  };

  const updateLeaveMasterRuleMonthlyLeaves = (ruleNumber, index, val) => {
    setLeaveMasterRuleMonthlyLeaves(prev => {
      return {
        ...prev,
        [ruleNumber]: prev[ruleNumber].map((d, i) =>
          i === index ? val.target.value : d
        ),
      };
    });
  };

  const transformRequest = values => {
    values.leaveYear = AppConstants.LEAVE.LEAVE_YEAR.CALENDAR_YEAR;
    var data = { ...values, color: colorValue };
    data.leaveMasterRules = data.leaveMasterRules.map((d, i) => {
      d.enableRollOver = d.rollOverCount !== '' && d.rollOverCount > 0;
      if (
        d.leaveCyclePeriod === AppConstants.LEAVE.LEAVE_CYCLE_PERIOD.MONTHLY
      ) {
        d.monthWiseLeaveCount = leaveMasterRuleMonthlyLeaves[indexes[i]].reduce(
          (a, d, i) => {
            a[i] = Number(d);
            return a;
          },
          {}
        );
      }

      if (d.leaveRuleApplicable === 'ALL') {
        delete d.department;
        delete d.location;
      } else {
        d.department = (d.department || []).map(d => d.key);
        d.location = d.department.length
          ? (d.location || []).map(d => d.key)
          : [];
      }

      return d;
    });
    return data;
  };

  const onSuccess = resp => {
    toast({
      title: t('add_leave_master_success_toast'),
      description: resp.message,
      status: 'success',
    });
    navigate('/home/leave/master/manage');
  };

  const onCancel = () => {
    navigate('/home/leave/master/manage');
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const onMouseOver = event => {
    setColorValue(event.hex);
  };

  const {
    isLoading,
    onSubmit,
    register,
    control,
    errors,
    reset: resetForm,
    getValues,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: CreateLeaveMasterValidationSchema(t),
    transformRequest,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flex={1} direction="column">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle title={t('add_leave_master_heading')} />
      </Flex>
      <Box
        as={'form'}
        onSubmit={onSubmit}
        noValidate="noValidate"
        bgColor="white"
        borderRadius="16px"
      >
        <Stack>
          <Grid templateColumns="repeat(10, 1fr)" p={10} gap={8}>
            <GridItem colSpan={4}>
              <ThemeInput
                label={t('label_leave_master_name')}
                name="name"
                error={errors.name}
                register={register}
                type="text"
                isRequired={true}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeSwitch
                label={t('label_leave_master_active')}
                name="status"
                error={errors.status}
                defaultValue={true}
                register={register}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={3}>
              <ThemeSwitch
                label={t('label_include_in_leave_balance')}
                name="includeInLeaveBalance"
                error={errors.includeInLeaveBalance}
                defaultValue={true}
                register={register}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={4}>
              <ThemeTextAreaInput
                type="text"
                label={t('label_leave_description')}
                name="description"
                error={Bro(errors).iCanHaz('description')}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={5} position="relative">
              <Box position="absolute" right="0px">
                <EditSvg />
              </Box>
              <ThemeInput
                name="leaveYear"
                label={t('label_leave_year')}
                defaultValue={t('leave_year_label')}
                type="text"
                isReadOnly={true}
                isRequired
              />
              <Text
                mt="2px"
                fontSize="13px"
                fontFamily="Open Sans"
                color="#818081"
              >
                *Selected Leave Year will be applicable to all Leave Types
              </Text>
            </GridItem>
          </Grid>

          <GridItem colSpan={8} pl={10} pb={5}>
            <ColorPicker
              color={colorValue}
              label="Display Color"
              onChange={onMouseOver}
            />
          </GridItem>

          <Box p={3} bg="#FAFAFA">
            <Flex display="flex" justifyContent="space-between">
              <Box as={Flex} justifyContent="space-between" ml={2}>
                <Text
                  fontFamily="Raleway"
                  fontStyle="normal"
                  fontWeight="bold"
                  fontSize="18px"
                  ml={3}
                >
                  {t('heading_applicable_scenarios')}
                </Text>
              </Box>
              <Center>
                <Box as={Flex} flexDirection="row" alignItems="center">
                  <Button
                    leftIcon={<AddIcon />}
                    onClick={addRule}
                    alignSelf="flex-end"
                  >
                    {t('button_add')}
                  </Button>
                </Box>
              </Center>
            </Flex>
          </Box>
          <Box>
            {indexes.map((item, index) => {
              const fieldName = `leaveMasterRules[${index}]`;
              return (
                <Stack key={index}>
                  {/* <Box pl={6}>
                      <ListHeader
                        msg={`${t('label_scenario')} ${index + 1}`}
                        index={indexes.length}
                        onBtnClick={removeRule(index)}
                      />
                    </Box> */}
                  <Box as={Flex} justifyContent="space-between" pl={6}>
                    <Text m={5} fontSize="16px" fontWeight="800">{`${t(
                      'label_scenario'
                    )} ${index + 1}`}</Text>

                    {index > 0 ? (
                      <Text
                        my={5}
                        mr={8}
                        fontSize="14px"
                        style={{ cursor: 'pointer' }}
                        fontWeight="600"
                        color="red.400"
                        onClick={removeRule(index)}
                      >
                        Remove Scenario
                      </Text>
                    ) : (
                      <></>
                    )}
                  </Box>
                  <Grid templateColumns="repeat(12, 1fr)" px={10} gap={8}>
                    <GridItem colSpan={4} pt={2}>
                      <ThemeRadioGroup
                        label={t('label_pay_type')}
                        name={`[${fieldName}].payType`}
                        error={Bro(errors).iCanHaz(
                          `leaveMasterRules.${index}.payType`
                        )}
                        register={register}
                        options={leavePayTypeOptions}
                        direction="row"
                        spacing="20"
                        isRequired
                      />
                    </GridItem>
                    <GridItem colSpan={4}>
                      <ThemeInput
                        label={t('label_leave_count')}
                        name={`[${fieldName}].leaveCount`}
                        error={Bro(errors).iCanHaz(
                          `leaveMasterRules.${index}.leaveCount`
                        )}
                        register={register}
                        type="number"
                        isRequired
                        onChange={updateLeaveYearCalendarValues.bind(
                          null,
                          'leaveCount',
                          index
                        )}
                      />
                      <Text
                        mt="2px"
                        fontSize="13px"
                        fontFamily="Open Sans"
                        color="#818081"
                      >
                        Default annual allowance for the leave type that all
                        employees will get
                      </Text>
                    </GridItem>
                    <GridItem colSpan={4}>
                      <ThemeInput
                        label={t('label_rollover_count')}
                        name={`[${fieldName}].rollOverCount`}
                        error={Bro(errors).iCanHaz(
                          `leaveMasterRules.${index}.rollOverCount`
                        )}
                        register={register}
                        type="number"
                      />
                    </GridItem>
                    <GridItem colSpan={4}>
                      <ThemeInput
                        label={t('label_negative_leave_balance_allowed')}
                        placeholder="0, -1, -2..."
                        name={`[${fieldName}].allowedNegativeLeaveCount`}
                        error={Bro(errors).iCanHaz(
                          `leaveMasterRules.${index}.allowedNegativeLeaveCount`
                        )}
                        register={register}
                        type="number"
                      />
                    </GridItem>
                    <GridItem colSpan={4}>
                      <ThemeInput
                        label={t('label_overall_capping')}
                        placeholder="max number of allowed leaves"
                        name={`[${fieldName}].maximumRollOverLimit`}
                        error={Bro(errors).iCanHaz(
                          `leaveMasterRules.${index}.maximumRollOverLimit`
                        )}
                        register={register}
                        type="number"
                      />
                    </GridItem>

                    <GridItem colSpan={6}>
                      <Grid templateColumns="repeat(8, 1fr)" gap={8}>
                        <GridItem colSpan={8}>
                          <ThemeRadioGroup
                            label={t('label_rule_applicable')}
                            name={`[${fieldName}].leaveRuleApplicable`}
                            error={Bro(errors).iCanHaz(
                              `leaveMasterRules.${index}.leaveRuleApplicable`
                            )}
                            register={register}
                            options={
                              index > 0
                                ? leaveRuleApplicableSpecificOptions
                                : leaveRuleApplicableAllOptions
                            }
                            radioGroupValue={index === 0 ? 'ALL' : 'SPECIFIC'}
                            // radioGroupOnChange={changeDepartmentVisibility.bind(
                            //   null,
                            //   index
                            // )}
                            direction="row"
                            spacing="5"
                            isRequired
                          />
                        </GridItem>
                        <GridItem
                          hidden={index<1}
                          colSpan={6}
                        >
                          <MultiSelect
                            name={`[${fieldName}].department`}
                            label={t('label_department')}
                            error={Bro(errors).iCanHaz(
                              `leaveMasterRules.${index}.department`
                            )}
                            placeholder={t('select_an_option')}
                            options={departmentOptions}
                            onChange={changeLocationVisibility.bind(
                              null,
                              index
                            )}
                            labelKey="label"
                            valueKey="key"
                            control={control}
                            isMulti
                          />
                        </GridItem>
                        <GridItem
                          hidden={!locationVisibility[index]}
                          colSpan={6}
                        >
                          <MultiSelect
                            options={locationOptions}
                            label={t('label_location')}
                            name={`[${fieldName}].location`}
                            placeholder={t('select_an_option')}
                            onChange={changeLocationVisibility.bind(
                              null,
                              index
                            )}
                            labelKey="label"
                            valueKey="key"
                            control={control}
                            isMulti
                            error={Bro(errors).iCanHaz(
                              `leaveMasterRules.${index}.location`
                            )}
                          />
                        </GridItem>
                      </Grid>
                    </GridItem>
                    <GridItem colSpan={6}>
                      <ThemeRadioGroup
                        radioGroupOnChange={updateLeaveYearCalendarValues.bind(
                          null,
                          'leaveCyclePeriod',
                          index
                        )}
                        label={t('label_leave_cycle_period')}
                        name={`[${fieldName}].leaveCyclePeriod`}
                        error={Bro(errors).iCanHaz(
                          `leaveMasterRules.${index}.leaveCyclePeriod`
                        )}
                        register={register}
                        options={lifeCyclePeriodOptions}
                        direction="row"
                        spacing="5"
                        isRequired
                      />

                      <Grid
                        templateColumns="repeat(3, 1fr)"
                        mt={14}
                        hidden={!monthlyLeaveCalendarVisibility[index]}
                      >
                        {leaveYearCalender.map((d, i) => (
                          <GridItem
                            key={i + d}
                            colSpan={1}
                            h={12}
                            bg={i % 2 === 0 ? '#EAEEF4' : '#FFFFFF'}
                            border="0.01px solid #ACACAC"
                            style={
                              selectedCalendarMonth[index] === i
                                ? {
                                    cursor: 'pointer',
                                    background: '#0461FF',
                                    color: '#FFFFFF',
                                  }
                                : { cursor: 'pointer' }
                            }
                            onClick={() => {
                              setSelectedCalendarMonth(prev => ({
                                ...prev,
                                [index]: i,
                              }));
                            }}
                          >
                            <Box
                              as={Flex}
                              justifyContent="space-between"
                              direction="column"
                              alignItems="center"
                            >
                              <Text fontSize="0.75rem">
                                {(leaveMasterRuleMonthlyLeaves[index] || [])[
                                  i
                                ] || ''}
                              </Text>
                              <Text fontSize="0.75rem">{d}</Text>
                            </Box>
                          </GridItem>
                        ))}

                        {selectedCalendarMonth[index] !== undefined && (
                          <GridItem colSpan={3} mt={5}>
                            <ThemeInput
                              label={
                                leaveYearCalender[selectedCalendarMonth[index]]
                              }
                              register={register}
                              type="text"
                              value={
                                (leaveMasterRuleMonthlyLeaves[index] || [])[
                                  selectedCalendarMonth[index]
                                ] || ''
                              }
                              onChange={updateLeaveMasterRuleMonthlyLeaves.bind(
                                null,
                                index,
                                selectedCalendarMonth[index]
                              )}
                            />
                          </GridItem>
                        )}
                      </Grid>
                    </GridItem>
                  </Grid>
                  <Box pt={2}>
                    <Box p={3} bg="#FAFAFA"></Box>
                  </Box>
                </Stack>
              );
            })}
          </Box>
        </Stack>
        <Stack pb="20px" px="25px"></Stack>

        <Flex px={10} pb={10}>
          <Button type="submit" isLoading={isLoading} mr={10}>
            {t('btn_create_leave_master')}
          </Button>
          <Button onClick={onCancel} variant="ghost">
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default CreateLeaveMaster;
