import React, { useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import { Flex } from '@chakra-ui/react';
 
const PdfViewer = ({pdfUrl}) => {
  // const [page, setPage] = useState(1);
  const canvasRef = useRef(null);
 
  // const { pdfDocument, pdfPage } = usePdf({
  //   file: pdfUrl,
  //   // page,
  //   canvasRef,
  // });

  const { pdfDocument } = usePdf({
    file: pdfUrl,
    // page,
    canvasRef,
  });
 
  return (
    <Flex>
    {!pdfDocument && <Flex>Loading...</Flex>}
      <canvas ref={canvasRef} />
      {Boolean(pdfDocument && pdfDocument.numPages) && (
        <Flex>
          {/* <ul className="pager">
            <li className="previous">
              <Button disabled={page === 1} onClick={() => setPage(page - 1)}>
                Previous
              </Button>
            </li>
            <li className="next">
              <Button
                disabled={page === pdfDocument.numPages}
                onClick={() => setPage(page + 1)}
              >
                Next
              </Button>
            </li> 
          </ul> */}
        </Flex>
      )}
      </Flex>
  );
};
export default PdfViewer;
