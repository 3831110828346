import * as React from "react";
import { Icon } from "@chakra-ui/react"


function PendingCircle(props) {
  return (
    <Icon
    width={5}
    height={5}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
       <path
        d="M10.5 1.75a8.75 8.75 0 100 17.5 8.75 8.75 0 000-17.5zm0 15.75a7 7 0 110-14 7 7 0 010 14z"
        fill="#C4C4C4"
      />
      <path
        d="M14 9.625h-2.625V7a.875.875 0 00-1.75 0v3.5a.875.875 0 00.875.875H14a.875.875 0 100-1.75z"
        fill="#C4C4C4"
      />
    </Icon>
  )
}

export default PendingCircle;

