import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  ClientDataGrid, defaultGridOptions
} from '@bit/sixsprints.utils.kg-grid';
import { Flex } from '@chakra-ui/react';
import { Link } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import Rodal from 'rodal';
import 'rodal/lib/rodal.css';
import {
  ActionContainer,
  GridButton,
  GridInput
} from '../../components/misc/grid-ui';
import PageTitle from '../../components/misc/page-title';
import { getDateFormatter } from '../../utils/helpers';
import useNotify from '../../utils/notifications';
import ROUTES from '../routes';

const EmployeeList = () => {
  const { t } = useI18nContext();
  const [isOpen, setIsOpen] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const toast = useNotify();
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });

  const previewCallback = val => {
    setPreviewData(val);
    setIsOpen(true);
  };

  const onSubmitImport = () => {
    setIsOpen(false);
  };

  const transformColDef = fields => {
    const modifiedColDef = fields.map(field => {
      const { cellRenderer, ...rest } = field;
      switch (field.colId) {
        case 'userId':
          return {
            ...rest,
            cellRendererFramework: params => {
              return (
                <Link
                  to={`/edit-details/${params.data.slug}`}
                  className="link"
                  state={{
                    data: params.data ? params.data : {},
                  }}
                >
                  {params.value ? params.value : ''}
                </Link>
              );
            },
          };
        case 'department':
          return  {
            ...rest,
            cellRendererFramework: params => {
              return (
                <>
                  {Bro(params).iCanHaz('data.workInformationDto.departmentName')}
                </>
              );
            },
          };
         case 'designation' :
        return  {
          ...rest,
          cellRendererFramework: params => {
            return (
              <>
                {Bro(params).iCanHaz('data.workInformationDto.designationName')}
              </>
            );
          },
        };
         case 'dateOfJoining':
        return  {
          ...rest,
          cellRendererFramework: params => {
            return (
              <>
                {getDateFormatter(Bro(params).iCanHaz('data.workInformationDto.dateOfJoining'),'DD/MM/YYYY')}
              </>
            );
          },
        };
      }

      return field;
    });

    return modifiedColDef;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flex={1} direction="column">
      <PageTitle style={{mb:2}} title={t('user_page_title')} />
      <DataGrid
        gridProps={{ ...defaultGridOptions, transformColDef, previewCallback }}
        beans={{ restApi, storage, i18n: t, notificationService: simpleToast }}
        dataProps={{ collection: 'user', csvName: 'user_list' }}
        uiComponents={{ ActionContainer, Button: GridButton, Input: GridInput, addNewBtnText: t('add_employee_heading'),
        addNewPath: ROUTES.DASHBOARD + ROUTES.ADD_EMPLOYEE, }}
      />

      {isOpen && (
        <Rodal
          visible={true}
          showCloseButton={false}
          animation="slideRight"
          width={95}
          height={95}
          measure="%"
          className="filter-modal"
          customStyles={{ overflow: 'auto' }}
          style={{ marginBottom: 20 }}
        >
          <ClientDataGrid
            beans={{
              restApi,
              storage,
              i18n: t,
              notificationService: simpleToast,
            }}
            dataProps={{
              collection: 'user',
              rowData: previewData,
              onImportSuccessCallback: onSubmitImport,
              hideAddNew: true,
              colWidth: 150,
            }}
            uiComponents={{
              ActionContainer,
              Button: GridButton,
            }}
          />
        </Rodal>
      )}
    </Flex>
  );
};

export default EmployeeList;
