export const AppConstants = {
  bASEURL: 'https://api.hr.dev.sixsprintscloud.com/api/v1',

  TIMEZONE_HEADER: 'X-Requested-With',

  MESSAGE: {},

  ENTITY: {},

  AUTH: {
    AUTH_TOKEN_KEY: 'X-AUTH-TOKEN',
    ACCEPT_LANGUAGE: 'Accept-Language',
    AUTH_TENANT_ID: 'X-TENANT-ID',
    ROLE: 'ROLE',
    USER_KEY: 'USER',
    LANG_CODE: 'langCode',
    TOKEN: '_token',
    MEMBER_KEY: 'MEMBER',
    CONFIG: 'CONFIG',
    TENANT: 'TENANT',
    PERMISSION: 'PERMISSION',
  },

  ROLE: {
    ADMIN: 'ADMIN',
    USER: 'USER'
  },

  FILTERS: {},

  TOPICS: {},

  STATUS: {},

  LEAVE: {
    LEAVE_YEAR: {
      CALENDAR_YEAR: 'CALENDAR_YEAR',
      CALEDAR_YEAR_MONTHS: [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ],
      FINANCIAL_YEAR: 'FINANCIAL_YEAR',
      FINANCIAL_YEAR_MONTHS: [
        'April', 'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December', 'January', 'February', 'March'
      ],
    },
    LEAVE_CYCLE_PERIOD: {
      MONTHLY: 'MONTHLY',
      YEARLY : 'YEARLY',
    },
    LEAVE_RULE_APPLICABLE: {
      ALL: 'ALL',
      SPECIFIC: 'SPECIFIC'
    }
  },
  REGULARISATION_STATUS: {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    REJECTED:'REJECTED',
    CANCELLED: 'CANCELLED'
  },
  ATTENDANCE_TYPE: {
    PRESENT: {
      label: 'present',
      color: '#67CE78'
    },
    REG_REQUESTED: {
      label: 'regular_request',
      color: '#21f241',
    },
    LEAVE: {
      label: 'leave',
      color: '#2678FE',
    },
    WEEKEND: {
      label: 'weekend',
      color: '#D1472A',
    },
    HOLIDAY: {
      label: 'holiday',
      color: '#000000',
    }
  }
};
