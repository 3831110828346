import { useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, GridItem, Stack } from '@chakra-ui/react';
import { useNavigate } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import ThemeDateInput from '../../components/form-elements/date-picker/date-picker';
import SelectThemeInput from '../../components/form-elements/select-input';
import ThemeInput from '../../components/form-elements/theme-input';
import useNotify from '../../utils/notifications';
import PageTitle from '../../components/misc/page-title';
import ThemeAsyncSelectInput from '../../components/form-elements/theme-async-select-input';
import DataService from '../../services/data-service';
import { AddEmployeeValidationSchema } from '../../utils/validations';

const apiOptions = {
  url: '/user',
  method: 'post',
};

const AddEmployee = () => {
  const navigate = useNavigate();
  const toast = useNotify();
  const { t, langCode } = useI18nContext();

  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
  const [availableSeats, setAvailableSeats] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const transformRequest = values => {
    const data = { ...values };
    data.workInformationDto.managerSlug = data.workInformationDto.managerSlug.slug;
    data.workInformationDto.dateOfJoining = new Date(
      data.workInformationDto.dateOfJoining
    ).getTime();
    data.workInformationDto.departmentSlug =
      data.workInformationDto.departmentSlug.slug;
    data.workInformationDto.designationSlug =
      data.workInformationDto.designationSlug.slug;
    data.workInformationDto.employeeTypeSlug =
      data.workInformationDto.employeeTypeSlug.slug;
    data.workInformationDto.locationSlug =
      data.workInformationDto.locationSlug.slug;
    data.workInformationDto.roleSlug = data.workInformationDto.roleSlug.slug;
    data.workInformationDto.sourceOfHireSlug =
      data.workInformationDto.sourceOfHireSlug.slug;
    data.workInformationDto.employeeStatusSlug =
    data.workInformationDto.employeeStatusSlug.slug;  
    data.userId = data.idPrefix + data.idSuffix;
    if (data.contractEndDate) {
      data.contractEndDate = new Date(data.contractEndDate).getTime();
    }
    return data;
  };

  const onSuccess = resp => {
    toast({
      title: t('add_employee_success_toast'),
      description: resp.message,
      status: 'success',
    });
    navigate('/home/employee');
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isLoading, onSubmit, register, control, errors } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: AddEmployeeValidationSchema(t),
    transformRequest,
  });
  const transformDepartmentList = data => {
    let transformDepartmentList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.departmentName[langCode];
      return obj;
    });
    return transformDepartmentList;
  };

  const promiseOptionsForDepartment = async () => {
    let data = await DataService.post('/department/search', {
      page: 0,
      size: 30,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformDepartmentList(data);
    return data;
  };

  const transformLocationList = data => {
    let transformLocationList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.locationName[langCode];
      return obj;
    });
    return transformLocationList;
  };

  const promiseOptionsForLocation = async () => {
    let data = await DataService.post('/location/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformLocationList(data);
    return data;
  };

  const transformUserList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.userId = item.userId;
      obj.name = item.personalInfo.firstName + ' ' + item.personalInfo.lastName;
      obj.manager = item.manager;
      obj.managerSlug = item.managerSlug;
      obj.slug = item.slug;
      return obj;
    });
    return transformedList;
  };

  const promiseOptions = async text => {
    let data = await DataService.post('/user/search', {
      page: 0,
      size: 50,
      filterModel: {
        'personalInfo.firstName': {
          filterType: 'text',
          type: null,
          filter: text,
        },
        'personalInfo.lastName': {
          filterType: 'text',
          type: null,
          filter: text,
        },
      },
    }).then(resp => resp.data.data.content);

    let managerData = data.filter(item => {
      return item?.workInformationDto?.roleName?.includes('Manager');
    });

    return transformUserList(managerData);
  };

  const transformDesignationList = data => {
    let transformDesignationList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.designationName[langCode];
      return obj;
    });
    return transformDesignationList;
  };

  const promiseOptionsForDesignation = async () => {
    let data = await DataService.post('/designation/search', {
      page: 0,
      size: 40,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformDesignationList(data);
    return data;
  };

  const transformEmployeeTypeList = data => {
    let transformEmployeeTypeList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.employeeType[langCode];
      return obj;
    });
    return transformEmployeeTypeList;
  };

  const promiseOptionsForEmployeeType = async () => {
    let data = await DataService.post('/employeeType/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformEmployeeTypeList(data);
    return data;
  };

  const transformRoleList = data => {
    let transformRoleList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.roleName[langCode];
      return obj;
    });
    return transformRoleList;
  };

  const promiseOptionsForRole = async () => {
    let data = await DataService.post('/roleMaster/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformRoleList(data);
    return data;
  };

  const transformSourceOfHireList = data => {
    let transformSourceOfHireList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.sourceOfHire[langCode];
      return obj;
    });
    return transformSourceOfHireList;
  };

  const promiseOptionsForSourceOfHire = async () => {
    let data = await DataService.post('/sourceOfHire/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformSourceOfHireList(data);
    return data;
  };

  const transformEmployeeStatus = data => {
    let transformEmployeeStatus = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.statusName[langCode];
      return obj;
    });
    return transformEmployeeStatus;
  };

  const promiseOptionsForEmployeeStatus = async () => {
    let data = await DataService.post('/employeeStatusMaster/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformEmployeeStatus(data);
    return data;
  };

  useEffect(() => {
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode]);

  const getUserInfo = () => {
    DataService.get(`/seats/seats-avaialable`).then(resp => {
      const data = Bro(resp).iCanHaz('data.data');
      setAvailableSeats(
        data.map(item => {
          return { key: item };
        })
      );
    });
  };

  useEffect(getUserInfo, []);

  const onCancel = () => {
    navigate(-1);
  };
  return (
    <Flex flex={1} direction="column" mb={5}>
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle title={t('add_employee_heading')} />
      </Flex>
      <Box
        as={'form'}
        onSubmit={onSubmit}
        noValidate="noValidate"
        bgColor="white"
        borderRadius="16px"
      >
        <Stack>
          <Grid templateColumns="repeat(6, 1fr)" p={10} gap={8} mr={20}>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_id_prefix')}
                name="idPrefix"
                type="text"
                error={Bro(errors).iCanHaz('idPrefix')}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_emp_id')}
                name="idSuffix"
                type="text"
                error={Bro(errors).iCanHaz('idSuffix')}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_first_name')}
                name="personalInfo.firstName"
                type="text"
                error={Bro(errors).iCanHaz('personalInfo.firstName')}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeInput
                label={t('label_last_name')}
                name="personalInfo.lastName"
                type="text"
                error={Bro(errors).iCanHaz('personalInfo.lastName')}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeInput
                label={t('label_official_mail')}
                name="email"
                type="email"
                error={Bro(errors).iCanHaz('email')}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeInput
                label={t('label_personal_mail')}
                name="personalEmail"
                type="email"
                error={Bro(errors).iCanHaz('personalEmail')}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                promiseOptions={promiseOptionsForDepartment}
                defaultValue=""
                labelKey="name"
                valueKey="slug"
                name="workInformationDto.departmentSlug"
                control={control}
                error={Bro(errors).iCanHaz('workInformationDto.departmentSlug')}
                label={t('department')}
                placeholder={t('select_an_option')}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue=""
                promiseOptions={promiseOptionsForLocation}
                labelKey="name"
                valueKey="slug"
                name="workInformationDto.locationSlug"
                error={Bro(errors).iCanHaz('workInformationDto.locationSlug')}
                control={control}
                label={t('label_location')}
                placeholder={<SearchIcon />}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeAsyncSelectInput
                showKeyInLabel={true}
                promiseOptions={promiseOptions}
                labelKey="name"
                valueKey="slug"
                labelIdKey="userId"
                name="workInformationDto.managerSlug"
                defaultValue=""
                control={control}
                label={t('reporting_to')}
                error={Bro(errors).iCanHaz('workInformationDto.managerSlug')}
                placeholder={<SearchIcon />}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                promiseOptions={promiseOptionsForDesignation}
                defaultValue=""
                labelKey="name"
                valueKey="slug"
                name="workInformationDto.designationSlug"
                control={control}
                error={Bro(errors).iCanHaz(
                  'workInformationDto.designationSlug'
                )}
                label={t('label_designation')}
                placeholder={t('select_an_option')}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeDateInput
                label={t('date_of_joc')}
                name="workInformationDto.dateOfJoining"
                error={Bro(errors).iCanHaz('workInformationDto.dateOfJoining')}
                onSelect={date => setStartDate(date)}
                defaultValue=""
                showTimeSelect={false}
                dateFormat="yyyy/MM/dd"
                control={control}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                promiseOptions={promiseOptionsForEmployeeType}
                defaultValue=""
                labelKey="name"
                valueKey="slug"
                name="workInformationDto.employeeTypeSlug"
                error={Bro(errors).iCanHaz(
                  'workInformationDto.employeeTypeSlug'
                )}
                control={control}
                label={t('employee_type')}
                placeholder={t('select_an_option')}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                promiseOptions={promiseOptionsForRole}
                defaultValue=""
                labelKey="name"
                valueKey="slug"
                name="workInformationDto.roleSlug"
                error={Bro(errors).iCanHaz('workInformationDto.roleSlug')}
                control={control}
                label={t('label_role')}
                placeholder={t('select_an_option')}
                isRequired
                // isMulti
              />
            </GridItem>
            <GridItem colSpan={2} mt={4}>
              <SelectThemeInput
                options={availableSeats}
                label={t('label_seating_location')}
                labelKey="key"
                valueKey="key"
                name="workInformationDto.seatingLocation"
                error={Bro(errors).iCanHaz(
                  'workInformationDto.seatingLocation'
                )}
                register={register}
                placeholder={t('select_an_option')}
                type="text"
                mt={3}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                promiseOptions={promiseOptionsForSourceOfHire}
                defaultValue=""
                labelKey="name"
                valueKey="slug"
                name="workInformationDto.sourceOfHireSlug"
                control={control}
                error={Bro(errors).iCanHaz(
                  'workInformationDto.sourceOfHireSlug'
                )}
                label={t('label_source_of_hire')}
                placeholder={t('select_an_option')}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                promiseOptions={promiseOptionsForEmployeeStatus}
                defaultValue=""
                labelKey="name"
                valueKey="slug"
                name="workInformationDto.employeeStatusSlug"
                control={control}
                error={Bro(errors).iCanHaz(
                  'workInformationDto.employeeStatusSlug'
                )}
                label={t('label_employee_status')}
                placeholder={t('select_an_option')}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={2}>
              <ThemeInput
                label={t('label_work_phone')}
                name="workInformationDto.phone"
                type="text"
                error={Bro(errors).iCanHaz('workInformationDto.phone')}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeDateInput
                label={t('contract_end_date')}
                name="contractEndDate"
                defaultValue=""
                showTimeSelect={false}
                minDate={startDate}
                dateFormat="yyyy/MM/dd"
                control={control}
                mt={1}
              />
            </GridItem>
          </Grid>
        </Stack>
        <Flex px={10} pb={10}>
          <Button
            type="submit"
            px={'2rem'}
            leftIcon={<AddIcon />}
            isLoading={isLoading}
          >
            {t('button_add')}
          </Button>
          <Button ml={5} variant="ghost" onClick={onCancel}>
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AddEmployee;
