import React, { useState } from 'react';
import {
  Box,
  // Center,
  Flex,
  IconButton,
  // Image,
  Link,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';

// Icons
// import EyeIcon from '../../../../icons/eye-icon';
import CloudDwnldIcon from '../../../../icons/cloud-download-icon';
import { Keys } from '@bit/sixsprints.utils.data';
import { storage } from '@bit/sixsprints.core.beans-web';
import { AppConstants } from '../../../../utils/constants';

// import PdfViewer from '../../../../components/misc/pdf-viewer';
// import ModalDialog from '../../../../components/modals/modal';
import { getDateFormatter, isAdmin } from '../../../../utils/helpers';
import colors from '../../../../theme/foundations/colors';
import SelectThemeInput from '../../../../components/form-elements/select-input';

const UploadDocView = ({
  columns,
  rows,
  // viewClickCallback,
  downloadClickCallback,
  register,
  setOtherDoc,
  setHealthDoc,
  setTenthEducationDocUpload,
  setTwelfthEducationDocUpload,
  onChangeForOtherDoc,
  onChangeForHealthDoc,
  onChangeForTenthDoc,
  onChangeForTwelfthDoc,
}) => {
  const [imageSrc, setImageSrc] = useState('');
  // const onViewClick = (rowData, i) => {
  //   viewClickCallback && viewClickCallback(rowData, i);
  //   setImageSrc(
  //     `${rowData.url}&${AppConstants.AUTH.AUTH_TOKEN_KEY}=${storage.get(
  //       Keys.TOKEN
  //     )}`
  //   );
  //   onOpen();
  // };

  const onDownloadClick = (rowData, i) => {
    downloadClickCallback && downloadClickCallback(rowData, i);
  };

  //modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box borderRadius="16px" overflow="hidden" bg="white">
      <Table fontSize="14px" variant="striped" colorScheme="strip" size="sm">
        <Thead background={colors.brand['100']}>
          <Tr>
            {columns.map((col, i) => (
              <Th key={i} p={5}>
                <Text
                  fontSize="14px"
                  {...(i === columns.length - 1 && { align: 'center' })}
                >
                  {col}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {rows.map((row, i) => {
            return (
              <Tr key={i}>
                <Td px={5}>
                  <Flex flexDirection="row" alignItems="center">
                    {row.documentName}
                  </Flex>
                </Td>
                <Td px={5}>
                  {getDateFormatter(row.date, 'DD/MM/yyyy') || 'NA'}
                </Td>
                <Td px={5}>
                  {isAdmin() ? (
                    <SelectThemeInput
                      options={[
                        {
                          key: 'UNDER_REVIEW',
                          label: 'Under Review',
                        },
                        {
                          key: 'APPROVED',
                          label: 'Approved',
                        },
                        {
                          key: 'RE_UPLOAD',
                          label: 'Re Upload',
                        },
                      ]}
                      valueKey="key"
                      labelKey="label"
                      name={'row.documentStatus'}
                      defaultValue={row.documentStatus}
                      onChange={event => {
                        setOtherDoc &&
                          setOtherDoc(prev => {
                            let prevArray = [...prev];
                            prevArray[i]['documentStatus'] = event.target.value;
                            onChangeForOtherDoc(event)
                            return [...prevArray];
                          });

                        setHealthDoc &&
                          setHealthDoc(prev => {
                            let prevArray = [...prev];
                            prevArray[i]['documentStatus'] = event.target.value;
                            onChangeForHealthDoc(event)
                            return [...prevArray];
                          });

                        setTenthEducationDocUpload &&
                          setTenthEducationDocUpload(prev => {
                            let prevArray = [...prev];
                            prevArray[i]['documentStatus'] = event.target.value;
                            onChangeForTenthDoc(event)
                            return [...prevArray];
                          });

                        setTwelfthEducationDocUpload &&
                          setTwelfthEducationDocUpload(prev => {
                            let prevArray = [...prev];
                            prevArray[i]['documentStatus'] = event.target.value;
                            onChangeForTwelfthDoc(event)
                            return [...prevArray];
                          });
                      }}
                      register={register}
                    />
                  ) : (
                    <>{row.documentStatus || 'NA'}</>
                  )}
                </Td>
                <Td px={5}>
                  <Flex justify="center" py={1}>
                    {row.url ? (
                      <>
                        {/* <IconButton
                          bg="none"
                          size="sm"
                          boxShadow="none"
                          variant="ghost"
                          icon={<EyeIcon boxSize="24px" />}
                          onClick={() => onViewClick(row, i)}
                          mr={3}
                        /> */}

                        <Link
                          href={`${row.url}&${
                            AppConstants.AUTH.AUTH_TOKEN_KEY
                          }=${storage.get(Keys.TOKEN)}&download=true`}
                          download
                          isExternal
                        >
                          <IconButton
                            bg="none"
                            size="sm"
                            boxShadow="none"
                            variant="ghost"
                            icon={<CloudDwnldIcon boxSize="24px" />}
                            onClick={() => onDownloadClick(row, i)}
                            mr={3}
                          />
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      {/* <ModalDialog
        isOpen={isOpen}
        onClose={onClose}
        size="xxl"
        showCloseBtn={true}
        scrollBehavior="inside"
        closeOnOverlayClick={true}
        bodyContent={
          <Center h="100%" p="5px">
            {imageSrc.includes('pdf') ? (
              <PdfViewer pdfUrl={imageSrc} />
            ) : (
              <Image src={imageSrc} />
            )}
          </Center>
        }
      /> */}
    </Box>
  );
};

export default UploadDocView;
