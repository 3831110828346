import { storage } from '@bit/sixsprints.core.beans-web';
import { Keys } from '@bit/sixsprints.utils.data';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Box, Flex, Spacer } from '@chakra-ui/react';
import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import SideBar from '../../components/misc/sidebar';
import DocumentInfo from './steps/document-upload/document-info';
import BankInfo from './steps/personal-info/bank-info';
import DependentInfo from './steps/personal-info/dependent-info';
import EmergencyInfo from './steps/personal-info/emergency-info';
import HealthInfo from './steps/personal-info/health-info';
import PersonalInfo from './steps/personal-info/personal-info';
import ModalDialog from '../../components/modals/modal';
import BodyContent from './modal-body';
import Bro from 'brototype';
import Header from '../../components/misc/header';
import { AppConstants } from '../../utils/constants';
import SummaryInfo from './steps/personal-info/summary-info';
import WorkExperience from './steps/personal-info/work-experience';
import WorkInformation from './steps/personal-info/work-info';
import EducationInfo from './steps/personal-info/education-info';
import { getImageUrl } from '../../utils/helpers';

const TOTAL_STEP = 10;
const stepObj = t => {
  return [
    {
      label: t('personal_info_cap'),
      step: 0,
      steps: [
        {
          label: t('basic_info_label'),
          step: 0,
        },
        {
          label: t('work_exp_label'),
          step: 1,
        },
        {
          label: t('summary'),
          step: 2,
        },
        {
          label: t('work_info_label'),
          step: 3,
        },
        {
          label: t('sidebar_education'),
          step: 4,
        },
        {
          label: t('sidebar_bank_info'),
          step: 5,
        },
        {
          label: t('sidebar_dependents'),
          step: 6,
        },
        {
          label: t('sidebar_emergency'),
          step: 7,
        },
        {
          label: t('medical_records_label'),
          step: 8,
        },
        {
          label: t('sidebar_doc_upload'),
          step: 9,
        },
      ],
    },
    // {
    //   label: t('sidebar_uploaded_doc'),
    //   step: 10,
    // },
  ];
};
const Onboarding = () => {
  const navigate = useNavigate();
  const { t } = useI18nContext();
  const { slug, personalInfo, onboardingStep,isFirstTimeUser } = Bro(
    storage.get(Keys.USER)
  ).iCanHaz('data')
    ? Bro(storage.get(Keys.USER)).iCanHaz('data')
    : storage.get(Keys.USER);


    const [isOpen, setIsOpen] = useState(true);
    
    const [step, setStep] = useState(onboardingStep || 0);

  const next = () => {
    if (step < TOTAL_STEP - 1) setStep(step + 1);
  };
  const previous = () => {
    if (step >= 1) setStep(step - 1);
  };

  const onClick = stepVal => {
    setStep(stepVal);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const getStep = step => {
    switch (step) {
      case 0:
        return (
          <PersonalInfo t={t} next={next} prev={previous} userSlug={slug} />
        );
      case 1:
        return (
          <WorkExperience
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            showSkipButton={true}
          />
        );
      case 2:
        return (
          <SummaryInfo t={t} next={next} prev={previous} userSlug={slug} />
        );
      case 3:
        return (
          <WorkInformation t={t} next={next} prev={previous} userSlug={slug} />
        );
      case 4:
        return (
          <EducationInfo t={t} next={next} prev={previous} userSlug={slug} />
        );
      case 5:
        return <BankInfo t={t} next={next} prev={previous} userSlug={slug} />;
      case 6:
        return (
          <DependentInfo
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            showSkipButton={true}
          />
        );
      case 7:
        return (
          <EmergencyInfo t={t} next={next} prev={previous} userSlug={slug} />
        );
      case 8:
        return <HealthInfo t={t} next={next} prev={previous} userSlug={slug} />;
      case 9:
        return (
          <DocumentInfo t={t} next={next} prev={previous} userSlug={slug} />
        );
      // case 10:
      //   return (
      //     <UploadedDoc t={t} next={next} prev={previous} userSlug={slug} />
      //   );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    const checkForRedirection = () => {
      if (onboardingStep && onboardingStep > 9) {
        navigate('/home');
      }
    };
    checkForRedirection();
  }, []);

  const logo = getImageUrl(storage.get(AppConstants.AUTH.TENANT).assets.logoImageUrl);

  return (
    <Flex minH={'100vh'}>
      <Box
        w="22vw"
        h="100vh"
        maxW={360}
        position="sticky"
        top="0"
        background="white url(/assets/img/sidebar-footer-banner.png) no-repeat bottom left/contain"
      >
        <Box>
          <Flex justifyContent="center">
            <Box
              mx={'120px'}
              my={'20px'}
              w="30vw"
              h="15vh"
              position="sticky"
              top="0"
              background={`url(${logo}) no-repeat left/contain`}
            ></Box>
          </Flex>
          <Spacer />
          <SideBar
            onboardingStep={onboardingStep || 0}
            currentStep={step}
            stepObj={stepObj(t)}
            onClick={onClick}
          />
        </Box>
      </Box>
      <Box
        flex={1}
        px="5vw"
        pt={18}
        pb={20}
        bgColor="#F5F6F9"
        direction="column"
      >
        <Box marginLeft="30vw" marginBottom="2em">
          <Header />
        </Box>
        {getStep(step)}
      </Box>
      <ModalDialog
        isOpen={isOpen}
        bodyContent={
          <BodyContent
            isFirstTimeUser={isFirstTimeUser}
            onCloseBtnClick={onCloseModal}
            username={Bro(personalInfo).iCanHaz('firstName')}
          />
        }
      />
    </Flex>
  );
};

export default Onboarding;
