import React, { useEffect, useState } from 'react';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Stack,
  IconButton,
} from '@chakra-ui/react';
import Bro from 'brototype';
import { Controller } from 'react-hook-form';
import useNotify from '../../utils/notifications';
import DataService from '../../services/data-service';
import SmallUploadIcon from '../../icons/small-upload-icon';
import EyeIcon from '../../icons/eye-icon';

const DocumentUploadCertificate = ({
  name,
  label,
  register,
  uploadValue,
  error,
  isRequired,
  url,
  control,
  defaultValue,
  hideFileName,
  refName,
  index,
  certificateList,
  showViewIcon,
  borderRadius,
  ...rest
}) => {
  const [fileName, setFileName] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const { t } = useI18nContext();
  const toast = useNotify();
  const handleClick = () => {
    (refName || hiddenFileInput).current.value = '';
    (refName || hiddenFileInput).current.click();
  };

  const handleChange = (event, index) => {
    let fileArray = Object.values(event.target.files);
    let filePath = fileArray[0];

    let allowedExtensions = /(\.pdf|\.doc|\.docx|\.png|\.jpeg|\.jpg)$/i;
    if (filePath && filePath.size > 6000000) {
      toast({
        title: t('Error'),
        description: t('file_size_is_to_large'),
        status: 'error',
      });
      return;
    }
    if (!allowedExtensions.exec(filePath.name)) {
      toast({
        title: t('toast_mess_file_format_error'),
        description: t('toast_desc_file_format_error_pic'),
        status: 'error',
      });

      hiddenFileInput.current.value = '';
      return;
    }
    let formData = new FormData();
    formData.append('file', fileArray[0]);

    DataService.post(url, formData)
      .then(resp => {
        const response =
          Bro(resp).iCanHaz('data.data') || Bro(resp).iCanHaz('data');
        uploadValue(response);
        setFileName(prev => {
          let prevArray = [...prev];
          prevArray[index] = response || '';
          return [...prevArray];
        });
        toast({
          title: t('file_uploaded_successfully'),
          description: resp.message,
          status: 'success',
        });
      })
      .catch(err => {
        toast({
          title: t(err.message),
          description: err.message,
          status: 'error',
        });
      });
  };

  useEffect(() => {
    register(name);
  }, []);

  useEffect(() => {
    if (
      certificateList &&
      Array.isArray(certificateList) &&
      certificateList.length > 0
    ) {
      certificateList.map(item => {
        setFileName(prev => [...prev, item.url]);
      });
    }
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange }) => {
        return (
          <FormControl
            id={name}
            isInvalid={!!error}
            {...rest}
            isRequired={isRequired}
          >
            {label && (
              <FormLabel fontFamily="Open Sans" fontWeight="small" mb={3}>
                {label}
              </FormLabel>
            )}
            <Flex>
              {!hideFileName && (
                <Box
                  flex={1}
                  h="42px"
                  bgColor="#ffffff"
                  borderRadius={borderRadius||6}
                  minWidth="0"
                  //   onClick={handleClick}
                  border="1px solid #E2E3E5"
                >
                  <Text
                    fontFamily="Open Sans"
                    fontStyle="normal"
                    fontWeight="500"
                    fontSize="14px"
                    mr={3}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    mt={2}
                    mx={3}
                  >
                    {/* {fileName || t('label_no_file_chosen')} */}
                    {fileName[index] || t('label_no_file_chosen')}
                  </Text>
                </Box>
              )}
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {/* <Link
                  onClick={handleClick}
                  aria-label="Search database"
                  shadow="none"
                  // pl="1.5em"
                  mt={0.4}
                > */}
                  <IconButton
                    onClick={handleClick}
                    bgColor="brand.500"
                    borderRadius="12px"
                    aria-label="Search database"
                    icon={<SmallUploadIcon />}
                    ml={4}
                    shadow="none"
                  />
                {/* </Link> */}
                {showViewIcon && fileName[index] && (
                  <EyeIcon
                    // bg="none"
                    // size="sm"
                    // boxShadow="none"
                    // variant="ghost"
                    cursor="pointer"
                    // icon={<EyeIcon boxSize="20px" />}
                    onClick={() => {
                      fileName.length > 0 &&
                        window.open(`${fileName[index]}&download=true`);
                    }}
                  />
                )}
              </Stack>

              <input
                type="file"
                ref={refName || hiddenFileInput}
                // ref={() => {
                //   hiddenFileInput();
                //   refName()
                // }}
                name={name}
                accept=".pdf, .doc, .docx,.png,.jpeg,.jpg"
                onChange={event => {
                  handleChange(event, index);
                }}
                style={{ display: 'none' }}
              />
            </Flex>
            {error && (
              <FormErrorMessage>
                <Text alignSelf="center">{t('please_fill_the_detail')}</Text>
              </FormErrorMessage>
            )}
          </FormControl>
        );
      }}
    />
  );
};
export default DocumentUploadCertificate;
