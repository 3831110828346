import { useFormController } from '@bit/sixsprints.core.beans-web';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, GridItem , Center } from '@chakra-ui/react';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import SelectThemeInput from '../../../../components/form-elements/select-input';
import ThemeInput from '../../../../components/form-elements/theme-input';
import ThemeTextAreaInput from '../../../../components/form-elements/theme-textarea';
import ForwardArrow from '../../../../icons/forward-arrow';
import useNotify from '../../../../utils/notifications';
import { BankValidationSchema } from '../../../../utils/validations';
import DataService from '../../../../services/data-service';
import { navigate } from '@reach/router';
import ThemeHeading from '../../../../components/misc/heading';
import Loader from '../../../../components/misc/loader';

const BankInfo = ({ t, next, prev, userSlug,userData, isEdit }) => {
  const toast = useNotify();
  const [isDocLoading, setIsDocLoading] = useState(false);


  const apiOptions = {
    url: `/user/bank-info?userSlug=${userSlug}`,
    method: 'put',
  };

  const onSuccess = resp => {
    if (!isEdit) {
    toast({
      title: t('bank_info_success_toast'),
      description: resp.message,
      status: 'success',
    });
      next();
    }else{
      toast({
        title: t('bank_info_updated'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    errors,
    reset: resetForm,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: BankValidationSchema(t),
  });

  const getUserInfo = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.bankInfo');
        resetForm(data);
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(getUserInfo,[]);
  return (
    (isDocLoading  && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex>
          <Box as={Flex} direction="column" mb={5}>
            {!isEdit ? 
            (<ThemeHeading heading={t('bank_info_heading')} subHeading={t('details_subheading')} mb={10}/>):(<ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
              'edit_profile_header_text'
            )}`} subHeading={t('details_subheading')}/>)}
          </Box>
        </Flex>
        <Center>
          <Loader />
        </Center>
      </Flex>
    )) || (
    <Flex flexGrow={1}>
      <Flex flex={1} direction="column" mr={'10em'}>
        {!isEdit ? (
          <ThemeHeading heading={t('bank_info_heading')} subHeading={t('details_subheading')}/>):(<ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
            'edit_profile_header_text'
          )}`} subHeading={t('details_subheading')}/>)}
        <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate" mt={'2em'}>
          <Grid templateColumns="repeat(6, 1fr)" gap={6}>
            <GridItem colSpan={4}>
              <ThemeInput
                label={t('label_bank_name')}
                name="name"
                error={errors.name}
                register={register}
                type="text"
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_branch_name')}
                name="branchName"
                error={errors.branchName}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={6} mt={3}>
              <ThemeTextAreaInput
                label={t('label_branch_add')}
                name="branchAddress"
                error={errors.branchAddress}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <SelectThemeInput
                options={[
                  `${t('acc_type_current')}`,
                  `${t('acc_type_savings')}`,
                ]}
                label={t('label_acc_type')}
                name="accountType"
                error={errors.accountType}
                register={register}
                placeholder={t('placeholder_text_acc_type')}
                isRequired
                mt="1.1rem"
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeInput
                label={t('label_acc_no')}
                name="accountNumber"
                error={errors.accountNumber}
                register={register}
                type="number"
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeInput
                label={t('label_acc_holder_name')}
                name="accountHolderName"
                error={errors.accountHolderName}
                register={register}
                type="text"
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2} mt={3}>
              <ThemeInput
                label={t('label_ifsc_code')}
                name="ifscCode"
                error={errors.ifscCode}
                register={register}
                type="text"
                mt={1}
                isRequired
              />
            </GridItem>

            <GridItem colSpan={2} mt={3}>
              <ThemeInput
                label={t('label_swift_code')}
                name="swiftCode"
                error={errors.swiftCode}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
          </Grid>
          <Flex justifyContent="space-between" alignItems="center" my={14}>
            {isEdit ? (
              <Flex flex={1} justifyContent={'flex-end'}>
                <Button align="flex-end" type="submit" px={10} isLoading={isLoading}>
                {t('button_text_save')}
                </Button>
              </Flex>
            ) : (
              <>
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="ghost"
                  onClick={prev}
                >
                  {t('button_text_back')}
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  rightIcon={<ForwardArrow />}
                >
                  {t('button_text_save_next')}
                </Button>
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  ));
};

export default BankInfo;
