const AlertOutlineSvg=(props)=>{
    return(
        <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M10 1.667a8.333 8.333 0 100 16.666 8.333 8.333 0 000-16.666zm0 15a6.666 6.666 0 110-13.333 6.666 6.666 0 010 13.333z"
          fill="#ACACAC"
        />
        <path
          d="M10 14.167a.833.833 0 100-1.667.833.833 0 000 1.667zM10 5.833a.833.833 0 00-.833.834v4.166a.833.833 0 001.666 0V6.667A.833.833 0 0010 5.833z"
          fill="#ACACAC"
        />
      </svg>
    )
    
}

export default AlertOutlineSvg;