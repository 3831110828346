import { useFormController, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import {
  Box,
  Button,
  Flex,
  Heading,
  Grid,
  GridItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Bro from 'brototype';
import useNotify from '../../utils/notifications';
import ThemeInput from '../../components/form-elements/theme-input';
import ThemeRadioGroup from '../../components/form-elements/radio-input';
import ThemeDateInput from '../../components/form-elements/date-picker/date-picker';
import ThemeTextAreaInput from '../../components/form-elements/theme-textarea';
import { AddAppointmentValidationSchema } from '../../utils/validations';
import { AppConstants } from '../../utils/constants';
import { getDateFormatter } from '../../utils/helpers';


const apiOptions = {
  url: '/announcement',
  method: 'post',
};

const AppointmentForm = ({ announcementObj, isEdit,onCloseBtnClick }) => {
  const [type, setType] = useState(null);
  const [typeOfAnnouncementOptions, setTypeOfAnnouncementOptions] = useState(
    null
  );
  const toast = useNotify();
  const { t } = useI18nContext();

  const transformRequest = values => {
    const data = { ...values };
    data.startTime = new Date(data.startDate).getTime();
    data.endTime = new Date(data.endDate).getTime();
    return data;
  };

  const onSuccess = resp => {
    toast({
      title: t('add_announcement_success_toast'),
      description: resp.message,
      status: 'success',
    });
    onCloseBtnClick();
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };


  const {
    isLoading,
    onSubmit,
    register,
    control,
    errors,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: AddAppointmentValidationSchema(t),
    transformRequest,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let typeOfAnnouncementOptions = storage.get(AppConstants.AUTH.CONFIG)
      .announcementTypes;
    setTypeOfAnnouncementOptions(typeOfAnnouncementOptions);
  }, []);

  return (
    <Box>
      <Heading as="h2" fontSize="20px" p={4} fontFamily={'Raleway'}>
        {isEdit ? t('announcement.heading') : t('announcement_add_heading')}
      </Heading>
      <Box
        as={'form'}
        onSubmit={onSubmit}
        noValidate="noValidate"
        bgColor="white"
        borderRadius="16px"
      >
        <Stack>
          <Grid templateColumns="repeat(6, 1fr)" p={5} gap={4}>
            <GridItem colSpan={6}>
              {isEdit ? (
                <Box
                  as={Flex}
                  flexDirection="column"
                  fontFamily="Open Sans"
                  fontWeight="small"
                  mb="1px"
                >
                  <Text color="#818081">{t('label_type_of_announcement')}</Text>
                  <Text>{announcementObj.type}</Text>
                </Box>
              ) : (
                <ThemeRadioGroup
                  name="type"
                  radioGroupValue={type}
                  radioGroupOnChange={e => setType(e)}
                  label={t('label_type_of_announcement')}
                  error={Bro(errors).iCanHaz('type')}
                  register={register}
                  mt={1}
                  isRequired
                  options={typeOfAnnouncementOptions}
                  direction="row"
                  spacing="10"
                />
              )}
            </GridItem>
            <GridItem colSpan={6}>
              {isEdit ? (
                <Box
                  as={Flex}
                  flexDirection="column"
                  fontFamily="Open Sans"
                  fontWeight="small"
                  mb="1px"
                >
                  <Text color="#818081">{t('label_title')}</Text>
                  <Text>{announcementObj.title}</Text>
                </Box>
              ) : (
                <ThemeInput
                  label={t('label_title')}
                  name="title"
                  type="text"
                  error={Bro(errors).iCanHaz('title')}
                  register={register}
                  mt={1}
                  isRequired
                />
              )}
            </GridItem>
            <GridItem colSpan={6}>
              {isEdit ? (
                <Box
                  as={Flex}
                  flexDirection="column"
                  fontFamily="Open Sans"
                  fontWeight="small"
                  mb="1px"
                >
                  <Text color="#818081">{t('label_start_date')}</Text>
                  <Text>
                    {getDateFormatter(announcementObj.startTime, 'DD/MM/YYYY')}
                  </Text>
                </Box>
              ) : (
                <ThemeDateInput
                  label={t('label_start_date')}
                  name="startDate"
                  error={errors.startDate}
                  showTimeSelect={false}
                  dateFormat="yyyy/MM/dd"
                  control={control}
                  mt={1}
                  isRequired
                />
              )}
            </GridItem>
            <GridItem colSpan={6}>
              {isEdit ? (
                <Box
                  as={Flex}
                  flexDirection="column"
                  fontFamily="Open Sans"
                  fontWeight="small"
                  mb="1px"
                >
                  <Text color="#818081">{t('label_end_date')}</Text>
                  <Text>
                    {getDateFormatter(announcementObj.endTime, 'DD/MM/YYYY')}
                  </Text>
                </Box>
              ) : (
                <ThemeDateInput
                  label={t('label_end_date')}
                  name="endDate"
                  error={errors.endDate}
                  showTimeSelect={false}
                  dateFormat="yyyy/MM/dd"
                  control={control}
                  mt={1}
                  isRequired
                />
              )}
            </GridItem>

            <GridItem colSpan={6}>
              {isEdit ? (
                <Box
                  as={Flex}
                  flexDirection="column"
                  fontFamily="Open Sans"
                  fontWeight="small"
                  mb="1px"
                >
                  <Text color="#818081">{t('label_location')}</Text>
                  <Text>{announcementObj.location}</Text>
                </Box>
              ) : (
                <ThemeInput
                  type="text"
                  label={t('label_location')}
                  name="location"
                  error={Bro(errors).iCanHaz('location')}
                  register={register}
                  mt={1}
                />
              )}
            </GridItem>
            <GridItem colSpan={6}>
              {isEdit ? (
                <Box
                  as={Flex}
                  flexDirection="column"
                  fontFamily="Open Sans"
                  fontWeight="small"
                  mb="1px"
                >
                  <Text color="#818081">{t('label_description')}</Text>
                  <Text>{announcementObj.description}</Text>
                </Box>
              ) : (
                <ThemeTextAreaInput
                  type="text"
                  label={t('label_description')}
                  name="description"
                  error={Bro(errors).iCanHaz('description')}
                  register={register}
                  mt={1}
                  isRequired
                />
              )}
            </GridItem>
          </Grid>
        </Stack>
        <Flex px={4} pb={10}>
          {isEdit ? (
            ''
          ) : (
            <Button
              type="submit"
              isLoading={isLoading}
              isFullWidth={true}
              size="lg"
            >
              {t('button_text_save')}
            </Button>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default AppointmentForm;
