import { FormLabel, FormControl, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import React from 'react';
import Select from 'react-select';

const MultiSelect = ({
  name,
  control,
  defaultValue,
  label,
  error,
  isRequired,
  valueKey,
  labelKey,
  options,
  url,
  onChange: onSelectChange,
  placeholder,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange }) => {
        return (
          <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
            <FormLabel mb={4}>{label}</FormLabel>
            <Select
              fontSize="12px"
              placeholder={placeholder}
              onChange={value => {
                onChange(value);
                onSelectChange && onSelectChange(value);
              }}
              options={options}
              labelKey={labelKey}
              valueKey={valueKey}
              getOptionLabel={option => option[labelKey]}
              getOptionValue={option => option[valueKey]}
              defaultValue={defaultValue}
              isMulti
              {...rest}
            />
            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
          </FormControl>
        );
      }}
    />
  );
};

export default MultiSelect;
