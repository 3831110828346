import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Grid, GridItem, Stack } from '@chakra-ui/layout';
import { navigate, useLocation, useParams } from '@reach/router';
import ThemeInput from '../../../components/form-elements/theme-input';
import ThemeTextAreaInput from '../../../components/form-elements/theme-textarea';
import PageTitle from '../../../components/misc/page-title';
import ThemeAsyncSelectInput from '../../../components/form-elements/theme-async-select-input';
import { SearchIcon } from '@chakra-ui/icons';
import DataService from '../../../services/data-service';
import { useEffect, useState } from 'react';
import { useFormController } from '@bit/sixsprints.core.beans-web';
import { toast } from '@chakra-ui/react';
import ROUTES from '../../routes';
import useNotify from '../../../utils/notifications';
import { LocationSchema } from '../../../utils/validations';
import Bro from 'brototype';

const apiOptions = {
  url: '/location',
  method: 'post',
};

const apiOptionsPut = {
  url: '/location/update',
  method: 'put',
};

const AddLocation = () => {
  const toast = useNotify();
  const { t, languages, langCode } = useI18nContext();
  const langList = Object.keys(languages);

  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
  const locationData = useLocation().state;
  const { slug } = useParams();
  const isEdit = slug ? true : false;

  const navigateToPrevScreen = () => {
    navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_LOCATION);
  };

  const transformCountryList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.country[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptions = async () => {
    let data = await DataService.post('/country/search', {
      page: 0,
      size: 10,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformCountryList(data);
    return data;
  };

  const transformStateList = data => {
    let transformedStateList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.state[langCode];
      return obj;
    });
    return transformedStateList;
  };

  const promiseOptionsForState = async () => {
    let data = await DataService.post('/state/search', {
      page: 0,
      size: 10,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformStateList(data);
    return data;
  };

  const transformRequest = values => {
    let data = { ...values };
    data.countrySlug = data.countrySlug.slug;
    data.country = data.countrySlug.name;
    data.stateSlug = data.stateSlug.slug;
    data.state = data.stateSlug.name;
    let obj = isEdit ? { ...locationData, ...data } : data;
    return obj;
  };

  const onSuccess = resp => {
    toast({
      title: isEdit
        ? t('location_details_updated')
        : t('location_details_created'),
      description: resp.message,
      status: 'success',
    });
    navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_LOCATION);
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    errors,
    reset: resetForm,
    control,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions: isEdit ? apiOptionsPut : apiOptions,
    yupSchema: LocationSchema(t),
    transformRequest,
  });

  useEffect(() => {
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode]);



  return (
    <Flex flex={1} direction="column" mb={10}>
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle
          title={isEdit ? t('edit_location_label') : t('add_location_label')}
        />
      </Flex>
      <Box
        as={'form'}
        bgColor="white"
        onSubmit={onSubmit}
        noValidate="noValidate"
        borderRadius="16px"
      >
        <Stack>
          <Grid templateColumns="repeat(10, 1fr)" p={12} gap={8}>
            {langList.map(item => (
              <GridItem key={item} colSpan={4}>
                <ThemeInput
                  defaultValue={
                    isEdit ? locationData.locationName[`${item}`] : null
                  }
                  label={`${t('location_name_label')}- ${item.toUpperCase()}`}
                  name={`locationName.${item}`}
                  type="text"
                  register={register}
                  error={Bro(errors).iCanHaz(`locationName.${item}`)}
                  mt={1}
                  isRequired
                />
              </GridItem>
            ))}
            <GridItem colSpan={4}>
              <ThemeInput
                defaultValue={isEdit ? locationData.mail : null}
                label={t('mail_alias_label')}
                name="mail"
                type="text"
                register={register}
                error={Bro(errors).iCanHaz(`mail`)}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue={ {name: locationData && locationData.country ||'', slug: locationData && locationData.countrySlug ||''}}
                promiseOptions={promiseOptions}
                labelKey="name"
                valueKey="slug"
                name="countrySlug"
                control={control}
                // error={errors.countrySlug}
                label={t('field_select_country')}
                placeholder={<SearchIcon />}
              />
            </GridItem>
            <GridItem colSpan={4}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue={ {name: locationData && locationData.state ||'', slug: locationData && locationData.stateSlug ||''}}
                promiseOptions={promiseOptionsForState}
                labelKey="name"
                valueKey="slug"
                name="stateSlug"
                control={control}
                // error={errors.stateSlug}
                label={t('state_province_label')}
                placeholder={<SearchIcon />}
              />
            </GridItem>
            <GridItem colSpan={8}>
              <ThemeTextAreaInput
                defaultValue={isEdit ? locationData.description : null}
                name="description"
                type="text"
                label={t('description')}
                register={register}
              />
            </GridItem>
          </Grid>
        </Stack>
        <Flex px={10} pb={10} ml={2}>
          <Button px="2rem" type="submit" isLoading={isLoading} mr={5}>
            {t('button_text_save')}
          </Button>
          <Button onClick={navigateToPrevScreen} variant="ghost">
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};
export default AddLocation;
