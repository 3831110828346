import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

const Payroll = props => {
  return (
    <Flex h="100vh" justify="center" alignItems="center">
      <Box>Payroll</Box>
    </Flex>
  );
};

export default Payroll;
