import { useToast } from '@chakra-ui/react';

const useNotify = () => {
  const toast = useToast();
  const notify = ({ title, description, status }) => {
    toast({
      title,
      description,
      status: status,
      duration: 9000,
      isClosable: true,
      position: 'top-left',
    });
  };
  return notify;
};

export default useNotify;
