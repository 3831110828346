import { Box,Text} from '@chakra-ui/react';
import ApprovedIcon from '../../icons/check-circle';
import CancelledIcon from '../../icons/banned-circle';
import RejectIcon from '../../icons/cross-circle';
import PendingIcon from '../../icons/pending-circle';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';


const Legends = () => {
  const { t } = useI18nContext();

    return (
        <Box d="flex" >
            <Box d="flex" alignItems="center" mt={2} mr={10}>
                <ApprovedIcon />
                <Text fontSize="sm">{t('approve')}</Text>
            </Box>
            <Box d="flex" alignItems="center" mt={2} mr={10}>
                <RejectIcon />
                <Text fontSize="sm">{t('rejected')}</Text>
            </Box>
            <Box d="flex" alignItems="center" mt={2} mr={10}>
                <PendingIcon />
                <Text fontSize="sm">{t('pending')}</Text>
            </Box>
            <Box d="flex" alignItems="center" mt={2} mr={10}>
                <CancelledIcon />
                <Text fontSize="sm">{t('cancelled')}</Text>
            </Box>
        </Box>
    )
}
export default Legends;