import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Box, ListItem, Text, Stack, Collapse, List, Spacer } from '@chakra-ui/react';
import { useLocation } from '@reach/router';
import React, { useEffect, useState } from 'react';
import colors from '../../theme/foundations/colors';
import { hasChildren } from '../../utils/helpers';
import NavLink from './nav-link';
import ChevUpIcon from "../../icons/chevron-up";
import ChevDownIcon from "../../icons/chevron-down";




const SingleNav = ({ item }) => {
  const { t } = useI18nContext();
  const location = useLocation();

  const isLinkActive = item.exact
    ? location.pathname === item.path
    : location.pathname === item.path ||
    location.pathname.indexOf(item.path) !== -1;

  return (
    <NavLink to={item.path}>
      <ListItem height="35px" d="flex" justifyContent="space-between">
        <Box px={3} d="flex" alignItems="center">
          {item.icon && <item.icon
            boxSize={6}
            color={isLinkActive ? "brand.500" : colors.gray[200]}
            mr="1em"
          />}
          <Text
            fontFamily="Open Sans"
            fontSize="sm"
            fontStyle="normal"
            fontWeight="600"
          >
            {t(item.key)}
          </Text>
        </Box>
        <Box
          w={'5px'}
          background={isLinkActive ? "brand.500" : colors.white}
        ></Box>
      </ListItem>
    </NavLink>

  )
}

const MultiNav = ({ item }) => {
  const { t } = useI18nContext();
  const { items: children } = item;
  const location = useLocation();
  const isLinkActive = item.exact
    ? location.pathname === item.path
    : location.pathname === item.path ||
    location.pathname.indexOf(item.path) !== -1;
  const [open, setOpen] = useState(isLinkActive);


  useEffect(() => {
    setOpen(isLinkActive)
  }, [isLinkActive]);

  const handleClick = (e) => {
    e.preventDefault();
    setOpen((open) => !open);
  };

  return (
    <List>
      <NavLink to={item.path} onClick={handleClick}>
        <ListItem height="35px" d="flex" alignItems="center" justifyContent="space-between">
          <Box px={3} d="flex" alignItems="center">
            {item.icon && <item.icon
              boxSize={6}
              color={isLinkActive ? "brand.500" : colors.gray[200]}
              mr="1em"
            />}
            <Text
              fontFamily="Open Sans"
              color={isLinkActive ? "brand.500" : colors.gray[200]}
              fontSize="sm"
              fontStyle="normal"
              fontWeight="600"
            >
              {t(item.key)}
            </Text>
          </Box>
          <Spacer/>
          {open ?
            <ChevUpIcon
              mt={6}
              boxSize={8}
              onClick={handleClick}
              color={isLinkActive ? "brand.500" : colors.gray[200]}
            /> :
            <ChevDownIcon
              mt={6}
              boxSize={8}
              onClick={handleClick}
              color={isLinkActive ? "brand.500" : colors.gray[200]}
            />
          }
        </ListItem>
      </NavLink>
      <Box ml="2rem">
        <Collapse in={open}>
          <Stack spacing={2}>
            {children.map((child, index) => {
              return (
                <NavItem key={index} item={child} />
              );
            })}
          </Stack>
        </Collapse>
      </Box>
    </List>
  )

}

const NavItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiNav : SingleNav;
  return <Component item={item} />;
};

export default NavItem;