import { useFormController } from '@bit/sixsprints.core.beans-web';
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Stack,
} from '@chakra-ui/react';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import ThemeInput from '../../../../components/form-elements/theme-input';
import ThemeAsyncSelectInput from '../../../../components/form-elements/theme-async-select-input';
import ThemeDateInput from '../../../../components/form-elements/date-picker/date-picker';
import ThemeHeading from '../../../../components/misc/heading';
import ListHeader from '../../../../components/misc/list-header';
import ForwardArrow from '../../../../icons/forward-arrow';
import useNotify from '../../../../utils/notifications';
import { DependentValidationSchema } from '../../../../utils/validations';
import DataService from '../../../../services/data-service';
import SelectThemeInput from '../../../../components/form-elements/select-input';
import { navigate, useLocation } from '@reach/router';
import { storage } from '@bit/sixsprints.core.beans-web';
import { AppConstants } from '../../../../utils/constants';
import _ from 'lodash';
import moment from 'moment';
import Loader from '../../../../components/misc/loader';

const DependentInfo = ({
  t,
  next,
  prev,
  userSlug,
  userDataInfo,
  isEdit,
  showSkipButton = false,
}) => {
  const toast = useNotify();
  const [indexes, setIndexes] = useState([1]);
  const [counter, setCounter] = useState(0);

  const [isDocLoading, setIsDocLoading] = useState(false);
  const { langCode } = useI18nContext();
  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
  const [showSkipBtn, setShowSkipBtn] = useState(showSkipButton);
  const [relationships, setRelationships] = useState([]);

  const [gender, setGender] = useState(null);

  const apiOptions = {
    url: `/user/dependent-details?userSlug=${userSlug}`,
    method: 'put',
  };

  const transformRequest = values => {
    const data = { ...values };
    if (!_.isEmpty(data)) {
      _.forEach(data.dependent, function (obj,index) {
        _.set(
          obj,
          'dateOfBirth',
          moment(obj.dateOfBirth).valueOf(),
          _.set(obj, 'countrySlug', obj.country?.slug||userData[index]?.countrySlug),
          _.set(obj, 'stateSlug', obj.state?.slug||userData[index]?.stateSlug)
        );
        delete(obj.country)
        delete(obj.state);
      });
      return data.dependent;
    }
    return [null];
  };

  const onSuccess = resp => {
    if (!isEdit) {
      toast({
        title: t('dependent_info_success_toast'),
        description: resp.message,
        status: 'success',
      });

      next();
    } else {
      toast({
        title: t('dependent_info_updated'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    errors,
    getValues,
    reset: resetForm,
    control,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: DependentValidationSchema(t),
    transformRequest,
  });

  const addDependentDetail = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeDependentDetail = index => () => {
    setIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    setCounter(prevCounter => prevCounter - 1);
    const currFormValues = getValues();
    resetForm({
      dependent: currFormValues.dependent.filter(
        (item, _index) => _index !== index
      ),
    });
  };
  const [userData, setUserData] = useState(null);
  const getUserInfo = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.dependentDetails');
        showSkipButton && setShowSkipBtn(true);
        setUserData(data);
        let newData =
          data &&
          data.map(item => {
            item.dateOfBirth=moment(item.dateOfBirth).isValid() ? moment(item?.dateOfBirth).toDate():null;
            return item;
          });
        showSkipButton && setShowSkipBtn(true);
        setIndexes(data ? data : [0]);
        resetForm({
          dependent: newData,
        });
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  const transformCountryList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.country[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptionsForCountry = async () => {
    let data = await DataService.post('/country/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformCountryList(data);
    return data;
  };

  const transformStateList = (data = []) => {
    let transformedStateList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.state[langCode];
      return obj;
    });
    return transformedStateList || [];
  };

  const promiseOptionsForState = async () => {
    let data = await DataService.post('/state/search', {
      page: 0,
      size: 50,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformStateList(data);
    return data;
  };

  const skipDependent = () => {
    setIndexes([]);
    next();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode]);

  useEffect(getUserInfo, []);

  useEffect(() => {
    const masterdata = storage.get(AppConstants.AUTH.CONFIG);
    masterdata.relationShip && setRelationships(masterdata.relationShip);
    let genderArr = storage.get(AppConstants.AUTH.CONFIG).genders;
    setGender(genderArr);
  }, []);

  return (
    (isDocLoading && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex>
      <Box as={Flex} direction="column" mb={5}>
      {!isEdit ? (<ThemeHeading heading={t('dependent_details_heading')} subHeading={t('details_subheading')}/>):(
        <ThemeHeading heading={`${Bro(userDataInfo).iCanHaz('firstName')} ${t(
          'edit_profile_header_text'
        )}`} subHeading={t('details_subheading')}/>
      )}
      </Box>
    </Flex> 
        <Center>
          <Loader />
        </Center>
      </Flex>
    )) || (
      <Flex flexGrow={1}>
        <Flex flex={1} direction="column" mr={'10em'}>
          <Flex justifyContent="space-between" alignItems="center" mb={10}>
            {!isEdit ? (
              <ThemeHeading
                heading={t('dependent_details_heading')}
                subHeading={t('details_subheading')}
              />
            ) : (
              <ThemeHeading heading={`${Bro(userDataInfo).iCanHaz('firstName')} ${t(
                'edit_profile_header_text'
              )}`} subHeading={t('details_subheading')}/>
            )}
            <Center>
              <Button
                leftIcon={<AddIcon />}
                onClick={addDependentDetail}
                alignSelf="flex-end"
              >
                {t('button_add')}
              </Button>
              {showSkipBtn && (
                <Button
                  ml={5}
                  onClick={skipDependent}
                  disabled={isEdit}
                  alignSelf="flex-end"
                >
                  Skip
                </Button>
              )}
            </Center>
          </Flex>
          <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate">
            {indexes.map((item, index) => {
              const fieldName = `dependent[${index}]`;
              return (
                <Stack key={index}>
                  <ListHeader
                    msg={` ${t('label_dependent_details_msg')} ${index + 1} ${t(
                      'label_emergency_contact_msg2'
                    )}`}
                    index={indexes.length}
                    onBtnClick={removeDependentDetail(index)}
                  />
                  <Grid templateColumns="repeat(6, 1fr)" gap={6}>
                    <GridItem colSpan={2}>
                      <ThemeInput
                        label={t('label_last_name')}
                        name={`${fieldName}.lastName`}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.lastName`
                        )}
                        register={register}
                        type="text"
                        mt={1}
                        isRequired
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <ThemeInput
                        label={t('label_middle_name')}
                        name={`${fieldName}.middleName`}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.middleName`
                        )}
                        register={register}
                        type="text"
                        mt={1}
                      />
                    </GridItem>
                    <GridItem colSpan={2}>
                      <ThemeInput
                        label={t('label_first_name')}
                        name={`${fieldName}.firstName`}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.firstName`
                        )}
                        register={register}
                        type="text"
                        mt={1}
                        isRequired
                      />
                    </GridItem>
                    <GridItem colSpan={2} mt={3}>
                      <SelectThemeInput
                        options={gender}
                        label={t('label_gender')}
                        name={`${fieldName}.gender`}
                        valueKey="key"
                        labelKey="label"
                        placeholder={t('placeholder_text_gender')}
                        error={Bro(errors).iCanHaz(`dependent.${index}.gender`)}
                        register={register}
                        type="text"
                        mt="1.1rem"
                        isRequired
                      />
                    </GridItem>
                    <GridItem colSpan={2} mt={3}>
                      <ThemeDateInput
                        label={t('label_birthday')}
                        name={`${fieldName}.dateOfBirth`}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.dateOfBirth`
                        )}
                        showTimeSelect={false}
                        defaultValue={''}
                        dateFormat="yyyy/MM/dd"
                        control={control}
                        mt={1}
                        isRequired
                      />
                    </GridItem>
                    <GridItem colSpan={2} mt={3}>
                      <ThemeInput
                        label={t('label_occupation')}
                        name={`${fieldName}.occupation`}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.occupation`
                        )}
                        register={register}
                        type="text"
                        mt={1}
                        isRequired
                      />
                    </GridItem>

                    <GridItem colSpan={2} mt={3}>
                      <SelectThemeInput
                        options={relationships}
                        labelKey={'label'}
                        valueKey={'key'}
                        label={t('label_relationship')}
                        name={`${fieldName}.relationship`}
                        placeholder={t('placeholder_text_select')}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.relationship`
                        )}
                        register={register}
                        placeholder={t('select_an_option')}
                        defaultValue={''}
                        type="text"
                        mt="1.1rem"
                        isRequired
                      />
                    </GridItem>
                    <GridItem colSpan={2} mt={3}>
                      <ThemeInput
                        label={t('label_phone_number')}
                        name={`${fieldName}.phoneNumber`}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.phoneNumber`
                        )}
                        register={register}
                        type="text"
                        mt={1}
                        isRequired
                      />
                    </GridItem>
                    <GridItem colSpan={2} mt={3}>
                      <ThemeInput
                        label={t('label_email')}
                        name={`${fieldName}.emailId`}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.emailId`
                        )}
                        register={register}
                        type="text"
                        mt={1}
                      />
                    </GridItem>

                    <GridItem colSpan={4} mt={3}>
                      <ThemeInput
                        label={t('label_address')}
                        name={`${fieldName}.address`}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.address`
                        )}
                        register={register}
                        type="text"
                        mt={1}
                        bgColor="white"
                        isRequired
                      />
                    </GridItem>

                    <GridItem colSpan={2} mt={3}>
                      <ThemeInput
                        label={t('city_label')}
                        name={`${fieldName}.city`}
                        error={Bro(errors).iCanHaz(`dependent.${index}.city`)}
                        register={register}
                        type="text"
                        mt={1}
                        isRequired
                      />
                    </GridItem>

                    <GridItem colSpan={2} mt={3}>
                      <ThemeAsyncSelectInput
                        key={shouldLoadOptions}
                        promiseOptions={promiseOptionsForState}
                        labelKey="name"
                        valueKey="slug"
                        name={`${fieldName}.state`}
                        control={control}
                        error={Bro(errors).iCanHaz(`dependent.${index}.state`)}
                        label={t('state_province_label')}
                        defaultValue={[
                          {
                            name:
                              userData &&
                              Bro(userData[index]).iCanHaz('stateName'),
                            slug:
                              userData &&
                              Bro(userData[index]).iCanHaz(`stateSlug`),
                          },
                        ]}
                        placeholder={t('select_an_option')}
                      />
                    </GridItem>

                    <GridItem colSpan={2} mt={3}>
                      <ThemeInput
                        label={t('label_postal_code')}
                        name={`${fieldName}.postalCode`}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.postalCode`
                        )}
                        register={register}
                        type="text"
                        mt={1}
                        isRequired
                      />
                    </GridItem>

                    <GridItem colSpan={2} mt={3}>
                      <ThemeAsyncSelectInput
                        key={shouldLoadOptions}
                        promiseOptions={promiseOptionsForCountry}
                        labelKey="name"
                        valueKey="slug"
                        name={`${fieldName}.country`}
                        control={control}
                        error={Bro(errors).iCanHaz(
                          `dependent.${index}.country`
                        )}
                        label={t('field_select_country')}
                        defaultValue={[
                          {
                            name:
                              userData &&
                              Bro(userData[index]).iCanHaz('countryName'),
                            slug:
                              userData &&
                              Bro(userData[index]).iCanHaz(`countrySlug`),
                          },
                        ]}
                        placeholder={t('select_an_option')}
                      />
                    </GridItem>
                  </Grid>
                </Stack>
              );
            })}
            <Flex justifyContent="space-between" alignItems="center" my={14}>
              {isEdit ? (
                <Flex flex={1} justifyContent={'flex-end'}>
                  <Button align="flex-end" type="submit" px={10} isLoading={isLoading}>
                  {t('button_text_save')}
                  </Button>
                </Flex>
              ) : (
                <>
                  <Button
                    leftIcon={<ArrowBackIcon />}
                    variant="ghost"
                    onClick={prev}
                  >
                    {t('button_text_back')}
                  </Button>
                  <Button
                    type="submit"
                    isLoading={isLoading}
                    rightIcon={<ForwardArrow />}
                  >
                    {t('button_text_save_next')}
                  </Button>
                </>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
    )
  );
};

export default DependentInfo;
