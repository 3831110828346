import { useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Grid, GridItem, Stack } from '@chakra-ui/layout';
import { useLocation, useNavigate, useParams } from '@reach/router';
import Bro from 'brototype';
import React from 'react'
import ThemeInput from '../../../../components/form-elements/theme-input';
import PageTitle from '../../../../components/misc/page-title';
import useNotify from '../../../../utils/notifications';
import { MedicalRecordSchema } from '../../../../utils/validations';
import ROUTES from '../../../routes';

const post = {
  url: '/medicalRecord',
  method: 'post',
};

const put = {
  url: '/medicalRecord/update',
  method: 'put',
};

const AddMedicalRecord=()=>{
    const navigate = useNavigate();
    const toast = useNotify();
    const { t,languages } = useI18nContext();
    const langList = Object.keys(languages);

    const { slug } = useParams();
    const isEdit = slug ? true : false;
    const medicalRecordData = useLocation().state;


    const navigateToPrevScreen=()=>{
        navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_MEDICAL_RECORDS)
    }

    const transformRequest = values => {
      const data = Object.assign(medicalRecordData, values);
      return data;
    };

    const onSuccess = resp => {
      toast({
        title: !isEdit ? t('add_success') : t('update_success'),
        description: resp.message,
        status: 'success',
      });
      navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_MEDICAL_RECORDS);
    };
  
    const onError = resp => {
      toast({
        title: t('Error'),
        description: resp.message,
        status: 'error',
      });
    };
  
    const { isLoading, onSubmit, register, errors } = useFormController({
      onSuccess,
      onError,
      apiOptions: isEdit ? put : post,
      yupSchema: MedicalRecordSchema(t),
      transformRequest,
    });
  

    return(
        <Flex flex={1} direction="column">
        <Flex justifyContent="space-between" alignItems="center" mb={10}>
          <PageTitle title={isEdit ? t('update_medical_records'):t('add_medical_records')} />
        </Flex>
        <Box
          as={'form'}
          bgColor="white"
          borderRadius="16px"
          onSubmit={onSubmit}
          noValidate="noValidate"
        >
          <Stack>
            <Grid templateColumns="repeat(10, 1fr)" p={12} gap={8}>
            {langList.map(item => (
              <GridItem key={item} colSpan={4}>
                <ThemeInput
                 defaultValue={isEdit ? medicalRecordData.medicalRecordName[`${item}`] : null}
                  label={`${t('medicalRecordName')}- ${item.toUpperCase()}`}
                  name={`medicalRecordName.${item}`}
                  type="text"
                  register={register}
                  error={Bro(errors).iCanHaz(`medicalRecordName.${item}`)}
                  mt={1}
                  isRequired
                />
              </GridItem>
            ))}
            </Grid>
          </Stack>
          <Flex px={10} pb={10} ml={2}>
            <Button px="2rem" type="submit" isLoading={isLoading} mr={5}>
              {t('button_text_save')}
            </Button>
            <Button variant="ghost" onClick={navigateToPrevScreen}>
              {t('btn_cancel')}
            </Button>
          </Flex>
        </Box>
      </Flex>
      
    )
}
export default AddMedicalRecord;