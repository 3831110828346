import React from 'react';
import {
  Box,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Stack,
  Heading,
  Center
} from '@chakra-ui/react';

// Icons
import TrashIcon from '../../../icons/trash-icon';

import colors from '../../../theme/foundations/colors';
import SelectThemeInput from '../select-input';
import DocumentUploadCertificate from '../document-uploader';
import DateRenderer from './components/date';

const DocUploadTable = ({
  t,
  columns,
  register,
  formSetValue,
  formWatcher,
  documentList,
  control,
  indexes,
  addDocuments,
  removeDocuments,
  errors,
  Bro,
}) => {


  const setFileUrlIntoField = (fieldName, url) => {
    formSetValue(`${fieldName}.date`,new Date().getTime())
    formSetValue(`[${fieldName}].url`, url)
  };


  return (
    <Box borderRadius="16px" overflow="hidden" bg="white">
      <Table fontSize="14px" variant="striped" colorScheme="strip" size="sm">
        <Thead background={colors.brand['100']}>
          <Tr>
            {columns.map((col, i) => (
              <Th key={i} p={5}>
                <Text
                  fontSize="14px"
                  {...(i === columns.length - 1 && { align: 'center' })}
                >
                  {col}
                </Text>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {indexes.map((item, index) => {
            const fieldName = `documents[${index}]`;
            return (
              <Tr key={index}>
                <Td px={5}>
                  <Box w="25em">
                    <Flex flexDirection="row" alignItems="center">
                      <SelectThemeInput
                        options={documentList}
                        valueKey="slug"
                        labelKey="name"
                        name={`${fieldName}.documentSlug`}
                        error={Bro(errors).iCanHaz(`documents.${index}.documentSlug`)}
                        placeholder={t('select_an_option')}
                        register={register}
                        type="text"
                      />
                    </Flex>
                  </Box>
                </Td>
                <Td px={5}>
                  <DateRenderer
                    name={`${fieldName}.date`}
                    register={register}
                    value={formWatcher(`${fieldName}.date`) || item.date}
                  />
                </Td>
                <Td px={5}>
                  <Flex justifyContent="space-around">
                    <Center>
                    <Box px={2}>
                    <DocumentUploadCertificate
                      register={register}
                      control={control}
                      hideFileName
                      defaultValue=""
                      name={`[${fieldName}].url`}
                      url={'file/upload'}
                      index={index}
                      uploadValue={url =>setFileUrlIntoField(fieldName, url)
                      }
                    />
                    </Box>
                    <Box px={2}>
                    <IconButton
                      bg="none"
                      size="sm"
                      style={{ cursor: 'pointer' }}
                      boxShadow="none"
                      variant="ghost"
                      _focus="none"
                      icon={<TrashIcon boxSize="34px" />}
                      onClick={removeDocuments(index)}
                      mb={2}
                    />
                    </Box>
                    </Center>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
          <Stack display="inline" ml={4}>
            <Heading
              as="h5"
              size="sm"
              display="inline"
              onClick={addDocuments}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {t('add_more_document_label')}
            </Heading>
          </Stack>
        </Tbody>
      </Table>
    </Box>
  );
};

export default DocUploadTable;
