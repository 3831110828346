export const Country = {
    list:[
        {
          "country": "Afghanistan",
          "callingCode": "+993"
        },
        {
          "country": "Åland Islands",
          "callingCode": "+358"
        },
        {
          "country": "Albania",
          "callingCode": "+355"
        },
        {
          "country": "Algeria",
          "callingCode": "+213"
        },
        {
          "country": "American Samoa",
          "callingCode": "+1"
        },
        {
          "country": "Andorra",
          "callingCode": "+376"
        },
        {
          "country": "Angola",
          "callingCode": "+244"
        },
        {
          "country": "Anguilla",
          "callingCode": "+1"
        },
        {
          "country": "Antigua and Barbuda",
          "callingCode": "+1"
        },
        {
          "country": "Argentina",
          "callingCode": "+54"
        },
        {
          "country": "Armenia",
          "callingCode": "+374"
        },
        {
          "country": "Aruba",
          "callingCode": "+297"
        },
        {
          "country": "Ascension Island",
          "callingCode": "+247"
        },
        {
          "country": "Australia",
          "callingCode": "+61"
        },
        {
          "country": "Australian Antarctic Territory",
          "callingCode": "+672"
        },
        {
          "country": "Austria",
          "callingCode": "+43"
        },
        {
          "country": "Azerbaijan",
          "callingCode": "+994"
        },
        {
          "country": "Bahamas",
          "callingCode": "+1"
        },
        {
          "country": "Bahrain",
          "callingCode": "+973"
        },
        {
          "country": "Bangladesh",
          "callingCode": "+880"
        },
        {
          "country": "Barbados",
          "callingCode": "+1"
        },
        {
          "country": "Belarus",
          "callingCode": "+375"
        },
        {
          "country": "Belgium",
          "callingCode": "+32"
        },
        {
          "country": "Belize",
          "callingCode": "+501"
        },
        {
          "country": "Benin",
          "callingCode": "+229"
        },
        {
          "country": "Bermuda",
          "callingCode": "+1"
        },
        {
          "country": "Bhutan",
          "callingCode": "+975"
        },
        {
          "country": "Bolivia",
          "callingCode": "+591"
        },
        {
          "country": "Bonaire",
          "callingCode": "+599"
        },
        {
          "country": "Bosnia and Herzegovina (Bosnia-Herzegovina)",
          "callingCode": "+387"
        },
        {
          "country": "Botswana",
          "callingCode": "+267"
        },
        {
          "country": "Brazil",
          "callingCode": "+55"
        },
        {
          "country": "British Indian Ocean Territory",
          "callingCode": "+246"
        },
        {
          "country": "British Virgin Islands",
          "callingCode": "+1"
        },
        {
          "country": "Brunei",
          "callingCode": "+673"
        },
        {
          "country": "Bulgaria",
          "callingCode": "+359"
        },
        {
          "country": "Burkina Faso",
          "callingCode": "+226"
        },
        {
          "country": "Burma",
          "callingCode": "+95"
        },
        {
          "country": "Burundi",
          "callingCode": "+257"
        },
        {
          "country": "Cambodia",
          "callingCode": "+855"
        },
        {
          "country": "Cameroon",
          "callingCode": "+237"
        },
        {
          "country": "Canada",
          "callingCode": "+1"
        },
        {
          "country": "Canary Islands",
          "callingCode": "+34"
        },
        {
          "country": "Cape Verde",
          "callingCode": "+238"
        },
        {
          "country": "Cayman Islands",
          "callingCode": "+1"
        },
        {
          "country": "Central African Republic",
          "callingCode": "+236"
        },
        {
          "country": "Ceuta",
          "callingCode": "+34"
        },
        {
          "country": "Chad",
          "callingCode": "+235"
        },
        {
          "country": "Chagos Islands",
          "callingCode": "+246"
        },
        {
          "country": "Chile",
          "callingCode": "+56"
        },
        {
          "country": "People's Republic of China",
          "callingCode": "+86"
        },
        {
          "country": "Christmas Island",
          "callingCode": "+61"
        },
        {
          "country": "Cocos (Keeling) Islands",
          "callingCode": "+61"
        },
        {
          "country": "Colombia",
          "callingCode": "+57"
        },
        {
          "country": "Comoros",
          "callingCode": "+269"
        },
        {
          "country": "Republic of Congo",
          "callingCode": "+242"
        },
        {
          "country": "Democratic Republic of Congo",
          "callingCode": "+243"
        },
        {
          "country": "Cook Islands",
          "callingCode": "+682"
        },
        {
          "country": "Costa Rica",
          "callingCode": "+506"
        },
        {
          "country": "Croatia",
          "callingCode": "+385"
        },
        {
          "country": "Cuba",
          "callingCode": "+53"
        },
        {
          "country": "Curaçao",
          "callingCode": "+599"
        },
        {
          "country": "Curaçao and the Caribbean Netherlands",
          "callingCode": "+599"
        },
        {
          "country": "Cyprus",
          "callingCode": "+357"
        },
        {
          "country": "Czech Republic",
          "callingCode": "+420"
        },
        {
          "country": "Denmark",
          "callingCode": "+45"
        },
        {
          "country": "Diego Garcia",
          "callingCode": "+246"
        },
        {
          "country": "Djibouti",
          "callingCode": "+253"
        },
        {
          "country": "Dominica",
          "callingCode": "+1"
        },
        {
          "country": "Dominican Republic",
          "callingCode": "+1"
        },
        {
          "country": "East Timor",
          "callingCode": "+670"
        },
        {
          "country": "Easter Island",
          "callingCode": "+56"
        },
        {
          "country": "Ecuador",
          "callingCode": "+593"
        },
        {
          "country": "Egypt",
          "callingCode": "+20"
        },
        {
          "country": "El Salvador",
          "callingCode": "+503"
        },
        {
          "country": "England",
          "callingCode": "+44"
        },
        {
          "country": "Equatorial Guinea",
          "callingCode": "+240"
        },
        {
          "country": "Eritrea",
          "callingCode": "+291"
        },
        {
          "country": "Estonia",
          "callingCode": "+372"
        },
        {
          "country": "Ethiopia",
          "callingCode": "+251"
        },
        {
          "country": "Eswatini",
          "callingCode": "+268"
        },
        {
          "country": "Falkland Islands",
          "callingCode": "+500"
        },
        {
          "country": "Faroe Islands",
          "callingCode": "+298"
        },
        {
          "country": "Federated States of Micronesia",
          "callingCode": "+691"
        },
        {
          "country": "Fiji",
          "callingCode": "+679"
        },
        {
          "country": "Finland",
          "callingCode": "+358"
        },
        {
          "country": "France",
          "callingCode": "+33"
        },
        {
          "country": "French Overseas Departments and Territories",
          "callingCode": "+262"
        },
        {
          "country": "French Guiana",
          "callingCode": "+594"
        },
        {
          "country": "French Polynesia",
          "callingCode": "+689"
        },
        {
          "country": "Gabon",
          "callingCode": "+241"
        },
        {
          "country": "Gambia",
          "callingCode": "+220"
        },
        {
          "country": "Georgia",
          "callingCode": "+995"
        },
        {
          "country": "Germany",
          "callingCode": "+49"
        },
        {
          "country": "Ghana",
          "callingCode": "+233"
        },
        {
          "country": "Gibraltar",
          "callingCode": "+350"
        },
        {
          "country": "Greece",
          "callingCode": "+30"
        },
        {
          "country": "Greenland",
          "callingCode": "+299"
        },
        {
          "country": "Grenada",
          "callingCode": "+1"
        },
        {
          "country": "Guadeloupe",
          "callingCode": "+590"
        },
        {
          "country": "Guam",
          "callingCode": "+1"
        },
        {
          "country": "Guatemala",
          "callingCode": "+502"
        },
        {
          "country": "Guernsey",
          "callingCode": "+44"
        },
        {
          "country": "Guinea",
          "callingCode": "+224"
        },
        {
          "country": "Guinea-Bissau",
          "callingCode": "+245"
        },
        {
          "country": "Guyana",
          "callingCode": "+592"
        },
        {
          "country": "Haiti",
          "callingCode": "+509"
        },
        {
          "country": "Hawaii",
          "callingCode": "+1"
        },
        {
          "country": "Honduras",
          "callingCode": "+504"
        },
        {
          "country": "Hong Kong",
          "callingCode": "+852"
        },
        {
          "country": "Hungary",
          "callingCode": "+36"
        },
        {
          "country": "Iceland",
          "callingCode": "+354"
        },
        {
          "country": "India",
          "callingCode": "+91"
        },
        {
          "country": "Indonesia",
          "callingCode": "+62"
        },
        {
          "country": "Iran",
          "callingCode": "+98"
        },
        {
          "country": "Iraq",
          "callingCode": "+964"
        },
        {
          "country": "Ireland",
          "callingCode": "+353"
        },
        {
          "country": "Isle of Man",
          "callingCode": "+44"
        },
        {
          "country": "Israel",
          "callingCode": "+972"
        },
        {
          "country": "Italy",
          "callingCode": "+39"
        },
        {
          "country": "Ivory Coast",
          "callingCode": "+225"
        },
        {
          "country": "Jamaica",
          "callingCode": "+1"
        },
        {
          "country": "Jan Mayen",
          "callingCode": "+47"
        },
        {
          "country": "Japan",
          "callingCode": "+81"
        },
        {
          "country": "Jersey",
          "callingCode": "+44"
        },
        {
          "country": "Jordan",
          "callingCode": "+962"
        },
        {
          "country": "Kazakhstan",
          "callingCode": "+7"
        },
        {
          "country": "Kenya",
          "callingCode": "+254"
        },
        {
          "country": "Kiribati",
          "callingCode": "+686"
        },
        {
          "country": "Kosovo",
          "callingCode": "+383"
        },
        {
          "country": "Kuwait",
          "callingCode": "+965"
        },
        {
          "country": "Kyrgyzstan",
          "callingCode": "+996"
        },
        {
          "country": "Laos",
          "callingCode": "+856"
        },
        {
          "country": "Latvia",
          "callingCode": "+371"
        },
        {
          "country": "Lebanon",
          "callingCode": "+961"
        },
        {
          "country": "Lesotho",
          "callingCode": "+266"
        },
        {
          "country": "Liberia",
          "callingCode": "+231"
        },
        {
          "country": "Libya",
          "callingCode": "+218"
        },
        {
          "country": "Liechtenstein",
          "callingCode": "+423"
        },
        {
          "country": "Lithuania",
          "callingCode": "+370"
        },
        {
          "country": "Luxembourg",
          "callingCode": "+352"
        },
        {
          "country": "Macau",
          "callingCode": "+853"
        },
        {
          "country": "Madagascar",
          "callingCode": "+261"
        },
        {
          "country": "Madeira",
          "callingCode": "+351"
        },
        {
          "country": "Malawi",
          "callingCode": "+265"
        },
        {
          "country": "Malaysia",
          "callingCode": "+60"
        },
        {
          "country": "Maldives",
          "callingCode": "+960"
        },
        {
          "country": "Mali",
          "callingCode": "+223"
        },
        {
          "country": "Malta",
          "callingCode": "+356"
        },
        {
          "country": "Marshall Islands",
          "callingCode": "+692"
        },
        {
          "country": "Martinique",
          "callingCode": "+596"
        },
        {
          "country": "Mauritania",
          "callingCode": "+222"
        },
        {
          "country": "Mauritius",
          "callingCode": "+230"
        },
        {
          "country": "Mayotte",
          "callingCode": "+262"
        },
        {
          "country": "Melilla",
          "callingCode": "+34"
        },
        {
          "country": "Mexico",
          "callingCode": "+52"
        },
        {
          "country": "Moldova",
          "callingCode": "+373"
        },
        {
          "country": "Monaco",
          "callingCode": "+377"
        },
        {
          "country": "Mongolia",
          "callingCode": "+976"
        },
        {
          "country": "Montenegro",
          "callingCode": "+382"
        },
        {
          "country": "Montserrat",
          "callingCode": "+1"
        },
        {
          "country": "Morocco",
          "callingCode": "+212"
        },
        {
          "country": "Mozambique",
          "callingCode": "+258"
        },
        {
          "country": "Myanmar",
          "callingCode": "+95"
        },
        {
          "country": "Artsakh",
          "callingCode": "+374"
        },
        {
          "country": "Namibia",
          "callingCode": "+264"
        },
        {
          "country": "Nauru",
          "callingCode": "+674"
        },
        {
          "country": "Nepal",
          "callingCode": "+977"
        },
        {
          "country": "Netherlands",
          "callingCode": "+31"
        },
        {
          "country": "New Caledonia",
          "callingCode": "+687"
        },
        {
          "country": "New Zealand",
          "callingCode": "+64"
        },
        {
          "country": "Nicaragua",
          "callingCode": "+505"
        },
        {
          "country": "Niger",
          "callingCode": "+227"
        },
        {
          "country": "Nigeria",
          "callingCode": "+234"
        },
        {
          "country": "Niue",
          "callingCode": "+683"
        },
        {
          "country": "Norfolk Island",
          "callingCode": "+672"
        },
        {
          "country": "North Korea",
          "callingCode": "+850"
        },
        {
          "country": "North Macedonia",
          "callingCode": "+389"
        },
        {
          "country": "Northern Cyprus",
          "callingCode": "+90"
        },
        {
          "country": "Northern Ireland",
          "callingCode": "+44"
        },
        {
          "country": "Northern Mariana Islands",
          "callingCode": "+1"
        },
        {
          "country": "Norway",
          "callingCode": "+47"
        },
        {
          "country": "Oman",
          "callingCode": "+968"
        },
        {
          "country": "Pakistan",
          "callingCode": "+92"
        },
        {
          "country": "Palau",
          "callingCode": "+680"
        },
        {
          "country": "Palestine, State of",
          "callingCode": "++970 / +972"
        },
        {
          "country": "Panama",
          "callingCode": "+507"
        },
        {
          "country": "Papua New Guinea",
          "callingCode": "+675"
        },
        {
          "country": "Paraguay",
          "callingCode": "+595"
        },
        {
          "country": "Peru",
          "callingCode": "+51"
        },
        {
          "country": "Philippines",
          "callingCode": "+63"
        },
        {
          "country": "Pitcairn Islands",
          "callingCode": "+64"
        },
        {
          "country": "Plazas de soberanía",
          "callingCode": "+34"
        },
        {
          "country": "Poland",
          "callingCode": "+48"
        },
        {
          "country": "Portugal",
          "callingCode": "+351"
        },
        {
          "country": "Príncipe",
          "callingCode": "+239"
        },
        {
          "country": "Puerto Rico",
          "callingCode": "+1"
        },
        {
          "country": "Qatar",
          "callingCode": "+974"
        },
        {
          "country": "Réunion",
          "callingCode": "+262"
        },
        {
          "country": "Romania",
          "callingCode": "+40"
        },
        {
          "country": "Russia (Russian Federation)",
          "callingCode": "+7"
        },
        {
          "country": "Rwanda",
          "callingCode": "+250"
        },
        {
          "country": "Saba",
          "callingCode": "+599"
        },
        {
          "country": "Sahrawi Arab Democratic Republic",
          "callingCode": "+212"
        },
        {
          "country": "Saint Barthélemy",
          "callingCode": "+590"
        },
        {
          "country": "Saint Helena and Tristan da Cunha (not Ascenscion)",
          "callingCode": "+290"
        },
        {
          "country": "Saint Kitts and Nevis",
          "callingCode": "+1"
        },
        {
          "country": "Saint Lucia",
          "callingCode": "+1"
        },
        {
          "country": "Collectivity of Saint Martin",
          "callingCode": "+590"
        },
        {
          "country": "Saint Pierre and Miquelon",
          "callingCode": "+508"
        },
        {
          "country": "Saint Vincent and the Grenadines",
          "callingCode": "+1"
        },
        {
          "country": "Samoa",
          "callingCode": "+685"
        },
        {
          "country": "San Marino",
          "callingCode": "+378"
        },
        {
          "country": "São Tomé and Príncipe",
          "callingCode": "+239"
        },
        {
          "country": "Saudi Arabia",
          "callingCode": "+966"
        },
        {
          "country": "Scotland",
          "callingCode": "+44"
        },
        {
          "country": "Senegal",
          "callingCode": "+221"
        },
        {
          "country": "Serbia",
          "callingCode": "+381"
        },
        {
          "country": "Seychelles",
          "callingCode": "+248"
        },
        {
          "country": "Sierra Leone",
          "callingCode": "+232"
        },
        {
          "country": "Singapore",
          "callingCode": "+65"
        },
        {
          "country": "Sint Eustatius",
          "callingCode": "+599"
        },
        {
          "country": "Sint Maarten",
          "callingCode": "+1"
        },
        {
          "country": "Slovakia (Slovak Republic)",
          "callingCode": "+421"
        },
        {
          "country": "Slovenia",
          "callingCode": "+386"
        },
        {
          "country": "Solomon Islands",
          "callingCode": "+677"
        },
        {
          "country": "Somalia",
          "callingCode": "+252"
        },
        {
          "country": "South Africa",
          "callingCode": "+27"
        },
        {
          "country": "South Georgia and the South Sandwich Islands",
          "callingCode": "+500"
        },
        {
          "country": "South Korea",
          "callingCode": "+82"
        },
        {
          "country": "South Ossetia",
          "callingCode": "+995"
        },
        {
          "country": "South Sudan",
          "callingCode": "+211"
        },
        {
          "country": "Spain",
          "callingCode": "+34"
        },
        {
          "country": "Sri Lanka",
          "callingCode": "+94"
        },
        {
          "country": "Sudan",
          "callingCode": "+249"
        },
        {
          "country": "Suriname",
          "callingCode": "+597"
        },
        {
          "country": "Svalbard",
          "callingCode": "+47"
        },
        {
          "country": "Sweden",
          "callingCode": "+46"
        },
        {
          "country": "Switzerland",
          "callingCode": "+41"
        },
        {
          "country": "Syria",
          "callingCode": "+963"
        },
        {
          "country": "Taiwan",
          "callingCode": "+886"
        },
        {
          "country": "Tajikistan",
          "callingCode": "+992"
        },
        {
          "country": "Tanzania",
          "callingCode": "+255"
        },
        {
          "country": "Thailand",
          "callingCode": "+66"
        },
        {
          "country": "Togo",
          "callingCode": "+228"
        },
        {
          "country": "Tokelau",
          "callingCode": "+690"
        },
        {
          "country": "Tonga",
          "callingCode": "+676"
        },
        {
          "country": "Trinidad and Tobago",
          "callingCode": "+1"
        },
        {
          "country": "Tristan da Cunha",
          "callingCode": "+290"
        },
        {
          "country": "Tunisia",
          "callingCode": "+216"
        },
        {
          "country": "Turkey",
          "callingCode": "+90"
        },
        {
          "country": "Turkmenistan",
          "callingCode": "+993"
        },
        {
          "country": "Turks and Caicos Islands",
          "callingCode": "+1"
        },
        {
          "country": "Tuvalu",
          "callingCode": "+688"
        },
        {
          "country": "Uganda",
          "callingCode": "+256"
        },
        {
          "country": "Ukraine",
          "callingCode": "+380"
        },
        {
          "country": "United Arab Emirates",
          "callingCode": "+971"
        },
        {
          "country": "United Kingdom",
          "callingCode": "+44"
        },
        {
          "country": "United States (USA)",
          "callingCode": "+1"
        },
        {
          "country": "United States Virgin Islands",
          "callingCode": "+1"
        },
        {
          "country": "Uruguay",
          "callingCode": "+598"
        },
        {
          "country": "Uzbekistan",
          "callingCode": "+998"
        },
        {
          "country": "Vanuatu",
          "callingCode": "+678"
        },
        {
          "country": "Vatican City",
          "callingCode": "+39"
        },
        {
          "country": "Venezuela",
          "callingCode": "+58"
        },
        {
          "country": "Vietnam",
          "callingCode": "+84"
        },
        {
          "country": "U.S. Virgin Islands",
          "callingCode": "+1"
        },
        {
          "country": "Wales",
          "callingCode": "+44"
        },
        {
          "country": "Wallis and Futuna",
          "callingCode": "+681"
        },
        {
          "country": "Western Sahara",
          "callingCode": "+212"
        },
        {
          "country": "Yemen",
          "callingCode": "+967"
        },
        {
          "country": "Zambia",
          "callingCode": "+260"
        },
        {
          "country": "Zimbabwe",
          "callingCode": "+263"
        }
    ],
    timeZones: [
        {
          "abbreviation": "ACDT",
          "name": "Australian Central Daylight Time",
          "location": "Australia",
          "offset": "UTC +10:30"
        },
        {
          "abbreviation": "ACST",
          "name": "Australian Central Standard Time",
          "location": "Australia",
          "offset": "UTC +9:30"
        },
        {
          "abbreviation": "ACT",
          "name": "Acre Time",
          "location": "South America",
          "offset": "UTC -5"
        },
        {
          "abbreviation": "ACT",
          "name": "Australian Central Time",
          "location": "Australia",
          "offset": "UTC +9:30 / +10:30"
        },
        {
          "abbreviation": "ACWST",
          "name": "Australian Central Western Standard Time",
          "location": "Australia",
          "offset": "UTC +8:45"
        },
        {
          "abbreviation": "ADT",
          "name": "Arabia Daylight Time AST – Arabia Summer Time",
          "location": "Asia",
          "offset": "UTC +3"
        },
        {
          "abbreviation": "ADT",
          "name": "Atlantic Daylight Time",
          "location": "North AmericaAtlantic",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "AEDT",
          "name": "Australian Eastern Daylight Time",
          "location": "AustraliaPacific",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "AEST",
          "name": "Australian Eastern Standard Time",
          "location": "Australia",
          "offset": "UTC +10"
        },
        {
          "abbreviation": "AET",
          "name": "Australian Eastern Time",
          "location": "Australia",
          "offset": "UTC +10:00 / +11:00"
        },
        {
          "abbreviation": "AFT",
          "name": "Afghanistan Time",
          "location": "Asia",
          "offset": "UTC +4:30"
        },
        {
          "abbreviation": "AKDT",
          "name": "Alaska Daylight Time",
          "location": "North America",
          "offset": "UTC -8"
        },
        {
          "abbreviation": "AKST",
          "name": "Alaska Standard Time",
          "location": "North America",
          "offset": "UTC -9"
        },
        {
          "abbreviation": "ALMT",
          "name": "Alma-Ata Time",
          "location": "Asia",
          "offset": "UTC +6"
        },
        {
          "abbreviation": "AMST",
          "name": "Amazon Summer Time",
          "location": "South America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "AMST",
          "name": "Armenia Summer Time",
          "location": "Asia",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "AMT",
          "name": "Amazon Time",
          "location": "South America",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "AMT",
          "name": "Armenia Time",
          "location": "Asia",
          "offset": "UTC +4"
        },
        {
          "abbreviation": "ANAST",
          "name": "Anadyr Summer Time",
          "location": "Asia",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "ANAT",
          "name": "Anadyr Time",
          "location": "Asia",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "AQTT",
          "name": "Aqtobe Time",
          "location": "Asia",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "ART",
          "name": "Argentina Time",
          "location": "AntarcticaSouth America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "AST",
          "name": "Arabia Standard Time",
          "location": "Asia",
          "offset": "UTC +3"
        },
        {
          "abbreviation": "AST",
          "name": "Atlantic Standard Time",
          "location": "North AmericaAtlanticCaribbean",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "AT",
          "name": "Atlantic Time",
          "location": "North AmericaAtlantic",
          "offset": "UTC -3:00 / -4:00"
        },
        {
          "abbreviation": "AWDT",
          "name": "Australian Western Daylight Time",
          "location": "Australia",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "AWST",
          "name": "Australian Western Standard Time",
          "location": "Australia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "AZOST",
          "name": "Azores Summer Time",
          "location": "Atlantic",
          "offset": "UTC +0"
        },
        {
          "abbreviation": "AZOT",
          "name": "Azores Time AZOST – Azores Standard Time",
          "location": "Atlantic",
          "offset": "UTC -1"
        },
        {
          "abbreviation": "AZST",
          "name": "Azerbaijan Summer Time",
          "location": "Asia",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "AZT",
          "name": "Azerbaijan Time",
          "location": "Asia",
          "offset": "UTC +4"
        },
        {
          "abbreviation": "BNT",
          "name": "Brunei Darussalam Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "BOT",
          "name": "Bolivia Time",
          "location": "South America",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "BRST",
          "name": "Brasília Summer Time",
          "location": "South America",
          "offset": "UTC -2"
        },
        {
          "abbreviation": "BRT",
          "name": "Brasília Time",
          "location": "South America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "BST",
          "name": "Bangladesh Standard Time",
          "location": "Asia",
          "offset": "UTC +6"
        },
        {
          "abbreviation": "BST",
          "name": "Bougainville Standard Time",
          "location": "Pacific",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "BST",
          "name": "British Summer Time BDT",
          "location": "Europe",
          "offset": "UTC +1"
        },
        {
          "abbreviation": "BTT",
          "name": "Bhutan Time",
          "location": "Asia",
          "offset": "UTC +6"
        },
     
        {
          "abbreviation": "CAST",
          "name": "Casey Time",
          "location": "Antarctica",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "CAT",
          "name": "Central Africa Time",
          "location": "Africa",
          "offset": "UTC +2"
        },
        {
          "abbreviation": "CCT",
          "name": "Cocos Islands Time",
          "location": "Indian Ocean",
          "offset": "UTC +6:30"
        },
        {
          "abbreviation": "CDT",
          "name": "Central Daylight Time",
          "location": "North America",
          "offset": "UTC -5"
        },
        {
          "abbreviation": "CDT",
          "name": "Cuba Daylight Time",
          "location": "Caribbean",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "CEST",
          "name": "Central European Summer Time",
          "location": "EuropeAntarctica",
          "offset": "UTC +2"
        },
        {
          "abbreviation": "CET",
          "name": "Central European Time",
          "location": "EuropeAfricaAntarctica",
          "offset": "UTC +1"
        },
        {
          "abbreviation": "CHADT",
          "name": "Chatham Island Daylight Time",
          "location": "Pacific",
          "offset": "UTC +13:45"
        },
        {
          "abbreviation": "CHAST",
          "name": "Chatham Island Standard Time",
          "location": "Pacific",
          "offset": "UTC +12:45"
        },
        {
          "abbreviation": "CHOST",
          "name": "Choibalsan Summer Time",
          "location": "Asia",
          "offset": "UTC +9:00 / +8:00"
        },
        {
          "abbreviation": "CHOST",
          "name": "Choibalsan Summer Time",
          "location": "Asia",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "CHOT",
          "name": "Choibalsan Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "CHUT",
          "name": "Chuuk Time",
          "location": "Pacific",
          "offset": "UTC +10"
        },
        {
          "abbreviation": "CIDST",
          "name": "Cayman Islands Daylight Saving Time",
          "location": "Caribbean",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "CIST",
          "name": "Cayman Islands Standard Time",
          "location": "Caribbean",
          "offset": "UTC -5"
        },
        {
          "abbreviation": "CKT",
          "name": "Cook Island Time",
          "location": "Pacific",
          "offset": "UTC -10"
        },
        {
          "abbreviation": "CLST",
          "name": "Chile Summer Time",
          "location": "South America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "CLT",
          "name": "Chile Standard Time",
          "location": "South America",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "COT",
          "name": "Colombia Time",
          "location": "South America",
          "offset": "UTC -5"
        },
        {
          "abbreviation": "CST",
          "name": "Central Standard Time",
          "location": "North AmericaCentral America",
          "offset": "UTC -6"
        },
        {
          "abbreviation": "CST",
          "name": "China Standard Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "CST",
          "name": "Cuba Standard Time",
          "location": "Caribbean",
          "offset": "UTC -5"
        },
        {
          "abbreviation": "CT",
          "name": "Central Time",
          "location": "North America",
          "offset": "UTC -5:00 / -6:00"
        },
        {
          "abbreviation": "CVT",
          "name": "Cape Verde Time",
          "location": "Africa",
          "offset": "UTC -1"
        },
        {
          "abbreviation": "CXT",
          "name": "Christmas Island Time",
          "location": "Australia",
          "offset": "UTC +7"
        },
        {
          "abbreviation": "ChST",
          "name": "Chamorro Standard Time",
          "location": "Pacific",
          "offset": "UTC +10"
        },
    
        {
          "abbreviation": "EASST",
          "name": "Easter Island Summer Time",
          "location": "Pacific",
          "offset": "UTC -5"
        },
        {
          "abbreviation": "EAST",
          "name": "Easter Island Standard Time",
          "location": "Pacific",
          "offset": "UTC -6"
        },
        {
          "abbreviation": "EAT",
          "name": "Eastern Africa Time",
          "location": "AfricaIndian Ocean",
          "offset": "UTC +3"
        },
        {
          "abbreviation": "ECT",
          "name": "Ecuador Time",
          "location": "South America",
          "offset": "UTC -5"
        },
        {
          "abbreviation": "EDT",
          "name": "Eastern Daylight Time",
          "location": "North AmericaCaribbean",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "EEST",
          "name": "Eastern European Summer Time",
          "location": "EuropeAsia",
          "offset": "UTC +3"
        },
        {
          "abbreviation": "EET",
          "name": "Eastern European Time",
          "location": "EuropeAsiaAfrica",
          "offset": "UTC +2"
        },
        {
          "abbreviation": "EGST",
          "name": "Eastern Greenland Summer Time",
          "location": "North America",
          "offset": "UTC +0"
        },
        {
          "abbreviation": "EGT",
          "name": "East Greenland Time",
          "location": "North America",
          "offset": "UTC -1"
        },
        {
          "abbreviation": "EST",
          "name": "Eastern Standard Time",
          "location": "North AmericaCaribbeanCentral America",
          "offset": "UTC -5"
        },
        {
          "abbreviation": "ET",
          "name": "Eastern Time",
          "location": "North AmericaCaribbean",
          "offset": "UTC -4:00 / -5:00"
        },
        {
          "abbreviation": "FET",
          "name": "Further-Eastern European Time",
          "location": "Europe",
          "offset": "UTC +3"
        },
        {
          "abbreviation": "FJST",
          "name": "Fiji Summer Time",
          "location": "Pacific",
          "offset": "UTC +13"
        },
        {
          "abbreviation": "FJT",
          "name": "Fiji Time",
          "location": "Pacific",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "FKST",
          "name": "Falkland Islands Summer Time",
          "location": "South America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "FKT",
          "name": "Falkland Island Time",
          "location": "South America",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "FNT",
          "name": "Fernando de Noronha Time",
          "location": "South America",
          "offset": "UTC -2"
        },
        {
          "abbreviation": "GALT",
          "name": "Galapagos Time",
          "location": "Pacific",
          "offset": "UTC -6"
        },
        {
          "abbreviation": "GAMT",
          "name": "Gambier Time",
          "location": "Pacific",
          "offset": "UTC -9"
        },
        {
          "abbreviation": "GET",
          "name": "Georgia Standard Time",
          "location": "Asia",
          "offset": "UTC +4"
        },
        {
          "abbreviation": "GFT",
          "name": "French Guiana Time",
          "location": "South America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "GILT",
          "name": "Gilbert Island Time",
          "location": "Pacific",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "GMT",
          "name": "Greenwich Mean Time",
          "location": "EuropeAfricaNorth AmericaAntarctica",
          "offset": "UTC +0"
        },
        {
          "abbreviation": "GST",
          "name": "Gulf Standard Time",
          "location": "Asia",
          "offset": "UTC +4"
        },
        {
          "abbreviation": "GST",
          "name": "South Georgia Time",
          "location": "South America",
          "offset": "UTC -2"
        },
        {
          "abbreviation": "GYT",
          "name": "Guyana Time",
          "location": "South America",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "HADT",
          "name": "Hawaii-Aleutian Daylight Time",
          "location": "North America",
          "offset": "UTC -9"
        },
        {
          "abbreviation": "HAST",
          "name": "Hawaii-Aleutian Standard Time",
          "location": "North AmericaPacific",
          "offset": "UTC -10"
        },
        {
          "abbreviation": "HKT",
          "name": "Hong Kong Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "HOVST",
          "name": "Hovd Summer Time",
          "location": "Asia",
          "offset": "UTC +8:00 / +7:00"
        },
        {
          "abbreviation": "HOVST",
          "name": "Hovd Summer Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "HOVT",
          "name": "Hovd Time",
          "location": "Asia",
          "offset": "UTC +7"
        },
        {
          "abbreviation": "ICT",
          "name": "Indochina Time",
          "location": "Asia",
          "offset": "UTC +7"
        },
        {
          "abbreviation": "IDT",
          "name": "Israel Daylight Time",
          "location": "Asia",
          "offset": "UTC +3"
        },
        {
          "abbreviation": "IOT",
          "name": "Indian Chagos Time",
          "location": "Indian Ocean",
          "offset": "UTC +6"
        },
        {
          "abbreviation": "IRDT",
          "name": "Iran Daylight Time",
          "location": "Asia",
          "offset": "UTC +4:30"
        },
        {
          "abbreviation": "IRKST",
          "name": "Irkutsk Summer Time",
          "location": "Asia",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "IRKT",
          "name": "Irkutsk Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "IRST",
          "name": "Iran Standard Time",
          "location": "Asia",
          "offset": "UTC +3:30"
        },
        {
          "abbreviation": "IST",
          "name": "India Standard Time",
          "location": "Asia",
          "offset": "UTC +5:30"
        },
        {
          "abbreviation": "IST",
          "name": "Irish Standard Time",
          "location": "Europe",
          "offset": "UTC +1"
        },
        {
          "abbreviation": "IST",
          "name": "Israel Standard Time",
          "location": "Asia",
          "offset": "UTC +2"
        },
        {
          "abbreviation": "JST",
          "name": "Japan Standard Time",
          "location": "Asia",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "KGT",
          "name": "Kyrgyzstan Time",
          "location": "Asia",
          "offset": "UTC +6"
        },
        {
          "abbreviation": "KOST",
          "name": "Kosrae Time",
          "location": "Pacific",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "KRAST",
          "name": "Krasnoyarsk Summer Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "KRAT",
          "name": "Krasnoyarsk Time",
          "location": "Asia",
          "offset": "UTC +7"
        },
        {
          "abbreviation": "KST",
          "name": "Korea Standard Time",
          "location": "Asia",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "KUYT",
          "name": "Kuybyshev Time",
          "location": "Europe",
          "offset": "UTC +4"
        },
        {
          "abbreviation": "LHDT",
          "name": "Lord Howe Daylight Time",
          "location": "Australia",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "LHST",
          "name": "Lord Howe Standard Time",
          "location": "Australia",
          "offset": "UTC +10:30"
        },
        {
          "abbreviation": "LINT",
          "name": "Line Islands Time",
          "location": "Pacific",
          "offset": "UTC +14"
        },
    
        {
          "abbreviation": "MAGST",
          "name": "Magadan Summer Time",
          "location": "Asia",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "MAGT",
          "name": "Magadan Time",
          "location": "Asia",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "MART",
          "name": "Marquesas Time",
          "location": "Pacific",
          "offset": "UTC -9:30"
        },
        {
          "abbreviation": "MAWT",
          "name": "Mawson Time",
          "location": "Antarctica",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "MDT",
          "name": "Mountain Daylight Time",
          "location": "North America",
          "offset": "UTC -6"
        },
        {
          "abbreviation": "MHT",
          "name": "Marshall Islands Time",
          "location": "Pacific",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "MMT",
          "name": "Myanmar Time",
          "location": "Asia",
          "offset": "UTC +6:30"
        },
        {
          "abbreviation": "MSD",
          "name": "Moscow Daylight Time",
          "location": "Europe",
          "offset": "UTC +4"
        },
        {
          "abbreviation": "MSK",
          "name": "Moscow Standard Time",
          "location": "EuropeAsia",
          "offset": "UTC +3"
        },
        {
          "abbreviation": "MST",
          "name": "Mountain Standard Time",
          "location": "North America",
          "offset": "UTC -7"
        },
        {
          "abbreviation": "MT",
          "name": "Mountain Time",
          "location": "North America",
          "offset": "UTC -6:00 / -7:00"
        },
        {
          "abbreviation": "MUT",
          "name": "Mauritius Time",
          "location": "Africa",
          "offset": "UTC +4"
        },
        {
          "abbreviation": "MVT",
          "name": "Maldives Time",
          "location": "Asia",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "MYT",
          "name": "Malaysia Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "NCT",
          "name": "New Caledonia Time",
          "location": "Pacific",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "NDT",
          "name": "Newfoundland Daylight Time",
          "location": "North America",
          "offset": "UTC -2:30"
        },
        {
          "abbreviation": "NFT",
          "name": "Norfolk Time",
          "location": "Australia",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "NOVST",
          "name": "Novosibirsk Summer Time",
          "location": "Asia",
          "offset": "UTC +7"
        },
        {
          "abbreviation": "NOVT",
          "name": "Novosibirsk Time",
          "location": "Asia",
          "offset": "UTC +6"
        },
        {
          "abbreviation": "NPT",
          "name": "Nepal Time",
          "location": "Asia",
          "offset": "UTC +5:45"
        },
        {
          "abbreviation": "NRT",
          "name": "Nauru Time",
          "location": "Pacific",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "NST",
          "name": "Newfoundland Standard Time",
          "location": "North America",
          "offset": "UTC -3:30"
        },
        {
          "abbreviation": "NUT",
          "name": "Niue Time",
          "location": "Pacific",
          "offset": "UTC -11"
        },
        {
          "abbreviation": "NZDT",
          "name": "New Zealand Daylight Time",
          "location": "PacificAntarctica",
          "offset": "UTC +13"
        },
        {
          "abbreviation": "NZST",
          "name": "New Zealand Standard Time",
          "location": "PacificAntarctica",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "OMSST",
          "name": "Omsk Summer Time",
          "location": "Asia",
          "offset": "UTC +7"
        },
        {
          "abbreviation": "OMST",
          "name": "Omsk Standard Time",
          "location": "Asia",
          "offset": "UTC +6"
        },
        {
          "abbreviation": "ORAT",
          "name": "Oral Time",
          "location": "Asia",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "PDT",
          "name": "Pacific Daylight Time",
          "location": "North America",
          "offset": "UTC -7"
        },
        {
          "abbreviation": "PET",
          "name": "Peru Time",
          "location": "South America",
          "offset": "UTC -5"
        },
        {
          "abbreviation": "PETST",
          "name": "Kamchatka Summer Time",
          "location": "Asia",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "PETT",
          "name": "Kamchatka Time PETT",
          "location": "Asia",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "PGT",
          "name": "Papua New Guinea Time",
          "location": "Pacific",
          "offset": "UTC +10"
        },
        {
          "abbreviation": "PHOT",
          "name": "Phoenix Island Time",
          "location": "Pacific",
          "offset": "UTC +13"
        },
        {
          "abbreviation": "PHT",
          "name": "Philippine Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "PKT",
          "name": "Pakistan Standard Time",
          "location": "Asia",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "PMDT",
          "name": "Pierre & Miquelon Daylight Time",
          "location": "North America",
          "offset": "UTC -2"
        },
        {
          "abbreviation": "PMST",
          "name": "Pierre & Miquelon Standard Time",
          "location": "North America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "PONT",
          "name": "Pohnpei Standard Time",
          "location": "Pacific",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "PST",
          "name": "Pacific Standard Time",
          "location": "North America",
          "offset": "UTC -8"
        },
        {
          "abbreviation": "PST",
          "name": "Pitcairn Standard Time",
          "location": "Pacific",
          "offset": "UTC -8"
        },
        {
          "abbreviation": "PT",
          "name": "Pacific Time",
          "location": "North America",
          "offset": "UTC -7:00 / -8:00"
        },
        {
          "abbreviation": "PWT",
          "name": "Palau Time",
          "location": "Pacific",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "PYST",
          "name": "Paraguay Summer Time",
          "location": "South America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "PYT",
          "name": "Paraguay Time",
          "location": "South America",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "PYT",
          "name": "Pyongyang Time",
          "location": "Asia",
          "offset": "UTC +8:30"
        },
     
        {
          "abbreviation": "QYZT",
          "name": "Qyzylorda Time",
          "location": "Asia",
          "offset": "UTC +6"
        },
       
      
        {
          "abbreviation": "SAKT",
          "name": "Sakhalin Time",
          "location": "Asia",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "SAMT",
          "name": "Samara Time SAMT – Samara Standard Time",
          "location": "Europe",
          "offset": "UTC +4"
        },
        {
          "abbreviation": "SAST",
          "name": "South Africa Standard Time",
          "location": "Africa",
          "offset": "UTC +2"
        },
        {
          "abbreviation": "SBT",
          "name": "Solomon Islands Time",
          "location": "Pacific",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "SCT",
          "name": "Seychelles Time",
          "location": "Africa",
          "offset": "UTC +4"
        },
        {
          "abbreviation": "SGT",
          "name": "Singapore Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "SRET",
          "name": "Srednekolymsk Time",
          "location": "Asia",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "SRT",
          "name": "Suriname Time",
          "location": "South America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "SST",
          "name": "Samoa Standard Time",
          "location": "Pacific",
          "offset": "UTC -11"
        },
        {
          "abbreviation": "SYOT",
          "name": "Syowa Time",
          "location": "Antarctica",
          "offset": "UTC +3"
        },
        {
          "abbreviation": "TAHT",
          "name": "Tahiti Time",
          "location": "Pacific",
          "offset": "UTC -10"
        },
    
        {
          "abbreviation": "TJT",
          "name": "Tajikistan Time",
          "location": "Asia",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "TKT",
          "name": "Tokelau Time",
          "location": "Pacific",
          "offset": "UTC +13"
        },
        {
          "abbreviation": "TLT",
          "name": "East Timor Time",
          "location": "Asia",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "TMT",
          "name": "Turkmenistan Time",
          "location": "Asia",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "TOT",
          "name": "Tonga Time",
          "location": "Pacific",
          "offset": "UTC +13"
        },
        {
          "abbreviation": "TVT",
          "name": "Tuvalu Time",
          "location": "Pacific",
          "offset": "UTC +12"
        },
    
        {
          "abbreviation": "ULAST",
          "name": "Ulaanbaatar Summer Time",
          "location": "Asia",
          "offset": "UTC +9:00 / +8:00"
        },
        {
          "abbreviation": "ULAST",
          "name": "Ulaanbaatar Summer Time",
          "location": "Asia",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "ULAT",
          "name": "Ulaanbaatar Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "ULAT",
          "name": "Ulaanbaatar Time",
          "location": "Asia",
          "offset": "UTC +9:00 / +8:00"
        },
        {
          "abbreviation": "UTC",
          "name": "Coordinated Universal Time",
          "location": "Worldwide",
          "offset": "UTC"
        },
        {
          "abbreviation": "UYST",
          "name": "Uruguay Summer Time",
          "location": "South America",
          "offset": "UTC -2"
        },
        {
          "abbreviation": "UYT",
          "name": "Uruguay Time",
          "location": "South America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "UZT",
          "name": "Uzbekistan Time",
          "location": "Asia",
          "offset": "UTC +5"
        },
        {
          "abbreviation": "VET",
          "name": "Venezuelan Standard Time",
          "location": "South America",
          "offset": "UTC -4"
        },
        {
          "abbreviation": "VLAST",
          "name": "Vladivostok Summer Time",
          "location": "Asia",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "VLAT",
          "name": "Vladivostok Time",
          "location": "Asia",
          "offset": "UTC +10"
        },
        {
          "abbreviation": "VOST",
          "name": "Vostok Time",
          "location": "Antarctica",
          "offset": "UTC +6"
        },
        {
          "abbreviation": "VUT",
          "name": "Vanuatu Time",
          "location": "Pacific",
          "offset": "UTC +11"
        },
        {
          "abbreviation": "WAKT",
          "name": "Wake Time",
          "location": "Pacific",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "WARST",
          "name": "Western Argentine Summer Time",
          "location": "South America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "WAST",
          "name": "West Africa Summer Time",
          "location": "Africa",
          "offset": "UTC +2"
        },
        {
          "abbreviation": "WAT",
          "name": "West Africa Time",
          "location": "Africa",
          "offset": "UTC +1"
        },
        {
          "abbreviation": "WEST",
          "name": "Western European Summer Time",
          "location": "EuropeAfrica",
          "offset": "UTC +1"
        },
        {
          "abbreviation": "WET",
          "name": "Western European Time",
          "location": "EuropeAfrica",
          "offset": "UTC +0"
        },
        {
          "abbreviation": "WFT",
          "name": "Wallis and Futuna Time",
          "location": "Pacific",
          "offset": "UTC +12"
        },
        {
          "abbreviation": "WGST",
          "name": "Western Greenland Summer Time",
          "location": "North America",
          "offset": "UTC -2"
        },
        {
          "abbreviation": "WGT",
          "name": "West Greenland Time",
          "location": "North America",
          "offset": "UTC -3"
        },
        {
          "abbreviation": "WIB",
          "name": "Western Indonesian Time",
          "location": "Asia",
          "offset": "UTC +7"
        },
        {
          "abbreviation": "WIT",
          "name": "Eastern Indonesian Time",
          "location": "Asia",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "WITA",
          "name": "Central Indonesian Time",
          "location": "Asia",
          "offset": "UTC +8"
        },
        {
          "abbreviation": "WST",
          "name": "West Samoa Time",
          "location": "Pacific",
          "offset": "UTC +13"
        },
        {
          "abbreviation": "WST",
          "name": "Western Sahara Summer Time",
          "location": "Africa",
          "offset": "UTC +1"
        },
        {
          "abbreviation": "WT",
          "name": "Western Sahara Standard Time",
          "location": "Africa",
          "offset": "UTC +0"
        },
        {
          "abbreviation": "YAKST",
          "name": "Yakutsk Summer Time",
          "location": "Asia",
          "offset": "UTC +10"
        },
        {
          "abbreviation": "YAKT",
          "name": "Yakutsk Time",
          "location": "Asia",
          "offset": "UTC +9"
        },
        {
          "abbreviation": "YAPT",
          "name": "Yap Time",
          "location": "Pacific",
          "offset": "UTC +10"
        },
        {
          "abbreviation": "YEKST",
          "name": "Yekaterinburg Summer Time",
          "location": "Asia",
          "offset": "UTC +6"
        },
        {
          "abbreviation": "YEKT",
          "name": "Yekaterinburg Time",
          "location": "Asia",
          "offset": "UTC +5"
        }
      ]
}

