import React, { useEffect, useState } from 'react';
import { Link, useParams } from '@reach/router';
import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  defaultGridOptions,
} from '@bit/sixsprints.utils.kg-grid';
import { Box, Flex} from '@chakra-ui/react';
import Bro from 'brototype';
import {
  ActionContainer,
  GridButton,
  GridInput,
} from '../../components/misc/grid-ui';
import useNotify from '../../utils/notifications';
import 'rodal/lib/rodal.css';
import { AppConstants } from '../../utils/constants';
import { getDateFormatter, getFullName, getUser, pluralizer } from '../../utils/helpers';
import LeaveStatusSelect from '../../components/leave/leave-status-select';
import SvgEye from '../../components/misc/eye-svg';
import { Icon } from '@chakra-ui/icons';
import DataService from '../../services/data-service';
import ApprovedIcon from '../../icons/check-circle';
import CancelledIcon from '../../icons/banned-circle';
import RejectIcon from '../../icons/cross-circle';
import PendingIcon from '../../icons/pending-circle';
import Legends from '../attendance/legends';
import PageTitle from '../../components/misc/page-title';

const UserLeaveList = () => {
  const { t } = useI18nContext();
  const [userName, setUserName] = useState("");
  const toast = useNotify();
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });

  const leaveStatusOptions = storage.get(AppConstants.AUTH.CONFIG).leaveStatus;
  var { userSlug } = useParams();
  var isAdminUser = true;

  if (!userSlug) {
    userSlug = getUser()['slug'];
    isAdminUser = false;
  }
  const visibilityFilter = { "userSlug": { values: [userSlug], filterType: "set" } };

  const getUserInfo = () => {
    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const personalInfo = Bro(resp).iCanHaz('data.data.personalInfo');
        setUserName(`(${getFullName(personalInfo)})`);
      })
  };

 

  const transformColDef = fields => {
    var slugField = null;
    var modifiedColDef = fields.map(field => {
      if (field.colId !== userSlug) {
        field.filter = false;
      }
      const { cellRenderer, ...rest } = field;
      switch (field.colId) {
        case 'userId':
          return {
            ...rest,
            cellRendererFramework: params => {
              return (
                <Flex justifyContent="flex-start">
                  <Box>
                    {params.data.status === AppConstants.REGULARISATION_STATUS.APPROVED && <ApprovedIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.PENDING && <PendingIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.CANCELLED && <CancelledIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.REJECTED && <RejectIcon />}
                  </Box>
                  <Box ml="10px">{params.value}</Box>
                </Flex>
              )
            }
          }

        case 'fullName':
          field.sortable = false;
          return field;
        case 'leaveRaisedBy':
          field.sortable = false;
          return field;

        

        case 'startTime':
        case 'endTime':
          return {
            ...rest,
            cellRendererFramework: params => {
              return getDateFormatter(params.value, 'DD/MM/YYYY');
            },
          };

        case 'noOfDays':
          return {
            ...rest,
            cellRendererFramework: params => {
              return params.value + " day" + pluralizer(params.value);
            },
          };

        case 'leaveStatus':
          return {
            ...rest,
            cellRendererFramework: params => {
              return (<LeaveStatusSelect
                isDisabled={!isAdminUser}
                data={Bro(params).iCanHaz('data')}
                defaultValue={Bro(params).iCanHaz('data.status')}
                options={leaveStatusOptions}
              />);
            },
          };

        case 'slug':
          slugField = {
            ...rest,
            headerName: 'View',
            cellRendererFramework: params => {
              return (
                <Link
                  to={`/home/leave/view-leave/${params.value}`}
                >
                  <Icon as={SvgEye} />
                </Link>

              );
            },
          };
          return null;
        default:
          return field;
      }
    }).filter(d => !!d);
    modifiedColDef.push(slugField);
    return modifiedColDef;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    isAdminUser ?
      getUserInfo() :
      setUserName(`(${getFullName()})`);
  }, []);


  return (
    <Box>
      <Box d="flex" alignItems="center" justifyContent="space-between">
        <PageTitle title={`${t('leave_user_page_title')} ${userName}`} />
      </Box>
      <DataGrid
        gridProps={{ ...defaultGridOptions, transformColDef }}
        beans={{ restApi, storage, i18n: t, notificationService: simpleToast, defaultFilter: visibilityFilter }}
        dataProps={{ collection: 'leave', csvName: 'leave' }}
        uiComponents={{
          ActionContainer,
          Button: GridButton,
          Input: GridInput,
          hideImport: true,
          hideExport: true,
          hideAddNew: true,
          hideEdit: true,
          hideDelete: true,
          hideColumnState: true,
          hideSearch: true,
          hideReset: true,
        }}
      />
      <Legends />
    </Box>
  );
};

export default UserLeaveList;
