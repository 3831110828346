import React, { useEffect, useState } from 'react';
import { Link } from '@reach/router';
import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  defaultGridOptions,
} from '@bit/sixsprints.utils.kg-grid';
import { Box, Center, Flex, FormLabel, HStack, Icon } from '@chakra-ui/react';
import Bro from 'brototype';
import {
  GridButton,
  GridInput,
} from '../../components/misc/grid-ui';
import useNotify from '../../utils/notifications';
import colors from '../../theme/foundations/colors';
import 'rodal/lib/rodal.css';
import { AppConstants } from '../../utils/constants';
import { getDateFormatter, pluralizer } from '../../utils/helpers';
import LeaveStatusSelect from '../../components/leave/leave-status-select';
import SvgEye from '../../components/misc/eye-svg';
import ApprovedIcon from '../../icons/check-circle';
import CancelledIcon from '../../icons/banned-circle';
import RejectIcon from '../../icons/cross-circle';
import PendingIcon from '../../icons/pending-circle';
import Legends from '../attendance/legends';
import PageTitle from '../../components/misc/page-title';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import SelectThemeInput from '../../components/form-elements/select-input';
import DataService from '../../services/data-service';



export const LeaveActionContainer = props => {
  const gridApi = props.gridApi;
  const [statusOptions, setStatusOptions] = useState([]);
  const [year, setYear] = useState(moment().valueOf());
  const [status, setStatus] = useState("PENDING");
  const onYearChange = date => {
    setYear(date);
    setYearFilter(date);
  }

  const setYearFilter = (date) => {
    let startDate = moment(date).clone().startOf('year').valueOf();
    let endDate = moment(date).clone().endOf('year').valueOf();
    gridApi.getFilterInstance('dateCreated', filterInstance => {
      if (filterInstance) {
        if (filterInstance.componentInstance) {
          filterInstance.componentInstance.setState({
            filterType: "date", type: "inRange", filter: startDate, filterTo: endDate
          });
          filterInstance.componentInstance.handleSubmit();
        }
      }
    });
  }

  const onStatusChange = e => {
    let val = e.target.value;
    setStatus(val);
    gridApi.setFilterModel({ "status": { filterType: "set", values: [val] } })
    setYearFilter(year);
  }

  const fetchStatusOptions = () => {
    DataService.post('leave/column/master?column=status', {}).then(resp => {
      let data = Bro(resp).iCanHaz("data.data");
      data.shift();
      setStatusOptions(data);
    })
  }

  useEffect(() => {
    if (gridApi) {
      setTimeout(() => {
        gridApi.setFilterModel({ "status": { filterType: "set", values: [status] } })
        setYearFilter(year);
      }, 500);
      fetchStatusOptions();
    }
  }, [gridApi])
  return (
    <Flex justify="flex-end">
      <HStack spacing={4}>
        {props.children}
        {<>
          <Box w="120px">
            <DatePicker
              selected={year}
              maxDate={moment().clone().add(1, 'year').endOf().valueOf()}
              minDate={moment().clone().subtract(2, 'year').startOf().valueOf()}
              onChange={onYearChange}
              placeholderText="Select Year"
              yearItemNumber={3}
              dateFormat="yyyy"
              showYearPicker
            />
          </Box>
          <Box minWidth="120px" mt={1}>
            <SelectThemeInput
              mb={2}
              placeholder="Status"
              options={statusOptions}
              value={status}
              onChange={onStatusChange}
              labelKey="label"
              valueKey="key"
              type="text"
            />
          </Box>
        </>
        }
      </HStack>
    </Flex>
  );
}

const LeaveList = () => {
  const { t } = useI18nContext();
  const toast = useNotify();
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });

  const leaveStatusOptions = storage.get(AppConstants.AUTH.CONFIG).leaveStatus;

  const transformColDef = fields => {
    const modifiedColDef = fields.map(field => {
      const { cellRenderer, ...rest } = field;
      switch (field.colId) {
        case 'fullName':
          field.sortable = false;
          field.filter = false;
          return field;
        case 'leaveRaisedBy':
          field.sortable = false;
          field.filter = false;
          return field;
        case 'leaveType':
          field.sortable = false;
          field.filter = false;
          return field;
        case 'userId':
          return {
            ...rest,
            cellRendererFramework: params => {
              return (
                <Flex justifyContent="flex-start">
                  <Box
                  >
                    {params.data.status === AppConstants.REGULARISATION_STATUS.APPROVED && <ApprovedIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.PENDING && <PendingIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.CANCELLED && <CancelledIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.REJECTED && <RejectIcon />}
                  </Box>
                  <Box ml="10px">
                    <Link
                      to={`/home/leave/user/${params.data.userSlug}`}
                      className="link"
                    >
                      {params.value ? params.value : ''}
                    </Link>
                  </Box>
                </Flex>
              );
            },
          };

        case 'startTime':
        case 'endTime':
          return {
            ...rest,
            filter: false,
            cellRendererFramework: params => {
              return getDateFormatter(params.value, 'DD/MM/YYYY');
            },
          };

        case 'noOfDays':
          return {
            ...rest,
            filter: false,
            cellRendererFramework: params => {
              return params.value + " day" + pluralizer(params.value);
            },
          };

        case 'status':
          return {
            ...rest,
            cellRendererFramework: params => {
              return (<LeaveStatusSelect
                data={Bro(params).iCanHaz('data')}
                defaultValue={Bro(params).iCanHaz('data.status')}
                options={leaveStatusOptions}
              />);
            },
          };

        case 'slug':
          return {
            ...rest,
            filter: false,
            cellRendererFramework: params => {
              return (
                <Center>
                  <Link
                    to={`/home/leave/view-leave/${params.value}`}
                  >
                    <Icon as={SvgEye} />
                  </Link>
                </Center>
              );
            },
          }

        default:
          return field;
      }
    }).filter(d => !!d);

    return modifiedColDef;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const Title = () => (
    <PageTitle style={{ mb: 4 }} title={t('leave_page_title')}
    />
  );

  return (
    <Box>
      <DataGrid
        gridProps={{ ...defaultGridOptions, transformColDef }}
        beans={{ restApi, storage, i18n: t, notificationService: simpleToast }}
        dataProps={{ collection: 'leave', csvName: 'leave' }}
        uiComponents={{
          Title: Title,
          ActionContainer: LeaveActionContainer,
          Button: GridButton,
          Input: GridInput,
          hideEdit: true,
          hideExport: true,
          hideImport: true,
          hideAddNew: true,
          hideSearch: true
        }}
      />
      <Legends />
    </Box>
  );
};

export default LeaveList;
