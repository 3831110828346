import React from 'react';

const ForwardArrow = () => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.58317 7.91659H12.4548L9.12734 11.9133C9.05029 12.0059 8.99226 12.1129 8.95656 12.228C8.92085 12.3431 8.90817 12.4642 8.91923 12.5842C8.94158 12.8266 9.0593 13.0502 9.2465 13.2058C9.4337 13.3613 9.67504 13.4362 9.91743 13.4139C10.1598 13.3915 10.3834 13.2738 10.539 13.0866L15.1223 7.58659C15.1532 7.54284 15.1807 7.49688 15.2048 7.44909C15.2048 7.40325 15.2507 7.37575 15.269 7.32992C15.3106 7.22482 15.3323 7.11294 15.3332 6.99992C15.3323 6.88691 15.3106 6.77502 15.269 6.66992C15.269 6.62409 15.2232 6.59659 15.2048 6.55075C15.1807 6.50296 15.1532 6.457 15.1223 6.41325L10.539 0.913254C10.4528 0.809778 10.3449 0.726563 10.2229 0.669527C10.1009 0.612491 9.96784 0.583035 9.83317 0.583254C9.61899 0.582835 9.41142 0.657429 9.2465 0.794087C9.15368 0.87104 9.07696 0.965549 9.02072 1.0722C8.96448 1.17885 8.92983 1.29555 8.91876 1.41561C8.90769 1.53568 8.92042 1.65674 8.95621 1.77188C8.992 1.88701 9.05015 1.99396 9.12734 2.08659L12.4548 6.08325H1.58317C1.34006 6.08325 1.1069 6.17983 0.93499 6.35174C0.763081 6.52365 0.666504 6.75681 0.666504 6.99992C0.666504 7.24304 0.763081 7.47619 0.93499 7.6481C1.1069 7.82001 1.34006 7.91659 1.58317 7.91659Z"
        fill="white"
      />
    </svg>
  );
};
export default ForwardArrow;
