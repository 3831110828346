import {
  FormLabel,
  FormControl,
  FormErrorMessage,
  Textarea,
} from '@chakra-ui/react';
import React from 'react';

const ThemeTextAreaInput = ({
  name,
  register,
  label,
  error,
  isRequired,
  ...rest
}) => {
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
      <FormLabel>{label}</FormLabel>
      <Textarea
        name={name}
        focusBorderColor="brand.300"
        ref={register}
        {...rest}
      />
      {error && (
        <FormErrorMessage fontFamily="Open Sans" fontSize="12px">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default ThemeTextAreaInput;
