import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const TrashIcon = props => (
  <Icon width="100%" height="100%" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M21 6h-5V4.33A2.42 2.42 0 0013.5 2h-3A2.42 2.42 0 008 4.33V6H3a1 1 0 100 2h1v11a3 3 0 003 3h10a3 3 0 003-3V8h1a1 1 0 100-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4V4.33zM18 19a1 1 0 01-1 1H7a1 1 0 01-1-1V8h12v11z"
      fill="#CF142B"
    />
    <path
      d="M9 17a1 1 0 001-1v-4a1 1 0 10-2 0v4a1 1 0 001 1zM15 17a1 1 0 001-1v-4a1 1 0 00-2 0v4a1 1 0 001 1z"
      fill="#CF142B"
    />
  </Icon>
);

export default TrashIcon;
