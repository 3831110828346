import React from 'react';
const UploadSvg=(props)=>{
    return(
        <svg
      width={54}
      height={54}
      viewBox="0 0 54 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.598 25.402a2.25 2.25 0 00-3.15 0l-6.75 6.525a2.25 2.25 0 103.105 3.24l2.947-2.857V45a2.25 2.25 0 104.5 0V32.422l2.903 2.925a2.248 2.248 0 003.195 0 2.253 2.253 0 000-3.195l-6.75-6.75z"
        fill="#0461FF"
      />
      <path
        d="M39.757 15.75a13.5 13.5 0 00-25.515 0 11.251 11.251 0 00-6.93 18.608 2.25 2.25 0 103.375-2.857 6.75 6.75 0 015.063-11.25h.225a2.25 2.25 0 002.25-1.8 9 9 0 0117.64 0 2.25 2.25 0 002.25 1.8h.135a6.75 6.75 0 015.062 11.25 2.25 2.25 0 00.203 3.195 2.25 2.25 0 002.411.362 2.25 2.25 0 00.761-.565 11.25 11.25 0 00-6.93-18.742z"
        fill="#C6DAFF"
      />
    </svg>
    )
}
export default UploadSvg;