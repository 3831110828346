import { storage } from '@bit/sixsprints.core.beans-web';
import React, { useEffect, useState } from 'react';
import { AppConstants } from '../../utils/constants';
import AdminDashboard from '../admin-dashboard/adminDashboard';
import DashboardHome from './dashboard-home';

const MainDashboard = () => {
  const [roleName, setRoleName] = useState(null);

  useEffect(() => {
    const role = storage.get(AppConstants.AUTH.ROLE);
    setRoleName(role);
  }, []);
  return roleName === 'USER' ? <DashboardHome /> : <AdminDashboard />;
};

export default MainDashboard;
