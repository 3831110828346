import { useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, GridItem, Stack } from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect, useState } from 'react';
import MultiSelect from '../../../../components/form-elements/multi-select/multi-select';
import ThemeAsyncSelectInput from '../../../../components/form-elements/theme-async-select-input';
import ThemeInput from '../../../../components/form-elements/theme-input';
import PageTitle from '../../../../components/misc/page-title';
import DataService from '../../../../utils/data-service';
import { getSelectedCountryFromList, getSelectedTimeZone } from '../../../../utils/helpers';
import useNotify from '../../../../utils/notifications';
import { Country } from '../country/country-data';

const postOptions = {
  url: '/state',
  method: 'post',
};

const putOptions = {
  url: '/state/update',
  method: 'put',
};

const AddState = () => {
  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
  const navigate = useNavigate();
  const toast = useNotify();
  const { t, languages, langCode } = useI18nContext();
  const langList = Object.keys(languages);
  const stateData = useLocation().state;

  const { slug } = useParams();
  const isEdit = slug ? true : false;

  const transformRequest = values => {
    let data = { ...values };
    data.countrySlug = values.countrySlug.slug;
    let obj = isEdit ? {...stateData, ...data} : data;
    return obj;
  };

  const onSuccess = resp => {
    toast({
      title: t('add_success'),
      description: resp.message,
      status: 'success',
    });
    navigate(-1);
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const { isLoading, onSubmit, register, control, errors } = useFormController({
    onSuccess,
    onError,
    apiOptions: isEdit ? putOptions : postOptions,
    transformRequest,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const transformCountryList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.country[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptions = async () => {
    let data = await DataService.post('/country/search', {
      page: 0,
      size: 10,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformCountryList(data);
    return data;
  };

  useEffect(() => {
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode])


  return (
    <Flex flex={1} direction="column">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle title={ isEdit ? t('edit_state') : t('add_state')} />
      </Flex>
      <Box
        as={'form'}
        onSubmit={onSubmit}
        noValidate="noValidate"
        bgColor="white"
        borderRadius="16px"
      >
        <Stack>
          <Grid templateColumns="repeat(6, 1fr)" p={10} gap={8}>
            {langList.map(item => (
              <GridItem key={item} colSpan={2}>
                <ThemeInput
                  defaultValue={isEdit ? stateData.state[`${item}`] : null}
                  label={`${t('field_state')} - ${item.toUpperCase()}`}
                  name={`state.${item}`}
                  type="text"
                  error={Bro(errors).iCanHaz(`state.${item}`)}
                  register={register}
                  mt={1}
                  isRequired
                />
              </GridItem>
            ))}
            <GridItem colSpan={2}>
              <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue={isEdit ? {name: stateData.country, slug: stateData.countrySlug} : null}
                promiseOptions={promiseOptions}
                labelKey="name"
                valueKey="slug"
                name="countrySlug"
                control={control}
                error={errors.countrySlug}
                label={t('field_select_country')}
                placeholder={<SearchIcon />}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <MultiSelect
                name={'timeZone'}
                label={t('field_time_zone')}
                placeholder={t('select_timeZone')}
                error={Bro(errors).iCanHaz('timeZone')}
                options={Country.timeZones}
                defaultValue={
                  isEdit ? getSelectedTimeZone(stateData.timeZone) : null
                }
                labelKey={'name'}
                valueKey={'name'}
                control={control}
                isMulti={false}
                isRequired
              />
            </GridItem>
          </Grid>
        </Stack>
        <Flex px={10} pb={10}>
          <Button type="submit" isLoading={isLoading} mr={10}>
            {t('btn_submit')}
          </Button>
          <Button onClick={onCancel} variant="ghost">
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AddState;
