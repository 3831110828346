import { storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { Link, useNavigate } from '@reach/router';
import React from 'react';
import LangSelect from '../../components/misc/lang-select';
import ProfileSvg from '../../components/misc/profile-svg';
import LogoutIcon from '../../icons/dashboard/logout';
import ROUTES from '../../pages/routes';
import { loginSubject } from '../../services/data-service';
import Bro from 'brototype';
import { Keys } from '@bit/sixsprints.utils.data';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { AppConstants } from '../../utils/constants';

const Header = () => {
  const navigate = useNavigate();
  const { t } = useI18nContext();
  const { personalInfo } = Bro(storage.get(Keys.USER)).iCanHaz('data')
    ? Bro(storage.get(Keys.USER)).iCanHaz('data')
    : storage.get(Keys.USER);

  const onLogout = () => {
    storage.remove(Keys.TOKEN);
    storage.remove(Keys.USER);
    storage.remove(AppConstants.AUTH.PERMISSION);
    storage.remove(AppConstants.AUTH.ROLE);
    loginSubject.next(false);
    navigate(ROUTES.LOGIN, { replace: true });
  };

  return (
    <Flex mb={4}>
      <Spacer />
      <HStack spacing="1.2em">
        {/* <Box py="4px" px={'12px'} background="#FFFFFF" borderRadius="10px">
          <Flex>
            <Text
              color="#0461FF"
              fontWeight="600"
              fontSize="0.8rem"
              ml="4px"
              lineHeight="26px"
            >
              Admin
            </Text>

            <Spacer />

            <Image src={down} />
          </Flex>
        </Box> */}

        <LangSelect />

        {/* <Image src={notification} /> */}

        <Menu>
          <MenuButton>
            <Flex direction="row" alignItems="center">
              {/* <Wrap>
                <WrapItem>
                  <Avatar
                    name={Bro(personalInfo).iCanHaz('firstName')}
                    src=""
                    bgColor="#D0D0D0"
                  />
                </WrapItem>
              </Wrap> */}
              <Text
                fontSize="14px"
                fontFamily="Open Sans"
                fontWeight="600"
                lineHeight="19px"
                ml={5}
              >
                {Bro(personalInfo).iCanHaz('firstName')}
              </Text>
              <Icon ml={2} mt={1} as={ChevronDownIcon} w={5} h={5} />
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem>
              <Box>
                <Center>
                  <Link to={ROUTES.USER_PROFILE} replace>
                    <Flex alignItems="center">
                      <Box height="1.5rem" mr="10px">
                        <Icon as={ProfileSvg} />
                      </Box>
                      <Text
                        fontSize="md"
                        fontFamily="Open Sans"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="26px"
                        letterSpacing="0.03em"
                      >
                        {t('nav_profile')}
                      </Text>
                    </Flex>
                  </Link>
                </Center>
              </Box>
            </MenuItem>
            <MenuItem onClick={onLogout}>
              <Box>
                <Center>
                  <Link to={ROUTES.LOGIN} replace>
                    <Flex alignItems="center">
                      <LogoutIcon boxSize={6} ml="5px" mr="10px" />
                      <Text
                        fontSize="md"
                        fontFamily="Open Sans"
                        fontStyle="normal"
                        fontWeight="400"
                        lineHeight="26px"
                        letterSpacing="0.03em"
                      >
                        {t('nav_log_out')}
                      </Text>
                    </Flex>
                  </Link>
                </Center>
              </Box>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </Flex>
  );
};

export default Header;
