import { FormLabel, FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import React from "react";
import AsyncAutoComplete from "./async-autocomplete";
const ThemeAsyncSelectInput = ({
  name,
  control,
  defaultValue,
  label,
  error,
  isRequired,
  valueKey,
  labelKey,
  labelIdKey,
  options,
  onChangeAsyn,
  placeholder,
  promiseOptions,
  showKeyInLabel,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange }) => {
        return (
          <FormControl  id={name} isInvalid={!!error} isRequired={isRequired}>
            <FormLabel mb={"1rem"}>{label}</FormLabel>
            <AsyncAutoComplete
              promiseOptions={promiseOptions}
              showKeyInLabel={showKeyInLabel}
              placeholder={placeholder}
              labelKey={labelKey}
              labelIdKey={labelIdKey}
              valueKey={valueKey}
              defaultValue={defaultValue}
              onChange={(value) => {
                onChange(value);
                if(onChangeAsyn){
                  onChangeAsyn(value)
                }
              }}
              {...rest}
            />
            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
          </FormControl>
        );
      }}
    />
  );
};

export default ThemeAsyncSelectInput;
