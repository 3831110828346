import React, { useEffect } from 'react';
import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  defaultGridOptions,
} from '@bit/sixsprints.utils.kg-grid';
import { Flex, Heading } from '@chakra-ui/react';
import Bro from 'brototype';
import {
    ActionContainer,
    GridButton,
    GridInput,
  } from '../../../../components/misc/grid-ui';
import 'rodal/lib/rodal.css';
import useNotify from '../../../../utils/notifications';
import ROUTES from '../../../routes';
import { Link } from '@reach/router';


const ManageDocumentList=()=>{
    const { t } = useI18nContext();
    const toast = useNotify();
    const simpleToast = (msg, status = 'success') =>
      toast({
        description: Bro(msg).iCanHaz('response.data.message') || msg,
        status,
      });

  
    const transformColDef = fields => {
      const modifiedColDef = fields
        .map(field => {
          const { cellRenderer, ...rest } = field;
          switch (field.colId) {
            case 'documentName.en':
              return {
                ...rest,
                cellRendererFramework: params => {
                  return (
                    <Link
                      to={`${params.data.slug}`}
                      state={params.data}
                      className="link"
                    >
                      {params.value ? params.value : ''}
                    </Link>
                  );
                },
              }
            default:
              return field;
  
          }
        })
        .filter(d => !!d);
      return modifiedColDef;
    };
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
  
    const PageTitle = () => (
      <Heading as="h3" fontSize="24px" mb={4} fontWeight="600">
        {t('manage_document_list')}
      </Heading>
    );
    return (
        <Flex flex={1} direction="column">
        <DataGrid
          gridProps={{ ...defaultGridOptions, transformColDef }}
          beans={{ restApi, storage, i18n: t, notificationService: simpleToast }}
          dataProps={{ collection: 'documentList', csvName: 'documentList' }}
          uiComponents={{
            Title: PageTitle,
            ActionContainer,
            Button: GridButton,
            Input: GridInput,
            hideEdit: true,
            hideImport:true,
            hideExport:true,
            hideReset: true,
            hideColumnState: true,
            hideAddNew: false,
            addNewPath: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_DOCUMENT_LIST,
          }}
        />
      </Flex>
    )
}
export default ManageDocumentList;