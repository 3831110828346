import React, { useEffect } from 'react';
import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DataGrid, {
  defaultGridOptions
} from '@bit/sixsprints.utils.kg-grid';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import Bro from 'brototype';
import {
  ActionContainer,
  GridButton,
  GridInput,
} from '../../components/misc/grid-ui';
import useNotify from '../../utils/notifications';
import 'rodal/lib/rodal.css';
import { AppConstants } from '../../utils/constants';
import { getDateFormatter, getUser } from '../../utils/helpers';
import ROUTES from '../routes';
import PageTitle from '../../components/misc/page-title';
import ApprovedIcon from '../../icons/check-circle';
import CancelledIcon from '../../icons/banned-circle';
import RejectIcon from '../../icons/cross-circle';
import PendingIcon from '../../icons/pending-circle';
import Legends from './legends';
import { Link } from '@reach/router';


const AttnRegularisationList = () => {
  const { t } = useI18nContext();
  const toast = useNotify();
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });

  const role = storage.get(AppConstants.AUTH.ROLE);
  const isAdmin = role === AppConstants.ROLE.ADMIN;
  var visibilityFilter = null;
  if (!isAdmin) {
    const { slug } = getUser();
    visibilityFilter = { "userSlug": { values: [slug], filterType: "set" } };
  }


  const transformColDef = fields => {
    const modifiedColDef = fields.map(field => {
      const { cellRenderer, ...rest } = field;
      switch (field.colId) {
        case 'slug':
          return {
            ...rest,
            filter: false,
            cellRendererFramework: params => {
              return (
                <Flex justifyContent="flex-start">
                  <Box
                  >
                    {params.data.status === AppConstants.REGULARISATION_STATUS.APPROVED && <ApprovedIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.PENDING && <PendingIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.CANCELLED && <CancelledIcon />}
                    {params.data.status === AppConstants.REGULARISATION_STATUS.REJECTED && <RejectIcon />}
                  </Box>
                  <Box ml="10px">
                    {params.data.status === AppConstants.REGULARISATION_STATUS.PENDING ?
                      <Link
                        to={`/home/attendance/regularisation/request/${Bro(params).iCanHaz(
                          'data.slug'
                        )}`}
                        className="link"
                        state={{
                          data: params.data ? params.data : {},
                        }}
                      >
                        {params.value ? params.value : ''}
                      </Link> : params.value}
                  </Box>
                </Flex>
              );
            },
          };
        case 'reason':
          return {
            ...rest,
            filter: false,
            cellRendererFramework: params => {
              return (
                <Tooltip hasArrow label={params.value} bg="white" color="black" placement="left">
                  {params.value}
                </Tooltip>
              );
            },
          };

        case 'fromDate':
        case 'toDate':
          return {
            ...rest,
            filter: false,
            cellRendererFramework: params => {
              return getDateFormatter(params.value, 'DD/MM/YYYY');
            },
          };
        case 'status':
          field.filter = true;
          return field;
        default:
          field.filter = false;
          return field;
      }
    }).filter(d => !!d);

    return modifiedColDef;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const title = () => <PageTitle title={t('regularisation_requests')} />;

  return (
    <Flex flex={1} direction="column">
      <DataGrid
        gridProps={{ ...defaultGridOptions, transformColDef }}
        beans={{ restApi, storage, i18n: t, notificationService: simpleToast, defaultFilter: visibilityFilter }}
        dataProps={{ collection: 'attendance/regularization', csvName: 'attendance-regularization' }}
        uiComponents={{
          Title: title,
          ActionContainer,
          Button: GridButton,
          Input: GridInput,
          hideEdit: true,
          hideColumnState: true,
          hideExport: true,
          hideImport: true,
          hideDelete: true,
          addNewBtnText: t('apply_regularisation'),
          addNewPath: ROUTES.DASHBOARD + ROUTES.ATTENDANCE + ROUTES.REGULARISATION_REQUEST,
        }}
      />
      <Legends />

    </Flex>
  );
};

export default AttnRegularisationList;
