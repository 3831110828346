import { Heading, Box } from '@chakra-ui/react';
import React from 'react';

const ThemeHeading = ({ heading, subHeading, style,...rest }) => {
  return (
    <Box {...rest}>
       <Heading as="h3"fontFamily="Raleway" fontSize="24px" fontWeight="600"  {...style}>
        {heading}
      </Heading>
      {subHeading && (
        <Heading
          as=""
          fontSize="sm"
          mt={2}
          fontWeight="normal"
          alignSelf="flex-end"
          fontFamily="Raleway"
          color="black.300"
        >
          {subHeading}
        </Heading>
      )}
    </Box>
  );
};

export default ThemeHeading;
