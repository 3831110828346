import React, { useEffect, useState } from 'react';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Flex, Table, Th, Tr, Thead, Tbody, Box, Center, Text, Td } from '@chakra-ui/react';
import Bro from 'brototype';
import useNotify from '../../utils/notifications';
import colors from '../../theme/foundations/colors';
import DataService from '../../services/data-service';
import { getUser } from '../../utils/helpers';
import { useParams } from '@reach/router';
import Loader from '../../components/misc/loader';
import PageTitle from '../../components/misc/page-title';

const LeaveStatus = () => {
  const { t } = useI18nContext();
  const [ data, setData ] = useState(null);
  const toast = useNotify();

  var { slug } = useParams();
  if(!slug) {
    slug = getUser()['slug'];
  }

  const getUserLeaves = () => {
    DataService.get(`/userLeaveMaster/${slug}/date/${new Date().getTime()}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data');
        setData(data);
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserLeaves();
  }, []);

  return data === null ?
  (<Flex flex={1} direction="column">
      <PageTitle
        title={`Leave Status`}
      />
      <Center>
      <Loader />
      </Center>
    </Flex>
  ) :
  (
    <Flex flex={1} direction="column">
      <PageTitle
        title={t('leave_status')+' '+`(${data.firstName} ${data.lastName})`}
      />
      <Box my={5}>{t('total_leaves_label')}: {data.totalLeaves} &nbsp;&nbsp; {t('rem_leaves_label')}: {data.remainingLeaves}</Box>
      <Box borderRadius="16px" overflow="hidden" bg="white">
      <Table variant="striped" colorScheme="strip">
        <Thead>
          <Tr p={5} background={ colors.brand['100'] }>
            <Td><Text fontSize="13px" fontWeight="700">{t('leave_name_label')}</Text></Td>
            <Td><Text fontSize="13px" fontWeight="700">{t('year_start_bal_label')}</Text></Td>
            <Td><Text fontSize="13px" fontWeight="700">{t('leaves_curr_year_label')}</Text></Td>
            <Td><Text fontSize="13px" fontWeight="700">{t('leave_avail_year_label')}</Text></Td>
            <Td><Text fontSize="13px" fontWeight="700">{t('curr_leave_bal_label')}</Text></Td>
          </Tr>
        </Thead>
        <Tbody>
         
          {data.userLeaveRules.map((d,i) =>
          (<Tr key={i}>
            <td style={{ padding: 10,paddingLeft:20,fontSize:'14px' }}>{d.leaveName}</td>
            <td style={{ padding: 10,paddingLeft:45,fontSize:'14px' }}>{d.yearStartingCount}</td>
            <td style={{ padding: 10,paddingLeft:40,fontSize:'14px' }}>{d.yearCount}</td>
            <td style={{ padding: 10,paddingLeft:40,fontSize:'14px' }}>{d.totalLeaves - d.remainingLeaves}</td>
            <td style={{ padding: 10,paddingLeft:45,fontSize:'14px'}}>{d.remainingLeaves}</td>
          </Tr>))}
        </Tbody>
      </Table>
      </Box>
    </Flex>
  );
};

export default LeaveStatus;
