import { useEffect } from 'react';
import { getDateFormatter } from '../../../../utils/helpers';

const DateRenderer = ({ name, register, value }) => {
  useEffect(() => {
    register(name);
  }, []);

  return getDateFormatter(value, 'DD/MM/yyyy') || 'N/A';
};

export default DateRenderer;
