import * as React from "react";
import { Icon } from "@chakra-ui/react"


function CrossCircle(props) {
  return (
    <Icon
    width={5}
    height={5}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
     <path
        d="M10.5 1.75a8.75 8.75 0 100 17.5 8.75 8.75 0 000-17.5zm0 15.75a7 7 0 110-14 7 7 0 010 14z"
        fill="#EB0808"
      />
      <path
        d="M12.871 8.129a.874.874 0 00-1.242 0L10.5 9.266 9.371 8.13A.879.879 0 108.13 9.37L9.266 10.5 8.13 11.629a.875.875 0 000 1.242.875.875 0 001.242 0l1.13-1.137 1.128 1.137a.876.876 0 001.434-.285.876.876 0 00-.192-.957l-1.137-1.13 1.137-1.128a.875.875 0 000-1.242z"
        fill="#EB0808"
      />
    </Icon>
  )
}

export default CrossCircle;

