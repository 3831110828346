import React from 'react';
import AsyncSelect from 'react-select/async';



const AsyncAutoComplete = ({ placeholder, labelKey, valueKey, onChange, promiseOptions, showKeyInLabel,labelIdKey, ...rest }) => {
   
    return (
        <AsyncSelect
            onChange={val => onChange(val)}
            placeholder={placeholder}
            getOptionLabel={(option) => showKeyInLabel ? `${option[labelKey]} (${option[labelIdKey]})` : option[labelKey]}
            getOptionValue={(option) => option[valueKey]}
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            {...rest}
        />
    )
}

export default AsyncAutoComplete;
