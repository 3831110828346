import React, { useEffect, useState } from 'react';
import { useFormController } from '@bit/sixsprints.core.beans-web';
import { ArrowBackIcon, AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Center,
  Stack,
} from '@chakra-ui/react';
import Bro from 'brototype';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import SelectThemeInput from '../../../../components/form-elements/select-input';
import ThemeInput from '../../../../components/form-elements/theme-input';
import ThemeTextAreaInput from '../../../../components/form-elements/theme-textarea';
import ThemeDateInput from '../../../../components/form-elements/date-picker/date-picker';
import ThemeHeading from '../../../../components/misc/heading';
import ListHeader from '../../../../components/misc/list-header';
import ForwardArrow from '../../../../icons/forward-arrow';
import useNotify from '../../../../utils/notifications';
import { getDateDuration } from '../../../../utils/helpers';
// import { BankValidationSchema } from '../../../../utils/validations';
import DataService from '../../../../services/data-service';
import _ from 'lodash';
import moment from 'moment';
import { WorkExperienceSchema } from '../../../../utils/validations';
import Loader from '../../../../components/misc/loader';

const WorkExperience = ({
  next = () => {},
  prev,
  userSlug,
  isEdit,
  showSkipButton = false,
  userData,
}) => {
  const toast = useNotify();
  const [indexes, setIndexes] = useState([0]);
  const [counter, setCounter] = useState(0);
  const [showSkipBtn, setShowSkipBtn] = useState(showSkipButton);
  const [isDocLoading, setIsDocLoading] = useState(false);

  const { t } = useI18nContext();

  const apiOptions = {
    url: `/user/work-experience?userSlug=${userSlug}`,
    method: 'put',
  };

  const onSuccess = resp => {
    if (!isEdit) {
      toast({
        title: t('work_experience_success_toast'),
        description: resp.message,
        status: 'success',
      });
      next();
    } else {
      toast({
        title: t('work_experience_updated'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const transformRequest = values => {
    const data = { ...values };
    if (!_.isEmpty(data)) {
      _.forEach(data.workExperiences, function (obj,index) {
        _.set(obj, 'duration', getDateDuration(obj.fromDate, obj.toDate)||workExperiences[index] && workExperiences[index]?.duration)
        _.set(
          obj,
          'fromDate',
          moment(obj.fromDate).valueOf()||workExperiences[index].fromDate,
          _.set(obj, 'toDate', moment(obj.toDate).valueOf()||workExperiences[index].toDate),
        );
      });
      return data.workExperiences;
    }
    return [null];
  };
  
  const {
    isLoading,
    onSubmit,
    register,
    errors,
    setValue,
    control,
    reset: resetForm,
    getValues,
    formState:{ isDirty },
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    //  yupSchema: WorkExperienceSchema(t),
    transformRequest,
  });

  const addWorkExperience = () => {
    setIndexes(prevIndexes => [...prevIndexes, counter]);
    setCounter(prevCounter => prevCounter + 1);
  };

  const removeWorkExperience = index => () => {
    setIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    setCounter(prevCounter => prevCounter - 1);
    const currFormValues = getValues();

    resetForm({
      workExperiences: currFormValues.workExperiences.filter(
        (item, _index) => _index !== index
      ),
    });
  };

  const skipWorkExperience = () => {
    setIndexes([]);
    next();
  };
const [workExperiences,setWorkExperiences] = useState(null);

  const getUserInfo = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.workExperienceDto');
        showSkipButton && setShowSkipBtn(true);
        setIndexes(data ? data : [0]);
        setWorkExperiences(data)
        resetForm({
          workExperiences: data,
        });
        setIsDocLoading(false);

      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(getUserInfo, []);

  return (
    (isDocLoading  && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex>
          <Box as={Flex} direction="column" mb={5}>
            {!isEdit ? 
            (<ThemeHeading heading={t('work_experience_heading')} subHeading={t('details_subheading')} mb={10}/>):(<ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
              'edit_profile_header_text'
            )}`} subHeading={t('details_subheading')}/>)}
          </Box>
        </Flex>
        <Center>
          <Loader />
        </Center>
      </Flex>
    )) || (
    <Flex flexGrow={1}>
      <Flex flex={1} direction="column" mr={'10em'}>
        <Flex justifyContent="space-between" alignItems="center" mb={10}>
          {!isEdit ? (
            <ThemeHeading
              heading={t('work_experience_heading')}
              subHeading={t('details_subheading')}
            />
          ) : (
            <ThemeHeading
              heading={`${Bro(userData).iCanHaz('firstName')} ${t(
                'edit_profile_header_text'
              )}`}
              subHeading={t('details_subheading')}
            />
          )}
          <Center>
            <Button
              leftIcon={<AddIcon />}
              onClick={addWorkExperience}
              alignSelf="flex-end"
            >
              {t('button_add')}
            </Button>
            {showSkipBtn && (
              <Button
                ml={5}
                onClick={skipWorkExperience}
                disabled={isEdit}
                alignSelf="flex-end"
              >
                Skip
              </Button>
            )}
          </Center>
        </Flex>
        <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate">
          {indexes.map((item, index) => {
            const fieldName = `workExperiences[${index}]`;
            return (
              <Stack key={index}>
                <ListHeader
                  msg={`${t('label_work_experience_Detail_msg')} ${index + 1}`}
                  index={indexes.length}
                  onBtnClick={removeWorkExperience(index)}
                />
                <Grid templateColumns="repeat(6, 1fr)" gap={6}>
                  <GridItem colSpan={3}>
                    <ThemeInput
                      label={t('label_previous_company_name')}
                      name={`[${fieldName}].companyName`}
                      error={Bro(errors).iCanHaz(
                        `workExperiences.${index}.companyName`
                      )}
                      register={register}
                      type="text"
                      // isRequired
                      mt={1}
                    />
                  </GridItem>
                  <GridItem colSpan={3}>
                    <ThemeInput
                      label={t('label_job_title')}
                      name={`[${fieldName}].jobTitle`}
                      error={Bro(errors).iCanHaz(
                        `workExperiences.${index}.jobTitle`
                      )}
                      register={register}
                      type="text"
                      // isRequired
                      mt={1}
                    />
                  </GridItem>

                  <GridItem colSpan={2} mt={3}>
                    <ThemeDateInput
                      label={t('label_from_date')}
                      name={`[${fieldName}].fromDate`}
                      defaultValue=""
                      error={Bro(errors).iCanHaz(
                        `workExperiences.${index}.fromDate`
                      )}
                      showTimeSelect={false}
                      dateFormat="yyyy/MM/dd"
                      control={control}
                      mt={1}
                      // isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <ThemeDateInput
                      label={t('label_to_date')}
                      name={`[${fieldName}].toDate`}
                      defaultValue=""
                      error={Bro(errors).iCanHaz(
                        `workExperiences.${index}.toDate`
                      )}
                      showTimeSelect={false}
                      dateFormat="yyyy/MM/dd"
                      control={control}
                      mt={1}
                      // isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={2} mt={3}>
                    <SelectThemeInput
                      options={[
                        { label: 'Yes', key: 'YES' },
                        { label: 'No', key: 'NO' },
                      ]}
                      labelKey="label"
                      valueKey="key"
                      label={t('label_relevance')}
                      name={`[${fieldName}].relevance`}
                      error={Bro(errors).iCanHaz(
                        `workExperiences.${index}.relevance`
                      )}
                      register={register}
                      placeholder={t('select_an_option')}
                      mt="1.1rem"
                    />
                  </GridItem>

                  <GridItem colSpan={6} mt={3}>
                    <ThemeTextAreaInput
                      label={t('label_job_description')}
                      name={`[${fieldName}].jobDescription`}
                      error={Bro(errors).iCanHaz(
                        `workExperiences.${index}.jobDescription`
                      )}
                      register={register}
                      type="text"
                      mt={1}
                    />
                  </GridItem>
                </Grid>
              </Stack>
            );
          })}
          <Flex justifyContent="space-between" alignItems="center" my={14}>
            {isEdit ? (
              <Flex flex={1} justifyContent={'flex-end'}>
                <Button
                  align="flex-end"
                  type="submit"
                  px={10}
                  isLoading={isLoading}
                  disabled={workExperiences && workExperiences.length>0 ? false : !isDirty}
                >
                  {t('button_text_save')}
                </Button>
              </Flex>
            ) : (
              <>
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="ghost"
                  onClick={prev}
                >
                  {t('button_text_back')}
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  rightIcon={<ForwardArrow />}
                  disabled={workExperiences && workExperiences.length>0 ? false : !isDirty}
                >
                  {t('button_text_save_next')}
                </Button>
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  ));
};
export default WorkExperience;
