import {
  Box,
  Center,
  Flex,
  List,
  ListItem,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import ROUTES from '../../pages/routes';
import NavLink from './nav-link';

const StepsWithLabel = ({ onboardingStep, currentStep, item, onClick }) => (
  <Box
    direction="row"
    alignItems="center"
    mt={4}
    ml={'4.5vw'}
    onClick={() => {
      if (onboardingStep >= item.step) {
        onClick(item.step);
      }
    }}
  >
    <Text
      fontFamily="Open Sans"
      fontWeight="bold"
      fontSize="sm"
      color={currentStep === item.step ? 'brand.500' : '#818081'}
      lineHeight="md"
      cursor="pointer"
    >
      {item.label}
    </Text>
  </Box>
);

const StepWithNumber = ({
  onboardingStep,
  currentStep,
  item,
  index,
  onClick,
}) => (
  <Box w="16vw" alignItems="center" mb={4}>
    <Flex direction="row" alignItems="center">
      <Flex
        border="2px"
        borderColor={currentStep === item.step ? 'brand.500' : '#818081'}
        borderRadius="50px"
        h="36px"
        w="36px"
        justifyContent="center"
        alignItems="center"
      >
        <Text
          alignSelf="center"
          fontSize="md"
          fontFamily="Open Sans"
          fontStyle="normal"
          fontWeight="600"
          lineHeight="26px"
          color={currentStep === item.step ? 'brand.500' : '#818081'}
          letterSpacing="0.02em"
          textAlign="left"
        >
          {index + 1}
        </Text>
      </Flex>
      <Text
        onClick={() => {
          if (onboardingStep >= item.step) {
            onClick(item.step);
          }
        }}
        ml="22px"
        fontSize="md"
        fontFamily="Open Sans"
        fontStyle="normal"
        fontWeight="600"
        lineHeight="26px"
        color={currentStep === item.step ? 'brand.500' : '#818081'}
        letterSpacing="0.02em"
        textAlign="left"
        cursor="pointer"
      >
        {item.label}
      </Text>
    </Flex>
    {(item.steps || []).map((item, idx) => (
      <StepsWithLabel
        onboardingStep={onboardingStep}
        currentStep={currentStep}
        item={item}
        onClick={onClick}
        key={idx}
      />
    ))}
  </Box>
);

const SideBar = ({ onboardingStep, currentStep, stepObj, onClick }) => {
  return (
    <Flex direction="column">
      <Box w="16vw" ml={'3vw'}>
        {stepObj.map((step, idx) => (
          <StepWithNumber
            onboardingStep={onboardingStep}
            currentStep={currentStep}
            item={step}
            onClick={onClick}
            index={idx}
            key={idx}
          />
        ))}
      </Box>
      <Spacer />
      <List fontSize={'md'}>
        <Box mt="60px">
          <Box>
            <ListItem>
              <Center>
                <NavLink to={ROUTES.LOGIN} replace></NavLink>
              </Center>
            </ListItem>
          </Box>
        </Box>
      </List>
    </Flex>
  );
};

export default SideBar;
