import { storage, useFormController } from '@bit/sixsprints.core.beans-web';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, Grid, GridItem, Heading, Stack } from '@chakra-ui/react';
import Bro from 'brototype';
import moment from 'moment';
import { useEffect, useState } from 'react';
import ThemeDateInput from '../../../../../components/form-elements/date-picker/date-picker';
import DocumentUploadCertificate from '../../../../../components/form-elements/document-uploader';
import SelectThemeInput from '../../../../../components/form-elements/select-input';
import ThemeInput from '../../../../../components/form-elements/theme-input';
import ListHeader from '../../../../../components/misc/list-header';
import Loader from '../../../../../components/misc/loader';
import ForwardArrow from '../../../../../icons/forward-arrow';
import DataService from '../../../../../services/data-service';
import { AppConstants } from '../../../../../utils/constants';
import useNotify from '../../../../../utils/notifications';
import { SchoolInfoSchema } from '../../../../../utils/validations';

const TwelfthInfo = ({ t, prev, next,slug,isEdit }) => {
  const toast = useNotify();
  const [schoolBoard,setSchoolBoard] = useState(null);
  const [schoolMedium,setSchoolMedium] = useState(null);


  const [documentsIndexes, setDocumentsIndexes] = useState([0]);
  const [documentsCounter, setDocumentsCounter] = useState(0);

  const apiOptions = {
    url: `/user/education-details-twelfth?userSlug=${slug}`,
    method: 'put',
  };

  const onSuccess = resp => {
    if (!isEdit) {
      // const userData = storage.get(Keys.USER);
      // userData.personalInfo = userInfo;
      // storage.set(Keys.USER, userData);
      toast({
        title: t('twelth_details_success_toast'),
        description: resp.message,
        status: 'success',
      });
      next();
    }else{
      toast({
        title: t('twelth_details_updated_toast'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };
  


  const transformRequest = values => {
    const data={...values };
    data.passingYear = moment(values.passingYear).format('YYYY');
    return data; 
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };
  
  const { 
    isLoading,
    onSubmit,
    register,
    control,
    errors,
    setValue,
    getValues,
    reset: resetForm, } = useFormController({
      onSuccess,
      onError,
      apiOptions,
      yupSchema: SchoolInfoSchema(t),
      transformRequest,});
  
  useEffect(() => {
    let schoolBoardArr = storage.get(AppConstants.AUTH.CONFIG).schoolBoard;
    let schoolMediumArr = storage.get(AppConstants.AUTH.CONFIG).schoolMedium;
    setSchoolBoard(schoolBoardArr);
    setSchoolMedium(schoolMediumArr);
  }, []);


  const [userInfo, setUserInfo] = useState(null);
  const [isDocLoading, setIsDocLoading] = useState(false);

  const getUserInfo = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${slug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.educationDetails.details12');
        if (Bro(data).iCanHaz('passingYear')) {
          data.passingYear = moment(data.passingYear).isValid()
            ? moment(data.passingYear.toString()).toDate()
            : null;
        }
        setDocumentsIndexes(data.documents.length > 0 ? data.documents : [0]);
        setValue('documents', data.documents);
        setUserInfo(data);
        resetForm(data);
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  const addDocuments = () => {
    setDocumentsIndexes(prevIndexes => [...prevIndexes, documentsCounter]);
    setDocumentsCounter(prevCounter => prevCounter + 1);
  };

  const removeDocuments = index => () => {
    setDocumentsIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    setDocumentsCounter(prevCounter => prevCounter - 1);
    const currFormValues = getValues();
    let data = currFormValues.documents.filter(
      (item, _index) => _index !== index
    );
    currFormValues.documents = data;
    resetForm(currFormValues);
  };

  useEffect(getUserInfo, []);

  const documentList=
  [
    {
      label:'12th Marksheet',
      key:'12th_Marksheet',
    },
    {
      label:'12th Certificate',
      key:'12th_Certificate',
    },
    {
      label:'12th Provisonal Certificate',
      key:'12th_Provisonal_Certificate',
    }
  ]
  
  return (
    (isDocLoading && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex>
          <Box as={Flex} direction="column" mb={5}></Box>
        </Flex>
        <Center>
          <Loader />
        </Center>
      </Flex>
    )) || (
    <Flex flexGrow={1}>
      <Flex flex={1} direction="column" mr={'10em'} mt={10}>
      <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate">
          <Grid templateColumns="repeat(6, 1fr)" gap={6}>
            <GridItem colSpan={3}>
              <SelectThemeInput
                options={schoolBoard}
                label={t('board_label')}
                name="board"
                valueKey="key"
                labelKey="label"
                error={errors.board}
                register={register}
                placeholder={t('select_board')}
                type="text"
                mt={5}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={3}>
              <SelectThemeInput
                options={schoolMedium}
                label={t('school_medium_label')}
                name="schoolMedium"
                valueKey="key"
                labelKey="label"
                error={errors.schoolMedium}
                register={register}
                placeholder={t('select_school_medium')}
                type="text"
                mt={5}
                isRequired
              />
            </GridItem>

            <GridItem colSpan={3} mt={3}>
              <ThemeInput
                label={t('percentage_label')}
                name="percentage"
                error={errors.percentage}
                register={register}
                type="number"
                mt={1}
                isRequired
              />
            </GridItem>

            <GridItem colSpan={3} mt={3}>
              <ThemeDateInput
                label={t('label_passing_year')}
                name="passingYear"
                placeholder={t('select_an_option')}
                showTimeSelect={false}
                error={errors.passingYear}
                showYearPicker
                defaultValue={''}
                dateFormat="yyyy"
                //    maxDate={new Date()}
                yearItemNumber={10}
                control={control}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={3} mt={3}>
              <ThemeInput
                label={t('school_college_label')}
                name="schoolName"
                error={errors.schoolName}
                register={register}
                type="text"
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={6} mt={3} mr={'40%'}>
                <Heading as="h5" size="sm" mt={6}>
                  {t('documents_label')}
                </Heading>
                <Stack spacing={0}>
                  {documentsIndexes.map((item, index) => {
                    const fieldName = `documents[${index}]`;
                    return (
                      <Stack key={index} w="100%">
                        {index > 0 && (
                          <ListHeader
                            msg={`${t('document')} ${index + 1}`}
                            index={documentsIndexes.length}
                            onBtnClick={removeDocuments(index)}
                          />
                        )}
                        <Flex direction="row" justifyContent="space-between">
                          <Box w="48%">
                            <SelectThemeInput
                              options={documentList}
                              labelKey="label"
                              valueKey="key"
                              name={`[${fieldName}].documentName`}
                              error={Bro(errors).iCanHaz(
                                `documents.${index}.documentName`
                              )}
                              register={register}
                              type="text"
                              placeholder={t('select_an_option')}
                              mt={4}
                            />
                          </Box>
                          <Box w="50%" pt="1em" pl="0.7em">
                            <DocumentUploadCertificate
                              borderRadius={'12px'}
                              register={register}
                              control={control}
                              defaultValue=""
                              name={`[${fieldName}].url`}
                              url={'file/upload'}
                              error={Bro(errors).iCanHaz(
                                `documents.${index}.url`
                              )}
                              index={index}
                              certificateList={userInfo && userInfo.documents}
                              uploadValue={url =>
                                setValue(`[${fieldName}].url`, url)
                              }
                              //  showViewIcon='true'
                            />
                          </Box>
                        </Flex>
                      </Stack>
                    );
                  })}
                  <Stack display="inline">
                    <Heading
                      as="h5"
                      size="sm"
                      display="inline"
                      onClick={addDocuments}
                      style={{
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      {t('add_more_document_label')}
                    </Heading>
                  </Stack>
                </Stack>
              </GridItem>
          </Grid>
          <Flex justifyContent="space-between" alignItems="center" my={14}>
            {isEdit ? (
              <Flex flex={1} justifyContent={'flex-end'} justifyContent="space-between">
                  <Button
                leftIcon={<ArrowBackIcon />}
                variant="ghost"
                onClick={prev}
              >
                {t('button_text_back')}
              </Button>
                <Button align="flex-end" type="submit" px={10} isLoading={isLoading}>
                {t('button_text_save')}
                </Button>
              </Flex>
            ) : (
            <>
              <Button
                leftIcon={<ArrowBackIcon />}
                variant="ghost"
                onClick={prev}
              >
                {t('button_text_back')}
              </Button>
              <Button
                type="submit"
                isLoading={isLoading}
                rightIcon={<ForwardArrow />}
              >
                {t('button_text_save_next')}
              </Button>
            </>
             )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  ));
};
export default TwelfthInfo;
