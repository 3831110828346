const ja = {
  login_heading: 'ログイン',
  nav_log_out: 'ログアウト',
  nav_profile: 'プロファイル',
  login_subheading: 'アカウントにログインしてください。',
  login_form_label_email: '電子メールアドレス',
  login_form_otp: 'ワンタイムパスワード',
  login_form_label_password: 'パスワード',
  login_form_label_new_password: '新しいパスワード',
  login_form_confirm_new_password: '新しいパスワードを確認',
  login_forgot_password: 'パスワードをお忘れですか？',
  reset_pass_heading: 'パスワードを再設定する',
  forgot_pass_heading: 'パスワードを忘れた',
  set_pass_heading: 'パスワードを設定する',
  otp_required: 'ワンタイムパスワードが必要です。',
  reset_form_label_password: '新しいパスワード',
  reset_retype__form_label_password: '新しいパスワードを再入力する',
  login_submit: 'ログイン',
  login_email_invalid: 'メールアドレスが無効です。',
  form_name_invalid: 'フィールドが無効です。',
  accountNumber_invalid_length_msg: '無効な口座番号です。',
  account_holder_name_required: '口座名義が必要です。',
  resume_required: '履歴書が必要です。',
  graduationDegreeURL_required: '卒業証書が必要です。',
  login_email_required: '電子メールアドレスは空白することはできません。',
  login_password_required: 'パスワードは空白することはできません。',
  login_failed_toast_title: 'ログインに失敗しました。',
  form_field_required: 'フィールドが必要です。',
  dependent_details_heading: '扶養家族の詳細',
  details_subheading: '自分自身の詳細',
  emergency_details_heading: '緊急時の詳細',
  health_details_heading: '健康診断',
  last_name_required: '名字が必要です。',
  first_name_required: '下の名前が必要です。',
  employeeId_prefix_required: '従業員IDのプレフィックスが必要です。',
  employeeId_required: '従業員IDが必要です。',
  gender_required: '性別が必要です。',
  dob_required: '生年月日が必要です。',
  mobile_required: '電話番号が必要です。',
  department_required: '部署が必要です。',
  tou_required: 'ユーザーのタイプが必要です。',
  contract_type_required: '契約のタイプが必要です。',
  date_of_joining_required: '入社日が必要です。',
  positive_message: '正数のみが許可されます。',
  contract_required: '契約のタイプが必要です。',
  doj_required: '入社日が必要です。',
  bank_name_required: '銀行名が必要です。',
  branch_name_required: '支店名が必要です。',
  branch_code_required: '支店コードが必要です。',
  branch_address_required: '支店の住所が必要です。',
  account_type_required: '口座科目が必要です。',
  account_number_required: '口座番号が必要です。',
  employee_name_required: '従業員名が必要です。',
  phone_number_required: '電話番号が必要です。',
  employee_address_required: '従業員の住所が必要です。',
  blood_pressure_required: '血圧が必要です。',
  diabetes_required: '糖尿病が必要です。',
  blood_group_required: '血液型が必要です。',
  allergies_required: 'アレルギーが必要です。',
  occupation_required: '職業が必要です。',
  relationship_required: '関係が必要です。',
  postal_code_required: '郵便番号が必要です。',
  email_required: '電子メールアドレスが必要です。',
  id_required: 'IDが必要です。',
  state_required: '州が必要です。',
  blood_pressure: '血圧',
  diabetes: '糖尿病',
  blood_group: '血液型',
  previous_illness: '過去6ヶ月に病気があれば報告してください。',
  allergies: 'アレルギー',
  medical_certificate: '健康診断書',
  covid_test: 'Covidー19テスト',
  label_last_name: '名字が必要です。',
  label_middle_name: '真ん中の名前',
  label_first_name: '下の名前',
  label_id_prefix: '従業員IDのプレフィックス',
  label_emp_id: '従業員ID',
  label_gender: '性別',
  label_birthday: '生年月日',
  mobile_number: '電話番号',
  label_phone_number: '固定電話番号',
  department: '部署',
  title: '職名',
  type_of_contract: '契約のタイプ',
  date_of_joc: '入社日',
  contract_end_date: '契約終了日',
  button_text_back: '戻る',
  button_text_save_next: '保存して次へ',
  button_text_save: '保存する',
  button_add: '追加する',
  button_remove: '削除',
  label_postal_code: '郵便番号',
  label_state: '州',
  label_address: '住所',
  label_relationship: '関係',
  label_occupation: '職業',
  bank_info_heading: '銀行詳細',
  bank_info_subheading: '銀行詳細を入力してください。',
  label_bank_name: '銀行名',
  label_branch_name: '支店名',
  label_branch_code: '支店コード',
  label_branch_add: '支店所',
  label_acc_type: '口座科目',
  label_acc_no: '口座番号',
  label_emp_name: '従業員名',
  label_tsdate: '転勤開始日',
  label_emp_address: '従業員の住所',
  label_dependent_details_msg: '扶養家族の詳細',
  label_emergency_contact_msg1: '緊急時の詳細',
  label_emergency_contact_msg2: '(国籍の国で）',
  nav_dashboard: 'ダッシュボード',
  nav_user_dashboard: 'ユーザーダッシュボード',
  nav_employee: '従業員',
  nav_payroll: '給与',
  nav_user_management: 'ユーザー管理',
  aggrid_add_btn: '追加する',
  personal_info_head: '個人的な情報',
  personal_info: '個人的な情報',
  personal_info_cap: '個人的な情報',
  personal_info_heading: '個人的な情報',
  document_upload_heading: '書類アップロード',
  address_id_proof: '写真付き身分証明書、パスワード',
  employement_related_document: '雇用関連の文書（以前に雇用された会社の文書）',
  graduation_key: '卒業証書',
  resume_key: '履歴書',
  document_upload_subheading:
    '以下の書類のデジタルコピーをアップロードしてください。',
  label_doc_type: '書類のタイプ',
  placeholder_text_gender: '性別を選択する',
  gender_type_male: '男性',
  gender_type_female: '女性',
  gender_type_others: '他',
  label_phoneticFirstName: 'ふりがなに下の名前',
  label_phoneticMiddleName: 'ふりがなに真ん中の名前',
  label_phoneticLastName: 'ふりがなに名字',
  label_fax: 'ファックス',
  placeholder_text_acc_type: 'オプションを選択する',
  acc_type_current: '当座預金',
  acc_type_savings: '貯蓄口座',
  label_ifsc_code: 'IFSCコード',
  label_swift_code: 'Swiftコード',
  file_upload_text: 'ファイルを選択する',
  placeholder_blood_group: '血液型を選択する',
  blood_group_a_positive: 'A+',
  blood_group_b_positive: 'B+',
  blood_group_ab_positive: 'AB+',
  blood_group_o_positive: 'O+',
  sidebar_bank_info: '銀行詳細',
  sidebar_emergency: '緊急時の連絡先',
  sidebar_dependents: '扶養家族',
  sidebar_health_checkup: '健康診断',
  sidebar_doc_upload: '書類アップロード',
  label_email: '電子メールアドレス',
  label_official_mail: '電子メールアドレス',
  label_official_designation: '職名',
  label_no_file_chosen: 'ファイルは選択されていません。',
  button_text_choose_file: 'ファイルを選択する',
  user_page_title: 'ユーザー管理',
  breadcrumb_home: 'ホーム',
  add_employee_heading: '新従業員を追加する',
  welcome: 'ようこそ',
  description: '説明',
  attendance: '出勤',
  leaves: '休暇',
  team_members: 'チームメンバー',
  my_profile: 'マイプロファイル',
  create_announcement: '社告',
  announcement_add_btn: '追加する',
  announcement_add_heading: '社告を追加する',
  label_title: '題名',
  title_required: '題名が必要です。',
  title_invalid: '題名が無効です。',
  label_type_of_announcement: '社告のタイプ',
  type_of_announcement_required: '社告のタイプが必要です。',
  type_of_announcement_invalid: '通このタイプが無効です。',
  label_start_date: '開始日',
  start_date_required: '開始日が必要です。',
  label_end_date: '終了日',
  end_date_required: '終了日が必要です。',
  end_st_start_date_msg: '終了日が開始日より大きくする必要があります。',
  label_description: '説明',
  description_required: '説明が必要です。',
  add_announcement_success_toast: '社告が正常に作成されました。',
  'aggrid.import.success': '正常にインポートされました。',
  'aggrid.add.btn': '追加する',
  'aggrid.delete.btn': '削除',
  'aggrid.start.edit': '編集を開始',
  'aggrid.end.edit': '編集を終了',
  'aggrid.export.btn': 'エクスポート',
  'aggrid.import.btn': 'インポート',
  'aggrid.reset.all.btn': '全てリセット',
  'aggrid.search.here': 'ここで検索',
  'aggrid.no.rows.selected.delete': '削除する行を少なくとも一つ選択します。',
  'aggrid.confirm.delete': '選択した行を削除しても宜しいですか。',
  'aggrid.save.success': '成功',
  'aggrid.delete.success': '行を正常に削除しました。',
  'aggrid.total.rows': '合計行',
  'aggrid.data.import.success': '正常にデータをインポートされました。',
  'aggrid.import.loading.btn': '読み込み中',
  'aggrid.column.state.btn': '列の状態を保存する',
  'aggrid.submit.btn': '提出する',
  'aggrid.cancel.btn': 'キャンセル',
  'contract.date.msg': '契約日は入社日より大きくする必要があります。',
  Profile_Update_Requests: 'プロファイル更新リクエスト',
  Requested_Leaves: '要求された休暇',
  Other_Requests: '他のリクエスト',
  Add_Employee: '従業員を追加する',
  Special_Days: '特別な日',
  Payroll_Management: '給与管理',
  Employee_Gender: '従業員の性別',
  Request: 'リクエスト',
  quick_links: 'クイックリンク',
  Men: '男性',
  Women: '女性',
  update: '更新',
  blood_pressure_invalid: '血圧が無効です。',
  mobile_no_invalid: '電話番号が無効です。',
  state_invalid: '州が無効です。',
  address_required: '住所が無効です。',
  postal_code_invalid: '郵便番号が無効です。',
  pass_must_watch: 'パスワードが一致する必要がある',
  phone_no_invalid: '固定電話番号が無効です。',
  account_no_invalid: '口座番号が無効です。',
  ifsc_code_required: 'IFSCコードが必要です。',
  branch_name_invalid: '支店名が無効です。',
  bank_name_invalid: '銀行名が無効です。',
  ifsc_code_invalid: 'IFSCコードが無効です。',
  branch_address_invalid: '支店の住所が無効です。',
  acc_holder_name_invalid: '口座名義が無効です。',
  occupation_invalid: '職業が無効です。',
  relationship_invalid: '関係が無効です。',
  last_name_invalid: '名字が無効です。',
  first_name_invalid: '下の名前が無効です。',
  employeeId_prefix_invalid: '従業員IDのプレフィックスが無効です。',
  employeeId_invalid: '従業員IDが無効です。',
  designation_invalid: '職名が無効です。',
  employmentRelatedDocumentURL_required: '雇用関連の文書が必要です。',
  anyAddressIdproofURL_required: '住所IDの証明が必要です。',
  designation_required: '職名が必要です。',
  department_invalid: '部署が無効です。',
  date_invalid: '日付が無効です。',
  label_personal_mail: '個人的なメールアドレス',
  dob_invalid: '生年月日が無効です。',
  personal_info_success_toast: '個人的な情報は正常に追加されました。',
  bank_info_success_toast: '銀行詳細は正常に追加されました。',
  label_acc_holder_name: '口座名義',
  dependent_info_success_toast: '扶養家族の情報は正常に追加されました。',
  emergency_info_success_toast: '緊急時の連絡先は正常に追加されました。',
  document_upload_success_toast:
    '書類アップロードは正常にアップロードされました。',
  add_employee_success_toast: 'ユーザーは正常に作成されました。',
  label_designation: '職名',
  health_info_success_toast: '健康詳細は正常に追加されました。',
  reset_pass_success_toast: 'パスワードは正常に編集されました。',
  forgot_pass_success_toast_title:
    '公式メールアドレスにメールを送信されました。',
  label_personal_info: '個人的な情報',
  label_bank_info: '銀行詳細',
  label_dependent_info: '扶養家族の詳細',
  label_emergency_info: '緊急時の詳細',
  label_health_info: '健康詳細',
  label_document_info: '書類アップロード',
  edit_profile_header_text: 'プロファイル',
  'file.delete.success': 'ファイルは正常に削除をされました。',
  'no.data.msg': 'データなし',
  toast_mess_file_format_error: 'ファイル形式はサポートされていません。',
  toast_desc_file_format_error:
    'pdf,png,jpeg,jpgファイルのみがサポートされています。',
  personal_info_updated: '個人的な情報が更新されました。',
  health_info_updated: '健康詳細が更新されました。',
  emergency_info_updated: '緊急時の情報が更新されました。',
  dependent_info_updated: '扶養家族の情報が更新されました。',
  bank_info_updated: '銀行情報が更新されました。',
  document_updated_successfully_toast: '書類詳細が更新されました。',
  document_upload_successfully_toast: '書類が更新されました。',
  nav_hr_payroll: '休暇',
  sub_nav_leave: '休暇',
  btn_apply_leave: '休暇を申請する',
  btn_cancel: 'キャンセル',
  btn_submit: '提出する',
  apply_leave_success_toast: '正常に休暇を申請されました。',
  label_leave_type: '休暇のタイプ',
  leave_type_required: '休暇のタイプが必要です。',
  leave_type_invalid: '休暇のタイプが無効です。',
  label_day_type: '日付のタイプ',
  day_type_required: '日付のタイプが必要です。',
  day_type_invalid: '日付のタイプが無効です。',
  label_from: 'から',
  from_date_required: '”日付から”が必要です。',
  label_to: 'まで',
  to_date_required: '”日付まで”は必要です。',
  'to.lt.from.date.msg': '”日付まで”は”日付から”より少なくすることが必要です。',
  label_reason: '理由',
  reason_required: '理由が必要です。',
  from_not_equal_to_date_msg:
    '”日付から”と”日付まで”が一致する必要があります。',
  heading_leave_description: '休暇の説明',
  label_status: '状況',
  label_comment: 'コメントする',
  btn_approve: '承認する',
  btn_reject: '拒否する',
  leave_status_success_toast: '休暇の状況が更新されました。',
  add_leave_master_heading: '休暇のタイプを追加する',
  label_leave_master_name: '名前',
  label_leave_master_active: 'アクティブ',
  label_include_in_leave_balance: '休暇残高に含める',
  label_leave_year: '休暇の年',
  heading_applicable_scenarios: '該当するシナリオ',
  label_scenario: 'シナリオ',
  label_pay_type: '給与タイプ',
  label_leave_count: 'デフォルト手当',
  label_rollover_count: '許可されたロールオーバー休暇',
  label_negative_leave_balance_allowed: '許可されたネガティブ休暇',
  label_overall_capping: '最大休暇上限',
  label_rule_applicable: '～に該当する',
  label_department: '部署',
  label_location: '所在地',
  label_leave_cycle_period: '休暇のサイクル期間',
  btn_create_leave_master: '休暇を作成する',
  all: '全て',
  error_name_required: '名前を入力してください。',
  error_leave_year_required: '休暇の年を選択してください。',
  leave_count_required: '休暇の数を記入してください。',
  value_invalid: '有効な値を入力してください。',
  'rc.gt.lc.msg':
    'ロールオーバー休暇数は実際の休暇数より少なくする必要があります。',
  negative_allowed_balance_invalid:
    '許可されたネガティブ休暇は０またはネガティブである必要があります。',
  leave_rule_applicable_required: 'オプションを選択してください。',
  leave_master_page_title: '休暇のタイプ',
  btn_add_leave_type: '休暇のタイプを追加する',
  update_leave_master_heading: '休暇のタイプを更新する',
  btn_update_leave_master: '更新する',
  add_leave_master_success_toast: '休暇は正常に更新されました。',
  update_leave_master_success_toast: '休暇のタイプは正常に更新されました。',
  apply_leave_heading: '休暇を申請する',
  leave_page_title: '休暇の申請',
  leave_user_page_title: '休暇',
  manage_leave: '休暇を管理する',
  nav_attendance: '出勤',
  nav_attendance_text: '出勤',
  nav_attendance_list: 'リスト',
  nav_attendance_regularisation: '正則化',
  manage_regularisation: '正則化を管理する',
  regularisation_requests: '正則化のリクエスト',
  approve_reject_attn_request: 'リクエストは承認・拒否する',
  heading_regularisation: '正則化を申請する',
  edit_heading_regularisation: '正則化のリクエストを編集する',
  apply_regularisation: '正則化を申請する',
  attendance_summary: '従業員の出勤概要',
  mark_attendance: '出勤をマークする',
  button_mark_attendance: '出勤をマークする',
  check_box_work_from_home: '在宅勤務',
  employee_search: '従業員を検索する',
  regularisation_category: '正則化のカテゴリー',
  btn_apply: '申請する',
  category_required: 'カテゴリーが必要です。',
  overlap_holiday: '休日の重複',
  auro_approve: '自動承認する',
  approve: '承認した',
  rejected: '拒否した',
  cancelled: '取り消した',
  pending: '未解決',
  weekend: '週末',
  present: '現在',
  holiday: '祝日',
  absent: '休む',
  leave: '休暇',
  regular_request: '正則化のリクエスト',
  'grid.reset.col': 'リセット',
  'grid.columns': 'カラム',
  'grid.pin.column': 'ピンカラム',
  'grid.pin.left': '左をピンする',
  'grid.pin.right': '右をピンする',
  'grid.no.pin': 'ピンなし',
  'grid.autosize.this.col': 'このカラムのサイズが自動化する',
  'grid.autosize.all.col': '全てのカラムが自動化する',
  'grid.select.all': '全て選択する',
  'grid.clear.filter': 'フィルターをクリアする',
  'grid.apply.filter': 'フィルターを申請する',
  'grid.filters': 'フィルター',
  'grid.equals': '等しい',
  'grid.not.equals': '等しくない',
  'grid.less.than': 'より以下',
  'grid.less.than.or.equals': 'より以下か等しく',
  'grid.greater.than': 'より大きく',
  'grid.greater.than.or.equals': 'より大きくか等しく',
  'grid.in.range': '範囲内で',
  'grid.and.condition': 'と',
  'grid.or.condition': 'か',
  'announcement.heading': '社告',
  label_leave_description: '休暇の説明',
  select_an_option: 'オプションを選択する',
  leave_year_label: '暦年（1月1日から12月31日ま）',
  label_purpose: '休暇の目的',
  label_reporting_manager: 'レポートマネジャー',
  leave_raised_by: '休暇を申請した方',
  label_upload_doc: '書類の名前',
  nav_holiday: '休日',
  manage_holiday: '休日を管理する',
  add_holiday: '休日を追加する',
  add_holiday_heading: '休日を追加する',
  label_name: '名前',
  label_type_of_holiday: 'きゅうじつのタイプ',
  label_appicable: '～に該当する',
  label_shift: 'シフト',
  button_Submit: '提出する',
  create_holiday_success_toast: '休暇は正常に作成されました。',
  checkBoxText:
    '（この休日にすでに適用されているリーフは再処理され、それに応じて残高が調整されます）',
  manage_holiday_header: '休日を管理する',
  btn_add_holiday: '休日を追加する',
  label_reminder_sent: 'リマインダーを送信する前の日数',
  update_holiday_heading: '休日を更新する',
  update_holiday_success_toast: '休日が正常に更新されました。',
  skip_this_Scenario: 'シナリオをスキップする',
  manage_leave_label: '休暇を管理する',
  apply_leave: '休暇を申請する',
  leave_regularisation: '休暇のタイプ',
  manage_leave_master: '休暇のタイプを管理する',
  apply_leave_master: '休暇のタイプを追加する',
  add_more_documents_label: '書類を追加する',
  total_leaves_label: '合計休暇',
  rem_leaves_label: '残りの休暇',
  leave_name_label: '休暇の名前',
  year_start_bal_label: '年の開始バランス',
  leaves_curr_year_label: '今年発生した休暇',
  leave_avail_year_label: '今年使用した休暇',
  curr_leave_bal_label: '現在の休暇残高',
  nav_set_up: 'セットアップ',
  nav_general: '普通',
  nav_company_details: '会社詳細',
  nav_theme: 'テーマ',
  nav_location: '所在地',
  manage_location: '所在地を管理する',
  nav_department: '部署',
  manage_department: '部署を管理する',
  add_department: '部署を追加する',
  nav_designation: '職名',
  manage_designation: '職名を管理する',
  add_designation: '職名を追加する',
  add_success: '正常に作成されました。',
  update_success: '正常に更新されました。',
  nav_country: '国',
  manage_countries: '国を管理する',
  add_country: '国を追加する',
  edit_country: '国を編集する',
  field_country: '国の名前',
  field_country_code: '国コード（ISO）',
  field_calling_code: '電話をかける為の国コード',
  field_time_zone: '時間帯',
  country_name_required: '国の名前が必要です。',
  country_name_invalid: '国の名前が無効です。',
  country_code_required: '国コードが必要です。',
  country_code_invalid: '国コードが無効です。',
  calling_code_required: '電話をかける為の国コードが必要です。',
  calling_code_invalid: '電話をかける為の国コードが無効です。',
  timeZone_required: '時間帯が必要です。',
  select_timeZone: '時間帯を選択する',
  manage_state: '州を管理する',
  add_state: '州を追加する',
  edit_state: '州を編集する',
  field_state: '州',
  field_select_country: '国を選択する',
  add_location: '所在地を追加する',
  theme_label: 'テーマ',
  theme_subtitle: '組織のテーマをカスタマイズする',
  your_logo_label: '自分のロゴ',
  upload_file_label: 'ファイルをアップロードする',
  upload_logo_instruction1: 'このロゴはポータルに表示されます。',
  upload_logo_instruction2: '選好画像サイズ240px240px　最大サイズ2MB',
  choose_file_label: 'ファイルを選択する',
  color_theme_label: 'カラーテーマ',
  add_location_label: '所在地を追加する',
  location_name_label: '所在地名',
  mail_alias_label: 'メールエイリアス',
  state_province_label: '州・県',
  department_name: '部署名',
  department_lead: '部署長',
  parent_department: '親部署',
  designation_name: '職名名',
  upload_logo: 'ロゴをアップロードする',
  upload_logo_suggestion: '選好解像度80x 55',
  upload_logo_instruction: 'アップロードするファイルを参照する',
  upload_fevicon_instruction: 'ファビコン画像をアップロードする',
  favicon_label: 'ファビコン',
  company_details_label: '組織詳細',
  modify_settings_label: '設定を変更する',
  company_details_subheading:
    '組織の詳細を追加し、スーパー管理者を定義し、自分の会社のロゴを使用してパーソナライズし、他の会社関連の設定を追加します',
  basic_details: '普通な詳細',
  company_name: '会社名',
  website: 'ウェッブサイト',
  industry: '産業',
  contact_person_name: '連絡先の名前',
  contact_number: '連絡先番号',
  address_details: '住所',
  address_line1: '住所ライン１',
  address_line2: '住所ライン２',
  city_label: '都市',
  zip_pin_code: 'ZIP・PINコード',
  super_administrator: 'スーパー管理者',
  email_id: 'メールID',
  email_settings: 'メール設定',
  default_from_address: '住所からデフォルト',
  locale_settings: 'ロケール設定',
  country_region: '国・地域',
  time_zone: '時間帯',
  display_settings: '表示設定',
  name_to_be_displayed: '表示する名前',
  time_format: '時間形式',
  date_format: '日付形式',
  suggestion_super_admin:
    'ライセンス関連のすべての電子メールは、このメールIDに送信されます。',
  company_details_updated: 'Company Details Updated',

  //themes
  pic_uploaded_successfully: 'Picture uploaded successfully',
  themes_updated: 'Themes Updated Sucessfully',

  //roles_and_permissions
  nav_role_Permission: 'Roles and Permissions',
  manage_roles: 'Manage Roles',
  add_new_role: 'Add New Role',
  update_role: 'Update Role',
  roleName: 'Role Name',
  role_name_required: 'Role name is required',

  department_name_required: 'Department Name is required',
  edit_department: 'Edit Department',
  department_Details_updated: 'Department Details Updated',
  department_created: 'Department Created Successfully',

  designation_Details_updated: 'Designation Details Updated',
  designation_created: 'Designation Created Successfully',
  designation_name_required: 'Designation Name is required',
  edit_designation: 'Edit Designation',

  //document_and_records
  nav_document_records: 'Documents & Records',
  manage_qualification: 'Manage Qualification',
  add_qualification: 'Add Qualification',
  update_qualification:'Update Qualification',
  manage_medical_records: 'Manage Medical Records',
  add_medical_records: 'Add Medical Records',
  manage_document_list: 'Manage Document List',
  add_document_list: 'Add Document List',
  date_key: '日付',
  general_key: 'General',
  check_leave_status:'Check Leave Status',
  upload_document:'Upload Document',
  leave_status:'Leave Status',
  medicalRecordName:'Medical Record Name',

  //employee personnel
  nav_employee_personnel: '従業員の従業員',
  add_source_of_hire: '雇用元の追加',
  manage_source_of_hire: '雇用元の管理',
  source_of_hire_details_updated: '採用詳細のソースが更新されました',
  source_of_hire_created: '雇用のソースが正常に作成されました',
  source_of_hire_required: '雇用のソースが必要です',
  edit_source_of_hire: '雇用のソースを編集する',
  source_of_hire: '雇用のソース',

  add_employee_status: '従業員のステータスの追加',
  manage_employee_status: '従業員のステータスの管理',
  employee_status_name: 'ステータス名',
  employee_status_details_updated: '従業員の進捗の詳細が更新されました',
  employee_status_created: '従業員のステータスが正常に作成されました',
  employee_status_name_required: 'ステータス名が必要です',
  edit_employee_status: '従業員のステータスの編集',

  add_employee_type: '従業員タイプの追加',
  manage_employee_type: '従業員タイプの管理',
  employee_type: '従業員タイプ',
  employee_type_details_updated: '従業員タイプの詳細が更新されました',
  employee_type_created: '従業員タイプが正常に作成されました',
  employee_type_required: '従業員タイプが必要です',
  edit_employee_type: '従業員タイプの編集',
};

export default ja;
