import { storage } from '@bit/sixsprints.core.beans-web';
import { Keys } from '@bit/sixsprints.utils.data';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Checkbox } from '@chakra-ui/checkbox';
import {
  Box,
  Center,
  Flex,
  Heading,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/layout';
import { useNavigate } from '@reach/router';
import Bro from 'brototype';
import moment from 'moment';
import 'moment/locale/ja';
import 'moment/locale/en-in';
import React, { useEffect, useState } from 'react';
import { DayPicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ThemeButton from '../../components/buttons/theme-button';
import Loader from '../../components/misc/loader';
import PageTitle from '../../components/misc/page-title';
import { useWindowDimension } from '../../hooks/window-dimension';
import DataService from '../../services/data-service';
import colors from '../../theme/foundations/colors';
import { AppConstants } from '../../utils/constants';
import {
  getDateFormatter,
  getMonthEndDate,
  getMonthStartDate,
  getParsedErrorMessage,
} from '../../utils/helpers';
import useNotify from '../../utils/notifications';
import ROUTES from '../routes';
import PerfectScrollbar from 'react-perfect-scrollbar';

export const attendanceType = [
  {
    label: 'Present',
    color: '#67CE78',
  },
  {
    label: 'Weekend',
    color: '#D1472A',
  },
  {
    label: 'Leave',
    color: '#2678FE',
  },
  {
    label: 'Holiday',
    color: '#000000',
  },
];

const AttendanceCalendar = () => {
  const [width] = useWindowDimension();
  const [isWFH, setIsWFH] = useState(false);
  const [isPrevDisabled, setIsPrevDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isMarkAttendanceDisabled, setIsMarkAttendanceDisabled] =
    useState(false);
  const [calendarSize, setCalendarSize] = useState(width < 1367 ? 60 : 80);
  const [attendanceList, setAttendanceList] = useState([]);
  // const maxMonth = moment().clone().add(1, 'month').format('M');
  // const minMonth = moment().clone().subtract(2, 'month').format('M');
  const maxMonth = '12';
  const minMonth = '1';

  const [legends, setLegends] = useState([]);
  const [holidayList, setHolidayList] = useState(null);
  const holidayLegends = [
    { label: 'Gazzated Holiday', color: '#a4dd00' },
    { label: 'Restricted Holiday', color: '#0062b1' },
  ];

  const newLegends=[...legends,...holidayLegends];

  const [leaveDetails, setLeaveDetails] = useState(null);
  const { slug: userSlug } = Bro(storage.get(Keys.USER)).iCanHaz('data')
    ? Bro(storage.get(Keys.USER)).iCanHaz('data')
    : storage.get(Keys.USER);
  const navigate = useNavigate();
  const toast = useNotify();
  const { t, langCode } = useI18nContext();

  const customDayRender = val => {
    let day = moment(val).format('D');
    let bgColor;
    let fontColor = 'black';
    attendanceList.forEach(item => {
      if (
        day === moment(item.date).format('D') &&
        item.isRegularizationRequested
      ) {
        bgColor = AppConstants.ATTENDANCE_TYPE.REG_REQUESTED.color;
        fontColor = 'white';
      } else if (day === moment(item.date).format('D') && item.leaveName) {
        bgColor = item.leaveColor;
        fontColor = 'white';
      } else if (day === moment(item.date).format('D') && item.isPresent) {
        bgColor = AppConstants.ATTENDANCE_TYPE.PRESENT.color;
        fontColor = 'white';
      } else if (day === moment(item.date).format('D') && item.isWeekend) {
        bgColor = AppConstants.ATTENDANCE_TYPE.WEEKEND.color;
        fontColor = 'white';
      } else if (day === moment(item.date).format('D') && item.isHoliday) {
        bgColor = item.holidayColor;
        fontColor = 'white';
      }
    });
    return (
      <Box
        mx={2}
        py={2}
        borderRadius={10}
        color={fontColor}
        backgroundColor={bgColor}
      >
        {day}
      </Box>
    );
  };

  const onMarkAttendanceClicked = () => {
    let obj = {};
    obj.date = moment().clone().valueOf();
    obj.isWFH = isWFH;
    DataService.post('/attendance', obj)
      .then(() => {
        toast({
          title: t('Successfully Done.'),
          status: 'success',
        });
        fetchAttendance();
      })
      .catch(ex => {
        if (ex.response) {
          toast({
            title: t('Error'),
            description: getParsedErrorMessage(ex),
            status: 'error',
          });
        }
      });
  };

  const onMonthChange = date => {
    if (moment(date).clone().format('M') === maxMonth) {
      setIsNextDisabled(true);
      setIsPrevDisabled(false);
    } else {
      setIsNextDisabled(false);
    }
    if (moment(date).clone().format('M') === minMonth) {
      setIsPrevDisabled(true);
      setIsNextDisabled(false);
    } else {
      setIsPrevDisabled(false);
    }
    fetchAttendance(date);
  };

  const getLegends = data => {
    let legendsData = [];

    if (data.find(e => e.isPresent)) {
      legendsData.push(AppConstants.ATTENDANCE_TYPE.PRESENT);
    }
    if (data.find(e => e.isWeekend)) {
      legendsData.push(AppConstants.ATTENDANCE_TYPE.WEEKEND);
    }
    if (data.find(e => e.isRegularizationRequested)) {
      legendsData.push(AppConstants.ATTENDANCE_TYPE.REG_REQUESTED);
    }
    let leaves = data.filter(item => item.leaveName);
    setLeaveDetails(leaves);
    leaves = [
      ...new Map(leaves.map(item => [item['leaveName'], item])).values(),
    ];
    leaves.forEach(item => {
      let obj = {};
      obj.label = item.leaveName;
      obj.color = item.leaveColor;
      legendsData.push(obj);
    });

    let holidays = data.filter(item => item.holidayName);
    setHolidayList(holidays);
    holidays = [
      ...new Map(holidays.map(item => [item['holidayName'], item])).values(),
    ];
    holidays.forEach(item => {
      if (!item.isRegularizationRequested) {
        let obj = {};
        obj.label = item.holidayName;
        obj.color = item.holidayColor;
        obj.date = item.date;
        // legendsData.push(obj);
      }
    });
    setLegends(legendsData);
  };

  const fetchAttendance = date => {
    setAttendanceList([]);
    setLegends([]);

    let obj = {
      fromDate: getMonthStartDate(date),
      toDate: getMonthEndDate(date),
    };
    DataService.post(`attendance/user/${userSlug}`, obj).then(resp => {
      const data = Bro(resp).iCanHaz('data.data');
      if (moment(date).format('M') === moment().format('M')) {
        data.forEach(item => {
          if (item.isHoliday === true) {
            setIsMarkAttendanceDisabled(false);
          } else {
            if (moment(item.date).format('D') === moment().format('D')) {
              setIsMarkAttendanceDisabled(true);
            }
          }
        });
      } else {
        setIsMarkAttendanceDisabled(true);
      }
      getLegends(data);
      setAttendanceList(data);
    });
  };

  useEffect(() => {
    fetchAttendance();
    moment.locale(langCode);
  }, [langCode]);

  useEffect(() => {
    setCalendarSize(width < 1367 ? 60 : 80);
  }, [width]);

  return (
    <Box w="100%">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle title={t('mark_attendance')} />
      </Flex>
      <Box d="flex">
        <Box w="100%">
          <DayPicker
            daySize={calendarSize}
            noBorder
            noNavPrevButton={isPrevDisabled}
            noNavNextButton={isNextDisabled}
            transitionDuration={0}
            weekDayFormat="ddd"
            onNextMonthClick={onMonthChange}
            onPrevMonthClick={onMonthChange}
            hideKeyboardShortcutsPanel={true}
            numberOfMonths={1}
            renderDayContents={customDayRender}
          />
          {/* <Box my={5}>
            <Wrap>
              {legends.length ? (
                legends.map((item, index) => (
                  <WrapItem key={index}>
                    <Box mx={0.5} d="flex" justifyContent="center">
                      <Box
                        mr={1}
                        display="inline-block"
                        borderRadius={5}
                        width="20px"
                        height="20px"
                        backgroundColor={item.color}
                      ></Box>
                      <Text>{t(item.label)}</Text>
                    </Box>
                  </WrapItem>
                ))
              ) : (
                <Center>
                  <Loader colorScheme={'blue'} />
                </Center>
              )}
            </Wrap>
          </Box> */}
        </Box>
        <Box ml={5} w="100%">
          <Box
            d="flex"
            p={1}
            borderTopRadius={15}
            flexDirection="row"
            bg="white"
            justifyContent="space-between"
          >
            <Box ml={2}>
              <Text pl={2}>
                {t('date_key')} : {moment().format('ll')}
              </Text>
            </Box>
          </Box>

          <Box
            d="flex"
            bg="white"
            p={5}
            // borderBottomRadius={15}
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box d="flex" flexDirection="column" width="100%">
              <ThemeButton
                iconProp={{ px: '2', py: '1' }}
                px={5}
                py={12}
                d="flex"
                width="100%"
                style={{
                  textAlign: 'center',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                }}
                disabled={isMarkAttendanceDisabled}
                flexDirection="column"
                onClick={onMarkAttendanceClicked}
                bg="#E4EEFF"
                color={'brand.500'}
                text={t('button_mark_attendance')}
              />
            </Box>

            <ThemeButton
              mx={5}
              iconProp={{ px: '2', py: '1' }}
              px={5}
              py={12}
              d="flex"
              width="80%"
              style={{
                textAlign: 'center',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
              flexDirection="column"
              onClick={() =>
                navigate(ROUTES.DASHBOARD + ROUTES.LEAVE + ROUTES.ADD_LEAVE)
              }
              bg="#E4EEFF"
              color="#ED5019"
              text={t('btn_apply_leave')}
            />
            <ThemeButton
              iconProp={{ px: '2', py: '1' }}
              px={5}
              py={12}
              d="flex"
              width="80%"
              style={{
                textAlign: 'center',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
              }}
              flexDirection="column"
              onClick={() =>
                navigate(
                  ROUTES.DASHBOARD +
                    ROUTES.ATTENDANCE +
                    ROUTES.REGULARISATION_REQUEST
                )
              }
              bg="#E4EEFF"
              color={colors.brand[900]}
              text={t('apply_regularisation')}
            />
          </Box>
          <Box bg="white" borderBottomRadius={15}>
            <Checkbox
              checked={isWFH}
              onChange={() => (isWFH ? setIsWFH(false) : setIsWFH(true))}
              // mt={1}
              mb={2}
              ml={5}
              name="workFromHome"
            >
              {t('check_box_work_from_home')}
            </Checkbox>
          </Box>
          <Box my={3} py={2} bg="white" maxW='100%' w="100%" h="12em"  borderRadius={15}>
            <PerfectScrollbar>
            <Heading
              as="h4"
              fontFamily="Raleway"
              fontSize="18px"
              fontWeight="600"
              py={2}
              mx={5}
            >
              {'Holiday List'}
            </Heading>
              <Wrap>
                {holidayList?.length ? (
                  holidayList.map((item, index) => (
                    <WrapItem key={index}>
                      <Box mx={4} pr={100} my={1} d="flex" justifyContent="center">
                        <Box
                          mr={1}
                          display="inline-block"
                          borderRadius={5}
                          width="20px"
                          height="20px"
                          backgroundColor={item.holidayColor}
                        ></Box>
                        <Text fontFamily="Open Sans" fontSize="14px" mx={1}>
                          {getDateFormatter(item.date, 'll')}
                        </Text>
                        <Text fontSize="14px" fontFamily="Open Sans" mx={2}>
                          {item.holidayName}
                        </Text>
                      </Box>
                    </WrapItem>
                  ))
                ) : (
                  <Center>
                    {/* <Loader colorScheme={'blue'} /> */}
                    <Box>
                      <Text
                        fontFamily="Open Sans"
                        fontSize="14px"
                        mx={6}
                        my={2}
                      >
                        {'No Holiday(s) Available during this month'}
                      </Text>
                    </Box>
                  </Center>
                )}
              </Wrap>
            </PerfectScrollbar>
          </Box>
          {/* <Box>
            <Wrap>
              {holidayLegends?.length ? (
                holidayLegends.map((item, index) => (
                  <WrapItem>
                    <Box mx={2} d="flex" justifyContent="center">
                      <Box
                        mr={1}
                        display="inline-block"
                        borderRadius={5}
                        width="20px"
                        height="20px"
                        backgroundColor={item.colors}
                      ></Box>
                      <Text>{item.label}</Text>
                    </Box>
                  </WrapItem>
                ))
              ) : (
                <Center>  
                  <Loader colorScheme={'blue'} />
                </Center>
              )}
            </Wrap>
          </Box> */}
        </Box>
      </Box>
      <Box w="100%" py={3} bg="white" w="100%" h="10em" borderRadius={15} my={2}>
      <PerfectScrollbar>
        <Heading
          as="h4"
          fontFamily="Raleway"
          fontSize="18px"
          fontWeight="600"
          py={2}
          mx={5}
        >
          {'Leave Details'}
        </Heading>
        <Wrap>
          {leaveDetails?.length ? (
            leaveDetails.map((item, index) => (
              <WrapItem key={index}>
                <Box mx={5} my={1} d="flex" justifyContent="center">
                  <Box
                    mr={1}
                    display="inline-block"
                    borderRadius={5}
                    width="20px"
                    height="20px"
                    backgroundColor={item.leaveColor}
                  ></Box>
                  <Text fontFamily="Open Sans" fontSize="14px" mx={1}>
                    {getDateFormatter(item.date, 'll')}
                  </Text>
                  <Text fontSize="14px" fontFamily="Open Sans" mx={2}>
                    {item.leaveName}
                  </Text>
                  {item.leaveDayType === 'FULL' ? (
                    <Text fontSize="14px" fontFamily="Open Sans">
                      {''}
                    </Text>
                  ) : (
                    <Text fontSize="14px" fontFamily="Open Sans">
                      {'(half Day)'}
                    </Text>
                  )}
                </Box>
              </WrapItem>
            ))
          ) : (
            <Center>
              {/* <Loader colorScheme={'blue'} /> */}
              <Box>
                {' '}
                <Text fontFamily="Open Sans" fontSize="14px" mx={6} my={2}>
                  {'No Leave(s) taken during this month'}
                </Text>
              </Box>
            </Center>
          )}
        </Wrap>
        </PerfectScrollbar>
      </Box>
       <Box>
            <Wrap>
              {newLegends.length ? (
                newLegends.map((item, index) => (
                  <WrapItem key={index}>
                    <Box mx={0.5} d="flex" justifyContent="center">
                      <Box
                        mr={1}
                        display="inline-block"
                        borderRadius={5}
                        width="20px"
                        height="20px"
                        backgroundColor={item.color}
                      ></Box>
                      <Text>{t(item.label)}</Text>
                    </Box>
                  </WrapItem>
                ))
              ) : (
                <Center>
                  <Loader colorScheme={'blue'} />
                </Center>
              )}
            </Wrap>
          </Box>
    </Box>
  );
};

export default AttendanceCalendar;
