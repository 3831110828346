import ForgotPassword from '../pages/auth/forgot-password';
import Login from '../pages/auth/login';
import ResetPassword from '../pages/auth/reset-password';
import Onboarding from '../pages/onboarding/onboarding';
import AddEmployee from './employee/add-employee';
import EmployeeList from './employee/employee-list';
import Home from './home/home';
import Payroll from './payroll/payroll';
import DocumentDashboard from './document-dashboard/documentDashboard';
import AdminDocUpload from './admin-doc-upload/admin-doc-upload';
import ROUTES from './routes';
import EditUserDetails from './user-details/edit-user-details';
import UserProfile from './user-details/user-profile';
import UserProfileEdit from './user-details/user-profile-edit';
import LeaveList from './leave/leave-list';
import ApplyLeave from './leave/apply-leave';
import LeaveStatus from './leave/leave-status';
import ViewLeave from './leave/view-leave';
import UserLeaveList from './leave/user-leave-list'
import CreateLeaveMaster from './leave-master/create-leave-master';
import LeaveMasterList from './leave-master/leave-master-list';
import MainDashboard from './dashboard/main-dashboard';
import EditLeaveMaster from './leave-master/edit-leave-master';
import AttendanceCalendar from './attendance/mark-attendance';
import ManageAttendance from './attendance/manage-attendance';
import ApplyRegularisation from './attendance/apply-regularisation';
import AttnRegularisationList from './attendance/regularisation-list';
import AttendanceList from './attendance/attendance-list';
import ManageAttdRegularisation from './attendance/manage-regularisation';
import ManageHoliday from './holiday/manage-holiday';
import AddHoliday from './holiday/create-holiday';
import EditHoliday from './holiday/edit-holiday';
import ManageLocation from './set-up/location/manage-location';
import ManageCountry from './set-up/location/country/country';
import CompanyDetails from './set-up/general/company-details';
import Theme from './set-up/general/theme';
import AddCountry from './set-up/location/country/add-country';
import ManageDepartment from './set-up/department/manage-department';
import AddDepartment from './set-up/department/add-department';
import ManageDesignation from './set-up/designation/manage-designation';
import AddDesignation from './set-up/designation/add-designation';
import ManageState from './set-up/location/state/state';
import AddState from './set-up/location/state/add-state';
import AddLocation from './set-up/location/add-location';
import EditCompanyDetails from './set-up/general/edit-company-details';
import ManageRole from './set-up/roles-and-permissions/manage-role';
import AddRole from './set-up/roles-and-permissions/add-new-role';
import ManageDocumentList from './set-up/document-and-records/document-list/manage-document-list';
import AddDocumentList from './set-up/document-and-records/document-list/add-document-list';
import ManageMedicalRecords from './set-up/document-and-records/medical-records/manage-medical-records';
import AddMedicalRecord from './set-up/document-and-records/medical-records/add-medical-records';
import ManageQualification from './set-up/document-and-records/qualification/manage-qualification';
import AddQualification from './set-up/document-and-records/qualification/add-qualification';
import ManageEmployeeStatus from './set-up/employee-personnel/employee-status/manage-employee-status';
import AddEmployeeStatus from './set-up/employee-personnel/employee-status/add-employee-status';
import AddEmployeeType from './set-up/employee-personnel/employee-type/add-employee-type';
import ManageEmployeeType from './set-up/employee-personnel/employee-type/manage-employee-type';
import ManageSourceOfHire from './set-up/employee-personnel/source-of-hire/manage-source-of-hire';
import AddSourceOfHire from './set-up/employee-personnel/source-of-hire/add-source-of-hire';
// import ManageEmployeePayroll from './payroll/manage-employee-payroll';
// import DefineEmployeePayroll from './payroll/define-employee-payroll';
// import AddSalarystructure from './payroll/create-salary-structure';
// import ManageSalaryStructure from './payroll/manage-salary-structure';
// import AddSalaryComponents from './payroll/add-salary-components';
// import ManageSalaryComponents from './payroll/manage-salary-components';
// import EmployeePayrollList from './payroll/employee-payroll-list';

const routeObj = {
  default: {
    privateRoute: ROUTES.ONBOARDING,
    publicRoute: ROUTES.LOGIN,
  },
  private: [
    { path: ROUTES.ONBOARDING, component: Onboarding },

    { path: ROUTES.EDIT_DETAILS, component: EditUserDetails },
    { path: ROUTES.USER_PROFILE, component: UserProfileEdit },
    // { path: ROUTES.USER_PROFILE_EDIT, component: UserProfileEdit },
    {
      path: ROUTES.DASHBOARD,
      component: Home,
      routes: [
        {
          path: '/',
          component: MainDashboard,
        },
        { path: ROUTES.ADMIN_DASHBOARD, component: MainDashboard },
        { path: ROUTES.ADD_EMPLOYEE, component: AddEmployee },
        { path: ROUTES.EMPLOYEE_LIST, component: EmployeeList },
        { path: ROUTES.PAYROLL, component: Payroll },
        { path: ROUTES.ADMIN_DASHBOARD, component: MainDashboard },
        { path: ROUTES.DOCUMENT_DASHBOARD, component: DocumentDashboard },
        { path: ROUTES.DOCUMENT_UPLOAD_TABLE, component: AdminDocUpload },
        {
          path: ROUTES.LEAVE,
          component: (props) => <>{props.children}</>,
          routes:[
            {path:ROUTES.MANAGE_LEAVE,component:LeaveList},
            {path:ROUTES.USER_LEAVE,component: UserLeaveList},
            {path:ROUTES.USER_LEAVE_FOR_ADMIN,component: UserLeaveList},
            {path:ROUTES.ADD_LEAVE,component:ApplyLeave},
            {path:ROUTES.VIEW_LEAVE,component:ViewLeave},
            {path: ROUTES.LEAVE_STATUS, component: LeaveStatus},
            {path: ROUTES.LEAVE_STATUS_USER, component: LeaveStatus},
            {path:ROUTES.MANAGE_LEAVE_MASTER,component: LeaveMasterList},
            {path:ROUTES.MASTER_REQUEST,component:CreateLeaveMaster},
            {path:ROUTES.EDIT_REGULARISATION_REQUEST,component:EditLeaveMaster},
          ]
        },
 

        {
          path: ROUTES.ATTENDANCE,
          component: (props) => <>{props.children}</>,
          routes: [
            { path: ROUTES.MANAGE_ATTENDANCE, component: ManageAttendance },
            { path: ROUTES.ATTENDANCE_USER_LIST, component: AttendanceList },
            { path: ROUTES.ATTENDANCE_LIST, component: AttendanceList },
            { path: ROUTES.MARK_ATTENDANCE, component: AttendanceCalendar },
            { path: ROUTES.MANAGE_REGULARISATION, component: ManageAttdRegularisation },
            { path: ROUTES.REGULARISATION_LIST, component: AttnRegularisationList },
            { path: ROUTES.REGULARISATION_REQUEST, component: ApplyRegularisation },
            { path: ROUTES.ATTN_REGULARISATION_REQUEST_EDIT, component: ApplyRegularisation }
          ]
        },

        {
          path: ROUTES.HOLIDAY,
          component: (props) => <>{props.children}</>,
          routes: [
            { path: ROUTES.MANAGE_HOLIDAY, component: ManageHoliday },
            { path: ROUTES.ADD_HOLIDAY, component: AddHoliday },
            { path: ROUTES.EDIT_HOLIDAY, component:EditHoliday},
          ]
        },
        {
          path: ROUTES.SET_UP,
          component: (props) => <>{props.children}</>,
          routes: [
            { path: ROUTES.COMPANY_DETAILS, component: CompanyDetails },
            { path: ROUTES.EDIT_COMPANY_DETAILS, component: EditCompanyDetails},
            { path: ROUTES.THEME, component: Theme },
            
            { path: ROUTES.MANAGE_LOCATION, component: ManageLocation },
            { path: ROUTES.ADD_LOCATION, component: AddLocation},
            { path: ROUTES.EDIT_LOCATION, component: AddLocation},
            { path: ROUTES.MANAGE_COUNTRIES, component: ManageCountry },
            { path: ROUTES.ADD_COUNTRY, component: AddCountry },
            { path: ROUTES.EDIT_COUNTRY, component: AddCountry },

            { path: ROUTES.MANAGE_STATE, component: ManageState },
            { path: ROUTES.ADD_STATE, component: AddState },
            { path: ROUTES.EDIT_STATE, component: AddState },

            { path: ROUTES.MANAGE_DEPARTMENT, component: ManageDepartment },
            { path: ROUTES.ADD_DEPARTMENT, component: AddDepartment },
            { path: ROUTES.EDIT_DEPARTMENT, component: AddDepartment },

            { path: ROUTES.MANAGE_DESIGNATION, component: ManageDesignation },
            { path: ROUTES.ADD_DESIGNATION, component: AddDesignation },
            { path: ROUTES.EDIT_DESIGNATION, component: AddDesignation },

            { path: ROUTES.MANAGE_ROLE, component: ManageRole},
            { path: ROUTES.ADD_ROLE, component: AddRole},
            { path: ROUTES.EDIT_ROLE, component: AddRole },

            { path: ROUTES.MANAGE_DOCUMENT_LIST, component: ManageDocumentList },
            { path: ROUTES.ADD_DOCUMENT_LIST, component: AddDocumentList },
            { path: ROUTES.EDIT_DOCUMENT_LIST, component: AddDocumentList },

            { path: ROUTES.MANAGE_MEDICAL_RECORDS, component: ManageMedicalRecords},
            { path: ROUTES.ADD_MEDICAL_RECORDS, component: AddMedicalRecord},
            { path: ROUTES.EDIT_MEDICAL_RECORDS, component: AddMedicalRecord },

            { path: ROUTES.MANAGE_QUALIFICATION, component: ManageQualification},
            { path: ROUTES.ADD_QUALIFICATION, component: AddQualification},
            { path: ROUTES.EDIT_QUALIFICATION, component: AddQualification },

            { path: ROUTES.MANAGE_EMPLOYEE_STATUS, component: ManageEmployeeStatus},
            { path: ROUTES.ADD_EMPLOYEE_STATUS, component: AddEmployeeStatus},
            { path: ROUTES.EDIT_EMPLOYEE_STATUS,component: AddEmployeeStatus},
            { path: ROUTES.MANAGE_EMPLOYEE_TYPE, component: ManageEmployeeType},
            { path: ROUTES.ADD_EMPLOYEE_TYPE, component: AddEmployeeType},
            { path: ROUTES.EDIT_EMPLOYEE_TYPE,component: AddEmployeeType},
            { path: ROUTES.MANAGE_SOURCE_OF_HIRE, component: ManageSourceOfHire},
            { path: ROUTES.ADD_SOURCE_OF_HIRE, component: AddSourceOfHire},
            { path: ROUTES.EDIT_SOURCE_OF_HIRE, component: AddSourceOfHire},
          ]
        },
        // {
        //   path: ROUTES.PAYROLL,
        //   component: (props) => <>{props.children}</>,
        //   routes: [
        //     { path: ROUTES.MANAGE_PAYROLL, component: ManageEmployeePayroll },
        //     { path: ROUTES.DEFINE_PAYROLL, component: DefineEmployeePayroll },
        //     { path: ROUTES.EDIT_PAYROLL , component: DefineEmployeePayroll },
        //     { path: ROUTES.EMPLOYEE_PAYROLL, component: EmployeePayrollList},
        //     { path: ROUTES.EMPLOYEE_PAYROLL_FOR_ADMIN, component: EmployeePayrollList},
        //     { path: ROUTES.ADD_SALARY_STRUCTURE, component: AddSalarystructure},
        //     { path : ROUTES.MANAGE_SALARY_STRUCTURE, component: ManageSalaryStructure},
        //     { path: ROUTES.EDIT_SALARY_STRUCTURE, component: AddSalarystructure},
        //     { path : ROUTES.ADD_SALARY_COMPONENTS,component: AddSalaryComponents},
        //     { path: ROUTES.MANAGE_SALARY_COMPONENTS,component: ManageSalaryComponents},
        //     { path: ROUTES.EDIT_SALARY_COMPONENTS,component: AddSalaryComponents},
        //   ]
        // },
      ],
    },
  ],
  public: [
    {
      path: ROUTES.LOGIN,
      component: Login,
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      component: ForgotPassword,
    },
    {
      path: ROUTES.RESET_PASSWORD,
      component: ResetPassword,
    },
  ],
};

export default routeObj;
