import { useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import { Box, Button, Flex, Grid, GridItem, Stack } from '@chakra-ui/react';
import { useLocation, useNavigate, useParams } from '@reach/router';
import Bro from 'brototype';
import React, { useEffect } from 'react';
import MultiSelect from '../../../../components/form-elements/multi-select/multi-select';
import ThemeInput from '../../../../components/form-elements/theme-input';
import PageTitle from '../../../../components/misc/page-title';
import { getSelectedTimeZone } from '../../../../utils/helpers';
import useNotify from '../../../../utils/notifications';
import { CountrySchema } from '../../../../utils/validations';
import { Country } from './country-data';

const postOptions = {
  url: '/country',
  method: 'post',
};

const putOptions = {
  url: '/country/update',
  method: 'put',
};

const AddCountry = () => {
  const navigate = useNavigate();
  const toast = useNotify();
  const { t, languages } = useI18nContext();
  const langList = Object.keys(languages);
  const countryData = useLocation().state;
  const { slug } = useParams();
  const isEdit = slug ? true : false;

  const transformRequest = values => {
    let data = { ...values };
    data.timeZone = { name: values.timeZone.name, utc: values.timeZone.offset };
    data.countryCode = data.countryCode.toUpperCase();
    let obj = isEdit ? {...countryData, ...data} : data;
    return obj;
  };

  const onSuccess = resp => {
    toast({
      title: isEdit ? t('update_success') : t('add_success'),
      description: resp.message,
      status: 'success',
    });
    navigate(-1);
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  

  const {
    isLoading,
    onSubmit,
    register,
    control,
    errors,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions: isEdit ? putOptions : postOptions,
    yupSchema: CountrySchema(t),
    transformRequest,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Flex flex={1} direction="column">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle title={isEdit ? t('edit_country') : t('add_country')} />
      </Flex>
      <Box
        as={'form'}
        onSubmit={onSubmit}
        noValidate="noValidate"
        bgColor="white"
        borderRadius="16px"
      >
        <Stack>
          <Grid templateColumns="repeat(6, 1fr)" p={10} gap={8}>
            {langList.map(item => (
              <GridItem key={item} colSpan={2}>
                <ThemeInput
                  defaultValue={isEdit ? countryData.country[`${item}`] : null}
                  label={`${t('field_country')} - ${item.toUpperCase()}`}
                  name={`country.${item}`}
                  type="text"
                  error={Bro(errors).iCanHaz(`country.${item}`)}
                  register={register}
                  mt={1}
                  isRequired
                />
              </GridItem>
            ))}
            <GridItem colSpan={2}>
              <ThemeInput
                defaultValue={isEdit ? countryData.countryCode : null}
                label={t('field_country_code')}
                name="countryCode"
                textTransform="uppercase"
                type="text"
                error={Bro(errors).iCanHaz('countryCode')}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                defaultValue={isEdit ? countryData.callingCode : null}
                label={t('field_calling_code')}
                name="callingCode"
                type="text"
                error={Bro(errors).iCanHaz('callingCode')}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <Box mt={1}>
                <MultiSelect
                  name={'timeZone'}
                  label={t('field_time_zone')}
                  placeholder={t('select_timeZone')}
                  error={Bro(errors).iCanHaz('timeZone')}
                  options={Country.timeZones}
                  defaultValue={isEdit ? getSelectedTimeZone(countryData.timeZone) : null}
                  labelKey={'name'}
                  valueKey={'name'}
                  control={control}
                  isMulti={false}
                  isRequired
                />
              </Box>
            </GridItem>
          </Grid>
        </Stack>
        <Flex px={10} pb={10}>
          <Button type="submit" isLoading={isLoading} mr={10}>
            {t('btn_submit')}
          </Button>
          <Button onClick={onCancel} variant="ghost">
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default AddCountry;
