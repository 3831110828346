import { useFormController } from '@bit/sixsprints.core.beans-web';
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ThemeDateInput from '../../../../components/form-elements/date-picker/date-picker';
import SelectThemeInput from '../../../../components/form-elements/select-input';
import ThemeInput from '../../../../components/form-elements/theme-input';
import ForwardArrow from '../../../../icons/forward-arrow';
import useNotify from '../../../../utils/notifications';
import { PersonalValidationSchema } from '../../../../utils/validations';
import DataService from '../../../../services/data-service';
import { storage } from '@bit/sixsprints.core.beans-web';
import { Keys } from '@bit/sixsprints.utils.data';
import Bro from 'brototype';
import { AppConstants } from '../../../../utils/constants';
import moment from 'moment';
import UploadFile from '../../../../components/form-elements/Upload-file';
import ProfilePic from '../../../../icons/profile-pic';
import { getImageUrl } from '../../../../utils/helpers';
import ThemeAsyncSelectInput from '../../../../components/form-elements/theme-async-select-input';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import Loader from '../../../../components/misc/loader';
import ThemeHeading from '../../../../components/misc/heading';

const PersonalInfo = ({ t, next, userSlug, userData ,isEdit }) => {
  const toast = useNotify();
  const [userInfo, setUserInfo] = useState(null);
  const [gender, setGender] = useState(null);
  const [martialStatus, setMartialStatus] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
  const [email,setEmail] = useState(null);
  const [userId,setUserId] = useState(null);

  const { langCode } = useI18nContext();
  

  const apiOptions = {
    url: `/user/personal-info?userSlug=${userSlug}`,
    method: 'put',
  };
  
  const maritalStatus = [
    { label: 'Married', key: 'MARRIED' },
    {
      label: 'Unmarried',
      key: 'UNMARRIED',
    },
  ];

  const onSuccess = resp => {
    if (!isEdit) {
      const userData = storage.get(Keys.USER);
      userData.personalInfo = userInfo;
      storage.set(Keys.USER, userData);
      toast({
        title: t('personal_info_success_toast'),
        description: resp.message,
        status: 'success',
      });
      next();
    } else {
      toast({
        title: t('personal_info_updated'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  const transformRequest = values => {
    const data = Object.assign(userInfo,values);
    Object.keys(data).forEach((key) => {
      if (data[key] === '' || data[key] == null) {
        delete data[key];
      }
    });
     data.photo={
       url:imageSrc && imageSrc
     }
     data.currentAddress.countrySlug=Bro(data.currentAddress).iCanHaz('countrySlug.slug') || Bro(userInfo.currentAddress).iCanHaz('countrySlug');
     data.currentAddress.stateSlug=Bro(data.currentAddress).iCanHaz('stateSlug.slug') || Bro(userInfo.currentAddress).iCanHaz('stateSlug');
     data.permanentAddress.countrySlug=Bro(data.permanentAddress).iCanHaz('countrySlug.slug') || Bro(userInfo.permanentAddress).iCanHaz('countrySlug');
     data.permanentAddress.stateSlug=Bro(data.permanentAddress).iCanHaz('stateSlug.slug')|| Bro(userInfo.permanentAddress).iCanHaz('stateSlug');

    data.dateOfBirth = moment(data.dateOfBirth).valueOf();
    data.anniversaryDate =  data.maritalStatus==="MARRIED" ? data?.anniversaryDate && moment(data.anniversaryDate).valueOf() : null;
    
    return data;
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    control,
    errors,
    reset: resetForm,
    setValue
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: PersonalValidationSchema(t),
    transformRequest,
  });


  const getUserInfo = () => {
    DataService.get(`/user/${userSlug}`).then(resp => {
      const data = Bro(resp).iCanHaz('data.data.personalInfo');
      setEmail(Bro(resp).iCanHaz('data.data.email'))
      setUserId(Bro(resp).iCanHaz('data.data.userId'))
      setUserInfo(data);
      setImageSrc(Bro(data).iCanHaz('photo.url'))
      data.dateOfBirth = moment(data.dateOfBirth).isValid()
        ? moment(data.dateOfBirth).toDate()
        : null;
      data.anniversaryDate = moment(data.anniversaryDate).isValid()
        ? moment(data.anniversaryDate).toDate()
        : null;
        if(data.maritalStatus === 'MARRIED'){
          setMartialStatus(true)
        }else{
          setMartialStatus(false)
        }   
      resetForm(data);
    });
  };



  const onMartialStatusChnage = event => {
    if(event.target.value==='MARRIED'){
    setMartialStatus(true);
    }else{
      setMartialStatus(false)
    }
  };

 const setFileUrlIntoField = (url) => {
    setImageSrc(getImageUrl(url));
  };

  useEffect(() => {
    let genderArr = storage.get(AppConstants.AUTH.CONFIG).genders;
    setGender(genderArr);
  }, []);

  useEffect(getUserInfo, []);

  const transformCountryList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.country[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptions = async () => {
    let data = await DataService.post('/country/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformCountryList(data);
    return data;
  };

  const transformStateList = data => {
    let transformedStateList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.state[langCode];
      return obj;
    });
    return transformedStateList;
  };

  const promiseOptionsForState = async () => {
    let data = await DataService.post('/state/search', {
      page: 0,
      size: 50,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformStateList(data);
    return data;
  };

  useEffect(() => {
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode]);

  return ( !userInfo && (<Flex
    flex={1}
    h="100%"
    overflow="auto"
    direction="column"
    css={{
      '&::-webkit-scrollbar': {
        width: '0px',
      },
    }}
  >
    
    <Flex>
      <Box as={Flex} direction="column" mb={5}>
      {!isEdit ? (<ThemeHeading heading={t('basic_info_label')} subHeading={t('details_subheading')}/>):(
          userData?.firstName && <ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
          'edit_profile_header_text'
        )}`} subHeading={t('details_subheading')}/>
      )}
      </Box>
    </Flex>  <Center>
      <Loader/>
      </Center></Flex>)||(
    <Flex flexGrow={1}>
      <Flex flex={1} direction="column" mr='10rem'>
      {!isEdit ? (<ThemeHeading heading={t('basic_info_label')} subHeading={t('details_subheading')}/>):(
          userData?.firstName && <ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
          'edit_profile_header_text'
        )}`} subHeading={t('details_subheading')}/>
      )}
        <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate" mt={10}>
          <Flex pb="1em">
            <Box border="1px dashed #CDD0DF" w={110} h={110}>
              <Center>
                {imageSrc == null ? (
                  <ProfilePic/>
                ) : (
                  <Image w={110} h={110} src={imageSrc ? imageSrc : null} />
                )}
              </Center>
            </Box>
            <Flex flexDirection="column" flex={1}>
              <Text pt="0.7em" pl="1.5em" fontSize="14px" fontFamily="Open Sans" color="#818081">
                {t('profile_pic_instruction')}
              </Text>

              <UploadFile
                register={register}
                hideFileName
                profilePicLabel={t('upload_profile_picture')}
                name="photo.url"
                pl="1.5em"
                uploadValue={url => {
                  setValue(`photo.url`, url);
                  setFileUrlIntoField(url);
                }}
                
              />
            </Flex>
          </Flex>
          <Grid templateColumns="repeat(6, 1fr)" gap={6} >
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_first_name')}
                name="firstName"
                error={errors.firstName}
                register={register}
                type="text"
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_middle_name')}
                name="middleName"
                error={errors.middleName}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_last_name')}
                name="lastName"
                error={errors.lastName}
                register={register}
                type="text"
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_phoneticFirstName')}
                name="phoneticFirstName"
                error={errors.phoneticFirstName}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_phoneticMiddleName')}
                name="phoneticMiddleName"
                error={errors.phoneticMiddleName}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('label_phoneticLastName')}
                name="phoneticLastName"
                error={errors.phoneticLastName}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeDateInput
                label={t('label_birthday')}
                name="dateOfBirth"
                error={errors.dateOfBirth}
                defaultValue=""
                showTimeSelect={false}
                dateFormat="yyyy/MM/dd"
                maxDate={new Date()}
                control={control}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <SelectThemeInput
                options={gender}
                label={t('label_gender')}
                name="gender"
                valueKey="key"
                labelKey="label"
                placeholder={t('placeholder_text_gender')}
                error={errors.gender}
                register={register}
                type="text"
                mt="1.1rem"
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('mobile_number')}
                name="mobileNumber"
                type="Number"
                error={errors.mobileNumber}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('employee_ID_label')}
                name="employeeId"
                type="text"
                defaultValue={userId && userId}
                mt={1}
                isReadOnly
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('Jptc_email_ID_label')}
                name="jptcEmailId"
                type="email"
                defaultValue={email && email}
                mt={1}
                isReadOnly
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('personal_email_Id_label')}
                name="personalEmail"
                type="email"
                error={errors.personalEmail}
                register={register}
                mt={1}
              />
            </GridItem>
            </Grid>
            <Box w="100%" border="1px solid #D5D7DB" my="1.5rem" ></Box>
            <Grid templateColumns="repeat(6, 1fr)" gap={6}>
            <GridItem colSpan={4}>
              <ThemeInput
                label={t('curr_address_label')}
                name="currentAddress.addressLine1"
                error={Bro(errors).iCanHaz('currentAddress.addressLine1')}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('city_label')}
                name="currentAddress.city"
                type="text"
                error={Bro(errors).iCanHaz('currentAddress.city')}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2} mt={1}>
            <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue={ {name: Bro(userInfo).iCanHaz('currentAddress.state') ||'', slug: Bro(userInfo).iCanHaz('currentAddress.stateSlug') ||''}}
                promiseOptions={promiseOptionsForState}
                labelKey="name"
                valueKey="slug"
                name="currentAddress.stateSlug"
                control={control}
                // error={errors.stateSlug}
                label={t('state_province_label')}
                placeholder={t('select')}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('Pincode')}
                name="currentAddress.postal"
                type="number"
                error={Bro(errors).iCanHaz('currentAddress.postal')}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2} mt={1}>
            <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue={ {name: Bro(userInfo).iCanHaz('currentAddress.country') ||'', slug: Bro('userInfo').iCanHaz('currentAddress.countrySlug') ||''}}
                promiseOptions={promiseOptions}
                labelKey="name"
                valueKey="slug"
                name="currentAddress.countrySlug"
                control={control}
                // error={errors.countrySlug}
                label={t('field_select_country')}
                placeholder={t('select')}

              />
            </GridItem>
            </Grid>
            <Box w="100%" border="1px solid #D5D7DB" my="1.5rem" ></Box>
            <Grid templateColumns="repeat(6, 1fr)" gap={6}>
            <GridItem colSpan={4}>
              <ThemeInput
                label={t('permanent_add_label')}
                name="permanentAddress.addressLine1"
                error={Bro(errors).iCanHaz('permanentAddress.addressLine1')}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('city_label')}
                name="permanentAddress.city"
                type="text"
                error={Bro(errors).iCanHaz('permanentAddress.city')}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2} mt={1}>
            <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue={ {name: Bro(userInfo).iCanHaz('permanentAddress.state') ||'', slug: Bro(userInfo).iCanHaz('permanentAddress.stateSlug') ||''}}
                promiseOptions={promiseOptionsForState}
                labelKey="name"
                valueKey="slug"
                name="permanentAddress.stateSlug"
                control={control}
                label={t('state_province_label')}
                placeholder={t('select')}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('Pincode')}
                name="permanentAddress.postal"
                type="number"
                error={Bro(errors).iCanHaz('permanentAddress.postal')}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2} mt={1}>
            <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue={ {name: Bro(userInfo).iCanHaz('permanentAddress.country') ||'', slug: Bro(userInfo).iCanHaz('permanentAddress.countrySlug') ||''}}
                promiseOptions={promiseOptions}
                labelKey="name"
                valueKey="slug"
                name="permanentAddress.countrySlug"
                control={control}
                // error={errors.countrySlug}
                label={t('field_select_country')}
                placeholder={t('select')}
              />
            </GridItem>
            </Grid>
            <Box w="100%" border="1px solid #D5D7DB" my="1.5rem"></Box>
            <Grid templateColumns="repeat(6, 1fr)" gap={6}>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('aadhar_card_no_label')}
                name="aadhar"
                type="number"
                error={errors.aadhar}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('pan_number_label')}
                name="panCard"
                type="text"
                error={errors.panCard}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeInput
                label={t('passport_number_label')}
                name="passPort"
                type="text"
                error={errors.passPort}
                register={register}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={2}>
              <SelectThemeInput
                options={maritalStatus}
                label={t('marital_status_label')}
                name="maritalStatus"
                valueKey="key"
                labelKey="label"
                placeholder={t('Select')}
                onChange={value => {
                  onMartialStatusChnage(value);
                }}
                error={errors.maritalStatus}
                register={register}
                type="text"
                mt="1.1rem"
                isRequired
              />
            </GridItem>
            <GridItem colSpan={2}>
              <ThemeDateInput
                label={t('anniversary_date_label')}
                name="anniversaryDate"
                error={errors.anniversaryDate}
                showTimeSelect={false}
                defaultValue=""
                dateFormat="yyyy/MM/dd"
                maxDate={new Date()}
                control={control}
                disabled={martialStatus === true ? false : true}
                mt={1}
              />
            </GridItem>
          </Grid>
          <Flex justifyContent="flex-end" alignItems="center" mt={20}>
            {isEdit ? (
              <Flex flex={1} justifyContent="flex-end">
                <Button type="submit" px={10} isLoading={isLoading}>
                  {t('button_text_save')}
                </Button>
              </Flex>
            ) : (
              <Button
                type="submit"
                isLoading={isLoading}
                rightIcon={<ForwardArrow />}
              >
                {t('button_text_save_next')}
              </Button>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>)
  );
};

export default PersonalInfo;
