import * as React from 'react';
import { Icon } from '@chakra-ui/react';

const EditIcon = props => (
  <Icon width={28} height={28} viewBox="0 0 28 28" fill="none" {...props}>
    <g clipPath="url(#prefix__clip0)" fill="#24293D">
      <path
        opacity={0.4}
        d="M23.325 22.112H16.68c-.648 0-1.175.536-1.175 1.194 0 .66.527 1.194 1.175 1.194h6.644c.648 0 1.175-.534 1.175-1.194 0-.658-.527-1.194-1.175-1.194z"
      />
      <path d="M12.027 8.054l6.295 5.087a.362.362 0 01.06.5l-7.464 9.725c-.469.6-1.16.94-1.9.953l-4.075.05a.464.464 0 01-.457-.363L3.56 19.98c-.16-.74 0-1.505.47-2.095l7.5-9.77a.35.35 0 01.497-.06z" />
      <path
        opacity={0.4}
        d="M21.14 10.11l-1.213 1.515a.348.348 0 01-.494.056c-1.475-1.194-5.253-4.258-6.301-5.107a.36.36 0 01-.05-.504l1.17-1.453c1.061-1.367 2.913-1.493 4.407-.301l1.716 1.367c.704.551 1.173 1.279 1.334 2.044.185.841-.013 1.668-.568 2.383z"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h28v28H0z" />
      </clipPath>
    </defs>
  </Icon>
);

export default EditIcon;
