import React from "react";
import { Icon } from "@chakra-ui/react"


function ChevUpIcon(props) {
  return (
    <Icon
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
      <path
        d="M3.984 0a.934.934 0 00-.305.05.823.823 0 00-.26.143L.234 2.858a.675.675 0 00-.173.217.576.576 0 000 .512c.04.081.099.155.173.217a.825.825 0 00.26.145.936.936 0 00.611 0 .825.825 0 00.26-.145L3.985 1.6l2.627 2.118a.805.805 0 00.262.161.928.928 0 00.636.009.816.816 0 00.269-.154.662.662 0 00.172-.23.568.568 0 00-.037-.533.703.703 0 00-.203-.212L4.526.187A.882.882 0 003.984 0z"
        fill="currentColor"
      />
    </Icon>
  )
}

export default ChevUpIcon;


