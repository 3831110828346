import { Box, Center, Flex, List, Spacer, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import NavItem from './nav-item';
import { AppConstants } from '../../utils/constants';
import { storage } from '@bit/sixsprints.core.beans-web';
import ROUTES from '../routes';
import DashboardIcon from '../../icons/dashboard';
import UserIcon from '../../icons/user';
import PayrollIcon from '../../icons/payroll';
import { getImageUrl, isAdmin } from '../../utils/helpers';
import SetupIcon from '../../icons/setup';
import PerfectScrollbar from 'react-perfect-scrollbar';

const adminStructure = [
  {
    icon: DashboardIcon,
    key: 'nav_dashboard',
    exact: true,
    path: '/home',
  },
  {
    icon: SetupIcon,
    key: 'nav_set_up',
    path: ROUTES.DASHBOARD + ROUTES.SET_UP,
    items: [
      {
        key: 'nav_general',
        path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.GENERAL,
        items: [
          {
            key: 'nav_company_details',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.COMPANY_DETAILS,
          },
          {
            key: 'nav_theme',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.THEME,
          },
        ],
      },
      {
        key: 'nav_location',
        path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.LOCATION,
        items: [
          {
            key: 'manage_location',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_LOCATION,
          },
          {
            key: 'add_location',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_LOCATION,
          },
          {
            key: 'manage_countries',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_COUNTRIES,
          },
          {
            key: 'add_country',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_COUNTRY,
          },

          {
            key: 'manage_state',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_STATE,
          },
          {
            key: 'add_state',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_STATE,
          },
        ],
      },
      {
        key: 'nav_department',
        path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.DEPARTMENT,
        items: [
          {
            key: 'manage_department',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_DEPARTMENT,
          },
          {
            key: 'add_department',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_DEPARTMENT,
          },
        ],
      },
      {
        key: 'nav_designation',
        path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.DESIGNATION,
        items: [
          {
            key: 'manage_designation',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_DESIGNATION,
          },
          {
            key: 'add_designation',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_DESIGNATION,
          },
        ],
      },
      {
        key:'nav_role_Permission',
        path:ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ROLE,
        items:[
          {
            key:'manage_roles',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_ROLE,
          },
          {
            key:'add_new_role',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_ROLE,
          }
        ]
      },{
        key:'nav_document_records',
        path:ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.DOCUMENT_AND_RECORDS,
        items:[
          {
            key:'manage_qualification',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_QUALIFICATION,
          },
          {
            key:'add_qualification',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_QUALIFICATION,
          },
          {
            key:'manage_medical_records',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_MEDICAL_RECORDS,
          },
          {
            key:'add_medical_records',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_MEDICAL_RECORDS,
          },
          {
            key:'manage_document_list',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_DOCUMENT_LIST,
          },
          {
            key:'add_document_list',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_DOCUMENT_LIST,
          },
          
        ]
      },
      {
        key: 'nav_employee_personnel',
        path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.EMPLOYEE_PERSONNEL,
        items: [
          {
            key: 'manage_employee_type',
            path:
              ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_EMPLOYEE_TYPE,
          },
          {
            key: 'add_employee_type',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_EMPLOYEE_TYPE,
          },
          {
            key: 'manage_employee_status',
            path:
              ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_EMPLOYEE_STATUS,
          },
          {
            key: 'add_employee_status',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_EMPLOYEE_STATUS,
          },
          {
            key: 'manage_source_of_hire',
            path:
              ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_SOURCE_OF_HIRE,
          },
          {
            key: 'add_source_of_hire',
            path: ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.ADD_SOURCE_OF_HIRE,
          },
        ],
      },
      
    ],
  },
  
  
  {
    icon: UserIcon,
    key: 'nav_user_management',
    exact: true,
    path: '/home/employee',
  },

  {
    icon: PayrollIcon,
    key: 'nav_hr_payroll',
    path: ROUTES.DASHBOARD + ROUTES.LEAVE,
    items: [
      {
        key: 'manage_leave_label',
        path: ROUTES.DASHBOARD + ROUTES.LEAVE + ROUTES.MANAGE_LEAVE,
      },
      {
        key: 'apply_leave',
        path: ROUTES.DASHBOARD + ROUTES.LEAVE + ROUTES.ADD_LEAVE,
      },
      {
        key: 'leave_regularisation',
        path: ROUTES.DASHBOARD + ROUTES.LEAVE + ROUTES.LEAVE_MASTER,
        items: [
          {
            key: 'manage_leave_master',
            path: ROUTES.DASHBOARD + ROUTES.LEAVE + ROUTES.MANAGE_LEAVE_MASTER,
          },
          {
            key: 'apply_leave_master',
            path: ROUTES.DASHBOARD + ROUTES.LEAVE + ROUTES.MASTER_REQUEST,
          },
        ],
      },
    ],
  },
  {
    icon: PayrollIcon,
    key: 'nav_attendance',
    path: ROUTES.DASHBOARD + ROUTES.ATTENDANCE,
    items: [
      {
        key: 'attendance_summary',
        path: ROUTES.DASHBOARD + ROUTES.ATTENDANCE + ROUTES.MANAGE_ATTENDANCE,
      },
      {
        key: 'mark_attendance',
        path: ROUTES.DASHBOARD + ROUTES.ATTENDANCE + ROUTES.MARK_ATTENDANCE,
      },
      {
        key: 'nav_attendance_regularisation',
        path:
          ROUTES.DASHBOARD +
          ROUTES.ATTENDANCE +
          ROUTES.ATTENDANCE_REGULARISATION,
        items: [
          {
            key: 'manage_regularisation',
            path:
              ROUTES.DASHBOARD +
              ROUTES.ATTENDANCE +
              ROUTES.MANAGE_REGULARISATION,
          },
          {
            key: 'heading_regularisation',
            path:
              ROUTES.DASHBOARD +
              ROUTES.ATTENDANCE +
              ROUTES.REGULARISATION_REQUEST,
          },
        ],
      },
    ],
  },
  {
    icon: PayrollIcon,
    key: 'nav_holiday',
    path: ROUTES.DASHBOARD + ROUTES.HOLIDAY,
    items: [
      {
        key: 'manage_holiday',
        path: ROUTES.DASHBOARD + ROUTES.HOLIDAY + ROUTES.MANAGE_HOLIDAY,
      },
      {
        key: 'add_holiday',
        path: ROUTES.DASHBOARD + ROUTES.HOLIDAY + ROUTES.ADD_HOLIDAY,
      },
    ],
  },
  // {
  //   icon: PayrollIcon,
  //   key: 'nav_payroll',
  //   path: ROUTES.DASHBOARD + ROUTES.PAYROLL,
  //   items: [
  //     {
  //       key: 'manage_employee_payroll',
  //       path: ROUTES.DASHBOARD + ROUTES.PAYROLL + ROUTES.MANAGE_PAYROLL,
  //     },
  //     {
  //       key: 'define_employee_payroll',
  //       path: ROUTES.DASHBOARD + ROUTES.PAYROLL + ROUTES.DEFINE_PAYROLL,
  //     },
  //     {
  //       key: 'nav_structure',
  //       path:
  //         ROUTES.DASHBOARD +
  //         ROUTES.PAYROLL +
  //         ROUTES.STRUCTURE,
  //       items: [
  //         {
  //           key: 'manage_salary_structure',
  //           path:
  //             ROUTES.DASHBOARD +
  //             ROUTES.PAYROLL +
  //             ROUTES.MANAGE_SALARY_STRUCTURE,
  //         },
  //         {
  //           key: 'create_salary_structure',
  //           path:
  //             ROUTES.DASHBOARD +
  //             ROUTES.PAYROLL +
  //             ROUTES.ADD_SALARY_STRUCTURE,
  //         },
  //       ],
  //     },
  //     {
  //       key: 'nav_components',
  //       path:
  //         ROUTES.DASHBOARD +
  //         ROUTES.PAYROLL +
  //         ROUTES.SALARY_COMPONENTS,
  //       items: [
  //         {
  //           key: 'manage_salary_components',
  //           path:
  //             ROUTES.DASHBOARD +
  //             ROUTES.PAYROLL +
  //             ROUTES.MANAGE_SALARY_COMPONENTS,
  //         },
  //         {
  //           key: 'add_salary_components',
  //           path:
  //             ROUTES.DASHBOARD +
  //             ROUTES.PAYROLL +
  //             ROUTES.ADD_SALARY_COMPONENTS,
  //         },
  //       ],
  //     },
  //   ],
  // },
];

const userStructure = [
  {
    icon: DashboardIcon,
    key: 'nav_dashboard',
    exact: true,
    path: '/home',
  },
  {
    icon: UserIcon,
    key: 'nav_hr_payroll',
    path: ROUTES.DASHBOARD + ROUTES.LEAVE,
    items: [
      {
        key: 'manage_leave_label',
        path: ROUTES.DASHBOARD + ROUTES.LEAVE + ROUTES.USER_LEAVE,
      },
      {
        key: 'apply_leave',
        path: ROUTES.DASHBOARD + ROUTES.LEAVE + ROUTES.ADD_LEAVE,
      },
    ],
  },
  {
    icon: PayrollIcon,
    key: 'nav_attendance',
    path: ROUTES.DASHBOARD + ROUTES.ATTENDANCE,
    items: [
      {
        key: 'nav_attendance_list',
        path: ROUTES.DASHBOARD + ROUTES.ATTENDANCE + ROUTES.ATTENDANCE_LIST,
      },
      {
        key: 'mark_attendance',
        path: ROUTES.DASHBOARD + ROUTES.ATTENDANCE + ROUTES.MARK_ATTENDANCE,
      },
      {
        key: 'nav_attendance_regularisation',
        path:
          ROUTES.DASHBOARD +
          ROUTES.ATTENDANCE +
          ROUTES.ATTENDANCE_REGULARISATION,
        items: [
          {
            key: 'regularisation_requests',
            path:
              ROUTES.DASHBOARD + ROUTES.ATTENDANCE + ROUTES.REGULARISATION_LIST,
          },
          {
            key: 'heading_regularisation',
            path:
              ROUTES.DASHBOARD +
              ROUTES.ATTENDANCE +
              ROUTES.REGULARISATION_REQUEST,
          },
        ],
      },
    ],
  },
];

const LeftNav = () => {
  const structure = isAdmin() ? adminStructure : userStructure;
  const tenantBackground = getImageUrl(storage.get(AppConstants.AUTH.TENANT).assets
  .logoImageUrl);

  return (
    <List spacing={2} fontSize={'md'} overflowY="auto">
      <PerfectScrollbar>
        <Flex flexDirection="column" h="100vh">
          <Flex justifyContent="center">
            <Box
              mx={'70px'}
              my={'20px'}
              w="25vw"
              h="15vh"
              position="sticky"
              top="0"
              background={`url(${tenantBackground}) no-repeat left/contain`}
            ></Box>
          </Flex>

          <Box>
            <Stack spacing={5}>
              {structure.map(item => (
                <NavItem item={item} key={item.key} role={item.role} />
              ))}
            </Stack>
          </Box>
          <Spacer />
        </Flex>
      </PerfectScrollbar>
    </List>
  );
};

export default LeftNav;
