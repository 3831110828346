import React, { useEffect, useState } from 'react';
import { useParams } from '@reach/router';
import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import DatePicker from 'react-datepicker';
import {ClientDataGrid, clientGridOptions} from '@bit/sixsprints.utils.kg-grid';
import { Box, Flex } from '@chakra-ui/react';
import Bro from 'brototype';
import {ActionContainer,GridButton} from '../../components/misc/grid-ui';
import useNotify from '../../utils/notifications';
import 'rodal/lib/rodal.css';
import { getDateFormatter, getFullName, getMonthEndDate, getMonthStartDate, getUser } from '../../utils/helpers';
import DataService from '../../services/data-service';
import PageTitle from '../../components/misc/page-title';
import { getAttendanceStatus } from '../../utils/leave-util';
import moment from 'moment';

const AttendanceList = () => {
  const { t } = useI18nContext();
  const [month, setMonth] = useState(new Date());
  const [userName, setUserName] = useState("");
  const [attendanceData, setAttendanceData] = useState(null);
  const toast = useNotify();
  const simpleToast = (msg, status = 'success') =>
    toast({
      description: Bro(msg).iCanHaz('response.data.message') || msg,
      status,
    });

  let { userSlug } = useParams();
  let isAdminUser = true;

  if (!userSlug) {
    userSlug = getUser()['slug'];
    isAdminUser = false;
  }

  const getUserInfo = () => {
    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const personalInfo = Bro(resp).iCanHaz('data.data.personalInfo');
        setUserName(`(${getFullName(personalInfo)})`);
      })
  };

  const onMonthChange = date => {
    setMonth(date);
    fetchAttendance(moment().format('M') === moment(date).format('M') ? null : date);
  }

  const fetchAttendance = (date) => {
    let obj = { fromDate: getMonthStartDate(date), toDate: getMonthEndDate(date) }
    DataService.post(`attendance/user/${userSlug}`, obj).then(resp => {
      const data = Bro(resp).iCanHaz('data.data');
      setAttendanceData(data);
    })
  }


  const transformColDef = fields => {
    const modifiedColDef = fields.map(field => {
      const { cellRenderer, ...rest } = field;
      switch (field.colId) {
        case 'leaveType':
          return {
            ...rest,
            filter:false,
            cellRendererFramework: params => {
              return params.data.leaveName ? params.data.leaveName : '';
            },
          };
        case 'date':
          return {
            ...rest,
            filter:false,
            cellRendererFramework: params => {
              return getDateFormatter(params.value, 'DD/MM/YYYY');
            },
          };
        case 'isWFH':
          return {
            ...rest,
            filter:false,
            cellRendererFramework: params => {
              return params.value ? 'Yes': 'No';
            },
          };
        case 'status':
          return {
            ...rest,
            filter:false,
            cellRendererFramework: params => {
              const { label, color } = getAttendanceStatus(params.data);
              return (
                <Flex justifyContent="flex-start" alignItems="center">
                  <Box 
                    mr={1}
                    w="12px"
                    h="12px"
                    borderRadius="4px"
                    bg={color}
                  ></Box>
                  <Box >
                    {t(label)}
                  </Box>
                </Flex>
              )
            },
          };
        default:
          field.filter = false;
          return field;
      }
    }).filter(d => !!d);

    return modifiedColDef;
  };

  useEffect(() => {
    fetchAttendance()
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    isAdminUser ?
      getUserInfo() :
      setUserName(`(${getFullName()})`);
  }, []);



  return (
    <Flex flex={1} direction="column">
      <Box d="flex" alignItems="center" justifyContent="space-between">
        <PageTitle title={`${t('nav_attendance_text')} ${userName}`} />
        <Box>
          <DatePicker
            selected={month}
            // maxDate={moment().clone().add(1, 'month').endOf().valueOf()}
            // minDate={moment().clone().subtract(3, 'month').startOf().valueOf()}
            onChange={onMonthChange}
            dateFormat="MM/yyyy"
            showMonthYearPicker
          />
        </Box>
      </Box>

      <ClientDataGrid
        gridProps={{ ...clientGridOptions, transformColDef }}
        beans={{
          restApi,
          storage,
          i18n: t,
          notificationService: simpleToast,
        }}
        dataProps={{
          collection: 'attendance',
          rowData: attendanceData,
          hideAddNew: true,
          hideSubmit: true,
          hideCancel: true,
        }}
        uiComponents={{
          ActionContainer,
          Button: GridButton,
        }}
      />
    </Flex>
  );
};

export default AttendanceList;
