const baseStyle = {
  fontSize: 'md',
  color: 'black.100',
  fontWeight: '400',
  fontFamily: 'Open Sans',
  borderRadius: '16px',
};

const defaultProps = {
  size: 'md',
  variant: 'filled',
};

const variants = {
  filled: {
    bg: '#FFFFFF',
    border: '1px solid',
    borderColor: '#E4E4E4',
    borderRadius: '12px',
    _hover: {
      bg: '#FBFBFB',
    },
    _focus: {
      bg: '#FBFBFB',
    },
  },
};

const Textarea = {
  baseStyle,
  defaultProps,
  variants,
};

export default Textarea;
