import { Select } from '@chakra-ui/react';
import React, { useState } from 'react';
import Bro from 'brototype';
import DataService from '../../services/data-service';
import useNotify from '../../utils/notifications';

const LeaveMasterStatusSelect = ({
  defaultValue,
  options,
  data,
  color,
  bg,
  ...rest
}) => {
  const toast = useNotify();
  const [value, setValue] = useState(defaultValue);
  const [colorValue,setColorValue] = useState(value===true ? 'green.400': 'red.400')

  const onChange = (event) => {
    event.target.value==='true' ? setColorValue('green.400') : setColorValue('red.400')
    DataService.put(`/leaveMaster?propChanged=status`,
      { ...data, status: event.target.value === "true" })
      .then(resp => {
        setValue(event.target.value);
        toast({
          title: "Status Updated",
          status: "success"
        });
      })
      .catch((ex) => {
        setValue(defaultValue);
        toast({
          title: "Error",
          status: "error",
          description: Bro(ex).iCanHaz('message')
        });
      });
  }
  return (
    <Select
      height="30px"
      borderRadius="10px"
      onChange={onChange}
      fontFamily="Open Sans"
      fontSize="14px"
      focusBorderColor="brand.500"
      defaultValue={value}
      color={colorValue}
      {...rest}
    >
      {(options || []).map((op, idx) => {
        return (
          <option key={idx} value={ op['key'] } fontSize="14px" fontFamily="-apple-system, sans-serif;">
            {op['label']}
          </option>
        );
      })}
    </Select>
  );
};

export default LeaveMasterStatusSelect;
