import { Select } from '@chakra-ui/react';
import React, { useState } from 'react';
import Bro from 'brototype';
import DataService from '../../services/data-service';
import useNotify from '../../utils/notifications';

const StatusSelect = ({
  defaultValue,
  options,
  data,
  isDisabled,
  url,
  gridApi,
  ...rest
}) => {
  const toast = useNotify();
  const [value, setValue] = useState(defaultValue);

  const onChange = (event) => {
    DataService.put(url,
      { ...data, status: event.target.value })
      .then(() => {
        setValue(event.target.value);
        if(gridApi){
          gridApi.refreshServerSideStore({ purge: true });
        }
        toast({
          title: "Success",
          status: "success"
        });
      })
      .catch((ex) => {
        setValue(defaultValue);
        toast({
          title: "Error",
          status: "error",
          description: Bro(ex).iCanHaz('message')
        });
      });
  }

  return (
    <Select
      height="30px"
       borderRadius="10px"
      onChange={onChange}
      focusBorderColor="brand.300"
      defaultValue={value}
      isDisabled={isDisabled}
      {...rest}
    >
      {(options || []).map((op, idx) => {
        return (
          <option key={idx} value={ op['key'] } fontSize="14px">
            {op['label']}
          </option>
        );
      })}
    </Select>
  );
};

export default StatusSelect;
