import React, { useEffect, useState } from "react";
import { useI18nContext } from "@bit/sixsprints.utils.i18n/dist/language-context";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Link,
} from "@chakra-ui/react";
import Bro from "brototype";
import DataService from "../../services/data-service";
import useNotify from "../../utils/notifications";

const UploadFile = ({
  name,
  label,
  register,
  uploadValue,
  error,
  isRequired,
  hideFileName,
  ...rest
}) => {
  const [fileName, setFileName] = useState(null);
  const hiddenFileInput = React.useRef(null);
  const { t } = useI18nContext();
  const toast = useNotify();
  const handleClick = () => {
    hiddenFileInput.current.value = "";
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    let fileArray = Object.values(event.target.files);
    let filePath = fileArray[0]; 
    

    let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i; 
    if(filePath&&filePath.size>5000000){
      toast({
        title: t("Error"),
        description: t('file_size_is_to_large'),
        status: 'error',
      });
      return;
    }
    if (!allowedExtensions.exec(filePath.name)) { 
      toast({
        title: t('toast_mess_file_format_error'),
        description: t('toast_desc_file_format_error_pic'),
        status: 'error',
      });

      hiddenFileInput.current.value = ''; 
      return; 
  }  
  let formData = new FormData();
    formData.append("file", fileArray[0]);
    
  DataService.post('file/upload', formData)
      .then((resp) => {
        const response =
          Bro(resp).iCanHaz("data.data") || Bro(resp).iCanHaz("data");
        uploadValue(response);
        setFileName(fileArray[0].name);
        toast({
          title: t("pic_uploaded_successfully"),
          description: resp.message,
          status: "success",
        });
      })
      .catch((err) => {
        toast({
          title: t(err.message),
          description: err.message,
          status: "error",
        });
      });
  };

  useEffect(() => {
    register(name);
  }, []);

  return (
    <FormControl id={name} isInvalid={!!error} {...rest}>
      {label && (
        <FormLabel fontFamily="Open Sans" fontWeight="small" mb={3}>
          {label}
        </FormLabel>
      )}
      <Flex>
        {!hideFileName && (
          <Box
            flex={1}
            h="42px"
            bgColor="#ffffff"
            borderRadius="12px"
            minWidth="0"
            border="1px solid #E2E3E5"
          >
            <Text
              fontFamily="Open Sans"
              fontStyle="normal"
              fontWeight="500"
              fontSize="14px"
              mr={3}
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              mt={2}
              mx={3}
            >
              {fileName || t("label_no_file_chosen")}
            </Text>
          </Box>
        )}
        <Link
          onClick={handleClick}
          aria-label="Search database"
          shadow="none"
          fontWeight="600"
          color="brand.500"
          textDecoration="underline"
        >
          {t("file_upload_text")}
        </Link>
        <input
          type="file"
          ref={hiddenFileInput}
          name={name}
          accept=".jpg, .png, .jpeg"
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </Flex>
      {error && (
        <FormErrorMessage>
          <Text alignSelf="center">{t("form.field.required")}</Text>
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
export default UploadFile;
