import React, { useEffect, useState } from 'react';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Button, Image } from '@chakra-ui/react';
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
} from '@chakra-ui/layout';
import { useNavigate } from '@reach/router';
import PageTitle from '../../../components/misc/page-title';
import useNotify from '../../../utils/notifications';
import UploadSvg from '../../../components/misc/upload-svg';
import ColorPicker from '../../../components/form-elements/color-picker/color-picker';
import UploadFile from '../../../components/form-elements/Upload-file';
import { storage, useFormController } from '@bit/sixsprints.core.beans-web';
import ROUTES from '../../routes';
import Bro from 'brototype';
import DataService from '../../../services/data-service';
import { AppConstants } from '../../../utils/constants';
import { Keys } from '@bit/sixsprints.utils.data';
import Loader from '../../../components/misc/loader';
import { BehaviorSubject, Subscriber } from 'rxjs';

export const themeSubject = new BehaviorSubject(false);

const apiOptions = {
  url: '/org/update',
  method: 'put',
};

const Theme = () => {
  const navigate = useNavigate();
  const toast = useNotify();
  const { t } = useI18nContext();
  const [colorValue, setColorValue] = useState('');
  const [companyInfo, setCompanyInfo] = useState(null);

  const onMouseOver = event => {
    setColorValue(event.hex);
  };

  const onSuccess = resp => {
    themeSubject.next(true);
    toast({
      title: t('themes_updated'),
      description: resp.message,
      status: 'success',
    });
    navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.COMPANY_DETAILS);
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const transformRequest = data => {
    companyInfo.theme = {
      color: colorValue,
      faviCon: {
        documentName: 'faviCon',
        url: data.faviCon || Bro(companyInfo).iCanHaz('theme.faviCon.url'),
      },
      logo: {
        documentName: 'logo',
        url: data.logo || Bro(companyInfo).iCanHaz('theme.logo.url'),
      },
    };
    delete companyInfo['lastModifiedBy'];
    delete companyInfo['dateCreated'];
    delete companyInfo['dateModified'];

    return companyInfo;
  };

  const { isLoading, onSubmit, register, setValue, errors } = useFormController(
    {
      onSuccess,
      onError,
      apiOptions,
      transformRequest,
    }
  );

  const onReset = () => {
    window.location.reload();
  };

  const getCompanyInfo = () => {
    DataService.post('org/search', { page: 0, size: 4 }).then(resp => {
      const data = Bro(resp).iCanHaz('data.data.content');
      let companyDetails = data[0];
      setCompanyInfo(companyDetails);
      setColorValue(Bro(companyDetails).iCanHaz('theme.color'));
      viewlogoSrc(Bro(companyDetails).iCanHaz('theme.logo.url'));
      viewFeviconSrc(Bro(companyDetails).iCanHaz('theme.faviCon.url'));
    });
  };

  useEffect(getCompanyInfo, []);

  const [logoSrc, setlogoSrc] = useState('');

  const viewlogoSrc = rowData => {
    setlogoSrc(
      `${rowData}&${AppConstants.AUTH.AUTH_TOKEN_KEY}=${storage.get(
        Keys.TOKEN
      )}`
    );
  };

  const [feviconSrc, setFeviconSrc] = useState('');
  const viewFeviconSrc = rowData => {
    setFeviconSrc(
      `${rowData}&${AppConstants.AUTH.AUTH_TOKEN_KEY}=${storage.get(
        Keys.TOKEN
      )}`
    );
  };

  return (
    (!companyInfo && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex>
          <Box as={Flex} direction="column" mb={5}>
            <PageTitle title={t('theme_label')} />

            <Text
              fontFamily="Raleway"
              fontWeight="600"
              fontSize="14px"
              color="#818081"
              mt={2}
            >
              {t('theme_subtitle')}
            </Text>
          </Box>
        </Flex>{' '}
        <Center>
          <Loader />
        </Center>
      </Flex>
    )) || (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex direction="column" mb={5}>
          <PageTitle title={t('theme_label')} />
          <button onClick={() => this.hug('hugged')}></button>
          <Text
            fontFamily="Raleway"
            fontWeight="600"
            fontSize="14px"
            color="#818081"
            mt={2}
          >
            {t('theme_subtitle')}
          </Text>
        </Flex>
        <Box
          as={'form'}
          onSubmit={onSubmit}
          noValidate="noValidate"
          bgColor="white"
          borderRadius="16px"
          mb="2rem"
        >
          <Stack>
            <Grid templateColumns="repeat(10, 1fr)" p={12} gap={6}>
              <GridItem colSpan={10}>
                <Text fontSize="0.75rem" fontFamily="Open Sans">
                  {t('your_logo_label')}
                </Text>
              </GridItem>
              <Box>
                {logoSrc === null ? (
                  <Box
                    as={Flex}
                    bg=" #F4F5F8"
                    flexDirection="column"
                    border="1px dashed #ACACAC"
                    borderRadius="12px"
                  >
                    <Box p="2rem 7rem 0rem 7rem">
                      <Center>
                        <UploadSvg />
                      </Center>
                    </Box>
                    <Box mb="2rem">
                      <Center>
                        <Text
                          fontFamily="Open Sans"
                          fontWeight="600"
                          fontSize="14px"
                          color="brand.400"
                        >
                          {t('upload_file_label')}
                        </Text>
                      </Center>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    border="1px dashed #CDD0DF"
                    borderRadius="12px"
                    justifyContent="center"
                  >
                    <Image w="100%" h="100%" src={logoSrc ? logoSrc : null}  alt={t('your_logo_label')}/>
                  </Box>
                )}
              </Box>

              <Box as={Flex} flexDirection="column" w="55vh">
                <Box>
                  <Text fontFamily="Open Sans" fontSize="14px" color="#818081">
                    {t('upload_logo_instruction1')}
                  </Text>
                </Box>
                <Box mt="4px">
                  <Text fontFamily="Open Sans" fontSize="14px" color="#818081">
                    {t('upload_logo_instruction2')}
                  </Text>
                </Box>
                <Box mt="2em" cursor={'pointer'}>
                  <UploadFile
                    register={register}
                    hideFileName
                    name="logo"
                    uploadValue={url => {
                      setValue(`logo`, url);
                      viewlogoSrc(url);
                    }}
                  />
                </Box>
              </Box>
              <GridItem colSpan={10}>
                <Text fontSize="0.75rem" fontFamily="Open Sans">
                  {t('favicon_label')}
                </Text>
              </GridItem>
              {feviconSrc == null ? (
                <Box
                  as={Flex}
                  bg=" #F4F5F8"
                  flexDirection="column"
                  border="1px dashed #ACACAC"
                  borderRadius="12px"
                >
                  <Box>
                    <Box p="2rem 7rem 0rem 7rem">
                      <Center>
                        <UploadSvg />
                      </Center>
                    </Box>
                    <Box mb="2rem">
                      <Center>
                        <Text
                          fontFamily="Open Sans"
                          fontWeight="600"
                          fontSize="14px"
                          color="brand.400"
                        >
                          {t('upload_file_label')}
                        </Text>
                      </Center>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  w="180px"
                  border="1px dashed #CDD0DF"
                  borderRadius="12px"
                  justifyContent="center"
                  height="150px"
                >
                  <Image
                    w="100%"
                    h="100%"
                    src={feviconSrc ? feviconSrc : null}
                    alt={t('favicon_label')}
                  />
                </Box>
              )}
              <Box as={Flex} flexDirection="column" w="55vh" mt="4rem">
                <Box>
                  <Text fontFamily="Open Sans" fontSize="14px" color="#818081">
                    {t('upload_fevicon_instruction')}
                  </Text>
                </Box>
                <Box mt="2em" cursor={'pointer'}>
                  <UploadFile
                    register={register}
                    hideFileName
                    name="faviCon"
                    uploadValue={url => {
                      setValue(`faviCon`, url);
                      viewFeviconSrc(url);
                    }}
                  />
                </Box>
              </Box>
              <GridItem colSpan={10}>
                <ColorPicker
                  color={colorValue}
                  label={t('color_theme_label')}
                  onChange={onMouseOver}
                />
              </GridItem>
            </Grid>
          </Stack>
          <Flex px={10} pb={10} ml={2}>
            <Button type="submit" isLoading={isLoading} mr={5}>
              {t('button_Submit')}
            </Button>
            <Button onClick={onReset} variant="ghost">
              {t('grid.reset.col')}
            </Button>
          </Flex>
        </Box>
      </Flex>
    )
  );
};
export default Theme;
