import { Select } from '@chakra-ui/react';
import React, { useState } from 'react';
import Bro from 'brototype';
import DataService from '../../services/data-service';
import useNotify from '../../utils/notifications';

const LeaveStatusSelect = ({
  defaultValue,
  options,
  data,
  isDisabled,
  ...rest
}) => {
  const toast = useNotify();
  const [value, setValue] = useState(defaultValue);
  const [colorValue,setColorValue] = useState('blue.500')


  const onChange = (event) => {
    switch(event.target.value){
    case 'PENDING':
      setColorValue('blue.500')
      break;
    case 'APPROVED':
      setColorValue('green.500')

      break;
    case 'REJECTED':
      setColorValue('red.500')
      break;
    
    case 'CANCELLED':
      setColorValue('yellow.500')
      break;
    default:
        break;
    }

    DataService.put(`/leave?propChanged=status`,
      { ...data, status: event.target.value })
      .then(resp => {
        setValue(event.target.value);
        toast({
          title: "Success",
          status: "success"
        });
      })
      .catch((ex) => {
        setValue(defaultValue);
        toast({
          title: "Error",
          status: "error",
          description: Bro(ex).iCanHaz('message')
        });
      });
  }

  return (
    <Select
      height="30px"
       borderRadius="10px"
      onChange={onChange}
      focusBorderColor="brand.300"
      defaultValue={value}
      color={colorValue}
      // isDisabled={ !!isDisabled || value !== "PENDING" }
      isDisabled={ !!isDisabled }
      {...rest}
    >
      {(options || []).map((op, idx) => {
        return (
          <option key={idx} value={ op['key'] } fontSize="14px" fontFamily="-apple-system, sans-serif;">
            {op['label']}
          </option>
        );
      })}
    </Select>
  );
};

export default LeaveStatusSelect;
