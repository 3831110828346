import {
  authData,
  restApi,
  storage,
  useFormController,
} from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { useNavigate, useLocation } from '@reach/router';
import Bro from 'brototype';
import { useEffect } from 'react';
import ThemeInput from '../../components/form-elements/theme-input';
import LangSelect from '../../components/misc/lang-select';
import { AppConstants } from '../../utils/constants';
import useNotify from '../../utils/notifications';
import { ResetPassValidationSchema } from '../../utils/validations';
import useQueryParams from '../../hooks/query-params';

const apiOptions = {
  url: '/auth/user/reset',
  method: 'post',
};

const ResetPassword = props => {
  const toast = useNotify();
  const { t } = useI18nContext();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useQueryParams(location);
  const tenantBackground = storage.get(AppConstants.AUTH.TENANT).assets
    .sideBannerImageUrl;

  const transformRequest = values => {
    const data = { ...values };
    params.emailId
      ? (data.email = params.emailId)
      : (data.email = Bro(props).iCanHaz('location.state.email'));
    return data;
  };

  const onSuccess = resp => {
    toast({
      title: t('reset_pass_success_toast'),
      description: resp.message,
      status: 'success',
    });
    authData.setAuthData({
      user: Bro(resp).iCanHaz('data'),
      token: Bro(resp).iCanHaz('token'),
    });
    restApi.addGlobalHeader(
      AppConstants.AUTH.AUTH_TOKEN_KEY,
      Bro(resp).iCanHaz('token')
    );
    navigate('/', { replace: true });
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const { isLoading, onSubmit, register, errors } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: ResetPassValidationSchema(t),
    transformRequest,
  });

  useEffect(() => {
    register('email', Bro(props).iCanHaz('location.state.email'));
  }, []);

  return (
    <Flex h="100vh" bgColor="#F5F6F9">
      <Box position="absolute" top="1em" marginLeft="10vw">
        <LangSelect />
      </Box>
      <Flex flex={1} px={32} direction="column" justify="center">
        <Heading as="h1" size="lg">
          {t('reset_pass_heading')}
        </Heading>
        <Box as={'form'} maxW={440} onSubmit={onSubmit} noValidate="noValidate">
          <Stack spacing={6} my={16}>
            <ThemeInput
              label={t('login_form_otp')}
              name="otp"
              error={errors.otp}
              register={register}
              type="otp"
              isRequired
            />

            <ThemeInput
              label={t('login_form_label_new_password')}
              name="password"
              error={errors.password}
              register={register}
              isPasswordInput
              isRequired
            />

            <ThemeInput
              label={t('login_form_confirm_new_password')}
              name="rePassword"
              error={errors.rePassword}
              register={register}
              isPasswordInput
              isRequired
            />

            <Button type="submit" isLoading={isLoading} w="full">
              <Text fontSize="sm" fontWeight="bold" fontFamily="raleway">
                {t('reset_pass_heading')}
              </Text>
            </Button>
          </Stack>
        </Box>
      </Flex>
      <Box
        flex={2}
        h="full"
        bgImage={`url(${tenantBackground})`}
        bgRepeat="no-repeat"
        bgSize="100% 100%"
      />
    </Flex>
  );
};

export default ResetPassword;
