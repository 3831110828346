const baseStyle = {
  fontSize: 'sm',
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontWeight: '400',
  color: 'black.100',
};

const defaultProps = {
  size: 'md',
};

const FormErrorMessage = {
  baseStyle,
  defaultProps,
};

export default FormErrorMessage;
