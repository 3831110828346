import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Link as ReachLink } from '@reach/router';
import { Link } from '@chakra-ui/react';

const QuickLink = ({ title, path }) => {
  return (
    <Flex mb="1em" w={'50%'}>
      <Link
        as={ReachLink}
        color="brand.500"
        to={path}
        fontSize="md"
        fontFamily="Open Sans"
        fontWeight="400"
      >
        {title}
      </Link>
    </Flex>
  );
};

export default QuickLink;
