import { useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useNavigate , useParams } from '@reach/router';
import React, { useEffect, useState } from 'react';
import ThemeDateInput from '../../components/form-elements/date-picker/date-picker';
import SelectThemeInput from '../../components/form-elements/select-input';
import ThemeInput from '../../components/form-elements/theme-input';
import useNotify from '../../utils/notifications';
import ThemeRadioGroup from '../../components/form-elements/radio-input';
import ColorPicker from '../../components/form-elements/color-picker/color-picker';
import ThemeTextAreaInput from '../../components/form-elements/theme-textarea';
import { AddIcon } from '@chakra-ui/icons';
import Bro from 'brototype';
import MultiSelect from '../../components/form-elements/multi-select/multi-select';
import { CreateHolidayMasterSchema } from '../../utils/validations';
import { getColorOrDefault, getMonthEndDate, getMonthStartDate } from '../../utils/helpers';
import DataService from '../../services/data-service';
import PageTitle from '../../components/misc/page-title';
import moment from 'moment';


const apiOptionsPut = {
    url: '/holidayMaster/update',
    method: 'put',
  };

const EditHoliday = () => {
  const navigate = useNavigate();
  const toast = useNotify();
  const { slug } = useParams();

  const { t } = useI18nContext();
  // const [contract, setContract] = useState(null);
  // const [type, setType] = useState(null);
  const [colorValue, setColorValue] = useState('');
  const [indexes, setIndexes] = useState([0]);
  // const [counter, setCounter] = useState(0);
  // const [departmentVisibility, setDepartmentVisibility] = useState({});
  const [locationVisibility, setLocationVisibility] = useState({});
  // const [shiftVisibility,setShiftVisibility] = useState({});
  const [freezeBtnValue, setFreezeBtnValue] = useState(true);

  const [dataVal, setDataVal] = useState(null);

  const [radioButtonValues, setRadioButtonValues] = useState({});
  const [selectInputValues, setSelectInputValues] = useState({});

  const [checkBoxVal,setCheckBoxVal] = useState({})
  const [startDate, setStartDate] = useState(new Date());
  

  // const radio1=radioButtonValues["0_holidayType"]
  // const leaveRuleApplicableOptions = storage.get(AppConstants.AUTH.CONFIG)
  // .leaveRuleApplicable;

  const leaveRuleApplicableAllOptions = [
    { key: 'ALL', label: 'All Employees' },
  ];
  const leaveRuleApplicableSpecificOptions = [
    { key: 'SPECIFIC', label: 'Specific Employees' },
  ];
  
  // const changeDepartmentVisibility = (index, val) => {
  //   setDepartmentVisibility(prev => ({ ...prev, [index]: val !== 'ALL' }));
  //   if (val === 'ALL')
  //     setLocationVisibility(prev => ({ ...prev, [index]: false }));
  // };

  const changeLocationVisibility = (index, val) => {
    setLocationVisibility(prev => ({
      ...prev,
      [index]: val.length > 0,
    }));
  };
  
  const getUserInfo = () => {
    DataService.get(`/holidayMaster/${slug}`)
    .then(resp => {
      const data = Bro(resp).iCanHaz('data.data');
      resetForm(data);
        initializeHolidayData(data);
      })
      .catch(err =>
        toast({
          title: t('Error'),
          description: err.message,
          status: 'error',
        })
      );
  };
  
  const initializeHolidayData = data => {
    // var departmentVisibility = {};
    var locationVisibility = {};
    var radioButtonValues = {};
    var selectInputValues = {};
    var checkBoxValues= {};
    setColorValue(getColorOrDefault(data.color));
    resetForm({
      name: data.name,
      description: data.description,
      holidayMasterRule: data.holidayMasterRule.map((d, i) => {
       if(i===0){ setFreezeBtnValue(d.exclude===false ? false:true);}
        // if (
        //   d.holidayRuleApplicable !==
        //   AppConstants.LEAVE.LEAVE_RULE_APPLICABLE.ALL
        // ) {
        //   departmentVisibility[i] = true;
        // }
        d.department = (d.department || []).map(d => ({ key: d, label: d }));
        d.location = (d.location || []).map(d => ({ key: d, label: d }));
        if (d.department.length) {
          locationVisibility[i] = true;
        }
        radioButtonValues[i + '_holidayRuleApplicable'] =
        d.holidayRuleApplicable;
        checkBoxValues[i+ '_checkBoxValue'] = d.exclude;
        radioButtonValues[i + '_holidayType'] = d.holidayType;
        selectInputValues[i + '_department'] = d.department;
        selectInputValues[i + '_location'] = d.location;
        ThemeDateInput[i + '_fromDate'] = d.fromDate;
        return d;
      }),
    });
    // setDepartmentVisibility(departmentVisibility);
    setLocationVisibility(locationVisibility);
    setIndexes([...Array(data.holidayMasterRule.length)].map((d, i) => i));
    setRadioButtonValues(radioButtonValues);
    setSelectInputValues(selectInputValues);
    setCheckBoxVal(checkBoxValues);
    setDataVal(data);
  };

  const transformRequest = values => {
    const data = {...dataVal,...values,slug,color: colorValue };
    data.color= colorValue
    data.holidayMasterRule = data.holidayMasterRule.map((d, i) => {
      d.holidayType = i===0 && freezeBtnValue === true ? null : d.holidayType;
      if (d.leaveRuleApplicable === 'ALL') {
        delete d.department;
        delete d.location;
      } else {
        d.department = (d.department || []).map(d => d.key);
        d.location = d.department.length
        ? (d.location || []).map(d => d.key)
        : [];
      }
      d.exclude = i > 0 ? false : freezeBtnValue;
      d.remainderSent = i===0 && freezeBtnValue === true ? null : d.remainderSent;
      d.fromDate =
      i===0 && freezeBtnValue === true ? null : new Date(d.fromDate).getTime();
      d.toDate = i===0 && freezeBtnValue === true ? null : new Date(d.toDate).getTime();
      return d;
    });
    return data;
  };
  
  const onSuccess = resp => {
    toast({
      title: t('update_holiday_success_toast'),
      description: resp.message,
      status: 'success',
    });
    navigate(-1);
  };
  
  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    control,
    errors,
    getValues,
    reset: resetForm,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions:apiOptionsPut,
    yupSchema: CreateHolidayMasterSchema(t),
    transformRequest,
  });
  

  const typeOfHolidayOptions = [
    {
      key: "RESTRICTED",
      label: "Restricted",
    },
    {
      key: "GAZETTED",
      label: "Gazetted",
    },
  ];

  const departmentOptions = [
    {
      label:'IT',
      key:'IT',
    },
    {
      label: 'HR',
      key: 'HR',
    },
    {
      label: 'Sales',
      key: 'SALES',
    },
    {
      label: 'Marketing',
      key: 'MARKETING',
    },
  ];

  const locationOptions = [
    { label: 'Delhi', key: 'DELHI' },
    { label: 'Tokyo', key: 'TOKYO' },
    {label:'Gurugram',key:'GURUGRAM'},
    { label: 'Pune', key: 'PUNE' },
    { label: 'Mumbai', key: 'MUMBAI' },
  ];

  // const shiftOptions = [
  //   {label:'Day',key:'Day'},
  //   {label:'Night',key:'Night'},
  // ]

  const daysValue = [
    { label: '1', key: 1 },
    { label: '2', key: 2 },
    { label: '3', key: 3 },
    { label: '4', key: 4 },
  ];

  const addRule = () => {
    setIndexes(prevIndexes => [...prevIndexes, Math.max(...prevIndexes) + 1]);
  };

  const removeRule = index => () => {
    // setDepartmentVisibility(prev => ({ ...prev, [index]: false }));
    setLocationVisibility(prev => ({ ...prev, [index]: false }));

    setIndexes(prevIndexes => [
      ...prevIndexes.filter((item, _index) => _index !== index),
    ]);
    const currFormValues = getValues()
    resetForm(currFormValues,
      currFormValues.holidayMasterRule = currFormValues.holidayMasterRule.filter(
        (item, _index) => _index !== index
      ),
    );
  };
  

  const onMouseOver = event => {
    setColorValue(event.hex);
  };

  const onCancel = () => {
    navigate(-1);
  };

  const onFreezeScrenarioChange = event => { 
    setFreezeBtnValue(event.target.checked);
  };

  useEffect(() => getUserInfo(), []);

 

 

  return (
    dataVal!==null && (
    <Flex flex={1} direction="column">
      <Flex justifyContent="space-between" alignItems="center" mb={10}>
        <PageTitle title={t('update_holiday_heading')} />
      </Flex>
      <Box
        as={'form'}
        onSubmit={onSubmit}
        noValidate="noValidate"
        bgColor="white"
        borderRadius="16px"
      >
        <Stack>
          <Grid templateColumns="repeat(6, 1fr)" p={10} gap={10}>
            <GridItem colSpan={3}>
              <ThemeInput
                label={t('label_name')}
                name="name"
                type="text"
                error={errors.name}
                register={register}
                mt={1}
                isRequired
              />
            </GridItem>
            <GridItem colSpan={3} mt={2}>
              <ColorPicker
                color={colorValue}
                label="Display Color"
                onChange={onMouseOver}
              />
            </GridItem>
            <GridItem colSpan={3} pb={5}>
              <ThemeTextAreaInput
                type="text"
                label="Holiday Description"
                name="description"
                register={register}
              />
            </GridItem>
            <GridItem colSpan={3}>
              <Text fontSize="0.75rem">
                Reprocess leave applications based on this added holiday
              </Text>
              <Checkbox
                colorScheme="brand"
                // name="hideSalaryFromJobSeekers"
                // ref={register}
              >
                <Text color="#8D8D8D" fontSize="13px" mt={4}>
                  {t('checkBoxText')}
                </Text>
              </Checkbox>
            </GridItem>
          </Grid>
        </Stack>

        <Box p={3} bg="#FAFAFA">
          <Flex display="flex" justifyContent="space-between">
            <Box ml={2}>
              <Text
                fontFamily="Raleway"
                fontStyle="normal"
                fontWeight="bold"
                fontSize="18px"
                ml={3}
              >
                {t('heading_applicable_scenarios')}
              </Text>
            </Box>
            <Center>
              <Box as={Flex} mr={2} flexDirection="row" alignItems="center">
                <Button
                  leftIcon={<AddIcon />}
                  onClick={addRule}
                  alignSelf="flex-end"
                >
                  {t('button_add')}
                </Button>
              </Box>
            </Center>
          </Flex>
        </Box>
        <Box>
          {indexes.map((item, index) => {
            const fieldName = `holidayMasterRule[${index}]`;
            return (
              <Stack key={index}>
                <Box as={Flex} justifyContent="space-between" pl={6}>
                  <Text m={5} fontSize="16px" fontWeight="800">{`${t(
                    'label_scenario'
                  )} ${index + 1}`}</Text>

                  {index > 0 ? (
                    <Text
                      my={5}
                      mr={8}
                      fontSize="14px"
                      style={{ cursor: 'pointer' }}
                      fontWeight="600"
                      color="red.400"
                      onClick={removeRule(index)}
                    >
                      Remove Scenario
                    </Text>
                  ) : (
                    <Checkbox
                      colorScheme="brand"
                      name="freezeSecenario1"
                      onChange={value => {
                        onFreezeScrenarioChange(value);
                        
                      }}
                      defaultIsChecked={ Bro(checkBoxVal).iCanHaz(`${index}_checkBoxValue`)}
                      // ref={register}
                    >
                      <Text color="#8D8D8D" fontSize="13px" mr={8}>
                        {t('skip_this_Scenario')}
                      </Text>
                    </Checkbox>
                    
                  )}
                </Box>
                <Grid templateColumns="repeat(12, 1fr)" px={10} gap={8}>
                  <GridItem colSpan={6} pt={2}>
                    <ThemeRadioGroup
                      label={t('label_type_of_holiday')}
                      name={`[${fieldName}].holidayType`}
                      radioGroupValue={
                        Bro(radioButtonValues).iCanHaz(`${index}_holidayType`)}
                      
                      // radioGroupOnChange={e => setType(e)}
                      error={Bro(errors).iCanHaz(
                        `holidayMasterRule.${index}.holidayType`
                      )}
                      register={register}
                      mt={1}
                      isRequired
                      options={typeOfHolidayOptions}
                      isReadOnly={index > 0 ? false : freezeBtnValue && Bro(checkBoxVal).iCanHaz(`${index}_checkBoxValue`)}
                      direction="row"
                      spacing="20"
                    />
                  </GridItem>
                  <GridItem colSpan={3}>
                    <ThemeDateInput
                      showMonthDropdown={false}
                      showYearDropdown={false}
                      label={t('label_start_date')}
                      name={`[${fieldName}].fromDate`}
                      error={Bro(errors).iCanHaz(
                        `holidayMasterRule.${index}.fromDate`
                      )}
                      showTimeSelect={false}
                      dateFormat="yyyy/MM/dd"
                      minDate={getMonthStartDate(moment().subtract(4, 'months'))}
                      maxDate={getMonthEndDate(moment().add(7, 'months'))}
                      onSelect={date => setStartDate(date)}
                      disabled={index > 0 ? false : freezeBtnValue && Bro(checkBoxVal).iCanHaz(`${index}_checkBoxValue`)}
                      control={control}
                      //   defaultValue={}
                      mt={1}
                      isRequired
                    />
                  </GridItem>
                  <GridItem colSpan={3}>
                    <ThemeDateInput
                      showMonthDropdown={false}
                      showYearDropdown={false}
                      label={t('label_end_date')}
                      name={`[${fieldName}].toDate`}
                      error={Bro(errors).iCanHaz(
                        `holidayMasterRule.${index}.toDate`
                      )}
                      showTimeSelect={false}
                      dateFormat="yyyy/MM/dd"
                      minDate={startDate}
                      maxDate={getMonthEndDate(moment().add(7, 'months'))}
                      disabled={index > 0 ? false : freezeBtnValue && Bro(checkBoxVal).iCanHaz(`${index}_checkBoxValue`)}
                      control={control}
                      mt={1}
                      isRequired
                    />
                  </GridItem>

                  <GridItem colSpan={6}>
                    <Grid templateColumns="repeat(8, 1fr)" gap={8}>
                      <GridItem colSpan={8} mt={2}>
                        <ThemeRadioGroup
                          label={t('label_rule_applicable')}
                          name={`[${fieldName}].holidayRuleApplicable`}
                          error={Bro(errors).iCanHaz(
                            `holidayMasterRule.${index}.holidayRuleApplicable`
                          )}
                          register={register}
                          options={
                            index > 0
                              ? leaveRuleApplicableSpecificOptions
                              : leaveRuleApplicableAllOptions
                          }
                          radioGroupValue={index === 0 ? 'ALL' : 'SPECIFIC'}
                          // radioGroupValue={Bro(radioButtonValues).iCanHaz(`${index}_holidayRuleApplicable`)}
                          // radioGroupOnChange={changeDepartmentVisibility.bind(
                          //   null,
                          //   index
                          // )}
                          direction="row"
                          isReadOnly={
                            index > 0
                              ? false
                              : freezeBtnValue || index === 0
                              ? true
                              : false
                          }
                          spacing="20"
                          isRequired
                        />
                      </GridItem>
                      <GridItem
                        hidden={index<1}
                        colSpan={6}
                      >
                        <MultiSelect
                          name={`[${fieldName}].department`}
                          label={t('label_department')}
                          error={Bro(errors).iCanHaz(
                            `holidayMasterRules.${index}.department`
                          )}
                          placeholder={t('select_an_option')}
                          options={departmentOptions}
                          onChange={changeLocationVisibility.bind(null, index)}
                          labelKey="label"
                          valueKey="key"
                          defaultValue={
                            Bro(selectInputValues).iCanHaz(
                              `${index}_department`
                            )
                          }
                          control={control}
                          isMulti
                        />
                      </GridItem>
                      <GridItem hidden={!locationVisibility[index]} colSpan={6}>
                    
                        <MultiSelect
                          options={locationOptions}
                          label={t('label_location')}
                          name={`[${fieldName}].location`}
                          placeholder={t('select_an_option')}
                          // onChange={changeShiftVisibility.bind(null, index)}
                          defaultValue={
                            Bro(selectInputValues).iCanHaz(`${index}_location`)
                          }
                          labelKey="label"
                          valueKey="key"
                          control={control}
                          isMulti
                          error={Bro(errors).iCanHaz(
                            `leaveMasterRules.${index}.location`
                          )}
                        />
                      </GridItem>
                      {/* <GridItem hidden={!shiftVisibility[index]} colSpan={6}>
                      <MultiSelect
                          options={shiftOptions}
                          label={t('label_shift')}
                          name={`[${fieldName}].shift`}
                          placeholder={t('select_an_option')}
                          // onChange={changeShiftVisibility.bind(null, index)}
                          labelKey="label"
                          valueKey="key"
                          control={control}
                          isMulti
                          error={Bro(errors).iCanHaz(
                            `leaveMasterRules.${index}.shift`
                          )}
                        />
                      </GridItem> */}
                    </Grid>
                  </GridItem>
                  <GridItem colSpan={6}>
                    <SelectThemeInput
                      options={daysValue}
                      label={t('label_reminder_sent')}
                      labelKey="label"
                      valueKey="key"
                      name={`[${fieldName}].reminderSent`}
                      //error={errors.typeOfContract}
                      register={register}
                      placeholder={'Select a option'}
                      disabled={index > 0 ? false : freezeBtnValue && Bro(checkBoxVal).iCanHaz(`${index}_checkBoxValue`)}
                      type="text"
                      mt={3}
                    />
                  </GridItem>
                </Grid>
              </Stack>
            );
          })}
        </Box>

        <Box my={5} h={10} bg="#EAF2FF">
          <Center>
            <Text m={2} fontSize="14px" fontFamily="Open Sans">
              Note : Shift based Holidays will override the location based
              Holidays.
            </Text>
          </Center>
        </Box>
        <Flex px={10} pb={10}>
          <Button type="submit" isLoading={isLoading}>
            {t('btn_update_leave_master')}
          </Button>
          <Button
            ml={5}
            variant="ghost"
            onClick={onCancel}
          >
            {t('btn_cancel')}
          </Button>
        </Flex>
      </Box>
    </Flex>)
  );
};
export default EditHoliday;
