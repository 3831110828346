import { useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Grid, GridItem, Stack } from '@chakra-ui/layout';
import { useLocation, useNavigate, useParams } from '@reach/router';
import Bro from 'brototype';
import React from 'react'
import ThemeInput from '../../../../components/form-elements/theme-input';
import PageTitle from '../../../../components/misc/page-title';
import useNotify from '../../../../utils/notifications';
import { DocumentListSchema } from '../../../../utils/validations';
import ROUTES from '../../../routes';

const post = {
  url: '/documentList',
  method: 'post',
};

const put = {
  url: '/documentList/update',
  method: 'put',
};


const AddDocumentList=()=>{
    const navigate = useNavigate();
    const toast = useNotify();
    const { t,languages } = useI18nContext();
    const langList = Object.keys(languages);

    const { slug } = useParams();
    const isEdit = slug ? true : false;
    const documentListData = useLocation().state;

    const navigateToPrevScreen=()=>{
        navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_DOCUMENT_LIST)
    }

    
  const transformRequest = values => {
    const data = Object.assign(documentListData, values);
    return data;
  };

  const onSuccess = resp => {
    toast({
      title: !isEdit ? t('add_success') : t('update_success'),
      description: resp.message,
      status: 'success',
    });
    navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.MANAGE_DOCUMENT_LIST);
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const { isLoading, onSubmit, register, errors } = useFormController({
    onSuccess,
    onError,
    apiOptions: isEdit ? put : post,
    yupSchema: DocumentListSchema(t),
    transformRequest,
  });

    return (
        <Flex flex={1} direction="column">
        <Flex justifyContent="space-between" alignItems="center" mb={10}>
          <PageTitle title={isEdit ? t('update_document_list') : t('add_document_list')} />
        </Flex>
        <Box
          as={'form'}
          bgColor="white"
          borderRadius="16px"
          onSubmit={onSubmit}
          noValidate="noValidate"
        >
          <Stack>
            <Grid templateColumns="repeat(10, 1fr)" p={12} gap={8}>
            {langList.map(item => (
              <GridItem key={item} colSpan={4}>
                <ThemeInput
                 defaultValue={isEdit ? documentListData.documentName[`${item}`] : null}
                  label={`${t('label_upload_doc')}- ${item.toUpperCase()}`}
                  name={`documentName.${item}`}
                  type="text"
                  register={register}
                  error={Bro(errors).iCanHaz(`documentName.${item}`)}
                  mt={1}
                  isRequired
                />
              </GridItem>
            ))}
            </Grid>
          </Stack>
          <Flex px={10} pb={10} ml={2}>
            <Button px="2rem" type="submit" isLoading={isLoading} mr={5}>
              {t('button_text_save')}
            </Button>
            <Button variant="ghost" onClick={navigateToPrevScreen}>
              {t('btn_cancel')}
            </Button>
          </Flex>
        </Box>
      </Flex>
      
    )
}
export default AddDocumentList;