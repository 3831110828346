import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch
} from '@chakra-ui/react';
import React from 'react';

const ThemeSwitch = ({
  name,
  defaultValue,
  register,
  label,
  error,
  isRequired,
  ...rest
}) => {
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
      <Flex direction="row" mt={10}>
      <FormLabel fontFamily="Open Sans" fontWeight="small">
        {label}
      </FormLabel>
      <Switch
        size="md"
        name={name}
        defaultChecked={defaultValue}
        focusBorderColor="brand.300"
        ref={register}
        onBlur={e => e.target.value = e.target.value.trim()}
        {...rest}
      />
      </Flex>
      {error && (
        <FormErrorMessage fontFamily="Open Sans" fontSize="12px">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default ThemeSwitch;
