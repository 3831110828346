import React, { useEffect, useState } from 'react';
import { useFormController } from '@bit/sixsprints.core.beans-web';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Grid, GridItem,Center } from '@chakra-ui/react';
import Bro from 'brototype';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import SelectThemeInput from '../../../../components/form-elements/select-input';
import ThemeInput from '../../../../components/form-elements/theme-input';
import ThemeTextAreaInput from '../../../../components/form-elements/theme-textarea';
import ThemeHeading from '../../../../components/misc/heading';
import ForwardArrow from '../../../../icons/forward-arrow';
import useNotify from '../../../../utils/notifications';
import DataService from '../../../../services/data-service';
import Loader from '../../../../components/misc/loader';

const SummaryInfo = ({ next, prev, userSlug,userData, isEdit }) => {
  const toast = useNotify();
  const { t } = useI18nContext();
  const [isDocLoading, setIsDocLoading] = useState(false);


  const apiOptions = {
    url: `/user/user-summary?userSlug=${userSlug}`,
    method: 'put',
  };

  const onSuccess = resp => {
    if (!isEdit) {
      toast({
        title: t('summary_info_success_toast'),
        description: resp.message,
        status: 'success',
      });
      next();
    } else {
      toast({
        title: t('summary_info_updated'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    errors,
    setValue,
    reset: resetForm,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    // yupSchema: BankValidationSchema(t),
  });

  const getUserInfo = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.userSummaryDto');
        resetForm(data);
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(getUserInfo, []);

  return (
    (isDocLoading  && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
        <Flex>
          <Box as={Flex} direction="column" mb={5}>
            {!isEdit ? 
            (<ThemeHeading heading={t('summary_info_heading')} subHeading={t('details_subheading')} mb={10}/>):(<ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
              'edit_profile_header_text'
            )}`} subHeading={t('details_subheading')}/>)}
          </Box>
        </Flex>
        <Center>
          <Loader />
        </Center>
      </Flex>
    )) || (
    <Flex flexGrow={1}>
      <Flex flex={1} direction="column" mr={'10em'}>
        {!isEdit ? (
          <ThemeHeading heading={t('summary_info_heading')}  subHeading={t('details_subheading')} mb={10} />
        ):(<ThemeHeading mb={10} heading={`${Bro(userData).iCanHaz('firstName')} ${t(
          'edit_profile_header_text'
        )}`} subHeading={t('details_subheading')}/>)}
        <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate">
          <Grid templateColumns="repeat(6, 1fr)" gap={6}>
            <GridItem colSpan={6} mt={3}>
              <ThemeTextAreaInput
                label={t('label_job_description')}
                name="jobDescription"
                error={errors.jobDescription}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>

            <GridItem colSpan={6}>
              <ThemeInput
                label={t('label_about_me')}
                name="aboutMe"
                error={errors.aboutMe}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={6}>
              <ThemeInput
                label={t('label_askme_about_expertise')}
                name="userExpertise"
                error={errors.userExpertise}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
          </Grid>
          <Flex justifyContent="space-between" alignItems="center" my={14}>
            {isEdit ? (
              <Flex flex={1} justifyContent={'flex-end'}>
                <Button align="flex-end" type="submit" px={10} isLoading={isLoading}>
                  {t('button_text_save')}
                </Button>
              </Flex>
            ) : (
              <>
                <Button
                  leftIcon={<ArrowBackIcon />}
                  variant="ghost"
                  onClick={prev}
                >
                  {t('button_text_back')}
                </Button>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  rightIcon={<ForwardArrow />}
                >
                  {t('button_text_save_next')}
                </Button>
              </>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  ));
};

export default SummaryInfo;
