import React, { useEffect, useState } from 'react';
import { useFormController } from '@bit/sixsprints.core.beans-web';
import { ArrowBackIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Center, Flex, Grid, GridItem } from '@chakra-ui/react';
import Bro from 'brototype';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import SelectThemeInput from '../../../../components/form-elements/select-input';
import ThemeAsyncSelectInput from '../../../../components/form-elements/theme-async-select-input';
import ThemeInput from '../../../../components/form-elements/theme-input';
import ThemeDateInput from '../../../../components/form-elements/date-picker/date-picker';
import ThemeHeading from '../../../../components/misc/heading';
import ForwardArrow from '../../../../icons/forward-arrow';
import useNotify from '../../../../utils/notifications';
import { isAdmin } from '../../../../utils/helpers';
import DataService from '../../../../services/data-service';
import Loader from '../../../../components/misc/loader';

const WorkInformation = ({ next, prev, userSlug, userData, isEdit }) => {
  const toast = useNotify();
  const { t } = useI18nContext();
  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);
  const { langCode } = useI18nContext();
  const [workInfo, setWorkInfo] = useState({});
  const [previousExperience, setPreviousExperience] = useState(null);
  const [jptcExperience, setJptcExperience] = useState(null);
  const [totalExperience, setTotalExperience] = useState(null);
  const [availableSeats, setAvailableSeats] = useState([]);
  const apiOptions = {
    url: `/user/work-information?userSlug=${userSlug}`,
    method: 'put',
  };

  const onSuccess = resp => {
    if (!isEdit) {
      toast({
        title: t('work_information_success_toast'),
        description: resp.message,
        status: 'success',
      });
      next();
    } else {
      toast({
        title: t('work_information_updated'),
        description: resp.message,
        status: 'success',
      });
      next();
    }
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const transformRequest = values => {
    const data = { ...values };
    data.dateOfJoining =
      new Date(data.dateOfJoining).getTime() || workInfo?.dateOfJoining;

    data.seatingLocation = data?.seatingLocation || workInfo?.seatingLocation;

    data.departmentSlug = data?.department?.slug || workInfo?.departmentSlug;
    delete data.department;

    data.designationSlug = data?.designation?.slug || workInfo?.designationSlug;
    delete data.designation;

    data.employeeTypeSlug =
      data?.employeeType?.slug || workInfo?.employeeTypeSlug;
    delete data.employeeType;

    data.sourceOfHireSlug =
      data?.sourceOfHire?.slug || workInfo?.sourceOfHireSlug;
    delete data.sourceOfHire;

    data.employeeStatusSlug =
      data?.employeeStatus?.slug || workInfo?.employeeStatusSlug;
    delete data.employeeStatus;

    data.roleSlug = data?.role?.slug || workInfo?.roleSlug;
    delete data.role;

    data.managerSlug = data?.managerSlug?.slug || workInfo?.managerSlug;

    data.locationSlug = data?.location?.slug || workInfo?.locationSlug;
    delete data.location;

    // data.previousExperience = Number.parseFloat(previousExperience);
    // data.jptcExperience = Number.parseFloat(jptcExperience);

    // data.totalExperience =
    //   Number.parseFloat(data.previousExperience) +
    //     Number.parseFloat(data.jptcExperience) || 0;

    data.totalExperience = Number.parseFloat(totalExperience);

    return data;
  };

  const {
    isLoading,
    onSubmit,
    register,
    errors,
    setValue,
    control,
    reset: resetForm,
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    transformRequest,
  });
  const [isDocLoading, setIsDocLoading] = useState(false);

  const getUserInfo = () => {
    setIsDocLoading(true);
    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.workInformationDto');
        resetForm(data);
        setWorkInfo(data);
        // const jptcExperienceInYears = moment
        //   .duration(moment().diff(moment(jptcExp)))
        //   .asYears()
        //   .toFixed(2);
        let jptcExp = data?.jptcExperience;
        const jptcExperienceInYears = Number.parseFloat(jptcExp / 365).toFixed(
          2
        );
        setJptcExperience(jptcExperienceInYears);

        let prevExp = data?.previousExperience;
        const previousExperienceInYears = Number.parseFloat(
          prevExp / 365
        ).toFixed(2);
        setPreviousExperience(previousExperienceInYears);

        let totalExp = data?.totalExperience;
        const totalExperienceInYears = Number.parseFloat(
          totalExp / 365
        ).toFixed(2);
        setTotalExperience(totalExperienceInYears);

        // const workExperienceDto = Bro(resp).iCanHaz(
        //   'data.data.workExperienceDto'
        // );
        // const totalDurationInDays =
        //   workExperienceDto.reduce((total, item) => total + item.duration, 0) ||
        //   0;
        // const totalExperienceInYears = Number.parseFloat(
        //   totalDurationInDays / 365
        // ).toFixed(2);
        setIsDocLoading(false);
      })
      .catch(err => {
        setIsDocLoading(false);
      });
  };

  const transformUserList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      // obj.userId = item.userId;
      obj.name = item.personalInfo.firstName + ' ' + item.personalInfo.lastName;
      // obj.manager = item.manager;
      // obj.managerSlug = item.managerSlug;
      return obj;
    });
    return transformedList;
  };

  const promiseOptions = async text => {
    let data = await DataService.post('/user/search', {
      page: 0,
      size: 50,
      filterModel: {
        'personalInfo.firstName': {
          filterType: 'text',
          type: null,
          filter: text,
        },
        'personalInfo.lastName': {
          filterType: 'text',
          type: null,
          filter: text,
        },
      },
    }).then(resp => resp.data.data.content);

    let managerData = data.filter(item => {
      return item?.workInformationDto?.roleName?.includes('Manager');
    });
    return transformUserList(managerData);
  };

  const transformDepartmentList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.departmentName[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptionsForDepartment = async () => {
    let data = await DataService.post('/department/search', {
      page: 0,
      size: 30,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformDepartmentList(data);
    return data;
  };

  const transformLocationList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.locationName[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptionsForLocation = async () => {
    let data = await DataService.post('/location/search', {
      page: 0,
      size: 50,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformLocationList(data);
    return data;
  };

  const transformDesignationList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.designationName[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptionsForDesignation = async () => {
    let data = await DataService.post('/designation/search', {
      page: 0,
      size: 30,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformDesignationList(data);
    return data;
  };

  const transformEmployeeTypeList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.employeeType[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptionsForEmployeeType = async () => {
    let data = await DataService.post('/employeeType/search', {
      page: 0,
      size: 30,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformEmployeeTypeList(data);
    return data;
  };

  const transformRoleMasterList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.roleName[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptionsForRoleMaster = async () => {
    let data = await DataService.post('/roleMaster/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformRoleMasterList(data);
    return data;
  };

  const transformSourceOfHireList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.sourceOfHire[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptionsForSourceOfHire = async () => {
    let data = await DataService.post('/sourceOfHire/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformSourceOfHireList(data);
    return data;
  };

  const transformEmployeeStatus = data => {
    let transformEmployeeStatus = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.statusName[langCode];
      return obj;
    });
    return transformEmployeeStatus;
  };

  const promiseOptionsForEmployeeStatus = async () => {
    let data = await DataService.post('/employeeStatusMaster/search', {
      page: 0,
      size: 20,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformEmployeeStatus(data);
    return data;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(getUserInfo, []);

  const getSeatsInfo = () => {
    DataService.get(`/seats/seats-avaialable?userSlug=${userSlug}`).then(
      resp => {
        const data = Bro(resp).iCanHaz('data.data');
        setAvailableSeats(
          data.map(item => {
            return { key: item, label: item };
          })
        );
      }
    );
  };

  useEffect(getSeatsInfo, []);

  useEffect(() => {
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode]);

  return (
    (isDocLoading && (
      <Flex
        flex={1}
        h="100%"
        overflow="auto"
        direction="column"
        css={{
          '&::-webkit-scrollbar': {
            width: '0px',
          },
        }}
      >
       <Flex>
          <Box as={Flex} direction="column" mb={5}>
            {!isEdit ? 
            (<ThemeHeading heading={t('work_info_heading')} subHeading={t('details_subheading')} mb={10}/>):(<ThemeHeading heading={`${Bro(userData).iCanHaz('firstName')} ${t(
              'edit_profile_header_text'
            )}`} subHeading={t('details_subheading')}/>)}
          </Box>
        </Flex>
        <Center>
          <Loader />
        </Center>
      </Flex>
    )) || (
      <Flex flexGrow={1}>
        <Flex flex={1} direction="column" mr={'10em'}>
          {!isEdit ? (
            <ThemeHeading
              heading={t('work_info_heading')}
              subHeading={t('details_subheading')}
              mb={10}
            />
          ) : (
            <ThemeHeading
              mb={10}
              heading={`${Bro(userData).iCanHaz('firstName')} ${t(
                'edit_profile_header_text'
              )}`}
              subHeading={t('details_subheading')}
            />
          )}
          <Box as={'form'} onSubmit={onSubmit} noValidate="noValidate">
            <Grid templateColumns="repeat(6, 1fr)" gap={6}>
              <GridItem colSpan={2} mt={3}>
                {isAdmin() ? (
                  <ThemeAsyncSelectInput
                    key={shouldLoadOptions}
                    promiseOptions={promiseOptionsForDepartment}
                    labelKey="name"
                    valueKey="slug"
                    defaultValue={[
                      {
                        name: workInfo?.departmentName,
                        slug: workInfo?.departmentSlug,
                      },
                    ]}
                    name="department"
                    control={control}
                    error={errors.department}
                    label={t('label_department')}
                    placeholder={t('select_an_option')}
                  />
                ) : (
                  <ThemeInput
                    label={t('label_department')}
                    value={workInfo?.departmentName}
                    type="text"
                    isReadOnly
                  />
                )}
              </GridItem>
              <GridItem colSpan={2} mt={3}>
                {isAdmin() ? (
                  <ThemeAsyncSelectInput
                    key={shouldLoadOptions}
                    defaultValue={[
                      {
                        name: workInfo?.locationName,
                        slug: workInfo?.locationSlug,
                      },
                    ]}
                    promiseOptions={promiseOptionsForLocation}
                    labelKey="name"
                    valueKey="slug"
                    name="location"
                    control={control}
                    error={errors.location}
                    label={t('label_location')}
                    placeholder={t('select_an_option')}
                  />
                ) : (
                  <ThemeInput
                    label={t('label_location')}
                    value={workInfo?.locationName}
                    type="text"
                    isReadOnly
                  />
                )}
              </GridItem>
              <GridItem colSpan={2} mt={3}>
                {isAdmin() ? (
                  <ThemeAsyncSelectInput
                    // showKeyInLabel={true}
                    promiseOptions={promiseOptions}
                    defaultValue={[
                      {
                        name: workInfo?.reportingTo,
                        managerSlug: workInfo?.managerSlug,
                      },
                    ]}
                    labelKey="name"
                    valueKey="slug"
                    // labelIdKey="userId"
                    name="managerSlug"
                    control={control}
                    label={t('label_reporting_to')}
                    error={Bro(errors).iCanHaz('reportingTo')}
                    placeholder={<SearchIcon />}
                  />
                ) : (
                  <ThemeInput
                    label={t('label_reporting_to')}
                    value={workInfo?.reportingTo}
                    type="text"
                    isReadOnly
                  />
                )}
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                {isAdmin() ? (
                  <ThemeAsyncSelectInput
                    key={shouldLoadOptions}
                    promiseOptions={promiseOptionsForDesignation}
                    defaultValue={[
                      {
                        name: workInfo?.designationName,
                        slug: workInfo?.designationSlug,
                      },
                    ]}
                    labelKey="name"
                    valueKey="slug"
                    name="designation"
                    control={control}
                    error={errors.designation}
                    label={t('label_designation')}
                    placeholder={t('select_an_option')}
                  />
                ) : (
                  <ThemeInput
                    label={t('label_designation')}
                    value={workInfo?.designationName}
                    type="text"
                    isReadOnly
                  />
                )}
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                <ThemeDateInput
                  label={t('label_date_of_joining')}
                  name="dateOfJoining"
                  error={errors.dateOfJoining}
                  showTimeSelect={false}
                  dateFormat="yyyy/MM/dd"
                  control={control}
                  mt={1}
                  disabled={!isAdmin()}
                />
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                {isAdmin() ? (
                  <ThemeAsyncSelectInput
                    key={shouldLoadOptions}
                    promiseOptions={promiseOptionsForEmployeeType}
                    labelKey="name"
                    valueKey="slug"
                    defaultValue={[
                      {
                        name: workInfo?.employeeType,
                        slug: workInfo?.employeeTypeSlug,
                      },
                    ]}
                    name="employeeType"
                    control={control}
                    error={errors.employeeType}
                    label={t('label_employee_type')}
                    placeholder={t('select_an_option')}
                  />
                ) : (
                  <ThemeInput
                    label={t('label_employee_type')}
                    value={workInfo?.employeeType}
                    type="text"
                    isReadOnly
                  />
                )}
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                {isAdmin() ? (
                  <ThemeAsyncSelectInput
                    key={shouldLoadOptions}
                    promiseOptions={promiseOptionsForRoleMaster}
                    labelKey="name"
                    valueKey="slug"
                    name="role"
                    defaultValue={[
                      {
                        name: workInfo?.roleName,
                        slug: workInfo?.roleSlug,
                      },
                    ]}
                    control={control}
                    error={errors.role}
                    label={t('label_role')}
                    placeholder={t('select_an_option')}
                  />
                ) : (
                  <ThemeInput
                    label={t('label_role')}
                    value={workInfo?.roleName}
                    type="text"
                    isReadOnly
                  />
                )}
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                {isAdmin() ? (
                  <SelectThemeInput
                    options={availableSeats}
                    label={t('label_seating_location')}
                    name="seatingLocation"
                    error={errors.seatingLocation}
                    valueKey="key"
                    labelKey="label"
                    register={register}
                    type="text"
                    placeholder={t('select_an_option')}
                    mt="1.1rem"
                  />
                ) : (
                  <ThemeInput
                    label={t('label_seating_location')}
                    value={workInfo?.seatingLocation}
                    type="text"
                    isReadOnly
                  />
                )}
              </GridItem>
              <GridItem colSpan={2} mt={2}>
                <ThemeInput
                  label={t('label_work_phone')}
                  name="phone"
                  error={errors.workPhone}
                  register={register}
                  isReadOnly={!isAdmin()}
                  type="text"
                  mt={1}
                  control={control}
                />
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                <ThemeInput
                  label={t('label_previous_experience')}
                  value={previousExperience}
                  type="text"
                  mt={1}
                  control={control}
                  isReadOnly
                />
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                <ThemeInput
                  label={t('label_jptc_experience')}
                  value={jptcExperience}
                  type="number"
                  mt={1}
                  control={control}
                  isReadOnly
                />
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                <ThemeInput
                  label={t('label_total_experience')}
                  value={totalExperience}
                  type="text"
                  mt={1}
                  control={control}
                  isReadOnly
                />
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                {isAdmin() ? (
                  <ThemeAsyncSelectInput
                    key={shouldLoadOptions}
                    promiseOptions={promiseOptionsForEmployeeStatus}
                    defaultValue={[
                      {
                        name: workInfo?.employeeStatus,
                        slug: workInfo?.employeeStatusSlug,
                      },
                    ]}
                    labelKey="name"
                    valueKey="slug"
                    name="employeeStatus"
                    control={control}
                    error={errors.employeeStatus}
                    label={t('label_employee_status')}
                    placeholder={t('select_an_option')}
                  />
                ) : (
                  <ThemeInput
                    label={t('label_employee_status')}
                    value={workInfo?.employeeStatus}
                    type="text"
                    isReadOnly
                  />
                )}
              </GridItem>

              <GridItem colSpan={2} mt={3}>
                {isAdmin() && (
                  <ThemeAsyncSelectInput
                    key={shouldLoadOptions}
                    promiseOptions={promiseOptionsForSourceOfHire}
                    defaultValue={[
                      {
                        name: workInfo?.sourceOfHire,
                        slug: workInfo?.sourceOfHireSlug,
                      },
                    ]}
                    labelKey="name"
                    valueKey="slug"
                    name="sourceOfHire"
                    control={control}
                    error={errors.sourceOfHire}
                    label={t('label_source_of_hire')}
                    placeholder={t('select_an_option')}
                  />
                )}
              </GridItem>
            </Grid>
            <Flex justifyContent="space-between" alignItems="center" my={14}>
              {isEdit ? (
                <Flex flex={1} justifyContent={'flex-end'}>
                  {isAdmin() && (
                    <Button
                      align="flex-end"
                      type="submit"
                      px={10}
                      isLoading={isLoading}
                    >
                      {t('button_text_save')}
                    </Button>
                  )}
                </Flex>
              ) : (
                <>
                  <Button
                    leftIcon={<ArrowBackIcon />}
                    variant="ghost"
                    onClick={prev}
                  >
                    {t('button_text_back')}
                  </Button>
                  {isAdmin() ? (
                    <Button
                      type={'submit'}
                      isLoading={isLoading}
                      rightIcon={<ForwardArrow />}
                    >
                      {t('button_text_save_next')}
                    </Button>
                  ) : (
                    <Button
                      onClick={next}
                      isLoading={isLoading}
                      rightIcon={<ForwardArrow />}
                    >
                      {t('btn_text_next')}
                    </Button>
                  )}
                </>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
    )
  );
};

export default WorkInformation;
