const baseStyle = {
  fontSize: 'sm',
  color: 'black.100',
  fontWeight: 'normal',
  fontFamily: 'Open Sans',
};

const FormLabel = {
  baseStyle,
};

export default FormLabel;
