import * as React from 'react';
import { Icon } from '@chakra-ui/react';

function LogoutIcon(props) {
  return (
    <Icon width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
      <path
        opacity={0.4}
        d="M2.001 6.447C2.001 3.996 4.031 2 6.526 2h4.96c2.49 0 4.515 1.99 4.515 4.437v11.116c0 2.452-2.03 4.447-4.526 4.447H6.516c-2.49 0-4.515-1.99-4.515-4.437V6.447z"
        fill="#0461FF"
      />
      <path
        d="M21.778 11.455l-2.846-2.91a.736.736 0 00-1.06.003.785.785 0 00.002 1.089l1.559 1.593H9.548a.76.76 0 00-.75.77c0 .426.336.77.75.77h9.885l-1.56 1.593a.785.785 0 00-.001 1.089.74.74 0 00.532.227.74.74 0 00.528-.225l2.846-2.908a.783.783 0 000-1.091z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default LogoutIcon;
