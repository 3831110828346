import React from 'react';
import { FormLabel, FormControl, FormErrorMessage } from '@chakra-ui/react';
import CustomDatePicker from '@bit/sixsprints.date-picker.date-picker';
import './date-picker.css';

const ThemeDateInput = ({
  name,
  control,
  defaultValue,
  label,
  error,
  isRequired,
  ...rest
}) => {
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
      <FormLabel mb={"18px"}>{label}</FormLabel>
      <CustomDatePicker
        showMonthDropdown
        showYearDropdown
        name={name}
        control={control && control}
        defaultValue={defaultValue}
        showTimeSelect
        {...rest}
      />
      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export default ThemeDateInput;
