import React from "react";
import { Icon } from "@chakra-ui/react"




function CheckCircle(props) {
  return (
    <Icon
      width={5}
      height={5}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M10.5 1.75a8.75 8.75 0 100 17.5 8.75 8.75 0 000-17.5zm0 15.75a7 7 0 110-14 7 7 0 010 14z"
        fill="#1ECC78"
      />
      <path
        d="M12.863 7.341l-3.308 4.375L8.129 9.87a.876.876 0 10-1.383 1.076l2.127 2.721a.875.875 0 001.076.242.875.875 0 00.306-.25l3.999-5.25a.88.88 0 10-1.4-1.068h.009z"
        fill="#1ECC78"
      />
    </Icon>
  )
}

export default CheckCircle;



