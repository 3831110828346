const colors = {
  brand: {
    50: 'rgba(4, 97, 255, 0.1)',
    100: 'rgba(4, 97, 255, 0.2)',
    200: 'rgba(4, 97, 255, 0.4)',
    300: 'rgba(4, 97, 255, 0.6)',
    400: 'rgba(4, 97, 255, 0.8)',
    500: 'rgba(4, 97, 255, 1)',
    600: '#064bc1',
    700: 'rgb(11, 67, 165)',
    800: 'rgb(9, 58, 144)',
    900: 'rgb(7, 47, 117)',
  },
  gray: {
    100: '#F5F6F9',
    200: '#ACACAC',
    500: '#818081',
  },
  blue: {
    100: '#E4EEFF',
    300: '#0461FF',
    500: '#0461FF',
    900: '#542E71'
  },
};

export default colors;
