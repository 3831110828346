const baseStyle = {
  fontSize: 'md',
  color: 'black.100',
  borderRadius: '16px',
};

const defaultProps = {
  size: 'md',
  variant: 'filled',
};

const variants = {
  filled: {
    field: {
      border: '1px solid',
      borderColor: '#E4E4E4',
      borderRadius: '12px',
      fontWeight: '400',
      fontFamily: 'Open Sans',
      bg: '#FFFFFF',
      _hover: {
        bg: '#FBFBFB',
      },
      _focus: {
        bg: '#FBFBFB',
      },
    },
  },
  ghost: {
    field: {
      fontWeight: '400',
      fontFamily: 'Open Sans',
      _hover: {
        bg: '#FBFBFB',
      },
      _focus: {
        bg: '#FBFBFB',
      },
    },
  },
};

const Select = {
  baseStyle,
  defaultProps,
  variants,
};

export default Select;
