const styles = {
  global: {
    heading: {
      fontFamily: 'Raleway',
    },
    body: {
      fontFamily: 'Open Sans',
      color: '#2B2B2B',
      maxWidth: '100%',
      overflowX: 'hidden'
    },
  },
};

export default styles;
