import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Button } from '@chakra-ui/button';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/layout';
import { useLocation, useNavigate } from '@reach/router';
import ThemeRadioGroup from '../../../components/form-elements/radio-input';
import SelectThemeInput from '../../../components/form-elements/select-input';
import ThemeInput from '../../../components/form-elements/theme-input';
import AlertOutlineSvg from '../../../components/misc/alert-outline-svg';
import PageTitle from '../../../components/misc/page-title';
import { Center, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { storage, useFormController } from '@bit/sixsprints.core.beans-web';
import { AppConstants } from '../../../utils/constants';
import DataService from '../../../services/data-service';
import Bro from 'brototype';
import ROUTES from '../../routes';
import MultiSelect from '../../../components/form-elements/multi-select/multi-select';
import { Country } from '../location/country/country-data';
import { SearchIcon } from '@chakra-ui/icons';
import ThemeAsyncSelectInput from '../../../components/form-elements/theme-async-select-input';
import useNotify from '../../../utils/notifications';
import { getSelectedTimeZone } from '../../../utils/helpers';
import Loader from '../../../components/misc/loader';
import { CompanyDetailsSchema } from '../../../utils/validations';
import { BehaviorSubject } from 'rxjs';

export const titleSubject = new BehaviorSubject(false);


const apiOptions = {
  url: '/org/update',
  method: 'put',
};

const EditCompanyDetails = () => {
  const navigate = useNavigate();
  const toast = useNotify();
  const { t, languages, langCode } = useI18nContext();
  const langList = Object.keys(languages);
  const stateData = useLocation().state;
  const [shouldLoadOptions, setShouldLoadOptions] = useState(false);

  const [typeOfTimeFormat, setTypeOfTimeFormat] = useState(null);
  const [typeOfDateFormat, setTypeOfDateFormat] = useState(null);
  const [displayNameType, setDisplayNameType] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);

  const transformRequest = data => {
    const companyData=
    {
      address: {
        addressLine1: data.addressLine1,
        addressLine2: data.addressLine2,
        city: data.city,
        country: data.countrySlug && data.countrySlug.name,
        countrySlug:data.countrySlug && data.countrySlug.slug,
        postal: data.postal,
        state: data.stateSlug && data.stateSlug.name,
        stateSlug:data.stateSlug && data.stateSlug.slug,
      },
      contactNumber: data.contactNumber,
      contactPerson: data.contactPerson,
      defaultEmail: data.defaultEmail,
      displaySettings: {
        dateFormat: data.dateFormat || null,
        displayName: data.displayName || null,
        timeFormat: data.timeFormat || Bro(companyInfo).iCanHaz('displaySettings.timeFormat') || null ,
      },
      email: data.email,
      id: data.id,
      industry: data.industry,
      localeSetting: {
        country: data.countrySlug && data.countrySlug.name,
        countrySlug:data.countrySlug && data.countrySlug.slug,
        timeZone: data.timeZone
      },
      locales:companyInfo && companyInfo.locales,
      name: data.name,
      superAdminEmail: data.superAdminEmail,
      timeFormat: data.timeFormat,
      webSite: data.webSite,
      id:companyInfo && companyInfo.id,
      slug:companyInfo && companyInfo.slug,
      theme:companyInfo && companyInfo.theme,
    }
    return companyData;
  }

  const onSuccess = resp => {
    titleSubject.next(true);
    toast({
      title: t('company_details_updated'),
      description: resp.message,
      status: 'success',
    });
    navigate(ROUTES.DASHBOARD + ROUTES.SET_UP + ROUTES.COMPANY_DETAILS);
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const {
    isLoading,
    onSubmit,
    register,
    errors,
    reset: resetForm,
    control
  } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: CompanyDetailsSchema(t),
    transformRequest,
  });

  const getCompanyInfo = () => {
    DataService.post('org/search', { page: 0, size: 4 })
      .then(resp => {
        const data = Bro(resp).iCanHaz('data.data.content');
        let companyDetails=data[0];
      setCompanyInfo(companyDetails);
      resetForm(companyDetails);
    });
  };

  const transformCountryList = data => {
    let transformedList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.country[langCode];
      return obj;
    });
    return transformedList;
  };

  const promiseOptions = async () => {
    let data = await DataService.post('/country/search', {
      page: 0,
      size: 10,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data = transformCountryList(data);
    return data;
  };

  const transformStateList = data => {
    let transformedStateList = data.map(item => {
      let obj = {};
      obj.slug = item.slug;
      obj.name = item.state[langCode];
      return obj;
    });
    return transformedStateList;
  };

  const promiseOptionsForState = async () =>{
    let data = await DataService.post('/state/search',{
      page: 0,
      size: 10,
      filterModel: {},
    }).then(resp => resp.data.data.content);
    data=transformStateList(data);
    return data;
  }
  

  useEffect(() => {
    let timeFormat = storage.get(AppConstants.AUTH.CONFIG).timeFormat;
    let DateFormat = storage.get(AppConstants.AUTH.CONFIG).dateFormat;
    let displayNameType = storage.get(AppConstants.AUTH.CONFIG).dispalyName;
    setTypeOfTimeFormat(timeFormat);
    setTypeOfDateFormat(DateFormat);
    setDisplayNameType(displayNameType);
  }, []);

  useEffect(getCompanyInfo, []);

  useEffect(() => {
    setShouldLoadOptions(!shouldLoadOptions);
  }, [langCode])

  return ( !companyInfo && (<Flex
    flex={1}
    h="100%"
    overflow="auto"
    direction="column"
    css={{
      '&::-webkit-scrollbar': {
        width: '0px',
      },
    }}
  >
    
    <Flex>
      <Box as={Flex} direction="column" mb={5}>
        <PageTitle title={t('company_details_label')} />

        <Text
          fontFamily="Raleway"
          fontWeight="600"
          fontSize="14px"
          color="#818081"
          mt={2}
        >
          {t('company_details_subheading')}
        </Text>
      </Box>
    </Flex>  <Center>
      <Loader/>
      </Center></Flex>)||(


    <Flex
      flex={1}
      h="100%"
      overflow="auto"
      direction="column"
      css={{
        '&::-webkit-scrollbar': {
          width: '0px',
        },
      }}
    >
      
      <Flex>
        <Box as={Flex} direction="column" mb={5}>
          <PageTitle title={t('company_details_label')} />

          <Text
            fontFamily="Raleway"
            fontWeight="600"
            fontSize="14px"
            color="#818081"
            mt={2}
          >
            {t('company_details_subheading')}
          </Text>
        </Box>
      </Flex>
     
      <Box  
        as={'form'}
        onSubmit={onSubmit}
        noValidate="noValidate">
         <Box py={1} bgColor="white" borderRadius="16px">
        <Text
          m={4}
          px={4}
          fontSize="20px"
          fontWeight="bold"
          fontFamily="Raleway"
          color="#24293D"
        >
          {t('basic_details')}
        </Text>
        <Box m={5}>
          <Grid templateColumns="repeat(10, 1fr)" p={4} gap={6} mr={10}>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('company_name')}
                name="name"
                error={errors.name}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('website')}
                name="webSite"
                register={register}
                error={errors.website}
                type="text"
                mt={1}
              />
            </GridItem>

            <GridItem colSpan={5}>
              <ThemeInput
                label={t('industry')}
                name="industry"
                error={errors.industry}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>

            <GridItem colSpan={5}>
              <ThemeInput
                label={t('contact_person_name')}
                name="contactPerson"
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('contact_number')}
                name="contactNumber"
                error={Bro(errors).iCanHaz('contactNumber')}
                register={register}
                type="number"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('label_email')}
                name="email"
                error={Bro(errors).iCanHaz(`email`)}
                register={register}
                type="text"
                mt={1}
              />
            </GridItem>
          </Grid>
        </Box>
        <Box px={10} py={2} bg="#FAFAFA">
          <Text
            fontSize="16px"
            fontWeight="bold"
            fontFamily="Raleway"
            color="#24293D"
          >
            {t('address_details')}
          </Text>
        </Box>
        <Box m={5}>
          <Grid templateColumns="repeat(10, 1fr)" p={4} gap={6} mr={10}>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('address_line1')}
                name="addressLine1"
                register={register}
                defaultValue={companyInfo.address && companyInfo.address.addressLine1||''}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('address_line2')}
                name="addressLine2"
                register={register}
                defaultValue={companyInfo.address && companyInfo.address.addressLine2||''}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('city_label')}
                name="city"
                register={register}
                defaultValue={companyInfo.address && companyInfo.address.city || ''}
                type="text"
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={5}>
            <ThemeAsyncSelectInput
                key={shouldLoadOptions}
               defaultValue={ {name: companyInfo.address && companyInfo.address.state || '', slug: companyInfo.address && companyInfo.address.stateSlug|| ''}}
                promiseOptions={promiseOptionsForState}
                labelKey="name"
                valueKey="slug"
                name="stateSlug"
                control={control}
                error={errors.stateSlug}
                label={t('state_province_label')}
                placeholder={<SearchIcon />}
              />
            </GridItem>
            <GridItem colSpan={5}>
            <ThemeAsyncSelectInput
                key={shouldLoadOptions}
               defaultValue={ {name: companyInfo.address && companyInfo.address.country ||'', slug: companyInfo.address && companyInfo.address.countrySlug ||''}}
                promiseOptions={promiseOptions}
                labelKey="name"
                valueKey="slug"
                name="countrySlug"
                control={control}
                error={errors.countrySlug}
                label={t('field_select_country')}
                placeholder={<SearchIcon />}
              />
            </GridItem>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('zip_pin_code')}
                name="postal"
                register={register}
                defaultValue={companyInfo.address && companyInfo.address.postal|| ''}
                type="number"
                mt={1}
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <Box mt={4} py={1} bgColor="white" borderRadius="16px">
        <Box as={Flex} m={4} px={4}>
          <Text
            fontSize="20px"
            fontWeight="bold"
            fontFamily="Raleway"
            color="#24293D"
          >
            {t('super_administrator')}
          </Text>

          <Tooltip
            hasArrow
            borderRadius="14px"
            label={t('suggestion_super_admin')}
            placement="right-end"
            bg="white"
            color="black"
          >
            <Box m={2}>
              <AlertOutlineSvg />
            </Box>
          </Tooltip>
        </Box>
        <Box m={5}>
          <Grid templateColumns="repeat(10, 1fr)" p={4} gap={6} mr={10}>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('email_id')}
                name="superAdminEmail"
                error={Bro(errors).iCanHaz('superAdminEmail')}
                type="text"
                register={register}
                mt={1}
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <Box mt={4} py={1} bgColor="white" borderRadius="16px">
        <Text
          m={4}
          px={4}
          fontSize="20px"
          fontWeight="bold"
          fontFamily="Raleway"
          color="#24293D"
        >
          {t('email_settings')}
        </Text>
        <Box m={5}>
          <Grid templateColumns="repeat(10, 1fr)" p={4} gap={6} mr={10}>
            <GridItem colSpan={5}>
              <ThemeInput
                label={t('default_from_address')}
                name="defaultEmail"
                error={Bro(errors).iCanHaz('defaultEmail')}
                type="text"
                register={register}
                mt={1}
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <Box mt={4} py={1} bgColor="white" borderRadius="16px">
        <Text
          m={4}
          px={4}
          fontSize="20px"
          fontWeight="bold"
          fontFamily="Raleway"
          color="#24293D"
        >
          {t('locale_settings')}
        </Text>
        <Box m={5}>
          <Grid templateColumns="repeat(10, 1fr)" p={4} gap={6} mr={10}>
            <GridItem colSpan={5}>
            <ThemeAsyncSelectInput
                key={shouldLoadOptions}
                defaultValue={ {name: companyInfo.localeSetting && companyInfo.localeSetting.country || '', slug: companyInfo.localeSetting && companyInfo.localeSetting.countrySlug || ''}}
                promiseOptions={promiseOptions}
                labelKey="name"
                valueKey="slug"
                name="countrySlug"
                control={control}
                error={errors.countrySlug}
                label={t('field_select_country')}
                placeholder={<SearchIcon />}
              />
            </GridItem>
            <GridItem colSpan={5}>
            <MultiSelect
                name={'timeZone'}
                label={t('field_time_zone')}
                placeholder={t('select_timeZone')}
                error={Bro(errors).iCanHaz('timeZone')}
                options={Country.timeZones}
                defaultValue={
                 getSelectedTimeZone(companyInfo.localeSetting && companyInfo.localeSetting.timeZone || '') 
                }
                labelKey={'name'}
                valueKey={'name'}
                control={control}
                isMulti={false}
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <Box mt={4} py={1} bgColor="white" borderRadius="16px">
        <Text
          m={4}
          px={4}
          fontSize="20px"
          fontWeight="bold"
          fontFamily="Raleway"
          color="#24293D"
        >
          {t('display_settings')}
        </Text>
        <Box m={5}>
          <Grid templateColumns="repeat(10, 1fr)" p={4} gap={6} mr={10}>
            <GridItem colSpan={5}>
              <SelectThemeInput
                name="displayName"
                label={t('name_to_be_displayed')}
                labelKey="label"
                valueKey="key"
                options={displayNameType}
                register={register}
                defaultValue={companyInfo.displaySettings && companyInfo.displaySettings.displayName || ''}
                placeholder={t('select_an_option')}
                mt={1}
              />
            </GridItem>
            <GridItem colSpan={5}>
              <SelectThemeInput
                name="dateFormat"
                label={t('date_format')}
                labelKey="label"
                valueKey="key"
                options={typeOfDateFormat}
                register={register}
               defaultValue={companyInfo.displaySettings && companyInfo.displaySettings.dateFormat || ''}
                placeholder={t('select_an_option')}
                type="text"
                mt={1}
              />
            </GridItem>

            <GridItem colSpan={5}>
              <ThemeRadioGroup
                name="timeFormat"
                label={t('time_format')}
                register={register}
                options={typeOfTimeFormat}
                radioGroupValue={Bro(companyInfo).iCanHaz('displaySettings.timeFormat') || null}
                direction="row"
                spacing="20"
                mt={1}
              />
            </GridItem>
          </Grid>
        </Box>
      </Box>
      <Flex my={2}>
        <Button type="submit" px={6}  isLoading={isLoading}>
          {t('button_Submit')}
        </Button>
        <Button
          ml={5}
          variant="ghost"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t('btn_cancel')}
        </Button>
      </Flex>
      </Box>
    </Flex>
  )
  )
};
export default EditCompanyDetails;
