const ROUTES = {
  BASE: '/',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  FORGOT_PASSWORD: '/forgot-password',
  PERSONAL_INFO: 'personal-details',
  BANK_INFO: 'bank-details',
  DEPENDENT_INFO: 'dependent-details',
  EMERGENCY_INFO: 'emergency-details',
  HEALTH_INFO: 'health-details',
  DOCUMENT_INFO: 'document-details',
  DASHBOARD: '/home',
  ONBOARDING: '/onboarding',
  ADD_EMPLOYEE: '/employee/add-new',
  EMPLOYEE_LIST: '/employee',

  ADMIN_DASHBOARD: '/home',
  DOCUMENT_UPLOAD_TABLE: 'document-upload-table',
  DOCUMENT_DASHBOARD: '/employee/document-dashboard/:slug',
  USER_MANAGEMENT: 'user',
  EDIT_DETAILS:'/edit-details/:slug',
  USER_PROFILE:'/profile',
  USER_PROFILE_EDIT: '/profile/edit',

  // LEAVE_LIST: '/leave',
  // USER_LEAVE_LIST: '/leave/user',
  // USER_LEAVE_LIST_FOR_ADMIN: '/leave/user/:userSlug',
  // VIEW_LEAVE: '/leave/:slug',
  // APPLY_LEAVE: '/leave/apply',
  // ADD_LEAVE: '/leave/add-new',
  // LEAVE_STATUS: '/leave/status',
  // LEAVE_STATUS_USER: '/leave/status/:slug',

  // LEAVE_MASTER: '/leave-master',
  // LEAVE_MASTER_CREATE: '/leave-master/add-new',
  // LEAVE_MASTER_VIEW_EDIT: '/leave-master/:slug',

  LEAVE:'/leave',
  MANAGE_LEAVE:'/manage-leave',
  ADD_LEAVE:'/add-leave',
  VIEW_LEAVE:'/view-leave/:slug',
  EDIT_LEAVE:'/edit-leave/:slug',
  USER_LEAVE:'/user/',
  LEAVE_STATUS:'/status/',
  LEAVE_STATUS_USER:'/status/:slug',
  USER_LEAVE_FOR_ADMIN:'/user/:userSlug',
  LEAVE_MASTER:'/master',
  MANAGE_LEAVE_MASTER:'/master/manage',
  MASTER_REQUEST:'/master/request',
  EDIT_REGULARISATION_REQUEST:'/master/edit-request/:slug',

  ATTENDANCE: '/attendance',
  ATTENDANCE_LIST: '/attendance-list',
  MARK_ATTENDANCE: '/mark-attendance',
  MANAGE_ATTENDANCE: '/manage-attendance',
  ATTENDANCE_USER_LIST: '/manage-attendance/:userSlug',
  ATTENDANCE_REGULARISATION: '/regularisation',
  MANAGE_REGULARISATION : '/regularisation/manage',
  REGULARISATION_LIST : '/regularisation/list',
  REGULARISATION_REQUEST: '/regularisation/request',
  ATTN_REGULARISATION_REQUEST_EDIT: '/regularisation/request/:slug',

  HOLIDAY:'/holiday',
  ADD_HOLIDAY:'/add-holiday',
  MANAGE_HOLIDAY:'/manage-holiday',
  EDIT_HOLIDAY:'/edit-holiday/:slug',

  SET_UP:'/set-up',
  GENERAL: '/general',
  COMPANY_DETAILS: '/general/company-details',
  EDIT_COMPANY_DETAILS:'/general/company-details/edit',
  THEME: '/general/theme',
  LOCATION: '/location',
  MANAGE_LOCATION:'/location/manage-location',
  MANAGE_COUNTRIES:'/location/manage-countries',
  ADD_COUNTRY:'/location/add-country',
  EDIT_COUNTRY:'/location/manage-countries/:slug',
  MANAGE_STATE:'/location/manage-state',
  ADD_STATE:'/location/add-state',
  EDIT_STATE:'/location/manage-state/:slug',

  DEPARTMENT: '/department',
  MANAGE_DEPARTMENT:'/department/manage-department',
  ADD_DEPARTMENT:'/department/add-new',
  EDIT_DEPARTMENT:'/department/manage-department/:slug',


  DESIGNATION: '/designation',
  MANAGE_DESIGNATION:'/designation/manage-designation',
  ADD_DESIGNATION:'/designation/add-new',
  EDIT_DESIGNATION:'/designation/manage-designation/:slug',

  ADD_LOCATION:'/location/add-new',
  EDIT_LOCATION:'/location/manage-location/:slug',
  ROLE:'/role',
  ADD_ROLE:'/role/add-new',
  EDIT_ROLE:'/role/manage-role/:slug',
  MANAGE_ROLE:'/role/manage-role',

  DOCUMENT_AND_RECORDS:'/document-records',
  ADD_DOCUMENT_LIST:'/document-records/add-document-list',
  MANAGE_DOCUMENT_LIST:'/document-records/manage-document-list',
  EDIT_DOCUMENT_LIST:'/document-records/manage-document-list/:slug',
  ADD_MEDICAL_RECORDS:'/document-records/add-medical-records',
  MANAGE_MEDICAL_RECORDS:'/document-records/manage-medical-records',
  EDIT_MEDICAL_RECORDS:'/document-records/manage-medical-records/:slug',
  ADD_QUALIFICATION:'/document-records/add-qualification',
  EDIT_QUALIFICATION:'/document-records/manage-qualification/:slug',
  MANAGE_QUALIFICATION:'/document-records/manage-qualification',

  EMPLOYEE_PERSONNEL:'/employee-personnel',
  ADD_EMPLOYEE_STATUS:'/employee-personnel/add-employee-status',
  MANAGE_EMPLOYEE_STATUS:'/employee-personnel/manage-employee-status',
  EDIT_EMPLOYEE_STATUS:'/employee-personnel/manage-employee-status/:slug',
  ADD_EMPLOYEE_TYPE:'/employee-personnel/add-employee-type',
  MANAGE_EMPLOYEE_TYPE:'/employee-personnel/manage-employee-type',
  EDIT_EMPLOYEE_TYPE:'/employee-personnel/manage-employee-type/:slug',
  ADD_SOURCE_OF_HIRE:'/employee-personnel/add-source-of-hire',
  EDIT_SOURCE_OF_HIRE:'/employee-personnel/manage-source-of-hire/:slug',
  MANAGE_SOURCE_OF_HIRE:'/employee-personnel/manage-source-of-hire',

  PAYROLL: '/payroll',
  DEFINE_PAYROLL:'/define-payroll',
  MANAGE_PAYROLL:'/manage-payroll',
  EDIT_PAYROLL:'/manage-payroll/:slug',
  EMPLOYEE_PAYROLL:'/user',
  EMPLOYEE_PAYROLL_FOR_ADMIN:'/user/:userSlug',
  STRUCTURE:'/structure',
  ADD_SALARY_STRUCTURE:'/structure/add',
  MANAGE_SALARY_STRUCTURE:'/structure/manage',
  EDIT_SALARY_STRUCTURE:'/structure/manage/:slug',
  SALARY_COMPONENTS:'/components',
  ADD_SALARY_COMPONENTS:'/components/add',
  MANAGE_SALARY_COMPONENTS:'/components/manage',
  EDIT_SALARY_COMPONENTS:'/components/manage/:slug',
};

export default ROUTES;
