import { storage, useFormController } from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import {
  Box,
  Button,
  Center,
  Flex,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import SideBar from '../../components/misc/sidebar';

import Header from '../../components/misc/header';
import { AppConstants } from '../../utils/constants';
import PersonalInfo from '../onboarding/steps/personal-info/personal-info';
import WorkExperience from '../onboarding/steps/personal-info/work-experience';
import SummaryInfo from '../onboarding/steps/personal-info/summary-info';
import WorkInformation from '../onboarding/steps/personal-info/work-info';
import EducationInfo from '../onboarding/steps/personal-info/education-info';
import BankInfo from '../onboarding/steps/personal-info/bank-info';
import DependentInfo from '../onboarding/steps/personal-info/dependent-info';
import EmergencyInfo from '../onboarding/steps/personal-info/emergency-info';
import HealthInfo from '../onboarding/steps/personal-info/health-info';
import DocumentInfo from '../onboarding/steps/document-upload/document-info';
import UploadedDoc from '../onboarding/steps/document-upload/uploaded-documents';
import { ArrowBackIcon } from '@chakra-ui/icons';
import DataService from '../../services/data-service';
import Bro from 'brototype';
import { getImageUrl } from '../../utils/helpers';
import AlertDialogModal from '../../components/alert-dialog/alert-dialog';
import PerfectScrollbar from 'react-perfect-scrollbar';


const TOTAL_STEP = 11;
const stepObj = t => {
  return [
    {
      label: t('profile_info'),
      step: 0,
      steps: [
        {
          label: t('basic_info_label'),
          step: 0,
        },
        {
          label: t('work_exp_label'),
          step: 1,
        },
        {
          label: t('summary'),
          step: 2,
        },
        {
          label: t('work_info_label'),
          step: 3,
        },
        {
          label: t('sidebar_education'),
          step: 4,
        },
        {
          label: t('sidebar_bank_info'),
          step: 5,
        },
        {
          label: t('sidebar_dependents'),
          step: 6,
        },
        {
          label: t('sidebar_emergency'),
          step: 7,
        },
        {
          label: t('medical_records_label'),
          step: 8,
        },
        {
          label: t('sidebar_doc_upload'),
          step: 9,
        },
      ],
    },
    {
      label: t('sidebar_uploaded_doc'),
      step: 10,
    },
  ];
};
const EditUserDetails = ({ slug }) => {
  const { t } = useI18nContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [step, setStep] = useState(0);

  const next = () => {
    if (step < TOTAL_STEP - 1) setStep(step + 1);
  };
  const previous = () => {
    if (step >= 1) setStep(step - 1);
  };

  const onClick = stepVal => {
    setStep(stepVal);
  };

  const [userData, setUserData] = useState(null);
  const getUserInfo = () => {
    DataService.get(`/user/${slug}`).then(resp => {
      const data = Bro(resp).iCanHaz('data.data.personalInfo');
      setUserData(data);
    });
  };

  useEffect(getUserInfo, []);

  const getStep = step => {
    switch (step) {
      case 0:
        return (
          <PersonalInfo
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userData={userData}
            isEdit
          />
        );
      case 1:
        return (
          <WorkExperience
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userData={userData}
            isEdit
          />
        );
      case 2:
        return (
          <SummaryInfo
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userData={userData}
            isEdit
          />
        );
      case 3:
        return (
          <WorkInformation
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userData={userData}
            isEdit
          />
        );
      case 4:
        return (
          <EducationInfo
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userData={userData}
            isEdit
          />
        );
      case 5:
        return (
          <BankInfo
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userData={userData}
            isEdit
          />
        );
      case 6:
        return (
          <DependentInfo
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userDataInfo={userData}
            isEdit
          />
        );
      case 7:
        return (
          <EmergencyInfo
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userDataInfo={userData}
            isEdit
          />
        );
      case 8:
        return (
          <HealthInfo
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userData={userData}
            isEdit
          />
        );
      case 9:
        return (
          <DocumentInfo
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userData={userData}
            isEdit
          />
        );
      case 10:
        return (
          <UploadedDoc
            t={t}
            next={next}
            prev={previous}
            userSlug={slug}
            userData={userData}
            isEdit
          />
        );
      default:
        return <></>;
    }
  };

  const tenantBackground = getImageUrl(
    storage.get(AppConstants.AUTH.TENANT).assets.logoImageUrl
  );
  return (
    <Flex minH={'100vh'}>
      <Box
        w="22vw"
        h="100vh"
        maxW={360}
        overflowY="auto"
        position="sticky"
        top="0"
        // background="white url(/assets/img/sidebar-footer-banner.png) no-repeat bottom left/contain"
      >
         <PerfectScrollbar>
        <Box>
          <Flex justifyContent="center">
            <Box
              mx={'120px'}
              my={'30px'}
              w="30vw"
              h="15vh"
              position="sticky"
              top="0"
              background={`url(${tenantBackground}) no-repeat left/contain`}
            ></Box>
          </Flex>
          <Spacer />
          <SideBar
            onboardingStep={10}
            currentStep={step}
            stepObj={stepObj(t)}
            onClick={onClick}
          />
        </Box>
        <Box mb={5}>
          <Center><Button
        _focus="none"
          leftIcon={<ArrowBackIcon />}
          _focus="none"
          variant="ghost"
          onClick={() => {
            onOpen();
          }}
        >
          {t('Back to Main Menu')}
        </Button>
        </Center>
        </Box>
        </PerfectScrollbar>
      </Box>
      <Box
        flex={1}
        px="5vw"
        pt={18}
        pb={20}
        bgColor="#F5F6F9"
        direction="column"
      >
        <Box marginLeft="30vw" marginBottom="2em">
          <Header />
        </Box>
        {getStep(step)}
        
      </Box>
      <AlertDialogModal
        isOpen={isOpen}
        showCloseBtn={false}
        scrollBehavior="inside"
        closeOnOverlayClick={false}
        colorScheme="red.500"
        bodyContent={<ExitMenu onClose={onClose}/>}
        cancelRef
        showCloseBtn
      />
    </Flex>
  );
};
export default EditUserDetails;

export const ExitMenu = ({onClose}) => {
  const navigate = useNavigate();
  const { t } = useI18nContext();
  const { isLoading } = useFormController({});
  return (
    <>
      <Flex flexDirection="column" py={1} px={3}>
        <Text fontSize="16px" fontWeight="600" fontStyle="raleway">
          {t('exit_alert_msg_edit_profile')}
        </Text>
        <Flex justifyContent="flex-end" alignItems="center" mt={10}>
          <Button variant="ghost" _focus="none"  onClick={() => {
            onClose();
          }}>{t('btn_cancel')}</Button>
          <Button
            ml={2}
            px={10}
            onClick={() => {
              navigate('/home/employee');
            }}
            isLoading={isLoading}
          >
            {t('button_text_exit')}
          </Button>
        </Flex>
      </Flex>
    </>
  );
};
