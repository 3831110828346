import { storage } from '@bit/sixsprints.core.beans-web';
import { Text } from '@chakra-ui/layout';
import Bro from 'brototype';
import { AppConstants } from "./constants";
import { getDateFormatter } from './helpers';


export const getDefaultAllowanceFromRules = (leaveMasterRules) => {
  if (leaveMasterRules.length > 1) {
    return '-';
  }
  return leaveMasterRules[0]['leaveCount'];
  // for (let i = 0; i < leaveMasterRules.length; i++) {
  //   if (leaveMasterRules[i]['leaveRuleApplicable'] === AppConstants.LEAVE.LEAVE_RULE_APPLICABLE.ALL) {
  //     return leaveMasterRules[i]['leaveCount'];
  //   }
  // }
  // return '-';
};


export const getDefaultPayTypeFromRules = (leaveMasterRules) => {
  var payTypeMap = {};
  for (let i = 0; i < leaveMasterRules.length; i++) {
    payTypeMap[leaveMasterRules[i]['payType']] = 1;
  }

  if (Object.keys(payTypeMap).length === 1) {
    const leavePayTypeOptions = storage.get(AppConstants.AUTH.CONFIG).leavePayType;
    let val = Object.keys(payTypeMap)[0];
    return (leavePayTypeOptions.find(d => d.key === val) || { label: '-' }).label;
  }

  return '-';
};


export const getDefaultLocationFromRules = (leaveMasterRules, t) => {
  return leaveMasterRules.length > 1 ? '-' : t('all');
};

export const getAttendanceStatus = (data) => {
  let label; let color;
  if (data.isPresent) {
    label = AppConstants.ATTENDANCE_TYPE.PRESENT.label;
    color = AppConstants.ATTENDANCE_TYPE.PRESENT.color;
  }
   else if (data.isHoliday) {
    label = Bro(data).iCanHaz("holidayName");
    color = Bro(data).iCanHaz("holidayColor");
  } else if (data.isWeekend) {
    label = AppConstants.ATTENDANCE_TYPE.WEEKEND.label;
    color = AppConstants.ATTENDANCE_TYPE.WEEKEND.color;
  } if (data.isRegularizationRequested) {
    label = AppConstants.ATTENDANCE_TYPE.REG_REQUESTED.label;
    color = AppConstants.ATTENDANCE_TYPE.REG_REQUESTED.color;
  } else if(data.leaveName) {
    label = Bro(data).iCanHaz("leaveName");
    color = Bro(data).iCanHaz("leaveColor");
  }
  return { label, color };
}


export const getDefaultholidayType = (data, t) => {
  return data.length > 1 ? '-' : <Text>{data[0].holidayType}</Text>;
}

export const getDefaultDepartmentFromRules = (data, t) => {
  return data.length > 1 ? '-' : t('all');
}

export const getDefaultDateFromRules = (data, t) => {
  return data.length > 1 ? '-' : <Text>{getDateFormatter(data[0].fromDate, 'DD/MM/YYYY')}</Text>;
}

export const getDefaultDatetoFromRules = (data, t) => {
  return data.length > 1 ? '-' : <Text>{getDateFormatter(data[0].toDate, 'DD/MM/YYYY')}</Text>;
}

export const getDefaultApplicableForRules = (data) => {
  return data.length > 1 ? '-' : <Text>{data[0].holidayRuleApplicable}</Text>
}

export const getDepartmentLeadName = (data) =>{
  return data!==null ? <Text>{data.firstName+' '+data.lastName}</Text>:'-';
}