import { Flex, Image, Text } from '@chakra-ui/react';
import { getFirstAndLastCharacterInString } from '../../utils/helpers';

const defaultContainerStyle = {
  overflow: 'hidden',
  background: '#acacac',
};

const defaultTextStyle = {
  color: '#2b2b2b2',
  fontSize: '26px',
  fontWeight: 'bold',
  letterSpacing: 4,
};

const ProfilePic = ({
  containerProps,
  textProps,
  containerStyle,
  textStyle,
  personalInfo,
}) => {
  const { firstName, lastName, photo } = personalInfo || {};
  return (
    <>
      {photo?.url ? (
        <Flex justify="center" align="center" boxSize="50px"  borderRadius="full"  style={{ ...defaultContainerStyle, ...containerStyle }}
        {...containerProps}>
          <Image src={photo?.url || ''} alt="profile-pic" />
        </Flex>
      ) : (
        <Flex
          justify="center"
          align="center"
          boxSize="50px"
          borderRadius="full"
          style={{ ...defaultContainerStyle, ...containerStyle }}
          {...containerProps}
        >
          <Text style={{ ...defaultTextStyle, ...textStyle }} {...textProps}>
            {getFirstAndLastCharacterInString({
              _firstName: firstName || '',
              _lastName: lastName || '',
            })}
          </Text>
        </Flex>
      )}
    </>
  );
};

export default ProfilePic;
