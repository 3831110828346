import React from 'react';
import { Flex, HStack, Input } from '@chakra-ui/react';
import { Button } from '@chakra-ui/react';
import AddNewAction from './add-new-action';

export const ActionContainer = props => (
  <Flex my={1} justify="flex-end">
    <HStack spacing={6}>
      {props.children}
      {!props.hideAddNew && <AddNewAction addNewBtnText={props.addNewBtnText} addNewPath={props.addNewPath}/>}
    </HStack>
  </Flex>
);

export const GridButton = ({ children, ...props }) => (
  <Button size={'sm'} fontSize={'sm'} {...props}>
    {children}
  </Button>
);

export const GridInput = props => <Input {...props} fontSize="sm" />;
