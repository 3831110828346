import { Box, Flex, Text } from '@chakra-ui/react';
import ThemeHeading from '../../../../components/misc/heading';
import Bro from 'brototype';
import { useFormController } from '@bit/sixsprints.core.beans-web';
import DataService from '../../../../services/data-service';
import { useEffect, useState } from 'react';
import UploadDocView from './upload-doc-view';
import Loader from '../../../../components/misc/loader';
import useNotify from '../../../../utils/notifications';



const UploadedDoc = ({ t, userSlug, userData }) => {
  const toast = useNotify()

   const {
    register,
    setValue,
    watch,
  } = useFormController({
  
  });


  const [isDocLoading, setIsDocLoading] = useState(false);

  const [tenthEducationDocUpload,setTenthEducationDocUpload] = useState([]);

  const [twelfthEducationDocUpload,setTwelfthEducationDocUpload] = useState([]);

  const [educationDocUpload, setEducationDocUpload] = useState([]);

  const [healthCheckUpInfo,setHealthCheckUpInfo]=useState(null);    
  
  const [healthDoc,setHealthDoc] = useState([]);

  const [otherDoc,setOtherDoc] = useState([]);

  const [otherDocArr,setOtherDocArr] = useState([]);

  const [tenthInfo,setTenthInfo] = useState([]);

  const [twelfthInfo,setTwelfthInfo] = useState(null);


  const getUserInfo = () => {
    setIsDocLoading(true);

    DataService.get(`/user/${userSlug}`)
      .then(resp => {
        const tenthDocumentsArr =
          Bro(resp).iCanHaz('data.data.educationDetails.details10.documents') || [];
          setTenthEducationDocUpload(tenthDocumentsArr)
        const tenthInfo =  Bro(resp).iCanHaz('data.data.educationDetails.details10') || [];  
        setTenthInfo(tenthInfo);

        const twelthDocumentArr =  Bro(resp).iCanHaz('data.data.educationDetails.details12.documents') || []; 
        setTwelfthEducationDocUpload(twelthDocumentArr)
        const twelfthInfo =  Bro(resp).iCanHaz('data.data.educationDetails.details12') || [];  
        setTwelfthInfo(twelfthInfo);

        const graduatioDocumentArr = Bro(resp).iCanHaz('data.data.educationDetails.degreeDtails') ||[];

        let doc= graduatioDocumentArr.map((item)=>(item.certificate.map(newItem=>newItem)))
        let newArray=Array.prototype.concat.apply([], doc);

        const education = [...tenthDocumentsArr,...twelthDocumentArr,...newArray]
        setEducationDocUpload(education);

        const healthCheckUpInfo = Bro(resp).iCanHaz('data.data.healthCheckup') || []; 
        setHealthCheckUpInfo(healthCheckUpInfo)

        const healthDocsArr =  Bro(resp).iCanHaz('data.data.healthCheckup.documents') || [];
        setHealthDoc(healthDocsArr)

        const otherDocArr =  Bro(resp).iCanHaz('data.data.documentUpload.documents') || [];
        setOtherDoc(otherDocArr)
        setOtherDocArr(otherDocArr)
        setIsDocLoading(false);
      })
      .catch(() => {
        setIsDocLoading(false);
      });
  };


  useEffect(getUserInfo, []);

  const onChangeForOtherDoc=()=>{
    
  DataService.put(`/user/document-upload?userSlug=${userSlug}`,{'documents':otherDoc.length>0 ? otherDoc : otherDocArr})
    .then(resp => {
      toast({
        title: "Status Updated",
        status: "success"
      });
    })
    .catch((ex) => {
      toast({
        title: "Error",
        status: "error",
        description: Bro(ex).iCanHaz('message')
      });
    })

  }
 
  const onChangeForHealthDoc=()=>{

    DataService.put(`/user/health-checkup?userSlug=${userSlug}`,{
      'bloodPressure': healthCheckUpInfo && healthCheckUpInfo.bloodPressure,
      'bloodGroup': healthCheckUpInfo && healthCheckUpInfo.bloodGroup,
      'diabetes': healthCheckUpInfo && healthCheckUpInfo.diabetes,
      'allergies': healthCheckUpInfo && healthCheckUpInfo.bloodGroup,
      'documents': healthDoc})
    .then(resp => {
      toast({
        title: "Status Updated",
        status: "success"
      });
    })
    .catch((ex) => {
      toast({
        title: "Error",
        status: "error",
        description: Bro(ex).iCanHaz('message')
      });
    });
  }

  const onChangeForTenthDoc=()=>{
    DataService.put(`/user/education-details-tenth?userSlug=${userSlug}`,{
     ...tenthInfo,documents:tenthEducationDocUpload})
    .then(resp => {
      toast({
        title: "Status Updated",
        status: "success"
      });
    })
    .catch((ex) => {
      toast({
        title: "Error",
        status: "error",
        description: Bro(ex).iCanHaz('message')
      });
    });
  }

  const onChangeForTwelfthDoc=()=>{
    DataService.put(`/user/education-details-twelfth?userSlug=${userSlug}`,{
     ...twelfthInfo,documents:twelfthEducationDocUpload})
    .then(resp => {
      toast({
        title: t('label_status_updated'),
        status: "success"
      });
    })
    .catch((ex) => {
      toast({
        title: "Error",
        status: "error",
        description: Bro(ex).iCanHaz('message')
      });
    });
  }


  const columnData = ['Document Type', 'Uploaded Date', 'Status', 'Action']


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    isDocLoading ? (
      <Flex justify="center" alignItems="center">
        <Loader />
      </Flex>
    ) : (
    <Flex flexGrow={1}>
      <Flex flex={1} direction="column" mr={'10em'}>
        <ThemeHeading
          heading={`${Bro(userData).iCanHaz('firstName')} ${t(
            'edit_profile_header_text'
          )}`}
          subHeading={t('details_subheading')}
          mb={5}
        />

        {tenthEducationDocUpload.length>0 && <Box>
          <Text font-family="Raleway" fontWeight="bold" fontSize="16px" my={2}>
            {t('tenth_education')}
          </Text>
          <UploadDocView
            columns={columnData}
            rows={tenthEducationDocUpload}
            formSetValue={setValue}
            formWatcher={watch}
            register={register}
            setTenthEducationDocUpload={setTenthEducationDocUpload}
            onChangeForTenthDoc={onChangeForTenthDoc}
            // canReview={isEdit}
          />
        </Box>}

        {twelfthEducationDocUpload.length>0 && <Box my={tenthEducationDocUpload.length>0 ? 5 : 0}>
          <Text font-family="Raleway" fontWeight="bold" fontSize="16px" my={2}>
            {t('twelfth_education')}
          </Text>
          <UploadDocView
            columns={columnData}
            rows={twelfthEducationDocUpload}
            formSetValue={setValue}
            formWatcher={watch}
            register={register}
            setTwelfthEducationDocUpload={setTwelfthEducationDocUpload}
            onChangeForTwelfthDoc={onChangeForTwelfthDoc}
            // canReview={isEdit}
          />
        </Box>}

        {healthDoc.length>0 && <Box my={educationDocUpload.length>0 ? 5 : 0}>
          <Text font-family="Raleway" fontWeight="bold" fontSize="16px" my={2}>
            {t('medical_records_label')}
          </Text>
          <UploadDocView
            columns={columnData}
            rows={healthDoc}
            formSetValue={setValue}
            formWatcher={watch}
            register={register}
            setHealthDoc={setHealthDoc}
            onChangeForHealthDoc={onChangeForHealthDoc}
            // canReview={isEdit}
          />
        </Box>}

        {otherDoc.length>0 && <Box my={healthDoc.length>0 ? 5 : 0}>
          <Text font-family="Raleway" fontWeight="bold" fontSize="16px" my={2}>
           {t('sidebar_doc_upload')}
          </Text>
          <UploadDocView
            columns={columnData}
            rows={otherDoc}
            formSetValue={setValue}
            formWatcher={watch}
            register={register}
            setOtherDoc={setOtherDoc}
            onChangeForOtherDoc={onChangeForOtherDoc}
            // canReview={isEdit}
          />
        </Box>}
      </Flex>
    </Flex>
  ));
};
export default UploadedDoc;
