import {
  authData,
  useFormController,
  storage,
  restApi,
} from '@bit/sixsprints.core.beans-web';
import { useI18nContext } from '@bit/sixsprints.utils.i18n';
import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { Link, useNavigate } from '@reach/router';
import Bro from 'brototype';
import ThemeInput from '../../components/form-elements/theme-input';
import LangSelect from '../../components/misc/lang-select';
import { loginSubject } from '../../services/data-service';
import { AppConstants } from '../../utils/constants';
import useNotify from '../../utils/notifications';
import { LoginValidationSchema } from '../../utils/validations';
import ROUTES from '../routes';

const apiOptions = {
  url: '/auth/user/login',
  method: 'post',
};

const Login = () => {
  const navigate = useNavigate();
  const toast = useNotify();
  const { t } = useI18nContext();
  // const tenantBackground = storage.get(AppConstants.AUTH.TENANT).assets
  //   .sideBannerImageUrl;

  const onSuccess = resp => {
    authData.setAuthData({
      user: Bro(resp).iCanHaz('data'),
      token: Bro(resp).iCanHaz('token'),
    });
    storage.set(AppConstants.AUTH.PERMISSION, Bro(resp).iCanHaz('permissions'));
    storage.set(AppConstants.AUTH.ROLE, Bro(resp).iCanHaz('roleName'));
    restApi.addGlobalHeader(
      AppConstants.AUTH.AUTH_TOKEN_KEY,
      Bro(resp).iCanHaz('token')
    );
    loginSubject.next(true);
    navigate(ROUTES.ONBOARDING, { replace: true });
  };

  const onError = resp => {
    toast({
      title: t('Error'),
      description: resp.message,
      status: 'error',
    });
  };

  const { isLoading, onSubmit, register, errors } = useFormController({
    onSuccess,
    onError,
    apiOptions,
    yupSchema: LoginValidationSchema(t),
  });

  return (
    <Flex h="100vh" bgColor="#F5F6F9">
      <Box position="absolute" top="1em" marginLeft="10vw">
        <LangSelect />
      </Box>
      <Flex flex={1} px={{base: 10, sm:12, md: 20, lg: 32}} direction="column" justify="center">
        <Heading as="h1" size="lg">
          {t('login_heading')}
        </Heading>
        <Box as={'form'} maxW={440} onSubmit={onSubmit} noValidate="noValidate">
          <Stack spacing={6} my={16}>
            <ThemeInput
              label={t('login_form_label_email')}
              name="email"
              error={errors.email}
              register={register}
              type="email"
              isRequired
            />

            <ThemeInput
              label={t('login_form_label_password')}
              name="password"
              error={errors.password}
              register={register}
              isPasswordInput
              isRequired
            />

            <Text
              fontSize="sm"
              fontWeight="normal"
              alignSelf="flex-end"
              fontFamily="Open Sans"
              color="brand.500"
            >
              <Link
                to={ROUTES.FORGOT_PASSWORD}
                style={{
                  fontFamily: 'Open Sans',
                  fontWeight: '400',
                  color: 'brand.500',
                  fontSize: { md: '12px', xl: '16px' },
                  lineHeight: { md: '14px', xl: '22px' },
                }}
              >
                {t('login_forgot_password')}
              </Link>
            </Text>

            <Button type="submit" isLoading={isLoading} w="full">
              <Text fontSize="sm" fontWeight="bold" fontFamily="raleway">
                {t('login_submit')}
              </Text>
            </Button>
          </Stack>
        </Box>
      </Flex>
      <Box
        display={{base: "none", sm:"none", md: "flex"}}
        flex={2}
        h="full"
        bgImage="url('/assets/img/login-side-banner.png')"
        bgRepeat="no-repeat"
        bgSize="100% 100%"
      />
    </Flex>
  );
};

export default Login;
