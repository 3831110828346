import { restApi as Api } from '@bit/sixsprints.core.beans-web';
import { BehaviorSubject } from 'rxjs';
import { storage } from '@bit/sixsprints.core.beans-web';
import { Keys } from '@bit/sixsprints.utils.data';

const apiOptions = {
  url: '',
  method: '',
};

const DataService = {
  get(url) {
    apiOptions.url = url;
    apiOptions.method = 'get';
    return Api.fetch({ ...apiOptions });
  },

  post(url, data) {
    apiOptions.url = url;
    apiOptions.method = 'post';
    return Api.fetch({ ...apiOptions, data });
  },

  put(url, data) {
    apiOptions.url = url;
    apiOptions.method = 'put';
    return Api.fetch({ ...apiOptions, data });
  },

  delete(url) {
    apiOptions.url = url;
    apiOptions.method = 'delete';
    return Api.fetch({ ...apiOptions });
  },
};

export default DataService;
