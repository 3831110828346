import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react';
import SvgEyeOff from '../misc/eye-off-svg';
import SvgEye from '../misc/eye-svg';

const ThemeInput = ({
  name,
  register,
  label,
  error,
  isPasswordInput = false,
  isRequired,
  icon,
  ...rest
}) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={isRequired}>
      <FormLabel fontFamily="Open Sans" fontWeight="small" mb="14px">
        {label}
      </FormLabel>

      <InputGroup size="md">
        <Input
          name={name}
          focusBorderColor="brand.300"
          ref={register}
          onBlur={e => e.target.value = e.target.value.trim()}
          type={show ? 'text' : 'password'}
          {...rest}
        />
        {isPasswordInput && (
          <InputRightElement>
            <IconButton
              size="sm"
              boxShadow="none"
              variant="ghost"
              hover="none"
              _focus="none"
              icon={show ? <SvgEyeOff /> : <SvgEye />}
              onClick={handleClick}
            />
          </InputRightElement>
        )}
      </InputGroup>

      {error && (
        <FormErrorMessage fontFamily="Open Sans" fontSize="12px">
          {error.message}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default ThemeInput;
