import { useI18nContext } from '@bit/sixsprints.utils.i18n/dist/language-context';
import { Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';

const ListHeader = ({ msg, index, onBtnClick }) => {
  const { t } = useI18nContext();
  return (
    <Flex
      bgColor="#EAEEF4"
      border="1px solid #E2E3E5"
      mb={10}
      px={5}
      py={2}
      borderRadius="10px"
      justifyContent="space-between"
      alignItems="center"
      mt={index > 1 ? 10 : 0}
    >
      <Text
        color="#818081"
        fontFamily="Raleway"
        fontStyle="normal"
        fontWeight="500"
        fontSize="sm"
      >
        {msg}
      </Text>
      {index > 1 && (
        <Button
          h={'25px'}
          variant="ghost"
          color="red.500"
          fontFamily="Raleway"
          fontStyle="normal"
          fontWeight="500"
          fontSize="sm"
          onClick={onBtnClick}
        >
          {t('button_remove')}
        </Button>
      )}
    </Flex>
  );
};

export default ListHeader;
