import React from "react";

function ProfileSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#ACACAC"
        d="M14 17.703c5.062 0 9.333.822 9.333 3.996s-4.3 3.968-9.333 3.968c-5.06 0-9.333-.823-9.333-3.996 0-3.175 4.3-3.968 9.333-3.968zm0-15.37a6.152 6.152 0 016.176 6.173A6.153 6.153 0 0114 14.68a6.154 6.154 0 01-6.176-6.174A6.153 6.153 0 0114 2.333z"
      ></path>
    </svg>
  );
}

export default ProfileSvg;
