import React, { useEffect, useState } from 'react';
import { Select } from '@chakra-ui/react';
import { restApi, storage } from '@bit/sixsprints.core.beans-web';
import { AppConstants } from '../../utils/constants';
import DataService from '../../services/data-service';
import { useLangSelect } from '@bit/sixsprints.utils.i18n';
import { Keys } from '@bit/sixsprints.utils.data';
import { BehaviorSubject } from 'rxjs';

export const localStorageSubject = new BehaviorSubject(
  !!storage.get(Keys.TOKEN)
);

const LangSelect = props => {
  const { languages, currLang, onChange } = useLangSelect();
  const [lang, setLang] = useState(storage.get(AppConstants.AUTH.LANG_CODE));

  useEffect(() => {
    DataService.get('master-data')
      .then(resp => {
        storage.set(AppConstants.AUTH.CONFIG, resp.data.data);
        localStorageSubject.next(resp.data.data);
      });
  }, [lang]);

  return (
    <Select
      size="sm"
      bg="white"
      borderRadius="6px"
      onChange={e => {
        onChange(e.target.value);
        restApi.addGlobalHeader(
          AppConstants.AUTH.ACCEPT_LANGUAGE,
          e.target.value
        );
        setLang(e.target.value);
      }}
      value={currLang}
    >
      {Object.keys(languages).map((lang, i) => (
        <option key={i} value={lang}>
          {lang.toUpperCase()}
        </option>
      ))}
    </Select>
  );
};

export default LangSelect;
