import { Button } from "@chakra-ui/button";
import { AddIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/layout";
import React from "react";
// import colors from "../../theme/foundations/colors";

const ThemeButton = ({ bg, color, hoverBg, text, onClick, iconProp, ...rest }) => {
    const CustomAddIcon = () => {
        return (
            <Box  {...iconProp}   borderRadius={10} bg={color || "brand.500"}>
                <AddIcon  color="white" />
            </Box>
        )
    }


    return (
        <Button  {...rest}  onClick={onClick}  _disabled={{cursor:'not-allowed', bg:bg || "#E4EEFF"}} _hover={{bg: hoverBg || "#F2D7DA"}} color={color || "brand.500"} bg={bg || "#E4EEFF"}>
            <CustomAddIcon />
            {text}
        </Button>
    )
}

export default ThemeButton;