import moment from 'moment';
import { storage } from '@bit/sixsprints.core.beans-web';
import { Keys } from '@bit/sixsprints.utils.data';
import Bro from 'brototype';
import { AppConstants } from './constants';
import { Country } from '../pages/set-up/location/country/country-data';

export const getDateFormatter = (date, format) => {
  if (date) {
    return moment(date).format(format);
  }
  return '';
};

export const firstCharacter = name => {
  if (name) {
    let val = name.split(' ');
    return val[0].charAt(0);
  }
  return '';
};

export const toTitleCase = val => {
  let arr = val.toLowerCase().split(' ');
  arr = arr.map(item => item[0].toUpperCase() + item.slice(1));
  return arr.join(' ');
};

export const getFirstName = name => {
  if (name) {
    let [firstName] = name.split(' ');
    return firstName;
  }
  return '';
};

export const getFirstAndLastCharacterInString = ({
  name,
  _firstName = '',
  _lastName = '',
}) => {
  let firstName = _firstName,
    lastName = _lastName;

  if (name) {
    firstName = name.split(' ')[0];
    lastName = name.split(' ')[1];
  }

  const [F = ''] = firstName;
  const [L = ''] = lastName;

  return `${F}${L}`.toUpperCase();
};

export const LastCharacter = name => {
  let val = name.split(' ');
  return val.length > 1 ? val[val.length - 1].charAt(0) : '';
};

export const pluralizer = count => (count <= 1 && count >= -1 ? '' : 's');

export const getStringOfArr = arr => {
  if (arr) {
    return arr.join(', ');
  }
  return 'No data';
};

export const getUser = () => {
  return Bro(storage.get(Keys.USER)).iCanHaz('data')
    ? Bro(storage.get(Keys.USER)).iCanHaz('data')
    : storage.get(Keys.USER);
};

export const getUserPersonalInfo = () => {
  const { personalInfo } = getUser();
  return personalInfo;
};
export const getFullNameFromObject = personalInfo => {
  return personalInfo.firstName + ' ' + personalInfo.lastName;
};

export const getFullName = (data) => {
  return getFullNameFromObject( data ? data : getUserPersonalInfo());
};

export const roundToNearestHalf = num => Math.round(num * 2) / 2;

export const isAdmin = () => {
  const role = storage.get(AppConstants.AUTH.ROLE);
  return role === AppConstants.ROLE.ADMIN;
}

export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}

export function getMonthStartDate(month) {
  return month ? moment(month).clone().startOf('month').valueOf() : moment().clone().startOf('month').valueOf();
}

export function getMonthEndDate(month) {
  return month ? moment(month).clone().endOf('month').valueOf() : moment().clone().endOf('month').valueOf();
}

export function getParsedErrorMessage(err) {
  return Bro(err).iCanHaz('response.data.message');
}
 
export const isUndefinedOrNull = obj => {
  return typeof obj === 'undefined' || obj === null;
};
 
export const isUndefinedOrNullOrEmpty = obj => {
  return typeof obj === 'undefined' || obj === null || obj === '';
};

export const getColorOrDefault = color => {
  return isUndefinedOrNullOrEmpty(color) ? '' : color;
};

export const getStringFromUTC = (utc)=>{
  let obj = Country.timeZones.find(item => item.offset === utc );
  console.log(obj.name, utc);
  return obj ? obj.name : null;
}

export const getSelectedTimeZone = timeZone => {
  let filteredTimezone = Country.timeZones.filter(item => item.name === timeZone.name);
  return filteredTimezone[0];
};

export const getSelectedCountryFromList = (list, slug) => {
  let filteredCountry = list.filter(item => item.slug === slug);
  return filteredCountry[0];
};


export const getImageUrl = (url) => {
  return `${url}&${AppConstants.AUTH.AUTH_TOKEN_KEY}=${storage.get(
    Keys.TOKEN
  )}`
}

export const getDateDuration = (startDate, endDate) => {
  var startingDate = moment(startDate, 'DD-MM-YYYY');
  var endingDate = moment(endDate, 'DD-MM-YYYY');
  let duration = endingDate.diff(startingDate, 'days');
  return duration;
};
